import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Container,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper,
} from "@mui/material";
import CampaignIcon from "@mui/icons-material/Campaign";
import QuoteIcon from "@mui/icons-material/FormatQuote";
import WarningIcon from "@mui/icons-material/Warning";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
  overflowX: "auto",
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

function IsBasvuruFormuContents() {
  const [open, setOpen] = useState(false);

  const handleStajClick = () => {
    window.location.href =
      "https://bulut.kardemir.com/app/tr-TR/Form/Paylas/KardemirBulut/b7fb0318-19d6-4417-a89c-8ac2ad65c7ca";
  };

  const handleIsClick = () => {
    window.location.href =
      "   https://www.linkedin.com/company/sosyalkardemir/jobs/";
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 1 }}>
      <Header variant="h4" color="#1f2e5e">
        İş Başvuru Formu
      </Header>

      <StyledPaper elevation={3}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CampaignIcon fontSize="large" />
          <Typography
            sx={{
              marginBottom: "1rem",
              fontWeight: 600,
              fontSize: { xs: "1.5rem", lg: "2rem" },
            }}
          >
            Genel Bilgilendirme
          </Typography>
        </Box>

        <Box
          sx={{
            padding: { xs: "0 1rem", lg: "0 4rem 1rem 0" },
            textAlign: "justify",
          }}
        >
          <Typography>
            Genel Başvuruda, bilgileriniz açık iş pozisyonlarında
            değerlendirilmek üzere veritabanımızda 2 yıl süre ile kayıt altında
            saklanacak ve yalnızca ihtiyaç duyulması durumunda sizinle irtibata
            geçilecektir.
          </Typography>
          <br />
          <Typography>
            Staj başvuruları ise yalnızca staj başvuru dönemlerinde aktif olarak
            kullanılabilir. Staj başvuru tarihleri gerek web sayfamız üzerinden,
            gerekse sosyal medya hesaplarımız üzerinden duyurulacaktır.
          </Typography>
          <br />
          <Typography>
            İnternet sitemiz üzerinden bilgilerinizi doldurarak sistemimize
            kayıt olabilir, kayıt işlemi gerçekleştikten sonra staj veya genel
            başvuru yapabilirsiniz.
          </Typography>
        </Box>

        <Container
          sx={{ textAlign: "center", paddingTop: 4, paddingBottom: 4 }}
        >
          <Box
            sx={{
              backgroundColor: "#1f2e5e",
              color: "#fff",
              paddingTop: 4,
              paddingBottom: 4,
            }}
          >
            <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
              <QuoteIcon fontSize="large" /> Köklü Geçmiş, Güçlü Gelecek
            </Typography>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              <Typography
                variant="h4"
                gutterBottom
                sx={{ textDecoration: "underline" }}
              >
                Başvuru Seçeneklerimiz
              </Typography>

              <Box>
                <IconButton color="default" onClick={handleClickOpen}>
                  <WarningIcon fontSize="medium" />
                </IconButton>

                <Dialog
                  open={open}
                  onClose={handleClose}
                  fullWidth
                  maxWidth="md"
                >
                  <DialogTitle>Yasal Uyarı</DialogTitle>
                  <DialogContent dividers>
                    <Typography variant="body1" gutterBottom>
                      Kardemir Karabük Demir Çelik Sanayi ve Ticaret A.Ş. ("
                      <strong>Kardemir</strong>") olarak işbu Kullanıcı Paneli
                      aracılığıyla topladığımız kişisel verileriniz 6698 sayılı
                      Kişisel Verilerin Korunması Kanunu ("<strong>KVKK</strong>
                      ") ve yürürlükte bulunan ilgili mevzuat çerçevesinde; işe
                      alım, değerlendirme, iletişim, yerleştirme ve insan
                      kaynakları faaliyetleri amaçlarıyla iş sözleşmesinin
                      kurulması ve işveren sıfatıyla Kardemir’in aday
                      başvurularını değerlendirme ve insan kaynakları
                      politikalarını gerçekleştirme kapsamında bu amaçlarla
                      sınırlı olarak işlenecektir.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Bizlerle paylaşmış olduğunuz kişisel veriler, KVKK ve
                      ilgili sair mevzuatın öngördüğü süreler saklı kalmak
                      kaydıyla, işleme amacının gerektirdiği 2 yıl süre boyunca
                      saklanacaktır. Daha detaylı bilgi için, kişisel verilerin
                      korunması ile ilgili{" "}
                      <a
                        href="https://example.com/kardemir-aydinlatma-metni"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Kardemir Aydınlatma Metni
                      </a>
                      'ni inceleyebilir ve KVKK’nın 11. maddesi kapsamındaki
                      taleplerinizi{" "}
                      <a
                        href="https://example.com/ilgili-kisi-basvuru-formu"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        İlgili Kişi Başvuru Formu
                      </a>{" "}
                      ile belirtilen usullere uyarak gönderebilirsiniz. Konu ile
                      alakalı form sadece Kişisel Verilerin Korunması Kanunu 11.
                      Madde gereğince kullanılacak olup, iş başvuruları için
                      aşağıdaki butonu tıklayarak başvurunuzu
                      gerçekleştirebilirsiniz.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Konu ile ilgili detaylı bilgi almak ve İlgili Kişi Başvuru
                      Formu’na ulaşmak için{" "}
                      <a
                        href="https://example.com/tiklayin"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        tıklayın.
                      </a>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Kişisel Verilerin Korunması Kanunu kapsamında Çalışan
                      Adayı Aydınlatma Metni’ne erişmek için{" "}
                      <a
                        href="https://example.com/tiklayin"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        tıklayın.
                      </a>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Kişisel Verilerin Korunması Kanunu kapsamında Stajyer
                      Adayı Aydınlatma Metni’ne erişmek için{" "}
                      <a
                        href="https://example.com/tiklayin"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        tıklayın.
                      </a>
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Kapat
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </Box>

            <Grid
              container
              spacing={4}
              justifyContent="center"
              sx={{ paddingTop: 2 }}
            >
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  sx={{
                    width: "80%",
                    padding: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    backgroundColor: "#f5f5f5",
                    border: "2px solid #d11f25",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    color: "initial",
                    "&:hover": {
                      backgroundColor: "#d11f25",
                      color: "#ffffff",
                      transition: "background-color 0.3s ease",
                    },
                  }}
                  onClick={handleIsClick}
                >
                  <CampaignIcon
                    fontSize="large"
                    sx={{
                      color: "#inherit",
                      mb: 2,
                    }}
                  />
                  <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
                    İş Başvurusu
                  </Typography>
                  <Typography variant="body2">
                    Açık pozisyonlarda değerlendirilmek üzere iş başvurusu
                    yapmak için buraya tıklayın.
                  </Typography>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  sx={{
                    width: "80%",
                    padding: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    backgroundColor: "#f5f5f5",
                    border: "2px solid #1f2e5e",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    color: "initial", // İlk durumda varsayılan renk
                    "&:hover": {
                      backgroundColor: "#1f2e5e",
                      color: "#ffffff",
                      transition: "background-color 0.3s ease",
                    },
                  }}
                  onClick={handleStajClick}
                >
                  <CampaignIcon
                    fontSize="large"
                    sx={{
                      color: "inherit", // `inherit` ile Paper'ın `color` özelliğini devralır
                      mb: 2,
                    }}
                  />
                  <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
                    Staj Başvurusu
                  </Typography>
                  <Typography variant="body2">
                    Staj başvuru tarihleri süresince başvurunuzu
                    gerçekleştirebilirsiniz.
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </StyledPaper>
    </Box>
  );
}

export default IsBasvuruFormuContents;

// import React, { useState } from "react";
// import {
//   Box,
//   Typography,
//   Grid,
//   Container,
//   IconButton,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Button,
//   Paper,
// } from "@mui/material";
// import CampaignIcon from "@mui/icons-material/Campaign";
// import QuoteIcon from "@mui/icons-material/FormatQuote";
// import WarningIcon from "@mui/icons-material/Warning";
// import { styled } from "@mui/material/styles";

// const StyledPaper = styled(Paper)(({ theme }) => ({
//   padding: theme.spacing(3),
//   backgroundColor: "#ffffff",
//   boxShadow: theme.shadows[3],
//   overflowX: "auto",
// }));

// const Header = styled(Typography)(({ theme }) => ({
//   fontWeight: "bold",
//   textAlign: "center",
//   marginBottom: theme.spacing(3),
// }));

// function IsBasvuruFormuContents() {
//   const [open, setOpen] = useState(false);

//   const handleStajClick = () => {
//     window.location.href =
//       "https://bulut.kardemir.com/app/tr-TR/Form/Paylas/KardemirBulut/b7fb0318-19d6-4417-a89c-8ac2ad65c7ca";
//   };

//   const handleIsClick = () => {
//     window.location.href =
//       "https://www.linkedin.com/company/sosyalkardemir/jobs/";
//   };

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   return (
//     <Box sx={{ flexGrow: 1, padding: 1 }}>
//       <Header variant="h4">İş Başvuru Formu</Header>

//       <StyledPaper elevation={3}>
//         <Box sx={{ display: "flex", justifyContent: "center" }}>
//           <CampaignIcon fontSize="large" />
//           <Typography
//             sx={{
//               marginBottom: "1rem",
//               fontWeight: 600,
//               fontSize: { xs: "1.5rem", lg: "2rem" },
//             }}
//           >
//             Genel Bilgilendirme
//           </Typography>
//         </Box>

//         <Box
//           sx={{
//             padding: { xs: "0 1rem", lg: "0 4rem 1rem 0" },
//             textAlign: "justify",
//           }}
//         >
//           <Typography>
//             Genel Başvuruda, bilgileriniz açık iş pozisyonlarında
//             değerlendirilmek üzere veritabanımızda 2 yıl süre ile kayıt altında
//             saklanacak ve yalnızca ihtiyaç duyulması durumunda sizinle irtibata
//             geçilecektir.
//           </Typography>
//           <br />
//           <Typography>
//             Staj başvuruları ise yalnızca staj başvuru dönemlerinde aktif olarak
//             kullanılabilir. Staj başvuru tarihleri gerek web sayfamız üzerinden,
//             gerekse sosyal medya hesaplarımız üzerinden duyurulacaktır.
//           </Typography>
//           <br />
//           <Typography>
//             İnternet sitemiz üzerinden bilgilerinizi doldurarak sistemimize
//             kayıt olabilir, kayıt işlemi gerçekleştikten sonra staj veya genel
//             başvuru yapabilirsiniz.
//           </Typography>
//         </Box>

//         <Container
//           sx={{ textAlign: "center", paddingTop: 4, paddingBottom: 4 }}
//         >
//           <Box
//             sx={{
//               backgroundColor: "#1f2e5e",
//               color: "#fff",
//               paddingTop: 4,
//               paddingBottom: 4,
//             }}
//           >
//             <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
//               <QuoteIcon fontSize="large" /> Köklü Geçmiş, Güçlü Gelecek
//             </Typography>
//           </Box>

//           <Box sx={{ display: "flex", flexDirection: "column" }}>
//             <Box
//               sx={{
//                 display: "flex",
//                 flexDirection: "row",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 marginTop: "2rem",
//               }}
//             >
//               <Typography
//                 variant="h4"
//                 gutterBottom
//                 sx={{ textDecoration: "underline" }}
//               >
//                 Başvuru Seçeneklerimiz
//               </Typography>

//               <Box>
//                 <IconButton color="default" onClick={handleClickOpen}>
//                   <WarningIcon fontSize="medium" />
//                 </IconButton>

//                 <Dialog
//                   open={open}
//                   onClose={handleClose}
//                   fullWidth
//                   maxWidth="md"
//                 >
//                   <DialogTitle>Yasal Uyarı</DialogTitle>
//                   <DialogContent dividers>
//                     <Typography variant="body1" gutterBottom>
//                       Kardemir Karabük Demir Çelik Sanayi ve Ticaret A.Ş.
//                       ("Kardemir") olarak işbu Kullanıcı Paneli aracılığıyla
//                       topladığımız kişisel verileriniz 6698 sayılı Kişisel
//                       Verilerin Korunması Kanunu ("KVKK") ve yürürlükte bulunan
//                       ilgili mevzuat çerçevesinde; işe alım, değerlendirme,
//                       iletişim, yerleştirme ve insan kaynakları faaliyetleri
//                       amaçlarıyla iş sözleşmesinin kurulması ve işveren
//                       sıfatıyla Kardemir’in aday başvurularını değerlendirme ve
//                       insan kaynakları politikalarını gerçekleştirme kapsamında
//                       bu amaçlarla sınırlı olarak işlenecektir.
//                     </Typography>
//                     {/* ... Diğer Yasal Uyarı İçeriği ... */}
//                   </DialogContent>
//                   <DialogActions>
//                     <Button onClick={handleClose} color="primary">
//                       Kapat
//                     </Button>
//                   </DialogActions>
//                 </Dialog>
//               </Box>
//             </Box>

//             <Grid
//               container
//               spacing={4}
//               justifyContent="center"
//               sx={{ paddingTop: 2 }}
//             >
//               <Grid item xs={12} sm={6} md={4}>
//                 <Paper
//                   sx={{
//                     width: "100%",
//                     padding: 4,
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "center",
//                     justifyContent: "center",
//                     textAlign: "center",
//                     backgroundColor: "#f5f5f5",
//                     border: "2px solid #d11f25",
//                     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
//                     "&:hover": {
//                       backgroundColor: "#d11f25",
//                       color: "#ffffff",
//                       transition: "background-color 0.3s ease",
//                     },
//                   }}
//                   onClick={handleIsClick}
//                 >
//                   <CampaignIcon
//                     fontSize="large"
//                     sx={{ color: "#d11f25", mb: 2 }}
//                   />
//                   <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
//                     İş Başvurusu
//                   </Typography>
//                   <Typography variant="body2">
//                     Açık pozisyonlarda değerlendirilmek üzere iş başvurusu
//                     yapmak için buraya tıklayın.
//                   </Typography>
//                 </Paper>
//               </Grid>

//               <Grid item xs={12} sm={6} md={4}>
//                 <Paper
//                   sx={{
//                     width: "100%",
//                     padding: 4,
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "center",
//                     justifyContent: "center",
//                     textAlign: "center",
//                     backgroundColor: "#f5f5f5",
//                     border: "2px solid #1f2e5e",
//                     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
//                     "&:hover": {
//                       backgroundColor: "#1f2e5e",
//                       color: "#ffffff",
//                       transition: "background-color 0.3s ease",
//                     },
//                   }}
//                   onClick={handleStajClick}
//                 >
//                   <CampaignIcon
//                     fontSize="large"
//                     sx={{ color: "#1f2e5e", mb: 2 }}
//                   />
//                   <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
//                     Staj Başvurusu
//                   </Typography>
//                   <Typography variant="body2">
//                     Staj başvuru tarihleri süresince başvurunuzu
//                     gerçekleştirebilirsiniz.
//                   </Typography>
//                 </Paper>
//               </Grid>
//             </Grid>
//           </Box>
//         </Container>
//       </StyledPaper>
//     </Box>
//   );
// }

// export default IsBasvuruFormuContents;
