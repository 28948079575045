import { useState } from "react";
import SolYapiUrunler from "./SolYapiUrunler";

function Urunler() {
  const [page, setPage] = useState("Ürünlerimiz");

  return (
    <>
      <SolYapiUrunler setPage={setPage} page={page} />
    </>
  );
}

export default Urunler;
