import React, { useState } from "react";
import SolYapiKariyer from "./SolYapiKariyer";

const KurumsalKimlik = () => {
  const [page, setPage] = useState("Kurumsal Kimlik");

  return (
    <>
      <SolYapiKariyer setPage={setPage} page={page} />
    </>
  );
};

export default KurumsalKimlik;
