import {
  Box,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
  overflowX: "auto",
  maxWidth: 900, // Genişliği sınırlamak için maxWidth ayarlandı
  margin: "0 auto", // Merkezde hizalama sağlamak için margin kullanıldı
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

const Table = styled("table")(({ theme }) => ({
  width: "100%",
  borderCollapse: "collapse",
  "& th, & td": {
    padding: theme.spacing(2),
    textAlign: "left",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  "& th": {
    backgroundColor: "#1f2e5e",
    color: "#ffffff",
  },
  "& td": {
    backgroundColor: "#f5f5f5",
  },
}));

function AnalistContents() {
  function createData(adSoyad, firma, telefon) {
    return { adSoyad, firma, telefon };
  }

  const rows = [
    createData("ÖMER FARUK YÜKSEL", "AK YATIRIM", "00 90 212 334 94 67"),
    createData("CEMAL DEMİRTAŞ", "ATA YATIRIM", "00 90 212 310 64 28"),
    createData("FUNDA AFACAN", "BGC PARTNERS", "00 90 212 339 42 27"),
    createData("UĞUR BOZKURT", "DENİZ YATIRIM", "00 90 212 348 91 91"),
    createData("CAN ALAGÖZ", "QNB FİNANS YATIRIM", "00 90 212 336 72 73"),
    createData("ONUR MARŞAN", "GARANTİ YATIRIM", "00 90 212 384 11 25"),
    createData("BÜLENT YURDAGÜL", "HSBC SECURITIES", "00 90 212 376 46 12"),
    createData("ESRA ŞİRİNEL", "İŞ YATIRIM", "00 90 212 350 25 54"),
    createData("UMUT ÖZTÜRK", "OYAK YATIRIM", "00 90 212 319 12 11"),
    createData(
      "ESRA UZUN ÖZBASKIN",
      "ŞEKER YATIRIM",
      "00 90 212 334 33 33 / 245"
    ),
    createData(
      "BURAK SALMAN",
      "TACİRLER YATIRIM",
      "00 90 212 355 46 46 / 2678"
    ),
    createData("ERDEM KAYLI", "TEB YATIRIM", "00 90 216 636 45 29"),
    createData("KORAY PAMİR", "UNLU &CO", "00 90 212 367 36 94"),
    createData("SERHAT KAYA", "YATIRIM FİNANSMAN", "00 90 212 334 98 36"),
    createData("ASLI KUMBARACI", "YAPI KREDI YATIRIM", "00 90 212 319 83 65"),
    createData("SELAHATTİN AYDIN", "VAKIF YATIRIM", "00 90 212 352 35 77"),
    createData("ELİF ÇAMYARAN", "ZİRAAT YATIRIM", "-"),
  ];

  return (
    <Box sx={{ flexGrow: 1, padding: 1 }}>
      <Header variant="h4" color="#1f2e5e">
        Analist Bilgileri
      </Header>

      <StyledPaper elevation={3}>
        <Box
          sx={{
            padding: "0.5rem",
          }}
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: "100%" }} aria-label="simple table">
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.adSoyad}>
                    <TableCell
                      sx={{ borderRight: "1px solid gray", width: "30%" }}
                      component="th"
                      scope="row"
                    >
                      <Typography sx={{ fontWeight: "400", fontSize: "15px" }}>
                        {row.adSoyad}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="justify"
                      sx={{ borderRight: "1px solid lightgrey", width: "40%" }}
                    >
                      {row.firma}
                    </TableCell>
                    <TableCell
                      align="justify"
                      sx={{ width: "30%", whiteSpace: "nowrap" }}
                    >
                      {row.telefon}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </StyledPaper>
    </Box>
  );
}

export default AnalistContents;
