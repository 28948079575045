// import React, { useState } from "react";
// import { Document, Page, pdfjs } from "react-pdf";
// import HTMLFlipBook from "react-pageflip";
// import "react-pdf/dist/esm/Page/AnnotationLayer.css";
// import pdfImage from "../../images/katalogsayfa.png";
// import { Box, useMediaQuery, Modal, IconButton } from "@mui/material";
// import ZoomInIcon from "@mui/icons-material/ZoomIn";

// pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// function UrunKatalogumuzContents() {
//   const [numPages, setNumPages] = useState(null);
//   const [open, setOpen] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const isLargeScreen = useMediaQuery("(min-width:1085px)");

//   const onDocumentLoadSuccess = ({ numPages }) => {
//     setNumPages(numPages);
//   };

//   const handleOpen = (page) => {
//     setCurrentPage(page);
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   return (
//     <>
//       <Box sx={{ margin: "1rem" }}>
//         {isLargeScreen ? (
//           <Box>
//             <Document
//               file={`/pdfs/katalog2.pdf`}
//               onLoadSuccess={onDocumentLoadSuccess}
//               onLoadError={(error) =>
//                 console.error("Error loading PDF:", error)
//               }
//             >
//               <HTMLFlipBook width={500} height={400}>
//                 {[...Array(numPages || 0)].map((_, index) => (
//                   <Box key={index} sx={{ position: "relative" }}>
//                     <Page pageNumber={index + 1} />
//                     <IconButton
//                       onClick={() => handleOpen(index + 1)}
//                       sx={{
//                         position: "absolute",
//                         top: "50%",
//                         left: "50%",
//                         transform: "translate(-50%, -50%)",
//                         zIndex: 1,
//                       }}
//                     >
//                       <ZoomInIcon fontSize="large" />
//                     </IconButton>
//                   </Box>
//                 ))}
//               </HTMLFlipBook>
//             </Document>
//           </Box>
//         ) : (
//           <Box className="image-container">
//             <a
//               href="https://www.kardemir.com/dosyalar/satispazarlama/urunkatalogu/kardemir-urunler.pdf"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <img src={pdfImage} alt="PDF Link" className="responsive-image" />
//             </a>
//           </Box>
//         )}
//       </Box>

//       {/* Modal kısmı */}
//       <Modal
//         open={open}
//         onClose={handleClose}
//         sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
//       >
//         <Box
//           sx={{
//             backgroundColor: "white",
//             border: "2px solid #000",
//             borderRadius: "4px",
//             padding: "2rem",
//             width: "50%",
//             height: "70%",
//             overflow: "auto",
//             objectFit: "contain",
//             position: "relative",
//           }}
//         >
//           <Document
//             file={`/pdfs/katalog2.pdf`}
//             onLoadError={(error) => console.error("Error loading PDF:", error)}
//           >
//             <Page pageNumber={currentPage} width={800} />{" "}
//             {/* Sayfa genişliğini ayarlayabilirsiniz */}
//           </Document>
//         </Box>
//       </Modal>
//     </>
//   );
// }

// export default UrunKatalogumuzContents;
// FlipBook.js

// import React, { useState } from "react";
// import HTMLFlipBook from "react-pageflip";
// import "./flip-book.css";
// import { Document, Page, pdfjs } from "react-pdf";
// import "react-pdf/dist/esm/Page/AnnotationLayer.css";
// import pdf from "./katalog.pdf";
// import { Box } from "@mui/material";

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// const Pages = React.forwardRef((props, ref) => {
//   return (
//     <div width={550} height={700} ref={ref}>
//       <p>{props.children}</p>
//       <p>Sayfa numarası: {props.number}</p>
//     </div>
//   );
// });

// function UrunKatalogumuzContents() {
//   const [numPages, setNumPages] = useState(null);

//   const onDocumentLoadSuccess = ({ numPages }) => {
//     setNumPages(numPages);
//   };
//   return (
//     <Box sx={{ backgroundColor: "red", margin: "2rem 0" }}>
//       <div>
//         <HTMLFlipBook width={550} height={700} showCover={true}>
//           {[...Array(numPages).keys()].map((n) => (
//             <Pages number={`${n + 1}`}>
//               <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
//                 <Page
//                   pageNumber={n + 1}
//                   renderAnnotationLayer={false}
//                   renderTextLayer={false}
//                   width={550}
//                 />
//               </Document>
//             </Pages>
//           ))}
//         </HTMLFlipBook>
//       </div>
//     </Box>
//   );
// }

// export default UrunKatalogumuzContents;

// import React, { useState, useEffect } from "react";
// import HTMLFlipBook from "react-pageflip";
// import "./flip-book.css";
// import { Document, Page, pdfjs } from "react-pdf";
// import "react-pdf/dist/esm/Page/AnnotationLayer.css";
// import pdf from "./katalog.pdf";
// import { Box } from "@mui/material";

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// const Pages = React.forwardRef((props, ref) => {
//   return (
//     <div
//       className="demoPage"
//       ref={ref}
//       style={{
//         width: "100%",
//         height: "100%",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       }}
//     >
//       {props.children}
//     </div>
//   );
// });

// function UrunKatalogumuzContents() {
//   const [numPages, setNumPages] = useState(null);
//   const [containerSize, setContainerSize] = useState({
//     width: 550,
//     height: 700,
//   });

//   const onDocumentLoadSuccess = ({ numPages }) => {
//     setNumPages(numPages);
//   };

//   useEffect(() => {
//     // Window boyutuna göre dinamik boyutlandırma
//     const updateSize = () => {
//       const { innerWidth, innerHeight } = window;
//       const aspectRatio = 550 / 700; // PDF sayfasının oranı

//       let width = innerWidth;
//       let height = innerWidth / aspectRatio;

//       if (height > innerHeight) {
//         height = innerHeight;
//         width = innerHeight * aspectRatio;
//       }

//       setContainerSize({ width, height });
//     };

//     updateSize();
//     window.addEventListener("resize", updateSize);
//     return () => window.removeEventListener("resize", updateSize);
//   }, []);

//   return (
//     <Box
//       sx={{
//         backgroundColor: "red",
//         width: "100%",
//         height: "100vh",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         overflow: "hidden",
//         margin: 0,
//         padding: 0,
//       }}
//     >
//       <HTMLFlipBook
//         width={containerSize.width}
//         height={containerSize.height}
//         size="stretch"
//         style={{ width: containerSize.width, height: containerSize.height }}
//         showCover={true}
//       >
//         {[...Array(numPages).keys()].map((n) => (
//           <Pages key={n} number={`${n + 1}`}>
//             <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
//               <Page
//                 pageNumber={n + 1}
//                 renderAnnotationLayer={false}
//                 renderTextLayer={false}
//                 width={containerSize.width}
//               />
//             </Document>
//           </Pages>
//         ))}
//       </HTMLFlipBook>
//     </Box>
//   );
// }

// export default UrunKatalogumuzContents;

// import React, { useState } from "react";
// import HTMLFlipBook from "react-pageflip";
// import "./flip-book.css";
// import { Document, Page, pdfjs } from "react-pdf";
// import "react-pdf/dist/esm/Page/AnnotationLayer.css";
// import pdf from "./katalog.pdf";
// import kapakImage from "../../images/katalogsayfa.png"; // Kapak resmi
// import { Box, Dialog } from "@mui/material";

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// const Pages = React.forwardRef((props, ref) => {
//   return (
//     <div width={550} height={700} ref={ref}>
//       <p>{props.children}</p>
//       <p>Sayfa numarası: {props.number}</p>
//     </div>
//   );
// });

// function UrunKatalogumuzContents() {
//   const [numPages, setNumPages] = useState(null);
//   const [open, setOpen] = useState(false); // Panel açık/kapalı durumu

//   const onDocumentLoadSuccess = ({ numPages }) => {
//     setNumPages(numPages);
//   };

//   const handleClickOpen = () => {
//     setOpen(true); // Kapak resmine tıklanınca panel açılır
//   };

//   const handleClose = () => {
//     setOpen(false); // Panel kapanır
//   };

//   return (
//     <Box sx={{ backgroundColor: "red", margin: "2rem 0" }}>
//       {/* Kapak Resmi */}
//       {!open && (
//         <img
//           src={kapakImage}
//           alt="Katalog Kapak"
//           style={{ width: "100px", height: "100px", cursor: "pointer" }}
//           onClick={handleClickOpen}
//         />
//       )}

//       {/* Panel İçeriği */}
//       <Dialog fullScreen open={open} onClose={handleClose}>
//         <HTMLFlipBook width={550} height={700} showCover={true}>
//           {[...Array(numPages).keys()].map((n) => (
//             <Pages key={n} number={`${n + 1}`}>
//               <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
//                 <Page
//                   pageNumber={n + 1}
//                   renderAnnotationLayer={false}
//                   renderTextLayer={false}
//                   width={550}
//                 />
//               </Document>
//             </Pages>
//           ))}
//         </HTMLFlipBook>
//       </Dialog>
//     </Box>
//   );
// }

// export default UrunKatalogumuzContents;

import React, { useState, useEffect } from "react";
import HTMLFlipBook from "react-pageflip";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import pdf from "./katalog.pdf";
import kapakImage from "../../images/katalogsayfa.png";
import {
  Box,
  Dialog,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import pdfImage from "../../images/katalogsayfa.png";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Pages = React.forwardRef((props, ref) => {
  return (
    <div width={props.width} height={props.height} ref={ref}>
      <p>{props.children}</p>
      <p>Sayfa numarası: {props.number}</p>
    </div>
  );
});

function UrunKatalogumuzContents() {
  const [numPages, setNumPages] = useState(null);
  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const isLargeScreen = useMediaQuery("(min-width:850px)");

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Header = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: theme.spacing(3),
  }));

  return (
    <Box sx={{}}>
      <Header variant="h4" color="#1f2e5e">
        Ürün Kataloğumuz
      </Header>

      {isLargeScreen ? (
        <>
          {!open && (
            <div
              style={{
                position: "relative",
                display: "inline-block",
                cursor: "pointer",
                perspective: "1000px",
              }}
              onMouseEnter={(e) => {
                const container =
                  e.currentTarget.querySelector(".flip-container");
                container.style.transform = "rotateY(-10deg)";
                const overlay = e.currentTarget.querySelector(".overlay");
                overlay.style.opacity = 1;
              }}
              onMouseLeave={(e) => {
                const container =
                  e.currentTarget.querySelector(".flip-container");
                container.style.transform = "rotateY(0deg)";
                const overlay = e.currentTarget.querySelector(".overlay");
                overlay.style.opacity = 0;
              }}
              onClick={handleClickOpen}
            >
              <div
                className="flip-container"
                style={{
                  position: "relative",
                  transition: "transform 0.3s ease-in-out",
                  transformOrigin: "left center",
                }}
              >
                <img
                  src={kapakImage}
                  alt="Katalog Kapak"
                  style={{
                    width: "auto",
                    height: "300px",
                    display: "block",
                  }}
                />
                <div
                  className="overlay"
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    height: "20%",
                    backgroundColor: "#1f2e5e",
                    opacity: 0,
                    transition: "opacity 0.3s ease-in-out",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Ürün Kataloğu
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <Box className="image-container">
          <a
            href="https://www.kardemir.com/dosyalar/satispazarlama/urunkatalogu/kardemir-urunler.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={pdfImage} alt="PDF Link" className="responsive-image" />
          </a>
        </Box>
      )}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        sx={{ margin: "2rem 0", padding: "2rem" }}
      >
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{ position: "absolute", top: 10, right: 10 }}
        >
          <CloseIcon />
        </IconButton>

        <Box
          sx={{
            padding: "2rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <HTMLFlipBook
            width={width * 0.4}
            height={height * 0.8}
            showCover={true}
          >
            {[...Array(numPages).keys()].map((n) => (
              <Pages
                key={n}
                number={`${n + 1}`}
                width={width * 0.4}
                height={height * 0.8}
              >
                <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page
                    pageNumber={n + 1}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    width={width * 0.4}
                  />
                </Document>
              </Pages>
            ))}
          </HTMLFlipBook>
        </Box>
      </Dialog>
    </Box>
  );
}

export default UrunKatalogumuzContents;
