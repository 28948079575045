import React, { useState } from "react";
import SolYapiYatirimci from "./SolYapiYatirimci";

function OrtaklikYapisiYatirim() {
  const [page, setPage] = useState("Ortaklık Yapısı");

  return <SolYapiYatirimci setPage={setPage} page={page} />;
}

export default OrtaklikYapisiYatirim;
