import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import foto1 from "../../images/sikayet.png";
import foto2 from "../../images/sikayet2.jpg";
import { styled } from "@mui/material/styles";

const certificates = [
  {
    title: " ÜRÜN DAĞITIM LİSTESİ",
    details: [
      {
        name: "ÜRÜN DAĞITIM LİSTESİ",
        description1: "1.Çeyrek(27.12.2023)",
        description2: "2. Çeyrek (21.03.2024)",
        description3: " 3. Çeyrek (14.06.2024)",
        description4: "",
        description5: "",
        description6: "",
        description7: "",
        // description8: "",
        // description9: "",
        // description10: "",
        // description11: "",
      },
    ],
  },

  {
    title: " TANIMLAR",
    details: [
      {
        name: "TANIMLAR",
        description1:
          "İş bu SATIŞ GENEL ŞARTLARI’ nda KARABÜK DEMİR ÇELİK SANAYİ VE TİCARET A.Ş ”KARDEMİR”; KARDEMİR ’den ürün satın alan veya satın almak üzere talepte bulunan gerçek veya tüzel kişi “MÜŞTERİ” olarak anılmıştır.",
        description2:
          "Mamul: Kullanıma hazır halde, işlenmiş, satışa sunulan nihai üründür.",
        description3:
          "Yarı Mamul: Başka bir malın üretimine girdi(hammadde) olarak kullanılan üründür.",
        description4:
          "Yan Ürünler: Ana mamul üretim sürecinde proses çıktılarına ek oparasyonlar uygulanarak elde edilen ürünlerdir.",
        description5:
          "2. Sınıf Ürünler:Demiryolu Tekeri ve Ray ürünleri haricinde tolerans değerini max. %100 aşan Ürünler 2. Sınıf ürünlerdir.",
        description6:
          "Atık: Prosesler sonucunda oluşan ve çevreye olumsuz etkisi olabilecek maddelerdir",
        description7:
          "İhtiyaç Fazlası Malzeme ve Hurda Satışı: Uzun süre hareket görmeyen ve kullanım alanı kalmayan, ihtiyaçtan fazla olması nedeniyle kullanılmasına, tüketilmesine imkân bulunmayan yeni, kullanılmamış, kullanılmış, sağlam veya onarım ile kullanılabilir durumdaki ekipman ve demir dışı hurda malzemelerin satışıdır.",
        // description8: "",
        // description9: "",
        // description10: "",
        // description11: "",
      },
    ],
  },

  {
    title: "SATIŞ ŞEKİLLERİ",
    details: [
      {
        description1: "Satışlar aşağıdaki şekillerde yapılmaktadır:",
        description2:
          " Peşin Satış: Malzeme teslim edilmeden önce malzeme bedelinin şirketimiz hesaplarına yatırılması halinde oluşturulan satış şeklidir.",
        description3:
          "Vadeli Satış: 500 ton ve üzeri satışlarımızda %25’i peşin, %25’i 30, %25’i 60, %25’i 90 gün vadeli olarak bloke çek, firma çek + teminat mektubu yada DBS karşılığında oluşmaktadır.",
        description4:
          " İhraç Kayıtlı Satış: Müşterilerimizin KARDEMİR’den ihracatını yapmak koşuluyla almış olduğu ray, profil, kangal, köşebent, kalın yuvarlak) satış şeklidir.",
        description5:
          "DİİB’li Satış: KARDEMİR’den dahilde işleme izin belgesi kapsamında satın alınan malzemelerin işlenip başka ürünlere dönüştürülerek müşterilerimizce satışının yapılması işlemidir.",
        description6:
          "Sözleşmeli ve İhale Usulü Satış: Açılan kamu ihalelerine, Makam onayıyla iştirak edilen satış şeklidir. TCDD ray ihaleleri buna örnektir.",
        description7:
          "İhtiyaç Fazlası Malzeme ve Hurda Satışı: Uzun süre hareket görmeyen ve kullanım alanı kalmayan, ihtiyaçtan fazla olması nedeniyle kullanılmasına, tüketilmesine imkân bulunmayan yeni, kullanılmamış, kullanılmış, sağlam veya onarım ile kullanılabilir durumdaki ekipman ve demir dışı hurda malzemelerin satışıdır.",
        description8:
          "Ham katran, ham benzol ve amonyum sülfat ürünleri ihale açılarak firmalardan fiyat teklifi alınmak suretiyle makam onayıyla satılmaktadır.",
        description9:
          "Muhtelif boy std. ve 2.sınıf 20-100 mm arası çelik çubuklar, melez kütükler, 2.sınıf kangallar, 9 mt ve özel boy profiller, 2.sınıf ve std. platinalar dönem dönem ihale açılarak firmalardan fiyat teklifi alınmak suretiyle makam onayıyla satılmaktadır.",
        description10:
          "Direkt İhracat: KARDEMİR’de üretilen/üretilmesi hedeflenen tüm ürün ve hizmetlerin yurtdışı satışının gerçekleştirilebilmesi için yapılan ihracat faaliyetleridir. İhracat süreçleri Şirketimiz uhdesinde İhracat Müdürlüğü tarafında gerçekleşmektedir.",
        description11: "",
      },
    ],
  },

  {
    title: "ÖDEME ŞEKİLLERİ",
    details: [
      {
        description1:
          "Peşin: MÜŞTERİ, KARDEMİR tarafından yayınlanan fiyat listeleri üzerinden mal bedelinin tamamını peşin olarak yatırır.",
        description2:
          " Vadeli ödemeler fiyat listelerinde duyurulduğu şekilde firma çeki, bloke çek ve Genel Müdürlük makamınca belirlenen herhangi bir teminat alınarak günün koşullarında belirlenen vadeler doğrultusunda yapılır. Ödemenin vadeli gerçekleşebilmesi için Müşteri, Genel Müdürlük makamının belirlediği minimum sipariş zorunluluğunu karşılamalıdır. Ayrıca bazı ürün gruplarında da Genel Müdürlük makamının belirlediği indirimler uygulanmaktadır. Malzeme alımları için müşteriden alınan teminat mektupları –kesin- ibaresini ihtiva edecek ve ilgili teminat mektubunda KARDEMİR’den alınacak malzeme ile ilgili olduğu ifadesi yer alacaktır.Vadeli çekler için, ayrı ayrı teminat mektubu verilebileceği gibi; tek bir teminat mektubu da verilebilir. Her durumda kesin teminat mektuplarının vadeleri çek vadelerinden minimum 5 gün sonra olacak şekilde düzenlenmelidir. Ayrıca MÜŞTERİ, KARDEMİR’in kabul ettiği bankalar üzerinden DBS (Doğrudan Borçlandırma Sistemi) ile de vadeli ödemelerini gerçekleştirebilir.",
        description3:
          "Akreditif:İhracat operasyonlarında İhracat Müdürlüğü tarafından takip edilen bir ödeme enstrümanıdır.",
        description4:
          "  Vesaik Mukabili: Sözleşmeli satışlarda teslimat sonrası kabul yada fatura karşılığı yapılan ödeme şeklidir.",
        description5:
          "Kardemir; teslimi vadeye bağlı satışlarda, talepleri; üretim, sevk tarihi ve genel satış politikalarına göre kabul eder. Bu nedenle teslimi vadeye bağlı taleplerin siparişe bağlanıp bağlanmaması KARDEMİR’in opsiyonundadır.",
        description6: "",
        description7: "",
        description8: "",
        description9: "",
        description10: "",
        description11: "",
      },
    ],
  },

  {
    title: "SİPARİŞ ŞARTLARI",
    details: [
      {
        description1:
          "KARDEMİR’ce sipariş talebinin kabulü, KARDEMİR Satış Müdürlüğü’nce yapılır. Satış Şekilleri Madde 2.1,2.2, 2.3, 2.4.’de belirtilen peşin, vadeli, ihraç kayıtlı ve DİİB’li satışlar yayınlanan fiyat listeleri üzerinden yapılır.",
        description2:
          " Malzeme bedelinin KARDEMİR hesaplarına yatırılmış olması, siparişin kabulü anlamına gelmez. MÜŞTERİ, KARDEMİR’den talep ettiği malzeme bedelinin tamamını yada fiyat listelerimizde belirtilmiş olan vadelere göre ödenecek tutarı KARDEMİR’in hesap numaralarına yatırır. Sipariş talebi Kabul edilen MÜŞTERİ Yönetmeliğin YNT.PR.0010 prosedürünün 3. maddesinde belirtilen ödeme şekli şartları doğrultusunda malzeme bedeline ait tüm evrakların (dekont, çek, bloke çek, banko çek, teminat) KARDEMİR Satış Müdürlüğü ’ne intikal ettirmesine müteakip siparişname tanzim edilir, bir suret MÜŞTERİ’ye ve/veya MÜŞTERİ temsilcisine verilir.",
        description3:
          "Vadeli ödemelerde müşteri tarafından ödemenin zamanında yapılmaması durumunda ilgili teminat mektubu KARDEMİR tarafından irad kayıt edilir.",
        description4:
          "  KARDEMİR tarafından kabul edilen müşteri siparişleri Kardemir Satış Prosedürü Satış Usulleri doğrultusunda ve Kardemir’in termin programına göre üretim planı yapılır ve günlük olarak müşterilere ilan edilir.",
        description5:
          "Müşterilerin sipariş iptalleri ile ilgili talepleri, KARDEMİR’ce uygun görülmesi halinde yapılır. İstisnai durumlarda siparişin iptal edilmesi halinde (malzemenin üretilememesi, mücbir sebepler vs) iptal edilen sipariş bedeli gerçekleşen ödeme cinsinden (TL ödeme ise TL ödeme, döviz ödeme ise döviz ödeme) müşteriye iade edilir.",
        description6:
          "KARDEMİR; grev, lokavt, idari ve teknik zorunluluklar vb. mücbir sebepler ile teslim tarihini erteleyebileceği gibi, siparişi kısmen veya tamamen iptal edebilir. Siparişin iptal edilmesi durumunda MÜŞTERİ konu ile ilgili bilgilendirilir ve iptal edilen kısmı ile ilgili mal bedeli aynen iade edilir. MÜŞTERİ bu nedenle mal bedeli dışında herhangi bir talepte bulunamaz.",
        description7:
          "MÜŞTERİ, unvan, açık adres, vergi dairesi ve vergi numarasında meydana gelen değişiklikleri yazılı olarak Satış Müdürlüğü ’ne bildirmekle yükümlüdür.",
        description8: "",
        description9: "",
        description10: "",
        description11: "",
      },
    ],
  },

  {
    title: "TAAHHÜTLÜ ALIM",
    details: [
      {
        description1:
          "Şirketimizin, üretim ve planlama programına göre ürün bazında yapmış olduğu, günlük toplam üretimin Kardemir ihtiyaçları dışında kalan miktarın ürün bazında günlük üretim %30'u oranındaki miktarı Kardemir ortaklarına taahhütlü mal alım hakkı olarak tanınmaktadır.",
        description2:
          " Bu ürün grubu bazında bildirilmiş olan tonajlar her üç aylık dönemin son haftasında bir sonraki dönemin başvuru prosedürü Şirketimiz web sayfasında yayınlanmaktadır. Yayınlanan duyuruda malzeme cinsine göre ilgili üç aylık dönemin toplam malzeme miktarı,%30 taahhütlü malzeme miktarları, %70 serbest malzeme miktarları bildirilmektedir. Duyuruda belirtilen tarihe kadar talep sahibi hissedar müşterilerimizden şirket ünvanı/şahıs isimleri, sahip oldukları pay senetleri, nihai olmamakla birlikte alabilecekleri ürün miktarını gösterir yazıyı Satış Müdürlüğüne faks, posta ya da şahsen bildirmeleri gerekmektedir.",
        description3:
          "Taahhütlü mal alım hakkının kullanımı için her bir yıl itibariyle, yıllık üretim programına göre, Ocak-Mart, Nisan-Haziran, Temmuz-Eylül ve Ekim-Aralık dönemleri için geçerli olmak üzere yılda dört kere taahhütlü alım hakkının kullanım listesi oluşturulmaktadır.",
        description4:
          "  Taahhütlü mal alım hakkından faydalanmak için Şirkete başvuran ortaklar, Şirketin yıllık üretim programını dikkate alarak, başvuru sırasında kesin ibareli banka teminat mektubu teslim etmektedirler. Söz konusu teminat ortakların 3 aylık süre içerisinde almayı taahhüt ettikleri malın başvuru tarihindeki değerinin % 3'ü tutarında olacaktır. Teslim sürecinde senedini satan veya başvuru yapıp fakat ürünü satın almaktan vazgeçen, taahhütlü mal alma şartlarını kaybeden ortağın/kişinin teminatı hiçbir ilana, ihbara, mahkeme kararına gerek kalmaksızın Şirkete gelir olarak kaydedilmektedir ve söz konusu ortağın bir yıl süre ile taahhütlü mal alımı için başvuru hakkı olmamaktadır.",
        description5:
          "Taahhütlü mal alım hakkı olan firmaların siparişleri, sipariş tarihindeki (Kardemir hesaplarına ödemenin yapıldığı gün) üretimin Şirket ihtiyacı dışında kalan miktarın (ürün bazında günlük üretim) % 30'luk kısmından hisse senedine tekabül eden oranda mal verilmeye başlanarak karşılanacaktır. Taahhütlü mal alanlar % 70'lik kısımdan da Şirketin genel satış esasları dâhilinde mal alabilmektedir. % 70'lik kısımdan karşılanacak yeni siparişler için dağıtımın yapılacağı günkü bakiye sipariş miktarına göre oransal olarak talepler karşılanmaktadır. Bu mal teslim metodu dönemsel olarak devam etmekte ve dağıtım yukarıdaki şartlarda ve günlük üretime göre yapılmaktadır",
        description6:
          "Taahhütlü mal alım hakkına Şirket paylarına, Merkezi Kayıt Kuruluşu kayıtlarına göre en az 3 ay süre ile sahip ortaklar yararlanabilmektedir. Ürün tesliminin yapılabilmesi için pay sahipliğinin devam ediyor olması şarttır.",
        description7: "",
        description8: "",
        description9: "",
        description10: "",
        description11: "",
      },
    ],
  },

  {
    title: "TESLİM ŞARTLARI",
    details: [
      {
        description1:
          "Teslimatta, KARDEMİR kantar tartı tonajları esastır. Ancak, özellikle ray gibi tartı olanağının bulunmadığı 18 metre ve üzeri ürünlerin sevkiyatında Müşteri ile yapılan bir Kabul tutanağı varsa tonaj bu tutanaklarda belirlenen kg.mt üzerinden, Kabul tutanağı yoksa ürünün teorik kg.mt değeri üzerinden tonajlandırılır. Teslimat MÜŞTERİ veya kanuni vekiline yapılır.",
        description2:
          " Tesellüm KARDEMİR fabrika sahasında ve MÜŞTERİ‘nin temin edeceği kara veya demiryolu vasıtasına yapılabileceği gibi anlaşmaya bağlı olarak demiryollarında ilgili istasyon veya limana, denizyollarında ilgili iç limana veya yurtdışı limana yapılabilmektedir.",
        description3:
          "Mamul, yarı mamul ve yan ürünlerin satışları sipariş numarasına, teslimat miktarları ise stok veya imalat durumuna göre Satış Müdürlüğü’nce tespit edilir. Siparişe bağlanan malzemeler için MÜŞTERİ tarafından bir başka cinse çevirme talebinde bulunulması halinde, çevirmenin yapılıp yapılmaması KARDEMİR opsiyonundadır. Çevirme işlemi, kendi ürün grubu içerisinde sipariş tanzim tarihindeki fiyat üzerinden yapılır. Sevkiyat, vagon, tedarik ve saha yükleme imkanlarına göre ayarlanır. Yukarıdaki esaslar dahilinde yapılacak sevk ve teslimat günlük yayınlanır.",
        description4:
          "  MÜŞTERİ, KARDEMİR’in üretim şartlarına göre oluşabilecek, sipariş miktarı ile fiili teslim miktarı arasındaki farkı peşinen kabul eder.500 kg’a kadar fazla çıkışlar siparişteki fiyattan işlem görür.",
        description5:
          "MÜŞTERİ, KARDEMİR’in üretim toleranslarını ve standart paketleme şekillerini peşinen kabul eder. KARDEMİR üretim ve sevkiyat performansı göz önüne alarak köşebent ve profillerin paketleme usullerini belirlemekte serbestir. İkinci sınıf olarak satılan mallar için çelik cinsi, ebat ve kalite garantisi verilmez.",
        description6:
          "Günlük sevkiyat programına göre KARDEMİR sahası içine alınan vasıtaya herhangi bir nedenle yükleme yapılamaması durumunda, söz konusu nedeni nakliyeciye bildirilir.",
        description7: "",
        description8: "",
        description9: "",
        description10: "",
        description11: "",
      },
    ],
  },

  {
    title: "HATALI ÜRÜN BAŞVURULARI",
    details: [
      {
        description1:
          "MÜŞTERİ’ nin teslim aldığı ürün ile ilgili olarak; MÜŞTERİ tarafından dikkatli bir inceleme ve kontrol sonucunda ortaya çıkabilecek (görünür) kusurlar ile ilgili başvurular, ürünün herhangi bir işlem görmemiş olması koşulu ile, MÜŞTERİ’ ye teslimini izleyen 30 gün içinde kabul edilir. Bu sürenin aşılması durumunda pas, fiziki bozukluk vb. görünür kusurlar ile ilgili başvurular kabul edilmez.",
        description2:
          " KARDEMİR dışında yapılan bükme, boy kesme vb. işlemler sırasında görünür hale gelen kusurlar ile ilgili başvurular ancak işlem sırasında bilgilendirmek koşulu ile kabul edilir. Bununla beraber mekanik özelliklerin garantisi içi ilgili uluslararası standartta belirtilen süreler geçerlidir. YÖN.PR.0008 Şikayetlerin Çözümlenmesi Prosedüründe detaylı süreç açıklaması bulunmaktadır.",
        description3:
          "MÜŞTERİ, şikayete konu olan malzemelere ait sevk puantajı ve ürün etiketini bildirmek ve tetkik sırasında şikayeti inceleyen KARDEMİR elemanlarına ibraz etmek zorundadır.",
        description4:
          "  Tetkik sonucunda şikayetin haklı görülmesi halinde, KARDEMİR en fazla mal bedeli kadar sorumludur. MÜŞTERİ tarafından iddia edilen bağlantılı zararlarda KARDEMİR sorumlu tutulamaz.",
        description5: "",
        description6: ".",
        description7: "",
        description8: "",
        description9: "",
        description10: "",
        description11: "",
      },
    ],
  },

  {
    title: "VERGİ,RESİM,HARÇ VE SAİR MASRAFLAR",
    details: [
      {
        description1:
          "Siparişlere ilişkin her türlü vergi, resim, harç ve sair masrafların tamamı MÜŞTERİ’ ye aittir. Damga Vergisi Kanununa göre resmi daire sayılan kuruluşlara yapılan satışlara ait damga vergisi ise KARDEMİR’e aittir.",
        description2: " ",
        description3: "",
        description4: "  ",
        description5: "",
        description6: ".",
        description7: "",
        description8: "",
        description9: "",
        description10: "",
        description11: "",
      },
    ],
  },

  {
    title: "MÜŞTERİNİN TEBLİGAT ADRESİ",
    details: [
      {
        description1:
          "MÜŞTERİ ’nin tebligat adresi sipariş verme/sipariş kabul forumlarında yazılı olup, bu adreslere mektup, faks veya telgrafla yapılacak her türlü bildirim geçerli ve MÜŞTERİ’nin şahsına yapılmış sayılır. Müşterinin adres değişikliğini bildirmemesi halinde, yazılı adresine yapılacak tebligatlar geçerli olacaktır.",
        description2: " ",
        description3: "",
        description4: "  ",
        description5: "",
        description6: ".",
        description7: "",
        description8: "",
        description9: "",
        description10: "",
        description11: "",
      },
    ],
  },

  {
    title: " İHTİLAFLAR",
    details: [
      {
        description1:
          "Anlaşmazlık halinde Karabük Mahkeme ve İcra Daireleri yetkili olacaktır.",
        description2: " ",
        description3: "",
        description4: "  ",
        description5: "",
        description6: ".",
        description7: "",
        description8: "",
        description9: "",
        description10: "",
        description11: "",
      },
    ],
  },

  {
    title: "  ATIK YÖNETİMİ",
    details: [
      {
        description1:
          "Atık yönetiminin ve döngüsel ekonomi uygulamalarının güçlendirilmesi, doğal kaynakların sürdürülebilir kullanımı, çevre ve insan sağlığının korunması yaşamsal önemde olup, mevcut ve gelecek kuşaklara daha kaliteli ve sürdürülebilir bir yaşam ortamı sağlanmasında temel faktördür. Bu bilinçle Kardemir operasyonları neticesinde oluşan atıklar niteliklerine göre ve yasal düzenlemelerde belirtilen koşullarda yönetilmektedir.",
        description2:
          "Kardemir faaliyetlerinin çeşitli süreçlerinde oluşan atıklar, öncelikle şirket içinde veya şirket dışında yeniden kullanım olanağı olup olmaması durumuna göre ayrılır. Yeniden kullanım olanağı olan süreç çıktıları kullanım alanına yönlendirilir. Geri kazanılamayan atıklar kaynağında sınıflandırılarak ayrıştırılır, atık türüne uygun koşullarda geri dönüştürülür. Sadece geri dönüştürülemeyen atıklar ise yasalarda düzenlenen teknik ve koşullara uygun olarak deşarj ya da bertaraf edilir.",
        description3: "",
        description4: "  ",
        description5: "",
        description6: ".",
        description7: "",
        description8: "",
        description9: "",
        description10: "",
        description11: "",
      },
    ],
  },

  {
    title: " DİĞER",
    details: [
      {
        description1:
          "İş bu SATIŞ GENEL ŞARTLARI, açıkça aksi belirtilmedikçe MÜŞTERİ ’nin verdiği tüm siparişler için geçerlidir.",
        description2: "",
        description3: "",
        description4: "  ",
        description5: "",
        description6: ".",
        description7: "",
        description8: "",
        description9: "",
        description10: "",
        description11: "",
      },
    ],
  },
];

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

function SatisIslemleriContents() {
  return (
    <>
      {/* <Typography
        sx={{ fontSize: { xs: "1.5rem", lg: "2rem" }, fontWeight: 600 }}
        mb={2}
      >
        Mamul - Yarı Mamul ve Yan Ürünler Satış Genel İşlemleri
      </Typography> */}

      <Header variant="h4" color="#1f2e5e">
        Mamul - Yarı Mamul ve Yan Ürünler Satış Genel İşlemleri
      </Header>

      <Box sx={{ margin: { xs: "0", lg: "2rem " } }}>
        {certificates.map((section, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
            >
              <Paper
                elevation={2}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: 2,
                  width: "100%",
                  mb: 1,
                  backgroundColor: "#1f2e5e",
                }}
              >
                <Typography sx={{ fontWeight: 500, color: "#fff" }}>
                  {section.title}
                </Typography>
              </Paper>
            </AccordionSummary>
            <AccordionDetails>
              {section.details.map((detail, idx) => (
                <Paper
                  elevation={2}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    p: 2,
                    mb: 1,
                    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  }}
                >
                  {detail.description1}
                  <br />
                  {detail.description2}
                  <br />
                  {detail.description3}
                  <br />
                  {detail.description4}

                  <br />
                  <br />
                  <br />
                  {detail.description5}
                  <br />
                  {detail.description6}
                  <br />
                  {detail.description7}
                  <br />
                  {detail.description8}
                  <br />
                  {detail.description9}
                  <br />
                  {detail.description10}
                  <br />
                  {detail.description11}
                  <br />
                  {detail.description12}
                </Paper>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>

      <Typography variant="h4">ADR Ürünleri</Typography>

      <Box sx={{ margin: { xs: "0", lg: "2rem " } }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Paper
              elevation={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 2,
                width: "100%",
                mb: 1,
                backgroundColor: "#1f2e5e",
              }}
            >
              <Typography sx={{ fontWeight: 600, color: "#fff" }}>
                Tehlikeli Maddelerin Karayolu ile Taşınması
              </Typography>
            </Paper>
          </AccordionSummary>
          <AccordionDetails>
            <Paper
              elevation={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                p: 2,
                mb: 1,
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              }}
            >
              Ulaştırma, Denizcilik ve Haberleşme Bakanlığı tarafından
              hazırlanan Tehlikeli Maddelerin Karayolu ile Taşınması Hakkındaki
              Yönetmelikte, tehlikeli maddelerin; insan sağlığı ve diğer canlı
              varlıklar ile çevreye zarar vermeden güvenli ve düzenli bir
              şekilde kamuya açık karayoluyla taşınmasını sağlamak; bu
              faaliyetlerde yer alan gönderenlerin, alıcıların, dolduranların,
              yükleyenlerin, boşaltanların, paketleyenlerin, taşımacıların ve
              tehlikeli maddeleri taşıyan her türlü taşıt sürücüleri veya
              operatörlerinin hak, sorumluluk, yükümlülük ve çalışma koşullarına
              ilişkin usul ve esaslar belirtilmiştir.
              <br />
              <br />
              Bu yönetmelik kapsamında şirketimizde on üç kalem ürünün satışı
              yapılmaktadır. Bu ürünlere ait gerekli bilgilere ulaşmak için
              tıklayın
            </Paper>
          </AccordionDetails>
        </Accordion>
      </Box>

      <Typography variant="h4">
        Şikayet Bildirimi ve Çözümlenme Süreci
      </Typography>

      <Box sx={{ margin: { xs: "0", lg: "2rem " } }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Paper
              elevation={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 2,
                width: "100%",
                mb: 1,
                backgroundColor: "#1f2e5e",
              }}
            >
              <Typography sx={{ fontWeight: 600, color: "#fff" }}>
                Şikayet Bildirimi
              </Typography>
            </Paper>
          </AccordionSummary>
          <AccordionDetails>
            <Paper
              elevation={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                p: 2,
                mb: 1,
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              }}
            >
              Test ve analizler ile ilgili şikayetleriniz/önerileriniz ile
              ilgili bildirimlerinizi laboratuvar@kardemir.com mail
              adreslerinden yapabilirsiniz.
              <br />
              <br />
              Eğer şikayetiniz deney/test raporu ile ilgili ise rapor numarası,
              tarihi ve numune adı kayıtlarını tarafımıza bildirmenizi rica
              ederiz.
              <br />
              <br />
              Test/Deney talep konusu numunenin Laboratuvarlarımıza
              ulaştırılmasında sorumluluk Müşteriye ait olduğundan, numune
              teslimine kadar oluşabilecek (ambalajlanması ve taşınması)
              hasarlardan Şirketimiz Laboratuvarları sorumlu değildir. Bu yönde
              yapılacak şikayetler değerlendirmeye alınmayacaktır.
              <br />
              <br />
              Şikayetiniz yukarıda belirtilen mail adresine ulaştıktan sonra
              Kalite Güvence Metalurji ve Laboratuvarlar Müdürlüğü tarafından
              incelemeye alınır. Şikayetler için hedeflenen sonuçlandırma süresi
              10 gündür.
              <br />
              <br />
              Test/Deney raporları için şikayetlerin deney raporu tarihinden
              itibaren 15 gün içinde yapılması gerekmektedir. Bu süre içerisinde
              iletilmeyen şikayetler değerlendirmeye alınmayacaktır.
              <br />
              <br />
              <img
                src={foto1}
                alt="Logo 1"
                style={{ width: "80%", margin: "0 auto" }}
              />
            </Paper>
          </AccordionDetails>
        </Accordion>
      </Box>

      <Box sx={{ margin: "2rem " }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Paper
              elevation={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 2,
                width: "100%",
                mb: 1,
                backgroundColor: "#1f2e5e",
              }}
            >
              <Typography sx={{ fontWeight: 600, color: "#fff" }}>
                Çözümlenme Süreci
              </Typography>
            </Paper>
          </AccordionSummary>
          <AccordionDetails>
            <Paper
              elevation={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                p: 2,
                mb: 1,
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              }}
            >
              Şikâyetleriniz ile ilgili bildirimlerinizi
              musteriteknikhizmetler@kardemir.com / satis@kardemir.com mail
              adreslerinden, 0 370 419 10 93-56 no.lu faks numaralarından ya da
              Satış Müdürlüğü'ne elden ulaştırabilirsiniz. Eğer şikâyetiniz ürün
              ile ilgili ise: ürün ismi, ürüne ait sipariş numarası, ürüne ait
              döküm numarası ve şikâyete ait ek dosyalar: fotoğraf, video vb.
              dokümanlarla birlikte bildirmenizi rica ederiz.
              <br />
              <br />
              Şikâyetiniz tarafımıza ulaştıktan sonra Kalite ve Üretim
              Ünitelerimiz tarafından incelemeye alınır. Şikâyetinizin
              değerlendirilmesi ile alakalı geri bildirim inceleme sürecimizin
              her aşamasında otomatik olarak mail yoluyla tarafınıza bildirilir.
              <br />
              <br />
              Ayrıca standart malzemelerde kalite şikâyetlerinin 30 gün içinde
              yapılması gerekir. Bu süre içerisinde iletilmeyen kalite
              şikâyetlerinin şirketimizce dikkate alınmayacağını bildirir, 30
              gün içinde bize ulaşan şikâyetlerinize ait hedeflediğimiz
              sonuçlandırma sürelerimiz ise 18 gündür, Otomotiv şikayetleri 16
              gün, Demiryolu Tekeri şikayetleri 15 gündür.
              <br />
              <br />
              <img
                src={foto2}
                alt="Logo 1"
                style={{ width: "40%", margin: "0 auto" }}
              />
            </Paper>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
}

export default SatisIslemleriContents;
