import React from "react";
import OrtaklikYapisi from "./yatirimciPages/OrtaklikYapisiYatirim";
import Komiteler from "./yatirimciPages/Komiteler";
import { Box } from "@mui/material";
import AnaSozlesme from "./yatirimciPages/AnaSozlesme";
// import TicaretSicil from "./yatirimciPages/TicaretSicil";
// import BagimsizDenetci from "./yatirimciPages/BagimsizDenetci";
import GenelKurul from "./yatirimciPages/GenelKurul";
// import GenelKurulSorular from "./yatirimciPages/GenelKurulSorular";
// import OzelDurum from "./yatirimciPages/OzelDurum";
import Politikalar from "./yatirimciPages/Politikalar";
// import Tahvil from "./yatirimciPages/Tahvil";
import OzetMali from "./yatirimciPages/OzetMali";
import KarsilastirmaliGrafik from "./yatirimciPages/KarsilastirmaliGrafik";
// import EkonomikTakvim from "./yatirimciPages/EkonomikTakvim";
import HisseDetay from "./yatirimciPages/HisseDetay";
import HisseHesaplayici from "./yatirimciPages/HisseHesaplayici";
import AnalistBilgileri from "./yatirimciPages/Analist";
import Sunumlar from "./yatirimciPages/Sunumlar";
import Kredi from "./yatirimciPages/Kredi";

function YatirimciIliskileri({ collapse }) {
  return (
    <Box>
      {collapse === "Ortaklık Yapısı" ? (
        <OrtaklikYapisi />
      ) : collapse === "Komiteler" ? (
        <Komiteler />
      ) : collapse === "Ana Sözleşme" ? (
        <AnaSozlesme />
      ) : collapse === "Genel Kurul" ? (
        <GenelKurul />
      ) : collapse === "Politikalar" ? (
        <Politikalar />
      ) : collapse === "Özet Mali Tablolar" ? (
        <OzetMali />
      ) : collapse === "Karşılaştırmalı Grafik" ? (
        <KarsilastirmaliGrafik />
      ) : collapse === "Hisse Detay Grafik" ? (
        <HisseDetay />
      ) : collapse === "Hisse Hesaplayıcı" ? (
        <HisseHesaplayici />
      ) : collapse === "Analist Bilgileri" ? (
        <AnalistBilgileri />
      ) : collapse === "Sunumlar, Raporlar ve Tablolar" ? (
        <Sunumlar />
      ) : collapse === "Kredi Derecelendirme" ? (
        <Kredi />
      ) : null}
    </Box>
  );
}

export default YatirimciIliskileri;
