import React, { useState } from "react";
import SolYapiKariyer from "./SolYapiKariyer";

function NedenKardemir() {
  const [page, setPage] = useState("Neden Kardemir?");

  return (
    <>
      <SolYapiKariyer setPage={setPage} page={page} />
    </>
  );
}
export default NedenKardemir;
