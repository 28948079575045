import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
  overflowX: "auto",
  maxWidth: 800, // Genişliği sınırlamak için maxWidth ayarlandı
  margin: "0 auto", // Merkezde hizalama sağlamak için margin kullanıldı
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

const HisseDetayContents = () => {
  // Hisse senedi seçeneklerini tanımla
  const hisseSecenekleri = [
    { label: "KRDMA", value: "KRDMA" },
    { label: "KRDMB", value: "KRDMB" },
    { label: "KRDMD", value: "KRDMD" },
  ];

  // Seçili hisse senedi için state tanımla
  const [selectedHisse, setSelectedHisse] = useState("KRDMA");

  // Hisse verilerini tanımla (örnek veriler)
  const hisseVerileri = {
    KRDMA: [
      { name: "06/05", price: 22.64 },
      { name: "13/05", price: 22.94 },
      { name: "27/05", price: 26.18 },
      { name: "03/06", price: 24.82 },
      { name: "10/06", price: 23.8 },
      { name: "24/06", price: 24.68 },
      { name: "01/07", price: 24.24 },
      { name: "08/07", price: 25.86 },
      { name: "15/07", price: 26.2 },
      { name: "22/07", price: 25.62 },
      { name: "29/07", price: 23.78 },
    ],
    KRDMB: [
      { name: "06/05", price: 21.2 },
      { name: "13/05", price: 22.94 },
      { name: "27/05", price: 26.18 },
      { name: "03/06", price: 24.82 },
      { name: "10/06", price: 21.84 },
      { name: "24/06", price: 22.42 },
      { name: "01/07", price: 21.86 },
      { name: "08/07", price: 22.92 },
      { name: "15/07", price: 23.04 },
      { name: "22/07", price: 22.98 },
      { name: "29/07", price: 21.9 },
    ],
    KRDMD: [
      { name: "06/05", price: 22.64 },
      { name: "13/05", price: 24.94 },
      { name: "27/05", price: 26.18 },
      { name: "03/06", price: 28.82 },
      { name: "10/06", price: 23.8 },
      { name: "24/06", price: 32.14 },
      { name: "01/07", price: 24.24 },
      { name: "08/07", price: 25.86 },
      { name: "15/07", price: 26.2 },
      { name: "22/07", price: 28.62 },
      { name: "29/07", price: 29.94 },
    ],
  };

  // Hisse senedi seçimini değiştirme fonksiyonu
  const handleHisseChange = (event) => {
    setSelectedHisse(event.target.value);
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 1 }}>
      <Header variant="h4" color="#1f2e5e">
        Hisse Detay Grafikleri
      </Header>

      <StyledPaper elevation={3} sx={{ marginBottom: "2rem" }}>
        <FormControl fullWidth>
          <InputLabel id="hisse-secim-label">Hisse Senedi</InputLabel>
          <Select
            labelId="hisse-secim-label"
            id="hisse-secim"
            value={selectedHisse}
            label="Hisse Senedi"
            onChange={handleHisseChange}
            sx={{ marginBottom: "2rem" }}
          >
            {hisseSecenekleri.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={hisseVerileri[selectedHisse]}>
            <CartesianGrid strokeDasharray="1 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="price"
              stroke="#d1111a"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </StyledPaper>
    </Box>
  );
};

export default HisseDetayContents;
