import React from "react";
import { Box, Typography, Paper, Divider } from "@mui/material";
import { styled } from "@mui/system";

const data = [
  { year: "1937", events: ["Kuruluş", "Temellerin Atılması"] },
  { year: "1938", events: ["Makine Montajları"] },
  {
    year: "1939",
    events: [
      "Kuvvet Santrali",
      "Kok Fabrikası",
      "1 No.lu Yüksek Fırın",
      "İlk Türk Demiri",
    ],
  },
  { year: "1940", events: ["Çelikhane", "Trio Haddehane"] },
  { year: "1950", events: ["2 No.lu Yüksek Fırın"] },
  { year: "1953", events: ["Sinter"] },
  { year: "1955", events: ["Sümerbank’tan Ayrılması"] },
  { year: "1960", events: ["Düo Haddehane"] },
  { year: "1962", events: ["3 No.lu Yüksek Fırın"] },
  { year: "1964", events: ["Düo Profil Haddehanesi"] },
  { year: "1965", events: ["Kontinü Haddehane"] },
  { year: "1966", events: ["Çelik Konstrüksiyon"] },
  { year: "1967", events: ["Dökümhaneler"] },
  { year: "1976", events: ["Statü Değişikliği"] },
  { year: "1989", events: ["Grev"] },
  {
    year: "1994",
    events: [
      "Kapatılma Kararının Alınması",
      "Özelleştirilme Kararının Alınması",
    ],
  },
  {
    year: "1995",
    events: ["Özelleştirilmesi", "Hisselerinin Satışa Çıkarılması"],
  },
  {
    year: "1998",
    events: ["Pota Fırını", "Kütük Makinesi", "Borsaya Açılması"],
  },
  {
    year: "1999",
    events: [
      "Kireç Fabrikası ve Hava Ayrıştırma Tesisleri",
      "Çelikhane Konverter",
    ],
  },
  { year: "2000", events: ["Blum Makinesi"] },
  {
    year: "2007",
    events: ["Ray ve Profil Haddehanesi", "72 metre Boyunda İlk Ray Sevkiyatı"],
  },
  {
    year: "2008",
    events: ["Yeni Tesislerinin İşletmeye Alınması", "4 No.lu Yüksek Fırın"],
  },
  {
    year: "2011",
    events: [
      "Yeni Sinter Tesisi",
      "Kapasite Arttırımları",
      "3. Hava Ayrıştırma Tesisi",
    ],
  },
  {
    year: "2012",
    events: ["Hava Kalitesi İzleme İstasyonu", "Yeni Kireç Fabrikası"],
  },
  {
    year: "2013",
    events: [
      "3. Sürekli Döküm Makinesi",
      "Yeni Enerji Santrali",
      "Demiryolu Tekeri Üretim Tesisi",
      "Yeni Kok Fırınları",
    ],
  },
  {
    year: "2014",
    events: [
      "Eğitim ve Kültür Merkezi",
      "Merkezi Arıtma Tesisi",
      "3. Konverter",
      "Hidroelektrik Santrali",
      "Sinter Makinesi",
    ],
  },
  { year: "2015", events: ["5. Yüksek Fırın", "Üretim Rekoru"] },
  {
    year: "2016",
    events: ["İlk Kangal Üretimi", "Torpidoda Kükürt Giderme Tesisi"],
  },
  { year: "2017", events: ["TSI Sertifikası", "Kalite Ödülü"] },
  { year: "2018", events: ["IV. Hava Ayrıştırma Tesisi"] },
  {
    year: "2019",
    events: [
      "Kireç Fabrikası",
      "Yeni Sürekli Döküm Makinesi(SDM4)",
      "Sinter Tesisleri Meros Sistemi",
    ],
  },
  { year: "2020", events: ["Dijital Dönüşüm"] },
  { year: "2021", events: ["Demiryolu Tekeri Üretim Tesisi"] },
  { year: "2022", events: ["Konverter-3"] },
  { year: "2023", events: ["4 Nolu Pota Fırını ve SVC Sistemi"] },
];

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

const TimelineContainer = styled(Box)({
  position: "relative",
  padding: "1rem",
  borderRadius: "18px",
  backgroundColor: "#1f2e5e",
  width: "90%",
  margin: "2rem auto",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: "50%",
    width: "2px",
    height: "100%",
    backgroundColor: "#ddd",
    transform: "translateX(-50%)",
  },
});

const TimelineItem = styled(Paper)(({ theme }) => ({
  position: "relative",
  width: "40%",
  padding: "1rem",
  backgroundColor: "#f5f5f5",
  marginBottom: "1rem",
  borderBottomRightRadius: "20px",
  borderTopLeftRadius: "20px",

  // marginRight: "1rem",
  // marginLeft: "1rem",
  textAlign: "left",
  "&:nth-of-type(odd)": {
    marginLeft: "auto",
  },
  "&:nth-of-type(even)": {
    marginRight: "auto",
    textAlign: "left",
  },
  "&::before": {
    content: '""',
    position: "absolute",
    top: "46%",
    left: "-2%",
    // left: ({ side }) => (side === "left" ? "-10px" : "auto"),
    right: ({ side }) => (side === "right" ? "-10px" : "auto"),
    width: "18px",
    height: "18px",
    borderRadius: "50%",
    backgroundColor: "#1f2e5e",
    transform: "translateY(-50%)",
  },
}));

const KilometreTaslari2Context = () => {
  return (
    <Box>
      <Header variant="h4" color="#1f2e5e">
        Kilometre Taşlarımız
      </Header>
      <TimelineContainer>
        {data.map((item, index) => (
          <TimelineItem key={index} side={index % 2 === 0 ? "left" : "right"}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "600", color: "#1f2e5e" }}
            >
              {item.year}
            </Typography>
            <Divider sx={{ my: 1, backgroundColor: "#1f2e5e" }} />
            <Typography sx={{ color: "#1f2e5e" }}>
              {item.events.join(", ")}
            </Typography>
          </TimelineItem>
        ))}
      </TimelineContainer>
    </Box>
  );
};

export default KilometreTaslari2Context;
