import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
  overflowX: "auto",
  maxWidth: 800, // Genişliği sınırlamak için maxWidth ayarlandı
  margin: "0 auto", // Merkezde hizalama sağlamak için margin kullanıldı
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: "#1f2e5e",
  fontWeight: "bold",
  marginBottom: theme.spacing(2),
}));

const Table = styled("table")(({ theme }) => ({
  width: "100%",
  borderCollapse: "collapse",
  "& th, & td": {
    padding: theme.spacing(2),
    textAlign: "left",
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  "& th": {
    backgroundColor: "#f5f5f5",
    // color: "#1f2e5e",
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  "& td": {
    backgroundColor: "#f5f5f5",
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1f2e5e",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

function KomitelerContents() {
  function createData(adSoyad, görevi, iliski) {
    return { adSoyad, görevi, iliski };
  }

  function createData2(adSoyad, görevi, iliski) {
    return { adSoyad, görevi, iliski };
  }

  function createData3(adSoyad, görevi, iliski) {
    return { adSoyad, görevi, iliski };
  }

  function createData4(adSoyad, görevi, iliski) {
    return { adSoyad, görevi, iliski };
  }

  const rows = [
    createData("H. Abdullah KAYA", "Başkan", "Bağımsız Yönetim Kurulu Üyesi"),
    createData(
      "Prof. Dr. Arif Nihat GÜLLÜOĞLU",
      "Üye",
      "Bağımsız Yönetim Kurulu Üyesi"
    ),
    createData(
      "Prof. Dr. Mehmet Cahit ENSARİ",
      "Üye",
      "Bağımsız Yönetim Kurulu Üyesi"
    ),
  ];

  const rows2 = [
    createData2("H. Abdullah KAYA", "Başkan", "Bağımsız Yönetim Kurulu Üyesi"),
    createData2("Mustafa AYDIN", "Üye", "Yönetim Kurulu Üyesi"),
    createData2("Muhammed Ali OFLAZ", "Üye", "Yönetim Kurulu Üyesi"),
  ];

  const rows3 = [
    createData3(
      "Prof. Dr. Ercümend ARVAS",
      "Başkan",
      "Bağımsız Yönetim Kurulu Üyesi"
    ),
    createData3("Ömer DEMİRHAN", "Üye", "Yönetim Kurulu Üyesi"),
    createData3("Fatih Mehmet ERKOÇ", "Üye", "Yönetim Kurulu Üyesi"),
    createData3("Yusuf CANBOLAT", "Üye", "Yönetim Kurulu Üyesi"),
    createData3("Burcay YAVUZ", "Üye", "Yatırımcı İlişkileri Yöneticisi"),
  ];

  const rows4 = [
    createData4(
      "Prof. Dr. Mehmet Cahit ENSARİ",
      "Başkan",
      "Bağımsız Yönetim Kurulu Üyesi"
    ),
    createData4("Prof. Dr. İsmail DEMİR", "Üye", "Yönetim Kurulu Üyesi"),
    createData4("Kamil GÜLEÇ", "Üye", "Yönetim Kurulu Üyesi"),
  ];

  return (
    <Box sx={{ flexGrow: 1, padding: 1 }}>
      <Header variant="h4" color="#1f2e5e">
        Komiteler
      </Header>

      <StyledPaper elevation={3} sx={{ marginBottom: "2rem" }}>
        <Box>
          <Typography
            sx={{
              borderBottom: "1px solid #19467d",

              color: "#1f2e5e",
              fontSize: "2rem",
              fontWeight: "bold",
            }}
          >
            Denetimden Sorumlu Komite
          </Typography>
          <Box sx={{ margin: { xs: "0", md: "2rem " } }}>
            <Box sx={{ marginTop: "1rem", width: "100%" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Ad Soyad</StyledTableCell>
                      <StyledTableCell>Görevi</StyledTableCell>
                      <StyledTableCell>Şirket İle İlişkisi</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow>
                        <StyledTableCell align="right">
                          {row.adSoyad}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.görevi}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.iliski}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </StyledPaper>

      <StyledPaper elevation={3} sx={{ marginBottom: "2rem" }}>
        <Box>
          <Typography
            sx={{
              borderBottom: "1px solid #19467d",

              color: "#1f2e5e",
              fontSize: "2rem",
              fontWeight: "bold",
            }}
          >
            Riskin Erken Saptanması Komitesi
          </Typography>
          <Box sx={{ margin: { xs: "0", md: "2rem " } }}>
            <Box sx={{ marginTop: "1rem", width: "100%" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Ad Soyad</StyledTableCell>
                      <StyledTableCell>Görevi</StyledTableCell>
                      <StyledTableCell>Şirket İle İlişkisi</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows2.map((row) => (
                      <StyledTableRow>
                        <StyledTableCell align="right">
                          {row.adSoyad}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.görevi}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.iliski}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </StyledPaper>

      <StyledPaper elevation={3} sx={{ marginBottom: "2rem" }}>
        <Box>
          <Typography
            sx={{
              borderBottom: "1px solid #19467d",

              color: "#1f2e5e",
              fontSize: "2rem",
              fontWeight: "bold",
            }}
          >
            Kurumsal Yönetim Komitesi
          </Typography>
          <Box sx={{ margin: { xs: "0", md: "2rem " } }}>
            <Box sx={{ marginTop: "1rem", width: "100%" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Ad Soyad</StyledTableCell>
                      <StyledTableCell>Görevi</StyledTableCell>
                      <StyledTableCell>Şirket İle İlişkisi</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows3.map((row) => (
                      <StyledTableRow>
                        <StyledTableCell align="right">
                          {row.adSoyad}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.görevi}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.iliski}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </StyledPaper>

      <StyledPaper elevation={3} sx={{ marginBottom: "2rem" }}>
        <Box>
          <Typography
            sx={{
              borderBottom: "1px solid #19467d",

              color: "#1f2e5e",
              fontSize: "2rem",
              fontWeight: "bold",
            }}
          >
            Aday Belirleme Komitesi
          </Typography>
          <Box sx={{ margin: { xs: "0", md: "2rem " } }}>
            <Box sx={{ marginTop: "1rem", width: "100%" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Ad Soyad</StyledTableCell>
                      <StyledTableCell>Görevi</StyledTableCell>
                      <StyledTableCell>Şirket İle İlişkisi</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows4.map((row) => (
                      <StyledTableRow>
                        <StyledTableCell align="right">
                          {row.adSoyad}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.görevi}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.iliski}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </StyledPaper>

      <StyledPaper elevation={3} sx={{ marginBottom: "2rem" }}>
        <SectionTitle>
          Yönetim Kurulu Bünyesinde Oluşturulan Komitelerin Yapısı, Görev
          Alanları ve Çalışma Esasları
        </SectionTitle>
        <Box sx={{ mt: 2 }}>
          <Typography>
            <b>Oluşturulan Komitelerin Yapısı:</b>
          </Typography>
          <List sx={{ width: "100%", paddingLeft: "2rem" }}>
            {[
              "Yönetim Kurulu, bünyesinde oluşturacağı Denetimden Sorumlu Komite, Kurumsal Yönetim Komitesi ile Riskin Erken Saptanması Komitesi’nin üyelerini seçer. Kurumsal Yönetim Komitesi Sermaye Piyasası Kurulu düzenlemelerinde yer alan; Aday Gösterme Komitesi ve Ücret Komitesi’nin görevlerini de üstlenir.",
              "Komitelerin görev alanları ve çalışma esasları Sermaye Piyasası Kurulu düzenlemeleri de dikkate alınarak Yönetim Kurulu tarafından belirlenir.",
              "İcra Başkanı / Genel Müdür komitelerde görev alamaz.",
              "Yönetim Kurulu Üyeleri gerekmedikçe birden fazla komitede görev alamaz.",
              "Komitelerin görevlerini yerine getirmeleri için gereken her türlü kaynak ve destek Yönetim Kurulu tarafından sağlanır. Komiteler, gerekli gördükleri yöneticiyi toplantılarına davet edebilir ve görüşlerini alabilir.",
              "Komiteler, faaliyetleriyle ilgili olarak ihtiyaç gördükleri konularda bağımsız uzman görüşlerinden yararlanır. Komitelerin ihtiyaç duydukları danışmanlık hizmetlerinin bedeli şirket tarafından karşılanır.",
              "Komiteler, çalışmaların etkinliği için gerekli görülen sıklıkta toplanır, yaptıkları çalışmaları hakkındaki bilgiyi içeren raporlarını Yönetim Kurulu’na sunar.",
              "Şirketin, Sermaye Piyasası Kurulu Yönetim İlkelerine uyumunu izlemek, bu konuda iyileştirme çalışmalarında bulunmak ve yönetim kuruluna öneriler sunmak üzere kurulan Kurumsal Yönetim Komitesi iki üyeden oluşması halinde her ikisi de icrada görevli olmayan Yönetim Kurulu Üyelerinden oluşur.",
              "SPK düzenlemeleri ve Türk Ticaret Kanunu ile komitelere verilen/verilecek diğer görevleri yerine getirmek.",
            ].map((text, index) => (
              <ListItem key={index} sx={{ height: "4%", padding: "7px 0px" }}>
                <ListItemIcon sx={{ minWidth: "auto", marginRight: "8px" }}>
                  <FiberManualRecordIcon sx={{ fontSize: "10px" }} />
                </ListItemIcon>
                <ListItemText
                  primary={text}
                  primaryTypographyProps={{ fontSize: "14px" }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography>
            <b>Denetimden Sorumlu Komite:</b>
          </Typography>
          <List sx={{ width: "100%", paddingLeft: "2rem" }}>
            {[
              "Denetimden Sorumlu Komite en az iki üyeden oluşur.",
              "Komite üyelerinin tamamı Bağımsız Yönetim Kurulu Üyeleri arasından seçilir.",
              "Denetimden Sorumlu Komite’nin görev ve sorumluluğu, Yönetim Kurulu’nun Türk Ticaret Kanunu’ndan doğan sorumluluğunu ortadan kaldırmaz.",
              "Denetimden Sorumlu Komite; en az üç ayda bir olmak üzere yılda en az dört kere toplanır ve toplantı sonuçları tutanağa bağlanarak Yönetim Kurulu’na sunulur. Denetimden Sorumlu Komite kendi görev ve sorumluluk alanıyla ilgili olarak ulaştığı tespit ve önerileri Yönetim Kurulu’na yazılı olarak bildirir.",
              "Muhasebe sistemi, finansal bilgilerin kamuya açıklanması, bağımsız denetim ve iç kontrol sisteminin işleyişinin ve etkinliğinin gözetimini yapar.",
              "Bağımsız Denetim Kuruluşu’nu seçer ve bu kuruluştan alınacak hizmetleri belirleyerek Yönetim Kurulu’nun onayına sunar.",
              "Bağımsız denetim sözleşmelerinin hazırlanarak bağımsız denetim sürecinin başlatılmasını ve Bağımsız Denetim Kuruluşu’nun her aşamadaki çalışmalarını gözetir.",
              "Komite, sorumlu yöneticiler ve bağımsız denetçilerinin görüşlerini alarak, kamuya açıklanacak yıllık ve ara dönem finansal tabloların, izlenen muhasebe ilkelerine, gerçeğe uygunluğuna ve doğruluğuna ilişkin değerlendirmelerini Yönetim Kurulu’na yazılı olarak bildirir.",
              "Muhasebe ve iç kontrol sistemi ile bağımsız denetimiyle ilgili olarak Şirkete ulaşan şikayetlerin incelenmesi, sonuca bağlanması, Şirket çalışanlarının, Şirketin muhasebe ve bağımsız denetim konularındaki bildirimlerinin gizlilik ilkesi çerçevesinde değerlendirilmesi konularında uygulanacak yöntem ve kriterleri belirler.",
            ].map((text, index) => (
              <ListItem key={index} sx={{ height: "4%", padding: "7px 0px" }}>
                <ListItemIcon sx={{ minWidth: "auto", marginRight: "8px" }}>
                  <FiberManualRecordIcon sx={{ fontSize: "10px" }} />
                </ListItemIcon>
                <ListItemText
                  primary={text}
                  primaryTypographyProps={{ fontSize: "14px" }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography>
            <b>Kurumsal Yönetim Komitesi:</b>
          </Typography>
          <List sx={{ width: "100%", paddingLeft: "2rem" }}>
            {[
              "Kurumsal Yönetim Komitesi’nin başkanı Bağımsız Yönetim Kurulu Üyeleri arasından seçilir. Komitenin iki üyeden oluşması halinde her ikisi, ikiden fazla üyesinin bulunması halinde ise üyelerin çoğunluğu, icrada görevli olmayan Yönetim Kurulu Üyeleri’nden oluşur.",
              "Kurumsal Yönetim Komitesi, Sermaye Piyasası Kurulu düzenlemelerinde yer alan; Aday Gösterme Komitesi ve Ücret Komitesi’nin görevlerini de üstlenir.",
              "Komite Yönetim Kurulu’na kurumsal yönetim uygulamalarını iyileştirici tavsiyelerde bulunur.",
              "Pay sahipleri ile ilişkiler biriminin çalışmalarını gözetir.",
              "Yönetim Kurulu’na uygun adayların saptanması, değerlendirilmesi ve eğitilmesi konularında şeffaf bir sistemin oluşturulması ve bu hususta politika ve stratejiler belirlenmesi konularında çalışmalar yapar.",
              "Komite bağımsız üyeler ile ilgili aday tekliflerini, adayın bağımsızlık ölçütlerini taşıyıp taşımadığını dikkate alarak değerlendirir ve değerlendirmesine ilişkin raporunu Yönetim Kurulu onayına sunar.",
              "Bağımsız Yönetim Kurulu Üye adayı, mevzuat, Ana Sözleşme ve kurumsal yönetim ilkelerinde yer alan kriterler çerçevesinde bağımsız olduğuna ilişkin yazılı bir beyanı aday gösterildiği sırada komiteye verir.",
              "Yönetim Kurulu’nun yapısı ve verimliliği hakkında yapılabilecek değişikliklere ilişkin tavsiyelerini Yönetim Kurulu’na sunar.",
              "Yönetim Kurulu Üyeleri’nin ve üst düzey yöneticilerin performans değerlendirmesi ve kariyer planlaması konusundaki yaklaşım, ilke ve uygulamalara ilişkin tavsiyelerini Yönetim Kurulu’na sunar.",
              "Şirketin Genel Kurulun onayından geçmiş ücretlendirme politikalarını Yönetim kurulu adına takip eder.",
            ].map((text, index) => (
              <ListItem key={index} sx={{ height: "4%", padding: "7px 0px" }}>
                <ListItemIcon sx={{ minWidth: "auto", marginRight: "8px" }}>
                  <FiberManualRecordIcon sx={{ fontSize: "10px" }} />
                </ListItemIcon>
                <ListItemText
                  primary={text}
                  primaryTypographyProps={{ fontSize: "14px" }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography>
            <b>Riskin Erken Saptanması Komitesi:</b>
          </Typography>
          <List sx={{ width: "100%", paddingLeft: "2rem" }}>
            {[
              "Riskin Erken Saptanması Komitesi’nin başkanı Bağımsız Yönetim Kurulu Üyeleri arasından seçilir. Komitenin iki üyeden oluşması halinde her ikisi, ikiden fazla üyesinin bulunması halinde ise üyelerin çoğunluğu, icrada görevli olmayan Yönetim Kurulu Üyeleri’nden oluşur.",
              "Şirketin varlığını, gelişmesini ve devamını tehlikeye düşürebilecek risklerin erken teşhisi, tespit edilen risklerle ilgili gerekli önlemlerin uygulanması ve riskin yönetilmesi amacıyla çalışmalar yapar.",
              "Risk yönetim sistemlerini en az yılda bir kez gözden geçirir.",
            ].map((text, index) => (
              <ListItem key={index} sx={{ height: "4%", padding: "7px 0px" }}>
                <ListItemIcon sx={{ minWidth: "auto", marginRight: "8px" }}>
                  <FiberManualRecordIcon sx={{ fontSize: "10px" }} />
                </ListItemIcon>
                <ListItemText
                  primary={text}
                  primaryTypographyProps={{ fontSize: "14px" }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </StyledPaper>
    </Box>
  );
}

export default KomitelerContents;
