import { Typography, Box, Paper } from "@mui/material";
import foto1 from "../../images/karcimsa.png";
import foto2 from "../../images/ermaden.png";
import foto3 from "../../images/vademsas.png";
import foto4 from "../../images/epias.png";
import foto5 from "../../images/teknopark.png";
import foto1ortak from "../../images/kardokmak.png";
import foto2ortak from "../../images/karcel4.png";
import foto3ortak from "../../images/kardemirenerji.png";
import foto4ortak from "../../images/karlimtas2.png";
import { styled } from "@mui/material/styles";
import image from "../../images/image2.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/free-mode";
import { useEffect } from "react";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
  overflowX: "auto",
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

const linksOrtak = [
  "https://www.kardokmak.com.tr/",
  "https://www.karcel.com.tr/",
  "https://www.karsigorta.net/",
  "https://enbatielektrik.com/",
];

const linkIstirak = [
  "http://www.karcimsa.com.tr/",
  "https://www.erdemirmaden.com.tr/",
  "https://www.voestalpine.com/vademsas/tr/",
  "http://www.epias.com.tr/",
  "https://www.karabuktgb.com.tr/",
];

function BagliOrtakliklarVeIstirakler() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box
        component="img"
        alt=""
        src={image}
        style={{
          width: "100%",
          height: "30rem",
          cursor: "pointer",
        }}
      />
      <Box sx={{ flexGrow: 1, padding: 1 }}>
        {/* <Header variant="h4" sx={{ textDecoration: "underline" }}>
          Bağlı Ortaklıklarımız ve İştiraklerimiz{" "}
        </Header> */}

        <StyledPaper elevation={3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              mt: "2rem",
            }}
          >
            {/* <Typography>Bağlı Ortaklıklar</Typography> */}
            <Header variant="h4">Bağlı Ortaklıklar</Header>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: "2rem",
                position: "relative",
              }}
            >
              <Swiper
                slidesPerView="auto"
                spaceBetween={20}
                freeMode={true}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                navigation={true}
                modules={[Navigation]}
                style={{
                  width: "90%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  padding: "0 10px",
                }}
              >
                {[foto1ortak, foto2ortak, foto3ortak, foto4ortak].map(
                  (foto, index) => (
                    <SwiperSlide
                      key={index}
                      style={{
                        width: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <a
                        href={linksOrtak[index]}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={foto}
                          alt={`Logo ${index + 1}`}
                          style={{ width: "200px" }}
                        />
                      </a>
                    </SwiperSlide>
                  )
                )}
              </Swiper>
            </Box>

            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                mt: "1rem",
              }}
            >
              <img
                src={foto1ortak}
                alt="Logo 1"
                style={{ width: "200px", margin: "0 5px" }}
              />
              <img
                src={foto2ortak}
                alt="Logo 2"
                style={{ width: "200px", margin: "0 5px" }}
              />
              <img
                src={foto3ortak}
                alt="Logo 3"
                style={{ width: "200px", margin: "0 5px" }}
              />
              <img
                src={foto4ortak}
                alt="Logo 4"
                style={{ width: "200px", margin: "0 5px" }}
              />
            </Box> */}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              mt: "2rem",
            }}
          >
            {/* <Typography>İştiraklerimiz</Typography> */}
            <Header variant="h4">İştiraklerimiz</Header>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: "2rem",
                position: "relative",
              }}
            >
              <Swiper
                slidesPerView="auto"
                spaceBetween={20}
                freeMode={true}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                navigation={true}
                modules={[Navigation]}
                style={{
                  width: "90%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {[foto1, foto2, foto3, foto4, foto5].map((foto, index) => (
                  <SwiperSlide
                    key={index}
                    style={{
                      width: "auto",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <a
                      href={linkIstirak[index]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={foto}
                        alt={`Logo ${index + 1}`}
                        style={{ width: "200px" }}
                      />
                    </a>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          </Box>
          {/* <Header variant="h4">Bağlı Ortaklıklar</Header> */}
        </StyledPaper>
      </Box>
    </>
  );
}
export default BagliOrtakliklarVeIstirakler;
