import React, { useState } from "react";
import SolYapiYatirimci from "./SolYapiYatirimci";

function KarsilastirmaliGrafik() {
  const [page, setPage] = useState("Karşılaştırmalı Grafik");

  return <SolYapiYatirimci setPage={setPage} page={page} />;
}

export default KarsilastirmaliGrafik;
