import React, { useState } from "react";
import SolYapiTedarikci from "./SolYapiTedarikci";

function HurdaAlimFiyat() {
  const [page, setPage] = useState("Hurda Alım Fiyatları");

  return <SolYapiTedarikci setPage={setPage} page={page} />;
}

export default HurdaAlimFiyat;
