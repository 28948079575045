import React from "react";
import {
  Box,
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Link,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
  overflowX: "auto",
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: "#1f2e5e",
  fontWeight: "bold",
  marginBottom: theme.spacing(2),
}));

const certificates = [
  {
    title: "Kardemir Sürdürülebilirlik Raporları",
    details: [
      {
        name: "2022-Sürdürülebilirlik Raporu",
        link: "https://www.kardemir.com/dosyalar/sayfalar/1338/10112023/2023111014063998_sayfalar_1338_10112023.pdf?v=3d46910c_9227_d829_470f_8e6285172ab8",
      },
      {
        name: "2021-Sürdürülebilirlik Raporu",
        link: "https://www.kardemir.com/dosyalar/sayfalar/1338/29062022/2022062916412466_sayfalar_1338_29062022.pdf?v=bd54c1f9_1652_330e_8f99_cd2cd6967e16",
      },
      {
        name: "2020-Sürdürülebilirlik Raporu",
        link: "https://www.kardemir.com/dosyalar/sayfalar/1338/05082021/2021080508550667_sayfalar_1338_05082021.pdf?v=eb7ea1d2_a131_7764_d20d_1028a70f35c0",
      },
      {
        name: "2019-Sürdürülebilirlik Raporu",
        link: "https://www.kardemir.com/dosyalar/sayfalar/1338/22072020/2020072214385940_sayfalar_1309_22072020.pdf?v=c51fdc86_ccf9_1bf6_ddef_bec9c5307cc8",
      },
    ],
  },
];

const politikalar = [
  {
    title: "Politikalarımız ve Sertifikaları",
    details: [
      {
        name: "Kurumsal Risk Politikası",
        link: "https://www.kardemir.com/dosyalar/sayfalar/1190/31082020/2020083109074776_sayfalar_1190_31082020.pdf?v=3eaf6b48_8bb1_3750_b4e2_759d6d0c6bbc",
      },
      {
        name: "İnsan Kaynakları Politikası",
        link: "https://www.kardemir.com/dosyalar/sayfalar/1190/20122022/2022122016145798_sayfalar_1190_20122022.pdf?v=bba4426b_9731_2595_84cc_ef970948687e",
      },
      {
        name: "Eğitim Politikası",
        link: "https://www.kardemir.com/dosyalar/sayfalar/1190/31082020/2020083109024363_sayfalar_1190_31082020.pdf?v=b27cd36f_1266_b9a2_a744_8d7fdde2eb45",
      },
      {
        name: "İş Sağlığı ve Güvenliği Politikası ve Sertifikası",
        link: "https://www.kardemir.com/dosyalar/sayfalar/1190/06062024/2024060614560830_sayfalar_1190_06062024.pdf?v=a6f0bcd5_107c_1f09_b7a0_18e6534dd71f",
      },
      {
        name: "Güvenlik Yönetim (SEVESO) Politikası",
        link: "https://www.kardemir.com/dosyalar/sayfalar/1190/01092020/2020090108490406_sayfalar_1190_01092020.pdf?v=0d526c8e_90a2_6911_19f6_5048bbecde62",
      },
      {
        name: "Teknik Güvenlik Politikası",
        link: "https://www.kardemir.com/dosyalar/sayfalar/1190/31082020/2020083109034271_sayfalar_1190_31082020.pdf?v=d4a4bb41_cf75_cead_3698_2e6d6457224f",
      },
      {
        name: "Çevre Politikası ve Sertifikası",
        link: "https://www.kardemir.com/dosyalar/sayfalar/1190/14062022/2022061417155084_sayfalar_1190_14062022.pdf?v=6dee2cf5_1299_f892_190b_674afa021207",
      },
      {
        name: "Ar-Ge Politikası ve Belgemiz",
        link: "https://www.kardemir.com/dosyalar/sayfalar/1190/31082020/2020083109043545_sayfalar_1190_31082020.pdf?v=1b206edb_7219_ef24_cc20_8f0cae1ac48e",
      },
      {
        name: "Kalite Politikası, Sertifikası ve UK CARES",
        link: "https://www.kardemir.com/dosyalar/sayfalar/1190/31072024/2024073108314494_sayfalar_1190_31072024.pdf?v=2897abe5_d70d_a30f_0ca3_50ddc53d1428",
      },
      {
        name: "Bilgi Güvenliği Politikası ve Sertifikası",
        link: "https://www.kardemir.com/dosyalar/sayfalar/1190/06062024/2024060615015129_sayfalar_1190_06062024.pdf?v=7ead0899_02c2_f5e1_7e43_a062fe9de8f2",
      },
      {
        name: "Enerji Politikası ve Sertifikası",
        link: "https://www.kardemir.com/dosyalar/sayfalar/1190/20052024/2024052016533020_sayfalar_1190_20052024.pdf?v=3aee4733_5f92_ef60_d5a1_f79fda0ba876",
      },
      {
        name: "Laboratuvar Kalite-Tarafsızlık Politikası ve Sertifikaları",
        link: "https://www.kardemir.com/dosyalar/sayfalar/1190/23062023/2023062314082371_sayfalar_1190_23062023.pdf?v=ee961525_3413_cae4_5544_477f7faf72d2",
      },
      {
        name: "Müşteri Memnuniyeti Politikası ve Sertifikası",
        link: "https://www.kardemir.com/dosyalar/sayfalar/1190/28072023/2023072815491800_sayfalar_1190_28072023.pdf?v=2cf8e8a0_ed29_c851_056e_4e7e4c0143ca",
      },
      {
        name: "Otomotiv Kalite Yönetim Sistemi Sertifikası",
        link: "https://www.kardemir.com/dosyalar/sayfalar/1190/21112022/2022112109455628_sayfalar_1190_21112022.pdf?v=f287673b_51e5_0833_0e1e_a6a63e00fc61",
      },
      {
        name: "Demiryolu Uygulamaları Kalite Yönetim Sistemi Sertifikası",
        link: "https://www.kardemir.com/dosyalar/sayfalar/1190/24112022/2022112409094874_sayfalar_1190_24112022.PDF?v=14cd5c0b_9b1f_456f_1238_065e2c1191f3",
      },
      {
        name: "Tanınmış Marka",
        link: "https://www.kardemir.com/dosyalar/kurumsal/yonetimsistemleri/taninmismarka/KardemirTaninmisMarkaTescili.pdf",
      },
    ],
  },
];

function RaporveSertifikaContents() {
  return (
    <>
      <Box sx={{ flexGrow: 1, padding: 1 }}>
        <Header variant="h4" color="#1f2e5e">
          Rapor ve Sertifikaları
        </Header>
        <StyledPaper elevation={3}>
          <Typography
            variant="body1"
            sx={{ marginBottom: 2, textAlign: "justify" }}
          >
            Ulusal ve uluslararası standartlarında çelik üretirken, sahip
            olduğumuz yönetim sistemleri ve politikalarımız, sürekli iyileştirme
            disiplini ile tüm faaliyetlerimizde esas aldığımız iş mükemmelliğine
            hizmet etmektedir.
          </Typography>

          <SectionTitle variant="h5" mt={2}>
            Sürdürülebilirlik Raporlarımız
          </SectionTitle>

          <Box sx={{ marginX: { xs: "0", md: "2rem " } }}>
            {certificates.map((section, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index + 1}-content`}
                  id={`panel${index + 1}-header`}
                >
                  <Paper
                    elevation={2}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      p: 2,
                      width: "100%",
                      mb: 1,
                      backgroundColor: "#1f2e5e",
                    }}
                  >
                    <Typography sx={{ fontWeight: 600, color: "#fff" }}>
                      {section.title}
                    </Typography>
                  </Paper>
                </AccordionSummary>
                <AccordionDetails>
                  <Paper
                    elevation={2}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      p: 2,
                      mb: 1,
                      // backgroundColor: "#d11f25",
                    }}
                  >
                    {section.details.map((detail, idx) => (
                      <Paper
                        key={idx}
                        elevation={2}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          p: 2,
                          mb: 1,
                        }}
                      >
                        <Typography>
                          <Link
                            href={detail.link}
                            target="_blank"
                            rel="noopener"
                            sx={{
                              fontWeight: 600,
                              color: "#1f2e5e",
                              textDecoration: "none",
                            }}
                          >
                            {detail.name}
                          </Link>
                        </Typography>
                        <IconButton
                          href={detail.link}
                          target="_blank"
                          rel="noopener"
                        >
                          <PictureAsPdfIcon />
                        </IconButton>
                      </Paper>
                    ))}
                  </Paper>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>

          <SectionTitle variant="h5" mt={2}>
            Politikalarımız ve Sertifikalarımız
          </SectionTitle>

          <Box sx={{ margin: { xs: "0", md: "2rem " } }}>
            {politikalar.map((section, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index + 1}-content`}
                  id={`panel${index + 1}-header`}
                >
                  <Paper
                    elevation={2}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      p: 2,
                      width: "100%",
                      mb: 1,
                      backgroundColor: "#1f2e5e",
                    }}
                  >
                    <Typography sx={{ fontWeight: 600, color: "#fff" }}>
                      {section.title}
                    </Typography>
                  </Paper>
                </AccordionSummary>
                <AccordionDetails>
                  <Paper
                    elevation={2}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      p: 2,
                      mb: 1,
                      // backgroundColor: "#d11f25",
                    }}
                  >
                    {section.details.map((detail, idx) => (
                      <Paper
                        key={idx}
                        elevation={2}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          p: 2,
                          mb: 1,
                        }}
                      >
                        <Typography>
                          <Link
                            href={detail.link}
                            target="_blank"
                            rel="noopener"
                            sx={{
                              fontWeight: 600,
                              color: "#1f2e5e",
                              textDecoration: "none",
                            }}
                          >
                            {detail.name}
                          </Link>
                        </Typography>
                        <IconButton
                          href={detail.link}
                          target="_blank"
                          rel="noopener"
                        >
                          <PictureAsPdfIcon />
                        </IconButton>
                      </Paper>
                    ))}
                  </Paper>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>

          {/* <Box pb={3} sx={{ mt: 5 }}>
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3} mx={"1rem"}>
                <a
                  href="https://www.kardemir.com/dosyalar/KimyasalAnaliz/analiz.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Card
                    sx={{
                      width: "100%",
                      height: 250,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      "&:hover": {
                        backgroundColor: "#d11f25",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                        overflow: "hidden",
                        "&:hover": {
                          color: "#fff ",
                          transform: "scale(1.1) ",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "center",
                          height: "30%",
                          padding: "1rem 1rem 0 1rem",
                        }}
                      >
                        <Typography
                          sx={{
                            zIndex: "888",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "30%",
                          }}
                        >
                          ÇELİK KALİTELERİMİZ
                        </Typography>
                        <Link
                          style={{
                            textAlign: "center",
                            color: "blue",
                            padding: "4px",
                            textDecoration: "none",
                            fontSize: "10px",
                          }}
                          target="_blank"
                          href="https://www.kardemir.com/dosyalar/kalite/31/27022024/2024022712333622_kalite_31_27022024.pdf?v=20b48c73_320c_0561_7e7d_b7aae30d0e92"
                        >
                          <LinkIcon
                            fontSize="medium"
                            sx={{
                              color: "black",
                              "&:hover": {
                                color: "white",
                              },
                            }}
                          />
                        </Link>
                      </Box>
                      <CardMedia
                        component="img"
                        image={celik}
                        alt=""
                        sx={{
                          width: "100%",
                          height: "100%",
                          transition: "0.5s",
                          objectFit: "contain",
                          "&:hover": {
                            transform: "scale(1.1) ",
                          },
                        }}
                      />
                    </Box>
                  </Card>
                </a>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={3} xl={3} mx={"1rem"}>
                <a
                  href="https://www.kardemir.com/dosyalar/kalite/31/27022024/2024022712333622_kalite_31_27022024.pdf?v=20b48c73_320c_0561_7e7d_b7aae30d0e92"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Card
                    sx={{
                      width: "100%",
                      height: 250,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      "&:hover": {
                        backgroundColor: "#d11f25",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                        overflow: "hidden",
                        "&:hover": {
                          color: "#fff ",
                          transform: "scale(1.1) ",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "center",
                          height: "30%",
                          padding: "1rem 1rem 0 1rem",
                        }}
                      >
                        <Typography
                          sx={{
                            zIndex: "888",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "30%",
                          }}
                        >
                          TEST VE ANALİZ BİLGİLERİ
                        </Typography>
                        <Link
                          style={{
                            textAlign: "center",
                            color: "blue",
                            padding: "4px",
                            textDecoration: "none",
                            fontSize: "10px",
                          }}
                          target="_blank"
                          href="https://www.kardemir.com/dosyalar/kalite/31/27022024/2024022712333622_kalite_31_27022024.pdf?v=20b48c73_320c_0561_7e7d_b7aae30d0e92"
                        >
                          <LinkIcon
                            fontSize="medium"
                            sx={{
                              color: "black",
                              "&:hover": {
                                color: "white",
                              },
                            }}
                          />
                        </Link>
                      </Box>
                      <CardMedia
                        component="img"
                        image={analiz}
                        alt=""
                        sx={{
                          width: "100%",
                          height: "100%",
                          transition: "0.5s",
                          objectFit: "contain",
                          "&:hover": {
                            transform: "scale(1.1) ",
                          },
                        }}
                      />
                    </Box>
                  </Card>
                </a>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={3} xl={3} mx={"1rem"}>
                <a
                  href="https://www.kardemir.com/dosyalar/sayfalar/32/10012023/2023011016164326_sayfalar_32_10012023.pdf?v=890c15aa_75ca_0fb0_57e9_b50b4083fa7a"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Card
                    sx={{
                      width: "100%",
                      height: 250,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      "&:hover": {
                        backgroundColor: "#d11f25",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                        overflow: "hidden",
                        "&:hover": {
                          color: "#fff ",
                          transform: "scale(1.1) ",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "center",
                          height: "30%",
                          padding: "1.5rem 1rem 0 1rem",
                        }}
                      >
                        <Typography
                          sx={{
                            zIndex: "888",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          TEST VE DENEY TALEP FORMU
                        </Typography>
                        <Link
                          style={{
                            textAlign: "center",
                            color: "blue",
                            padding: "4px",
                            textDecoration: "none",
                            fontSize: "10px",
                          }}
                          target="_blank"
                          href="https://www.kardemir.com/dosyalar/sayfalar/32/10012023/2023011016164326_sayfalar_32_10012023.pdf?v=890c15aa_75ca_0fb0_57e9_b50b4083fa7a"
                        >
                          <LinkIcon
                            fontSize="medium"
                            sx={{
                              color: "black",
                              "&:hover": {
                                color: "white",
                              },
                            }}
                          />
                        </Link>
                      </Box>
                      <CardMedia
                        component="img"
                        image={deney}
                        alt=""
                        sx={{
                          width: "100%",
                          height: "100%",
                          transition: "0.5s",
                          objectFit: "contain",
                          "&:hover": {
                            transform: "scale(1.1) ",
                          },
                        }}
                      />
                    </Box>
                  </Card>
                </a>
              </Grid>
            </Grid>
          </Box> */}
        </StyledPaper>
      </Box>
    </>
  );
}

export default RaporveSertifikaContents;
