import React from "react";
import { Box, Typography, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import foto1 from "../../images/sustainability/enerji.png";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
  overflowX: "auto",
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

function EnerjiYonetimiContents() {
  return (
    <Box sx={{ flexGrow: 1, padding: 1 }}>
      <Header variant="h4" color="#1f2e5e">
        Enerji Yönetimi
      </Header>

      <StyledPaper elevation={3}>
        <Typography
          variant="body1"
          sx={{ marginBottom: 2, textAlign: "justify" }}
        >
          Demir çelik üretiminin temel girdisi olması nedeniyle enerji,
          yaratılan çevresel etki olarak da önemli bir yere sahiptir. Enerji
          yönetimi ve kaynakların verimli kullanılması iş stratejisine dahil
          edilmiş öncelikli konularımız arasındadır. <br />
          <br />
          İzlediğimiz Kardemir Enerji Politikası, enerji konusunda
          benimsediğimiz temel ilkeleri kapsayan bir rehber niteliğinde olup;
          enerji yönetimimizi bu doğrultuda şekillendiririz. Enerji Politikamızı
          ve enerji yönetim faaliyetlerimizi oluştururken, ana referans kaynağı
          olarak ISO 50001 Enerji Yönetim Sistem Standardı’ndan
          yararlanmaktayız.
          <br />
          <br />
          Sürekli iyileştirmenin esas alındığı enerji yönetiminde kısa, orta ve
          uzun vadeli hedefler belirlenir. Enerji performans sonuçları ise
          günlük ve aylık olarak Üst Yönetime raporlanır. İç ve dış denetimler
          de enerji yönetim uygulamalarımızın önemli birer parçasıdır.
          Şirketimiz bünyesinde iç denetimler; buna ek olarak mali denetimler ve
          ISO 50001 kapsamında bağımsız dış denetimler gerçekleştirilir. Ayrıca
          Kardemir operasyonları, kamu kurumlarının denetimlerine de tabidir.
          Denetimler ve doğrulama süreçlerinin ardından, enerji yönetim
          performansı kamu kuruluşlarıyla ve sürdürülebilirlik raporları ile de
          tüm dış paydaşlarla paylaşılır.
          <br />
          <br />
          Çevresel ve ekonomik açıdan sürdürülebilirlik, enerji yönetim
          politikamızın odağındadır. Buna binaen, tükettiğimiz elektrik
          enerjisinin %50’si proses gazlarının geri kazanılması ile
          üretilmektedir. Tüm iş süreçlerinde birim enerji tüketimlerinin
          düşürülmesi için verimlilik çalışmaları yapılmaktadır ve konu özelinde
          çalışanlara eğitimler düzenlenmektedir. Aynı zamanda,
          üniversite-sanayi iş birliği ile çeşitli verimlilik projelerinin
          hayata geçirilmesi için ortak çalışmalar yapılmaktadır.
        </Typography>
        <img
          src={foto1}
          alt="enerjiyonetim"
          style={{
            width: "50%",
            maxHeight: "40rem",
            display: "flex",

            margin: "2rem auto",
          }}
        />

        <Typography
          variant="body1"
          sx={{ marginBottom: 2, textAlign: "justify" }}
        >
          Tüm enerji kaynaklarımızı en etkin ve verimli şekilde değerlendirerek
          Kardemir’in sürdürülebilir büyümesine katkı sağlamayı amaçlayan Enerji
          Yönetim faaliyetlerimiz için{" "}
          <a
            href="https://www.kardemir.com/dosyalar/sayfalar/1187/27092022/2022092714003034_sayfalar_1187_27092022.pdf?v=5a3381f3_cd86_f4fd_2def_b4b8d620de2d"
            style={{ textDecoration: "underline", color: "inherit" }}
            target="_blank"
          >
            tıklayınız
          </a>
          .
        </Typography>

        {/* <Divider sx={{ marginY: 3 }} /> ÇİZGİ ÇEKME */}
      </StyledPaper>
    </Box>
  );
}

export default EnerjiYonetimiContents;
