import React from "react";
import { styled } from "@mui/material/styles";
import "../Urunler/PdfFlipBook.css";
import { Typography } from "@mui/material";

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

function KilometreTaslariContext() {
  return (
    <>
      <Header variant="h4" color="#1f2e5e">
        Kilometre Taşlarımız
      </Header>

      <div class="body">
        <div class="container">
          <div class="milestone-grid">
            <div class="milestone">
              <h3>1937</h3>
              <p>
                Kuruluş
                <br />
                Temellerin Atılması
              </p>
            </div>
            <div class="milestone">
              <h3>1938</h3>
              <p>Makine Montajları</p>
            </div>
            <div class="milestone">
              <h3>1939</h3>
              <p>
                Kuvvet Santrali
                <br />
                Kok Fabrikası
                <br />
                1 No.lu Yüksek Fırın
                <br />
                İlk Türk Demiri
              </p>
            </div>
            <div class="milestone">
              <h3>1940</h3>
              <p>
                Çelikhane
                <br />
                Trio Haddehane
              </p>
            </div>
            <div class="milestone">
              <h3>1950</h3>
              <p>2 No.lu Yüksek Fırın</p>
            </div>
            <div class="milestone">
              <h3>1953</h3>
              <p>Sinter</p>
            </div>
            <div class="milestone">
              <h3>1955</h3>
              <p>Sümerbank’tan Ayrılması</p>
            </div>
            <div class="milestone">
              <h3>1960</h3>
              <p>Düo Haddehane</p>
            </div>
            <div class="milestone">
              <h3>1962</h3>
              <p>3 No.lu Yüksek Fırın</p>
            </div>
            <div class="milestone">
              <h3>1964</h3>
              <p>Düo Profil Haddehanesi</p>
            </div>
            <div class="milestone">
              <h3>1965</h3>
              <p>Kontinü Haddehane</p>
            </div>
            <div class="milestone">
              <h3>1966</h3>
              <p>Çelik Konstrüksiyon</p>
            </div>
            <div class="milestone">
              <h3>1967</h3>
              <p>Dökümhaneler</p>
            </div>
            <div class="milestone">
              <h3>1976</h3>
              <p>Statü Değişikliği</p>
            </div>

            <div class="milestone">
              <h3>1989</h3>
              <p>Grev</p>
            </div>

            <div class="milestone">
              <h3>1994</h3>
              <p>
                {" "}
                Kapatılma Kararının Alınması
                <br />
                Özelleştirilme Kararının Alnması
              </p>
            </div>
            <div class="milestone">
              <h3>1995</h3>
              <p>
                Özelleştirilmesi <br />
                Hisselerinin Satışa Çıkarılması
              </p>
            </div>
            <div class="milestone">
              <h3>1998</h3>
              <p>
                Pota Fırını <br />
                Kütük Makinesi <br />
                Borsaya Açılması{" "}
              </p>
            </div>
            <div class="milestone">
              <h3>1999</h3>
              <p>
                Kireç Fabrikası ve Hava Ayrıştırma Tesisleri <br />
                Çelikhane Konverter{" "}
              </p>
            </div>
            <div class="milestone">
              <h3>2000</h3>
              <p> Blum Makinesi</p>
            </div>
            <div class="milestone">
              <h3>2007</h3>
              <p>
                Ray ve Profil Haddehanesi <br />
                72 metre Boyunda İlk Ray Sevkiyatı{" "}
              </p>
            </div>
            <div class="milestone">
              <h3>2008</h3>
              <p>
                Yeni Tesislerinin İşletmeye Alınması <br />4 No.lu Yüksek Fırın{" "}
              </p>
            </div>
            <div class="milestone">
              <h3>2011</h3>
              <p>
                Yeni Sinter Tesisi <br />
                Kapasite Arttırımları <br />
                3. Hava Ayrıştırma Tesisi{" "}
              </p>
            </div>

            <div class="milestone">
              <h3>2012</h3>
              <p>
                Hava Kalitesi İzleme İstasyonu <br />
                3. Hava Ayrıştırma Tesisi <br /> Yeni Kireç Fabrikası{" "}
              </p>
            </div>
            <div class="milestone">
              <h3>2013</h3>
              <p>
                3. Sürekli Döküm Makinesi <br />
                Yeni Enerji Santrali <br />
                Demiryolu Tekeri Üretim Tesisi <br />
                Yeni Kok Fırınları
              </p>
            </div>
            <div class="milestone">
              <h3>2014</h3>
              <p>
                Eğitim ve Kültür Merkezi <br />
                Merkezi Arıtma Tesisi <br />
                3. Konverter <br />
                Hidroelektrik Santrali <br />
                Sinter Makinesi
              </p>
            </div>
            <div class="milestone">
              <h3>2015</h3>
              <p>
                5. Yüksek Fırın <br />
                Üretim Rekoru
              </p>
            </div>
            <div class="milestone">
              <h3>2016</h3>
              <p>
                İlk Kangal Üretimi <br />
                Torpidoda Kükürt Giderme Tesisi
              </p>
            </div>
            <div class="milestone">
              <h3>2017</h3>
              <p>
                TSI Sertifikası <br />
                Kalite Ödülü
              </p>
            </div>
            <div class="milestone">
              <h3>2018</h3>
              <p>IV. Hava Ayrıştırma Tesisi</p>
            </div>
            <div class="milestone">
              <h3>2019</h3>
              <p>
                Kireç Fabrikası <br />
                Yeni Sürekli Döküm Makinesi(SDM4) <br />
                Sinter Tesisleri Meros Sistemi
              </p>
            </div>
            <div class="milestone">
              <h3>2020</h3>
              <p>Dijital Dönüşüm</p>
            </div>
            <div class="milestone">
              <h3>2021</h3>
              <p>Demiryolu Tekeri Üretim Tesisi</p>
            </div>
            <div class="milestone">
              <h3>2022</h3>
              <p>Konverter-3</p>
            </div>
            <div class="milestone">
              <h3>2023</h3>
              <p>4 Nolu Pota Fırını ve SVC Sistemi</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default KilometreTaslariContext;
