import React, { useState } from "react";
import SolYapiKariyer from "./SolYapiKariyer";

function SikcaSorulanSorular() {
  const [page, setPage] = useState("Sıkça Sorulan Sorular");

  return (
    <>
      <SolYapiKariyer setPage={setPage} page={page} />
    </>
  );
}
export default SikcaSorulanSorular;
