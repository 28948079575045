import React, { useState } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import foto1 from "../../images/ustyonetim/dyasacan.jpg";
import foto2 from "../../images/ustyonetim/aaycan.png";
import foto3 from "../../images/ustyonetim/fciftci.png";
import foto4 from "../../images/ustyonetim/mkarakus.png";
import foto5 from "../../images/ustyonetim/mserdogan.jpg";
import foto6 from "../../images/ustyonetim/sokluoglu.png";
import foto7 from "../../images/ustyonetim/htmusluoglu.png";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

const yonetimKurulu = [
  {
    name: "Dr. Dursunali Yaşacan",
    title: "Genel Müdür",
    image: foto1,
    link: "#",
    summary: (
      <>
        <Typography>
          Lisans eğitimini 1983-1988 yılları arasında Ortadoğu Teknik
          Üniversitesi Metalurji ve Malzeme Mühendisliği bölümünde tamamladıktan
          sonra 1989-1993 yılları arasında aynı üniversitede Malzeme Bilimi
          alanında yüksek lisans, 1990-1991 yıllarında ise İdari Bilimler
          Fakültesi'nde İşletme Yönetimi eğitimi almıştır. 2000-2006 yılları
          arasında İstanbul Teknik Üniversitesi'nde Doktora çalışmasını
          tamamlamıştır. <br />
          <br />
        </Typography>
        <Typography>
          Kariyerine 1989-1993 yılları arasında Ereğli Demir ve Çelik
          Fabrikaları T.A.Ş.'de 1. Soğuk Haddehane İşletme Mühendisi olarak
          başlamış olup, 1995-2002 yılları arasında 2. Soğuk Haddehane İşletme
          Mühendisi, 2002-2003 yıllarında 2. Soğuk Haddehane İşletme
          Başmühendisi, 2003-2006 yılları arasında 2. Soğuk Haddehane Fabrika
          Müdürü olarak görev yapmıştır. <br />
          <br />
        </Typography>
        <Typography>
          2006-2009 yılları arasında Borusan Holding/Borusan Mühendislik A.Ş.'de
          Teknik Direktör, 2009-2010 yıllarında ise Genel Müdür V. görevlerinde
          bulunmuştur. <br />
          <br />
        </Typography>
        <Typography>
          2010-2011 yılları arasında MMK Metalurji'de İstanbul Üretim Kompleksi
          Teknik Direktörü, 2011-2018 yılları arasında İstanbul Üretim Kompleksi
          Direktörü, 2018- 2020 yılları arasında MMK Metalurji Üretim Direktörü
          olarak çalışmıştır. <br />
          <br />
        </Typography>
        <Typography>
          2020-2024 yılları arasında Kibar Holding ortaklığı olan Posco Assan
          TST de üst düzey yöneticilik yapmıştır. <br />
          <br />
        </Typography>
        <Typography>
          Ocak 2024 tarihinden itibaren şirketimizde Genel Müdür olarak görev
          yapmakta olan Dr. Dursun Ali Yaşacan, iyi derecede İngilizce
          bilmektedir. <br />
          <br />
        </Typography>
      </>
    ),
  },
  {
    name: "Ahmet Aycan",
    title: "Genel Müdür Yardımcısı İşletmeler",
    image: foto2,
    link: "#",
    summary: (
      <>
        <Typography>
          1973 Karabük doğumludur. Lise eğitimini Karabük Demir Çelik Lisesi ve
          lisans eğitimini Yıldız Teknik Üniversitesi Metalurji Mühendisliği
          bölümünde tamamlamıştır. <br />
          <br />
        </Typography>
        <Typography>
          2000 yılında Kardemir’de Endüstri Etüd Proje Müdürlüğü bünyesinde
          Ar-Ge Mühendisi olarak göreve başlamıştır. 2002-2019 yılları arasında
          Yüksek Fırınlar ve Sinter Müdürlüğü bünyesinde Mühendis, Başmühendis,
          Müdür, Demir ve Çelik Üretim Direktörü pozisyonlarında çalışmıştır.
          2019-2023 yılları arasında Eti Bakır Samsun İşletmesinde Üretim Müdürü
          olarak görev almıştır. <br />
          <br />
        </Typography>
      </>
    ),
  },
  {
    name: "Fazıl Çiftçi",
    title: "Genel Müdür Yardımcısı Mali İşler (CFO)",
    image: foto3,
    link: "#",
    summary: (
      <>
        <Typography>
          Lisans eğitimini 1988-1992 yıllarında Ankara Üniversitesi Siyasal
          Bilgiler Fakültesi’nde Kamu Yönetimi, Yüksek Lisans (MBA) eğitimini
          Tennessee Üniversitesi’nde İşletme Yönetimi üzerine 2004-2005 yılları
          arasında tamamlamıştır. <br />
          <br />
        </Typography>
        <Typography>
          1993 yılında Maliye Bakanlığı’nda Hesap Uzmanı Yardımcısı olarak
          meslek hayatına başlamış olup; <br />
          <br />
        </Typography>
        <Typography>
          1996-2003 yılları arasında Maliye Bakanlığı Hesap Uzmanları Kurulu’nda
          Hesap Uzmanı, 2003-2006 yıllarında Baş Hesap Uzmanı görevlerinde
          bulundu. <br />
          <br />
        </Typography>
        <Typography>
          2006 yılında Maliye Bakanlığı Gelir İdaresi Başkanlığı’nda Grup
          Başkanı görevini yürütmüştür. <br />
          <br />
        </Typography>
        <Typography>
          2006-2007 yıllarında Maliye Bakanlığı Gelir Politikaları Genel
          Müdürlüğü’nde Daire Başkanı, 2007-2008 yılları arasında Genel Müdür
          Yardımcısı görevlerinde bulunmuştur. <br />
          <br />
        </Typography>
        <Typography>
          2008-2022 yılları arasında THY Teknik A.Ş.’de Mali ve İdari İşler
          Genel Müdür Yardımcısı ve 2022-2023 yıllarında İç Kontrol ve Denetim
          Başkanı görevlerini ifa etmiştir. <br />
          <br />
        </Typography>
        <Typography>
          2012-2013 yıllarında TSI Uçak Koltuk Üretimi A.Ş. Denetim Kurulu Üyesi
          ve 2014-2020 yılları arası Yönetim Kurulu Üyeliği görevlerinin yanı
          sıra 2011-2013 yıllarında THY Opet Havacılık Yakıtları A.Ş. Denetim
          Kurulu Üyesi görevlerini yürütmüştür. <br />
          <br />
        </Typography>
        <Typography>
          Ağustos 2023 tarihinden itibaren de şirketimizde Mali İşler Genel
          Müdür Yardımcısı olarak görev yapmakta olan Çiftçi, iyi derecede
          İngilizce bilmektedir. <br />
          <br />
        </Typography>
      </>
    ),
  },

  {
    name: "H. Tarkan Musluoğlu",
    title: "Genel Müdür Yardımcısı Satınalma",
    image: foto7,
    link: "#",
    summary: (
      <>
        <Typography>
          Lisans eğitimini 1985-1989 yılları arasında İstanbul Teknik
          Üniversitesi İşletme Mühendisliği Bölümü’nde tamamlamıştır. 1991
          yılında NETAŞ Northern Electric Telekomünikasyon A.Ş.'de Stratejik
          Planlama Uzmanı olarak meslek hayatına başlamış olup, 1995 yılında
          NETAŞ'ta Hazine Uzmanı görevinde de bulunmuştur. <br />
          <br />
        </Typography>
        <Typography>
          1997-1998 yılları arasında Kazakistan'daki Nortel Networks ve yerel
          ortaklar ile kurulan VESNET adlı ortak girişimde Finans Genel Müdür
          Yardımcısı görevini yürütmüştür. <br />
          <br />
        </Typography>
        <Typography>
          1999-2006 yılları arasında Borusan Makina ve Güç Sistemleri Sanayi ve
          Ticaret A.Ş.'de Orta Asya Operasyonları Finansal Kontrolör ve Lojistik
          Müdürü olarak görev alan Musluoğlu, 2006-2012 yılları arasında Borusan
          Makina'da Makine İthalat ve Planlama Müdürü görevini ifa etmiştir.{" "}
          <br />
          <br />
        </Typography>
        <Typography>
          2013-2018 yılları arasında OYAK Madencilik ve Metalurji Grubu'nda
          Yardımcı Malzemeler Satın Alma Direktörlüğü, son olarak ise 2019-2023
          yılları arasında OYAK Madencilik ve Metalurji Grubu'nda Lojistik Satın
          Alma Direktörü olarak görev yaparak çeşitli satın alma ve tedarik
          projelerine liderlik etmiştir. <br />
          <br />
        </Typography>
        <Typography>
          Ağustos 2024 tarihinden itibaren şirketimizde Satınalma Genel Müdür
          Yardımcısı olarak görev yapmakta olan Musluoğlu, iyi derecede
          İngilizce bilmektedir. <br />
          <br />
        </Typography>
      </>
    ),
  },

  {
    name: "Mustafa Karakuş",
    title:
      "Genel Müdür Yardımcısı İnsan Kaynakları ve İdari İşler Satınalma (V)",
    image: foto4,
    link: "#",
    summary: (
      <>
        <Typography>
          1995-1997 yılları arasında Eşrefoğlu Otomotiv bünyesinde
          Koordinatörlük; <br />
          <br />
        </Typography>
        <Typography>
          1997-2001 yılları arasında Tesa Elektronik Şirket Ortağı ve Şirket
          Müdürü görevlerini yürütmüştür. <br />
          <br />
        </Typography>
        <Typography>
          2001-2017 yılları İBB iştirak şirketlerinden İSBAK A.Ş. bünyesinde
          Sosyal ve Kültürel Gelişim Koordinatörü, İletişim ve Halkla İlişkiler
          Koordinatörü, İK Planlama ve Geliştirme Koordinatörü, İdari ve Sosyal
          Hizmetler Şefi, İK Eğitim ve Performans Değerlendirme Şefi
          görevlerinde bulunmuştur. <br />
          <br />
        </Typography>
        <Typography>
          2017-2020 yılları İBB İSTGÜVEN A.Ş. de Kurucu Şirket Müdürü ve Merkez
          Operasyon Müdürlüğü görevlerini yürütmüştür. <br />
          <br />
        </Typography>
        <Typography>
          2020-2022 yılları HEAŞ Havaalanı İşletme ve Havacılık Endüstrileri
          A.Ş. de Güvenlik Müdürlüğü görevini ifa etmiştir. <br />
          <br />
        </Typography>
        <Typography>
          2008-2022 yılları arasında THY Teknik A.Ş.’de Mali ve İdari İşler
          Genel Müdür Yardımcısı ve 2022-2023 yıllarında İç Kontrol ve Denetim
          Başkanı görevlerini ifa etmiştir. <br />
          <br />
        </Typography>
        <Typography>
          2022-2023 yılları HEAŞ Havaalanı İşletme ve Havacılık Endüstrileri
          A.Ş. Genel Müdürlüğü, Wolfkey Bilişim Teknolojileri A.Ş. Yönetim
          Kurulu Üyesi ve Vatanjet Havacılık A.Ş. Danışmanlığı görevlerini
          yürütmüştür. <br />
          <br />
        </Typography>
        <Typography>
          İngilizce ve Arapça dillerini bilmektedir. <br />
          <br />
        </Typography>
        <Typography>
          Ağustos 2023 tarihinden itibaren de şirketimizde İnsan Kaynakları ve
          İdari İşler Genel Müdür Yardımcısı olarak görev yapmaktadır. <br />
          <br />
        </Typography>
      </>
    ),
  },
  {
    name: "Mustafa Sinan Erdoğan",
    title: "Genel Müdür Yardımcısı (V) Satış ve Pazarlama",
    image: foto5,
    link: "#",
    summary: (
      <>
        <Typography>
          Lisans eğitimini 1994 yılında Orta Doğu Teknik Üniversitesi, Malzeme
          ve Metalurji Mühendisliği Bölümü'nde tamamlamıştır. <br />
          <br />
        </Typography>
        <Typography>
          1994 yılında Birlik Metalurji A.Ş bünyesinde Üretim Mühendisi olarak
          meslek hayatına başlamış olup; 1994-1999 yılları arasında Garanti
          Bankası'nda Portföy Yöneticiliği, 1999-2002 yıllarında Kardemir A.Ş
          Çelikhane Müdürlüğü'nde Çelikhane Modernizasyonu Montaj ve Devreye
          Alma Konverter İşletme Mühendisi ve Pota Fırını İşletme Mühendisi
          görevlerinde bulunmuştur. <br />
          <br />
        </Typography>
        <Typography>
          2002-2007 yılları arasında İsdemir A.Ş. Çelikhane Konverter İşletme
          Başmühendisi, 2007-2009 yıllarında Çelikhane Müdürü, 2010-2011
          yıllarında ise Laboratuvar Müdür Yardımcılığı görevlerinde
          bulunmuştur. 2011-2018 yıllarında Erdemir A.Ş.'de Satış Sonrası
          Hizmetler Müdürü olarak görev yapmıştır. <br />
          <br />
        </Typography>
        <Typography>
          2018 yılında Gazi Metal’de Satış ve Pazarlama Direktörü olarak görev
          almış, 2019-2023 yılları arasında Tat Metal Çelik A.Ş.’de Kalite
          Müdürü olarak görev yapmıştır.
          <br />
          <br />
        </Typography>
        <Typography>
          Ocak 2024 tarihinden itibaren şirketimizde Satış ve Pazarlama Genel
          Müdür Yardımcısı vekili olarak görev yapmakta olan Erdoğan, başlangıç
          seviyesinde Almanca ve iyi derecede İngilizce bilmektedir. <br />
          <br />
        </Typography>
      </>
    ),
  },
  {
    name: "Şerif Okluoğlu",
    title: "Genel Müdür Yardımcısı Strateji ve Yatırımlar",
    image: foto6,
    link: "#",
    summary: (
      <>
        <Typography>
          Şerif Okluoğlu, 1996 yılında meslek hayatına ısıtma soğutma ve
          havalandırma sektöründe Proje Mühendisi olarak başlamış, <br />
          <br />
        </Typography>
        <Typography>
          1998-2016 yılları arasında İstanbul Gaz Dağıtım A.Ş. şirketinde
          kariyerine 18 yıl boyunca devam ederek Konut ve Sanayi Tesisleri Proje
          Onay ve Kontrol Mühendisi, 2004-2016 Yılları arasında İGDAŞ şirketinde
          değişik pozisyonlarda yöneticilik görevlerinde bulunmuştur. <br />
          <br />
        </Typography>
        <Typography>
          2016 yılında Enerji ve Tabii Kaynaklar Bakanlığı iştirak şirketi
          ELTEMTEK Genel Müdür Yardımcılığı; <br />
          <br />
        </Typography>
        <Typography>
          2018 yılından 2022 Ağustos ayına kadar ELTEMTEK Genel Müdürlük
          görevini yürütmüştür.
          <br />
          <br />
        </Typography>
        <Typography>
          2018-2022 yılları arasında TSE Yönetim kurulu üyeliği yapmıştır,{" "}
          <br />
          <br />
        </Typography>
        <Typography>
          GÜNDER, TUREB, TESAB, ENVER gibi sivil toplum ve meslek kuruluşlarında
          Yönetim Kurulu Başkan Vekilliği ve Üyelikleri, Denetim Kurulu
          Başkanlığı ve Üyelikleri bulunmaktadır. <br />
          <br />
        </Typography>
        <Typography>
          Trakya Üniversitesi Mühendislik Mimarlık Fakültesi Makina Mühendisliği
          bölümünden mezun olup, Bahçeşehir Üniversitesi’nde MBA-İşletme yüksek
          lisansına devam etmektedir. <br />
          <br />
        </Typography>
        <Typography>
          Eylül 2022 tarihi itibariyle şirketimizde Strateji ve İş Geliştirmeden
          sorumlu Genel Müdür Yardımcısı olarak çalışmaya başlamıştır. <br />
          <br />
        </Typography>
      </>
    ),
  },
];

function UstYonetimContents() {
  const [summary, setSummary] = useState(true);
  const [summaryInfo, setSummaryInfo] = useState({});

  const handleSummaryClick = (image, name, title, summary) => {
    console.log(image, name, title, summary);

    setSummary(false);
    setSummaryInfo({ image, name, title, summary });
  };

  const StyledPaper = styled(Paper)(({ theme }) => ({
    // padding: theme.spacing(4),
    // backgroundColor: "#ffffff",
    // boxShadow: theme.shadows[3],
    overflowX: "auto",
    maxWidth: 1000,
    margin: "0 auto",
    padding: "2rem",
  }));

  const Header = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: theme.spacing(3),
  }));

  return (
    <>
      <Box sx={{ padding: "1rem 0.2rem" }}>
        <Box sx={{ flexGrow: 1, padding: 1 }}>
          <Header variant="h4" color="#1f2e5e">
            {" "}
            Üst Yönetim
          </Header>

          <StyledPaper>
            <Grid
              container
              spacing={0} // Grid elemanları arasında boşluk olmaması için spacing={0}
              justifyContent="center" // Ortaya hizalamak için
            >
              {yonetimKurulu.slice(0, 1).map((member, index) => (
                <Grid item key={index}>
                  <Box
                    onClick={() =>
                      handleSummaryClick(
                        member.image,
                        member.name,
                        member.title,
                        member.summary
                      )
                    }
                    sx={{
                      textAlign: "center",
                      borderRadius: "8px",
                      overflow: "hidden",
                      cursor: "pointer",
                      padding: "2rem 0",
                      margin: "0.1rem",
                      mx: "1rem", // Her iki kutu arasında 2rem'lik boşluk için margin-horizontal
                      height: "80%",
                      width: "15rem",
                      backgroundColor: "#f5f5f5",
                      transition: "transform 0.3s, background-color 0.3s",
                      "&:hover": {
                        transform: "scale(1.05)",
                        backgroundColor: "#1f2e5e",
                        color: "white",
                      },
                    }}
                  >
                    <Box
                      component="img"
                      src={member.image}
                      alt={member.name}
                      sx={{
                        height: "200px",
                        objectFit: "cover",
                        // borderRadius: "50%",
                      }}
                    />
                    <Box sx={{ padding: "1rem" }}>
                      <Typography variant="h6">{member.name}</Typography>
                      <Typography variant="subtitle2">
                        {member.title}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>

            <Grid
              container
              spacing={0} // Grid elemanları arasında boşluk olmaması için spacing={0}
              justifyContent="center" // Ortaya hizalamak için
            >
              {yonetimKurulu.slice(1).map((member, index) => (
                <Grid item key={index}>
                  <Box
                    onClick={() =>
                      handleSummaryClick(
                        member.image,
                        member.name,
                        member.title,
                        member.summary
                      )
                    }
                    sx={{
                      textAlign: "center",
                      borderRadius: "8px",
                      overflow: "hidden",
                      cursor: "pointer",
                      padding: "2rem 0",
                      margin: "0.5rem",
                      height: "80%",
                      width: "15rem",
                      backgroundColor: "#f5f5f5",
                      transition: "transform 0.3s, background-color 0.3s",
                      "&:hover": {
                        transform: "scale(1.05)",
                        backgroundColor: "#1f2e5e",
                        color: "white",
                      },
                    }}
                  >
                    <Box
                      component="img"
                      src={member.image}
                      alt={member.name}
                      sx={{
                        height: "200px",
                        objectFit: "cover",
                        // borderRadius: "50%",
                      }}
                    />
                    <Box sx={{ padding: "1rem" }}>
                      <Typography variant="h6">{member.name}</Typography>
                      <Typography variant="subtitle2">
                        {member.title}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </StyledPaper>
        </Box>
      </Box>

      {!summary && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 9999,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              maxWidth: "800px",
              height: "80%",
              maxHeight: "90vh",
              backgroundColor: "white",
              zIndex: 999,
              borderTop: "5px solid #9e2632",
              borderLeft: "5px solid #9e2632",
              borderRight: "5px solid #0e2542",
              borderBottom: "5px solid #0e2542",
              borderRadius: "8px",
              overflow: "hidden",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
              justifyContent: "space-between",
              mx: "10%",
              flexDirection: "row",
              "@media (max-width: 600px)": {
                flexDirection: "column",
                width: "90%",
              },
            }}
          >
            <Box
              sx={{
                flexShrink: 0,
                width: "37%",
                backgroundColor: "#0e2542",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "16px",
                boxSizing: "border-box",
                position: "relative",
                "@media (max-width: 600px)": {
                  width: "100%",
                  height: "auto",
                  padding: "8px",
                },
              }}
            >
              <CloseIcon
                onClick={() => setSummary(true)}
                sx={{
                  cursor: "pointer",
                  padding: "8px",
                  backgroundColor: "white",
                  borderRadius: "50%",
                  position: "absolute",
                  top: "16px",
                  left: "16px",
                }}
              />
              <Box
                component="img"
                src={summaryInfo.image}
                alt={summaryInfo.name}
                sx={{
                  width: "100%",
                  maxWidth: "150px",
                  height: "auto",
                  objectFit: "cover",
                  // borderRadius: "50%",
                  marginBottom: "16px",
                }}
              />
              <Typography sx={{ color: "white", textAlign: "center" }}>
                {summaryInfo.name}
              </Typography>
              <Typography
                sx={{ marginTop: "8px", color: "white", textAlign: "center" }}
              >
                {summaryInfo.title}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "63%",
                padding: "16px",
                overflowY: "auto",
                height: "100%",
                margin: "auto",
                "@media (max-width: 600px)": {
                  width: "100%",
                  padding: "8px",
                },
              }}
            >
              <Box
                sx={{
                  maxHeight: "calc(100% - 40px)",
                  overflowY: "auto",
                }}
              >
                {summaryInfo.summary}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

export default UstYonetimContents;
