import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  Paper,
  Box,
  Typography,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
  overflowX: "auto",
  maxWidth: 800, // Genişliği sınırlamak için maxWidth ayarlandı
  margin: "0 auto", // Merkezde hizalama sağlamak için margin kullanıldı
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

const OzetMaliContents = () => {
  const [selectedStock, setSelectedStock] = useState("KRDMA");

  const data = {
    KRDMA: {
      bilanço: [
        {
          varlıklar: "Dönen Varlıklar",
          "2024/3": "6,238,603,155",
          "2023/12": "5,315,039,274",
          "2023/9": "4,149,762,985",
        },
        {
          varlıklar: "Nakit ve Nakit Benzerleri",
          "2024/3": "1,110,904,454",
          "2023/12": "1,002,686,439",
          "2023/9": "244,358,239",
        },
        {
          varlıklar: "Ticari Alacaklar",
          "2024/3": "4,260,334,251",
          "2023/12": "3,252,698,520",
          "2023/9": "2,679,083,189",
        },
        {
          varlıklar: "Diğer Alacaklar",
          "2024/3": "51,502,309",
          "2023/12": "43,926,165",
          "2023/9": "38,695,119",
        },
        {
          varlıklar: "Stoklar",
          "2024/3": "779,986,580",
          "2023/12": "873,167,434",
          "2023/9": "1,002,447,920",
        },
        {
          varlıklar: "Diğer Dönen Varlıklar",
          "2024/3": "36,416,082",
          "2023/12": "142,560,716",
          "2023/9": "185,178,518",
        },
        {
          varlıklar: "Duran Varlıklar",
          "2024/3": "17,088,913,576",
          "2023/12": "16,861,604,883",
          "2023/9": "16,824,163,319",
        },
        {
          varlıklar: "Maddi Duran Varlıklar",
          "2024/3": "16,430,134,595",
          "2023/12": "16,206,125,207",
          "2023/9": "16,170,545,299",
        },
        {
          varlıklar: "Maddi Olmayan Duran Varlıklar",
          "2024/3": "157,771,169",
          "2023/12": "150,699,409",
          "2023/9": "138,711,846",
        },
        {
          varlıklar: "Özkaynak Yöntemiyle Değerlenen Yatırımlar",
          "2024/3": "0",
          "2023/12": "0",
          "2023/9": "0",
        },
        {
          varlıklar: "Diğer Duran Varlıklar",
          "2024/3": "500,885,684",
          "2023/12": "504,780,267",
          "2023/9": "514,906,174",
        },
      ],
      gelirTablosu: [
        {
          kalem: "Hasılat",
          "2024/3": "2,573,444,459",
          "2023/12": "10,881,270,763",
          "2023/9": "5,758,857,499",
        },
        {
          kalem: "Satışların Maliyeti",
          "2024/3": "-2,326,592,811",
          "2023/12": "-10,419,745,862",
          "2023/9": "-4,819,965,215",
        },
        {
          kalem: "Brüt Kar",
          "2024/3": "246,851,648",
          "2023/12": "461,524,901",
          "2023/9": "938,892,284",
        },
        {
          kalem: "Faaliyet Karı/Zararı",
          "2024/3": "-227,913,188",
          "2023/12": "-1,406,667,853",
          "2023/9": "61,119,603",
        },
        {
          kalem: "Esas Faaliyet Karı/Zararı",
          "2024/3": "-264,607,602",
          "2023/12": "-1,509,003,274",
          "2023/9": "-1,402,110,391",
        },
        {
          kalem: "Diğer Faaliyet Gelirleri",
          "2024/3": "48,325,374",
          "2023/12": "163,012,529",
          "2023/9": "113,144,230",
        },
        {
          kalem: "Özkaynak Yöntemiyle Değerlenen Yatırımların Karı/Zararı",
          "2024/3": "15,783,040",
          "2023/12": "-16,194,392",
          "2023/9": "-14,798,403",
        },
        {
          kalem: "Finansman Giderleri",
          "2024/3": "-372,669,889",
          "2023/12": "-1,293,195,672",
          "2023/9": "-849,589,136",
        },
        {
          kalem: "Vergi Öncesi Kar",
          "2024/3": "-499,594,839",
          "2023/12": "-2,738,868,301",
          "2023/9": "-1,558,868,347",
        },
        {
          kalem: "Dönem Karı/Zararı",
          "2024/3": "-495,799,180",
          "2023/12": "-2,794,794,410",
          "2023/9": "-1,498,396,991",
        },
        {
          kalem: "Dönem Karı",
          "2024/3": "-495,799,180",
          "2023/12": "-2,794,794,410",
          "2023/9": "-1,498,396,991",
        },
        {
          kalem: "Dönem Zararı",
          "2024/3": "-495,799,180",
          "2023/12": "-2,794,794,410",
          "2023/9": "-1,498,396,991",
        },
      ],
    },
    KRDMB: {
      bilanço: [
        {
          varlıklar: "Dönen Varlıklar",
          "2024/3": "6,238,603,155",
          "2023/12": "5,315,039,274",
          "2023/9": "4,149,762,985",
        },
        {
          varlıklar: "Nakit ve Nakit Benzerleri",
          "2024/3": "1,110,904,454",
          "2023/12": "1,002,686,439",
          "2023/9": "244,358,239",
        },
        {
          varlıklar: "Ticari Alacaklar",
          "2024/3": "4,260,334,251",
          "2023/12": "3,252,698,520",
          "2023/9": "2,679,083,189",
        },
        {
          varlıklar: "Diğer Alacaklar",
          "2024/3": "51,502,309",
          "2023/12": "43,926,165",
          "2023/9": "38,695,119",
        },
        {
          varlıklar: "Stoklar",
          "2024/3": "779,986,580",
          "2023/12": "873,167,434",
          "2023/9": "1,002,447,920",
        },
        {
          varlıklar: "Diğer Dönen Varlıklar",
          "2024/3": "36,416,082",
          "2023/12": "142,560,716",
          "2023/9": "185,178,518",
        },
        {
          varlıklar: "Duran Varlıklar",
          "2024/3": "17,088,913,576",
          "2023/12": "16,861,604,883",
          "2023/9": "16,824,163,319",
        },
        {
          varlıklar: "Maddi Duran Varlıklar",
          "2024/3": "16,430,134,595",
          "2023/12": "16,206,125,207",
          "2023/9": "16,170,545,299",
        },
        {
          varlıklar: "Maddi Olmayan Duran Varlıklar",
          "2024/3": "157,771,169",
          "2023/12": "150,699,409",
          "2023/9": "138,711,846",
        },
        {
          varlıklar: "Özkaynak Yöntemiyle Değerlenen Yatırımlar",
          "2024/3": "0",
          "2023/12": "0",
          "2023/9": "0",
        },
        {
          varlıklar: "Diğer Duran Varlıklar",
          "2024/3": "500,885,684",
          "2023/12": "504,780,267",
          "2023/9": "514,906,174",
        },
      ],
      gelirTablosu: [
        {
          kalem: "Hasılat",
          "2024/3": "2,573,444,459",
          "2023/12": "10,881,270,763",
          "2023/9": "5,758,857,499",
        },
        {
          kalem: "Satışların Maliyeti",
          "2024/3": "-2,326,592,811",
          "2023/12": "-10,419,745,862",
          "2023/9": "-4,819,965,215",
        },
        {
          kalem: "Brüt Kar",
          "2024/3": "246,851,648",
          "2023/12": "461,524,901",
          "2023/9": "938,892,284",
        },
        {
          kalem: "Faaliyet Karı/Zararı",
          "2024/3": "-227,913,188",
          "2023/12": "-1,406,667,853",
          "2023/9": "61,119,603",
        },
        {
          kalem: "Esas Faaliyet Karı/Zararı",
          "2024/3": "-264,607,602",
          "2023/12": "-1,509,003,274",
          "2023/9": "-1,402,110,391",
        },
        {
          kalem: "Diğer Faaliyet Gelirleri",
          "2024/3": "48,325,374",
          "2023/12": "163,012,529",
          "2023/9": "113,144,230",
        },
        {
          kalem: "Özkaynak Yöntemiyle Değerlenen Yatırımların Karı/Zararı",
          "2024/3": "15,783,040",
          "2023/12": "-16,194,392",
          "2023/9": "-14,798,403",
        },
        {
          kalem: "Finansman Giderleri",
          "2024/3": "-372,669,889",
          "2023/12": "-1,293,195,672",
          "2023/9": "-849,589,136",
        },
        {
          kalem: "Vergi Öncesi Kar",
          "2024/3": "-499,594,839",
          "2023/12": "-2,738,868,301",
          "2023/9": "-1,558,868,347",
        },
        {
          kalem: "Dönem Karı/Zararı",
          "2024/3": "-495,799,180",
          "2023/12": "-2,794,794,410",
          "2023/9": "-1,498,396,991",
        },
        {
          kalem: "Dönem Karı",
          "2024/3": "-495,799,180",
          "2023/12": "-2,794,794,410",
          "2023/9": "-1,498,396,991",
        },
        {
          kalem: "Dönem Zararı",
          "2024/3": "-495,799,180",
          "2023/12": "-2,794,794,410",
          "2023/9": "-1,498,396,991",
        },
      ],
    },
    KRDMD: {
      bilanço: [
        {
          varlıklar: "Dönen Varlıklar",
          "2024/3": "6,238,603,155",
          "2023/12": "5,315,039,274",
          "2023/9": "4,149,762,985",
        },
        {
          varlıklar: "Nakit ve Nakit Benzerleri",
          "2024/3": "1,110,904,454",
          "2023/12": "1,002,686,439",
          "2023/9": "244,358,239",
        },
        {
          varlıklar: "Ticari Alacaklar",
          "2024/3": "4,260,334,251",
          "2023/12": "3,252,698,520",
          "2023/9": "2,679,083,189",
        },
        {
          varlıklar: "Diğer Alacaklar",
          "2024/3": "51,502,309",
          "2023/12": "43,926,165",
          "2023/9": "38,695,119",
        },
        {
          varlıklar: "Stoklar",
          "2024/3": "779,986,580",
          "2023/12": "873,167,434",
          "2023/9": "1,002,447,920",
        },
        {
          varlıklar: "Diğer Dönen Varlıklar",
          "2024/3": "36,416,082",
          "2023/12": "142,560,716",
          "2023/9": "185,178,518",
        },
        {
          varlıklar: "Duran Varlıklar",
          "2024/3": "17,088,913,576",
          "2023/12": "16,861,604,883",
          "2023/9": "16,824,163,319",
        },
        {
          varlıklar: "Maddi Duran Varlıklar",
          "2024/3": "16,430,134,595",
          "2023/12": "16,206,125,207",
          "2023/9": "16,170,545,299",
        },
        {
          varlıklar: "Maddi Olmayan Duran Varlıklar",
          "2024/3": "157,771,169",
          "2023/12": "150,699,409",
          "2023/9": "138,711,846",
        },
        {
          varlıklar: "Özkaynak Yöntemiyle Değerlenen Yatırımlar",
          "2024/3": "0",
          "2023/12": "0",
          "2023/9": "0",
        },
        {
          varlıklar: "Diğer Duran Varlıklar",
          "2024/3": "500,885,684",
          "2023/12": "504,780,267",
          "2023/9": "514,906,174",
        },
      ],
      gelirTablosu: [
        {
          kalem: "Hasılat",
          "2024/3": "2,573,444,459",
          "2023/12": "10,881,270,763",
          "2023/9": "5,758,857,499",
        },
        {
          kalem: "Satışların Maliyeti",
          "2024/3": "-2,326,592,811",
          "2023/12": "-10,419,745,862",
          "2023/9": "-4,819,965,215",
        },
        {
          kalem: "Brüt Kar",
          "2024/3": "246,851,648",
          "2023/12": "461,524,901",
          "2023/9": "938,892,284",
        },
        {
          kalem: "Faaliyet Karı/Zararı",
          "2024/3": "-227,913,188",
          "2023/12": "-1,406,667,853",
          "2023/9": "61,119,603",
        },
        {
          kalem: "Esas Faaliyet Karı/Zararı",
          "2024/3": "-264,607,602",
          "2023/12": "-1,509,003,274",
          "2023/9": "-1,402,110,391",
        },
        {
          kalem: "Diğer Faaliyet Gelirleri",
          "2024/3": "48,325,374",
          "2023/12": "163,012,529",
          "2023/9": "113,144,230",
        },
        {
          kalem: "Özkaynak Yöntemiyle Değerlenen Yatırımların Karı/Zararı",
          "2024/3": "15,783,040",
          "2023/12": "-16,194,392",
          "2023/9": "-14,798,403",
        },
        {
          kalem: "Finansman Giderleri",
          "2024/3": "-372,669,889",
          "2023/12": "-1,293,195,672",
          "2023/9": "-849,589,136",
        },
        {
          kalem: "Vergi Öncesi Kar",
          "2024/3": "-499,594,839",
          "2023/12": "-2,738,868,301",
          "2023/9": "-1,558,868,347",
        },
        {
          kalem: "Dönem Karı/Zararı",
          "2024/3": "-495,799,180",
          "2023/12": "-2,794,794,410",
          "2023/9": "-1,498,396,991",
        },
        {
          kalem: "Dönem Karı",
          "2024/3": "-495,799,180",
          "2023/12": "-2,794,794,410",
          "2023/9": "-1,498,396,991",
        },
        {
          kalem: "Dönem Zararı",
          "2024/3": "-495,799,180",
          "2023/12": "-2,794,794,410",
          "2023/9": "-1,498,396,991",
        },
      ],
    },
  };

  const handleStockChange = (event) => {
    setSelectedStock(event.target.value);
  };
  return (
    <Box sx={{ flexGrow: 1, padding: 1 }}>
      <Header variant="h4" color="#1f2e5e">
        Özet Mali Tablolar
      </Header>

      <StyledPaper elevation={3} sx={{ marginBottom: "2rem" }}>
        <Select value={selectedStock} onChange={handleStockChange}>
          <MenuItem value="KRDMA">KRDMA</MenuItem>
          <MenuItem value="KRDMB">KRDMB</MenuItem>
          <MenuItem value="KRDMD">KRDMD</MenuItem>
        </Select>

        <h2
          style={{
            color: "#1f2e5e",
          }}
        >
          Bilanço
        </h2>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Varlıklar</TableCell>
              <TableCell>2024/3</TableCell>
              <TableCell>2023/12</TableCell>
              <TableCell>2023/9</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data[selectedStock].bilanço.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.varlıklar}</TableCell>
                <TableCell>{row["2024/3"]}</TableCell>
                <TableCell>{row["2023/12"]}</TableCell>
                <TableCell>{row["2023/9"]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <h2
          style={{
            color: "#1f2e5e",
          }}
        >
          Gelir Tablosu
        </h2>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Kalem</TableCell>
              <TableCell>2024/3</TableCell>
              <TableCell>2023/12</TableCell>
              <TableCell>2023/9</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data[selectedStock].gelirTablosu.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.kalem}</TableCell>
                <TableCell>{row["2024/3"]}</TableCell>
                <TableCell>{row["2023/12"]}</TableCell>
                <TableCell>{row["2023/9"]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledPaper>
    </Box>
  );
};

export default OzetMaliContents;
