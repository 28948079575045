import { Paper, Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import image from "../../images/image2.jpg";
import { useEffect } from "react";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(8),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
  overflowX: "auto",
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));
const SectionTitle = styled(Typography)(({ theme }) => ({
  color: "#1f2e5e",
  fontWeight: "bold",
  marginBottom: theme.spacing(2),
}));

function Kvkk() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box
        component="img"
        alt=""
        src={image}
        style={{
          width: "100%",
          height: "30rem",
          cursor: "pointer",
        }}
      />
      <Box sx={{ flexGrow: 1, padding: 1, marginTop: "1rem" }}>
        <Header variant="h4">
          Kişisel Verilerin İşlenmesine İlişkin Aydınlatma Metni ve Politikalar
        </Header>

        <StyledPaper sx={{ padding: "1rem", margin: "0.5rem" }}>
          <SectionTitle variant="h5">Aydınlatma Metni</SectionTitle>
          <Typography
            variant="body1"
            sx={{ marginBottom: 2, textAlign: "justify" }}
          >
            Veri Sorumlusu: Kardemir Karabük Demir Çelik Sanayi ve Ticaret A.Ş
            Fabrika Sahası 78170 Merkez/ Karabük Kardemir Karabük Demir Çelik
            Sanayi ve Ticaret A.Ş. (“Kardemir”) olarak; 6698 sayılı Kişisel
            Verilerin Korunması Kanunu (“KVKK”) ve ilgili mevzuat ve yasal
            düzenlemelerden kaynaklanan faaliyetleri çerçevesinde kişisel
            verilerinizin işlenmesi, saklanması ve aktarılmasına ilişkin siz;{" "}
            <br />
            <br /> -Çalışan Adaylarımızı,
            <br />
            <br />
            -Stajyer Adaylarımızı,
            <br />
            <br />
            -Ziyaretçilerimizi,
            <br />
            <br />
            -Çevrimiçi Ziyaretçilerimizi,
            <br />
            <br />
            -Hissedar/ Ortaklarımızı,
            <br />
            <br />
            -Yönetim Kurulu Üyelerimizi,
            <br />
            <br />
            -Müşterilerimizi ve
            <br />
            <br />
            -Potansiyel Müşterilerimizi
            <br />
            <br />
            bilgilendirmek amacıyla işbu aydınlatma metnini hazırladık.
          </Typography>

          <SectionTitle variant="h5">Politikalar</SectionTitle>
          <Typography
            variant="body1"
            sx={{ marginBottom: 2, textAlign: "justify" }}
          >
            Kişisel Verilerin Korunması Kanunu’na ilişkin politikalarımıza
            aşağıdaki bağlantılardan ulaşabilirsiniz.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Kişisel Verilerin Korunması ve İşlenmesi Politikamız için{" "}
            <a
              href="https://www.kardemir.com/dosyalar/kvkk/kisisel_verileri_koruma.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              tıklayın.
            </a>
          </Typography>

          <Typography variant="body1" gutterBottom>
            Kişisel Veri Saklama ve İmha Politikamız için{" "}
            <a
              href="https://www.kardemir.com/dosyalar/kvkk/Kisisel_verileri_Saklama.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              tıklayın.
            </a>
          </Typography>

          <Typography variant="body1" gutterBottom>
            Çerez Politikamız için{" "}
            <a
              href="/cerez_politikasi"
              target="_blank"
              rel="noopener noreferrer"
            >
              tıklayın.
            </a>
          </Typography>

          <SectionTitle variant="h5">
            Hangi Kişisel Verileriniz İşlenmektedir ve Kişisel Verilerinizin
            İşlenme Amaçları Nelerdir?
          </SectionTitle>
          <Typography
            variant="body1"
            sx={{ marginBottom: 2, textAlign: "justify" }}
          >
            <strong>Çalışan Adayları:</strong>
            <br />
            - Kimlik Bilgileri: Ad, soyad, T.C. kimlik numarası, doğum tarihi
            gibi bilgilerinizi içerir.
            <br />
            - İletişim Bilgileri: Telefon numarası, e-posta adresi gibi iletişim
            bilgileri.
            <br />
            - Özlük Bilgileri: Eğitim durumu, iş deneyimi, sertifikalar vb.
            <br />
            - İşlem Güvenliği Bilgileri: Bilgi güvenliği ve sistem erişim
            kayıtları.
            <br />
            - Finans Bilgileri: Banka hesap numarası, maaş bilgileri vb.
            <br />
            - Mesleki Deneyim Bilgileri: Önceki iş deneyimlerinize dair
            bilgiler.
            <br />
            - Sağlık Bilgileri: Sağlık raporları, iş sağlığı ve güvenliği ile
            ilgili bilgiler.
            <br />
            - Ceza Mahkumiyeti ve Güvenlik Tedbirleri Bilgileri: Sabıka kaydı,
            adli kontrol tedbirleri gibi bilgiler.
            <br />
            - Referans Bilgileri: Önceki işverenler veya referans kişileri ile
            ilgili bilgiler.
            <br />
            - Görsel ve İşitsel Kayıtlar: Mülakat fotoğrafları ve videoları.
            <br />
            <br />
            <strong>Stajyer Adayları:</strong>
            <br />
            - Kimlik Bilgileri: Ad, soyad, T.C. kimlik numarası, doğum tarihi.
            <br />
            - İletişim Bilgileri: Telefon numarası, e-posta adresi.
            <br />
            - Özlük Bilgileri: Eğitim durumu, staj başvurusu bilgileri.
            <br />
            - İşlem Güvenliği Bilgileri: Bilgi güvenliği ve sistem erişim
            kayıtları.
            <br />
            - Sağlık Bilgileri: Sağlık durumu ile ilgili bilgiler.
            <br />
            - Ceza Mahkumiyeti ve Güvenlik Tedbirleri Bilgileri: Sabıka kaydı,
            adli kontrol tedbirleri.
            <br />
            - Referans Bilgileri: Stajyer adayının referans bilgileri.
            <br />
            - Görsel ve İşitsel Kayıtlar: Görüntü ve ses kayıtları.
            <br />
            <br />
            <strong>Ziyaretçiler:</strong>
            <br />
            - Kimlik Bilgileri: Ad, soyad, T.C. kimlik numarası gibi bilgiler.
            <br />
            - İletişim Bilgileri: Telefon numarası, e-posta adresi.
            <br />
            - Fiziksel Mekan Güvenliği Bilgileri: Güvenlik kayıtları ve
            ziyaretçi giriş çıkış bilgileri.
            <br />
            - İşlem Güvenliği Bilgileri: Bilgi güvenliği ve sistem erişim
            kayıtları.
            <br />
            <br />
            <strong>Çevrimiçi Ziyaretçiler:</strong>
            <br />
            - İşlem Güvenliği Bilgileri: Web sitesi kullanım verileri ve sistem
            erişim kayıtları.
            <br />
            <br />
            <strong>Hissedar/Ortak:</strong>
            <br />
            - Kimlik Bilgileri: Ad, soyad, T.C. kimlik numarası ve diğer kimlik
            bilgileri.
            <br />
            <br />
            <strong>Yönetim Kurulu Üyeleri:</strong>
            <br />
            - Kimlik Bilgileri: Ad, soyad, T.C. kimlik numarası.
            <br />
            - İletişim Bilgileri: Telefon numarası, e-posta adresi.
            <br />
            - Özlük Bilgileri: Eğitim durumu, önceki iş deneyimleri.
            <br />
            - Hukuki İşlem Bilgileri: Hukuki süreçlerle ilgili bilgiler.
            <br />
            - Fiziksel Mekan Güvenliği Bilgileri: Güvenlik kayıtları.
            <br />
            - İşlem Güvenliği Bilgileri: Bilgi güvenliği ve sistem erişim
            kayıtları.
            <br />
            - Finans Bilgileri: Mali durum ve hesap bilgileri.
            <br />
            - Risk Yönetimi Bilgileri: Risk yönetimi ve strateji bilgileri.
            <br />
            <br />
            <strong>Müşterilerimiz:</strong>
            <br />
            - Kimlik Bilgileri: Ad, soyad, T.C. kimlik numarası.
            <br />
            - İletişim Bilgileri: Telefon numarası, e-posta adresi.
            <br />
            - Finans Bilgileri: Ödeme bilgileri, fatura bilgileri.
            <br />
            - Müşteri İşlem Bilgileri: Satın alma geçmişi, sipariş detayları.
            <br />
            - Pazarlama Bilgileri: Tercihler ve pazarlama ile ilgili bilgiler.
            <br />
            - Şirket Yetkililerine Ait Bilgiler: Şirket yetkililerinin iletişim
            bilgileri.
            <br />
            - Görsel ve İşitsel Kayıtlar: Görüntü ve ses kayıtları.
            <br />
            <br />
            <strong>Potansiyel Müşterilerimiz:</strong>
            <br />
            - Kimlik Bilgileri: Ad, soyad, iletişim bilgileri.
            <br />
            - İletişim Bilgileri: Telefon numarası, e-posta adresi.
            <br />
            - Görsel ve İşitsel Kayıtlar: Görüntü ve ses kayıtları.
            <br />
            <br />
            Yukarıda sayılan amaçlar kapsamında gerçekleştirilen kişisel veri
            işleme faaliyetinin, KVKK kapsamında öngörülen ilgili kişinin açık
            rızasının varlığına dayanarak yapılmaktadır. İşbu metin, kişisel
            verilerinizin işlenmesine ilişkin kapsamlı bilgi sağlamak ve sizleri
            bilgilendirmek amacıyla hazırlanmıştır.
          </Typography>

          <SectionTitle variant="h5">
            Kişisel Verilerinizin Toplanma Yöntemleri Nelerdir?
          </SectionTitle>
          <Typography
            variant="body1"
            sx={{ marginBottom: 2, textAlign: "justify" }}
          >
            Yukarıda kategoriler halinde belirtilen kişisel verileriniz; başvuru
            formları, iş sözleşmesi, sözleşmeler, özgeçmiş, iş yerinde bulunan
            elektronik takip ve fiziksel erişim kontrol sistemleri, (ÖRN:
            biyometrik ve kartlı geçiş sistemleri, CCTV), bilgi sistemleri ve
            elektronik cihazlar (ÖRN: telekomünikasyon altyapısı, bilgisayar ve
            telefonlar), İŞKUR ve özel istihdam büroları (Kariyer.net, vb.
            dahil), Headhunter hizmeti sağlayan taraflar, üçüncü taraflar (ÖRN:
            KKB ve Findeks), çevrimiçi web sitesi, kendi sitemiz tarafından
            yaratılan Çerezler/Cookieler, üçüncü taraflarca yaratılan Takip
            Çerezleri/Tracking Cookieler (ÖRN:Hotjar), site kullanımı ölçüm
            sistemleri (ÖRN:Google Analytics) ve ilgili kişi tarafından beyan
            edilen diğer belgeler vasıtasıyla toplanmaktadır.
          </Typography>

          <SectionTitle variant="h5">
            Kişisel Verilerinizin Toplanmasının Hukuki Sebebi Nedir?
          </SectionTitle>
          <Typography
            variant="body1"
            sx={{ marginBottom: 2, textAlign: "justify" }}
          >
            Kişisel verileriniz,Kardemir tarafından; yukarıda açıklanan
            amaçların gerçekleştirilmesi doğrultusunda, başta Türk Ticaret
            Kanunu, Türk Borçlar Kanunu ve KVKK düzenlemeleri olmak üzere ilgili
            mevzuat uyarınca sözleşmenin kurulması, hak tahsisi ve meşru
            menfaatlerimiz kapsamında işlenmektedir.
          </Typography>

          <SectionTitle variant="h5">
            Kişisel Verilerinizi Üçüncü Bir Kişiye Aktarıyor Muyuz?
          </SectionTitle>
          <Typography
            variant="body1"
            sx={{ marginBottom: 2, textAlign: "justify" }}
          >
            Kişisel verileriniz, <br />
            <br />
            -Yasal düzenlemenin öngördüğü kapsamda, faaliyetlerin mevzuata uygun
            yürütülmesi, hukuk işlerinin takibi ve yürütülmesi, yetkili kişi,
            kurum ve kuruluşlara bilgi verilmesi, çalışanlar için iş akdi ve
            mevzuattan kaynaklı yükümlülüklerin yerine getirilmesi, iş
            faaliyetlerinin yürütülmesi/denetimi ve iş sağlığı/güvenliği
            faaliyetlerinin yürütülmesi amaçlarıyla yurt içindeki Yetkili Kamu
            Kurum ve Kuruluşları’na, ve
            <br />
            <br />
            -Yasal düzenlemenin öngördüğü kapsamda, faaliyetlerin mevzuata uygun
            yürütülmesi ve yetkili kişi, kurum ve kuruluşlara bilgi verilmesi
            amaçlarıyla yurt içindeki Gerçek Kişiler veya Özel Hukuk Tüzel
            Kişileri’ne
            <br />
            <br />
            aktarılabilecektir.
          </Typography>

          <SectionTitle variant="h5">
            Kişisel Verilerinizi Yurt Dışına Aktarıyor Muyuz?
          </SectionTitle>
          <Typography
            variant="body1"
            sx={{ marginBottom: 2, textAlign: "justify" }}
          >
            Kardemir olarak, kişisel verilerinizi yurt dışına aktarmamaktayız.
          </Typography>

          <SectionTitle variant="h5">
            Kişisel Verileriniz Ne Kadar Süre ile Saklanır?
          </SectionTitle>
          <Typography
            variant="body1"
            sx={{ marginBottom: 2, textAlign: "justify" }}
          >
            Kişisel verilerinizin saklama süresi aşağıdaki şekildedir:
            <br />
            <br />
            -Kanunda veya ilgili mevzuatta verinin saklanması için bir süre
            belirlenmişse söz konusu veri en az bu süre kadar saklanmak
            zorundadır. Olası bir mahkeme talebinin veya kanunla yetkili
            kılınmış bir idari merciinin ilgili veriye ilişkin talebinin
            tarafımıza geç ulaşması veya tarafı olabileceğimiz bir ihtilafın
            meydana gelmesi gibi ihtimaller gözetilmek suretiyle, verilerinizin
            saklanması için mevzuatta öngörülen sürelere 6 ay ila 1 yıl arası
            bir süre eklenerek verilerin saklama süresi belirlenmekte ve
            belirlenen sürenin sonunda söz konusu veriler silinmektedir.
            <br />
            <br />
            -Mevzuatta, işlediğimiz verinin saklanma süresine yönelik bir süre
            öngörülmemiş ise aramızdaki ilişkinin gereği olarak ve tarafınızla
            yapılan sözleşmede belirlenen süre boyunca verileriniz saklanır. Bu
            ilişki sona erdikten sonra veya sözleşmede belirlenen süre geçtikten
            sonra veriniz herhangi bir talebinize gerek olmadan silinir, yok
            edilir veya anonim hale getirilir.
            <br />
            <br />
            -Saklama süresi mevzuatta belirlenmiş verilerinizin öngörülen
            sürelerden önce silinmesini talep etmeniz halinde söz konusu
            talebiniz gerçekleştirilemeyecektir.
            <br />
            <br />
            -Saklama süresine ilişkin mevzuatta bir süre öngörülmeyen ve işleme
            amacı olmayan verileriniz silinmesine dair talepte bulunmanız
            halinde ise derhal veya en geç 6 ay içerisinde silinir.
          </Typography>

          <SectionTitle variant="h5">
            Kişisel Verileriniz İle İlgili Olarak Kullanabileceğiniz Haklarınız
            Nelerdir?
          </SectionTitle>
          <Typography
            variant="body1"
            sx={{ marginBottom: 2, textAlign: "justify" }}
          >
            Kişisel verilerinize ilişkin;
            <br />
            <br />
            -Kişisel verilerinizin işlenip işlenmediğini öğrenme,
            <br />
            <br />
            -Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,
            <br />
            <br />
            -Kişisel verilerin işlenme amacını ve bunların amacına uygun
            kullanılıp kullanılmadığını öğrenme,
            <br />
            <br />
            -Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı
            üçüncü kişileri bilme,
            <br />
            <br />
            -Kişisel verilerinizin eksik veya yanlış işlenmiş olması halinde
            bunların düzeltilmesini isteme,
            <br />
            <br />
            -KVKK mevzuatında öngörülen şartlar çerçevesinde kişisel
            verilerinizin silinmesini veya yok edilmesini isteme,
            <br />
            <br />
            -Eksik veya yanlış verilerin düzeltilmesi ile kişisel verilerinizin
            silinmesi veya yok edilmesini talep ettiğinizde, bu durumun kişisel
            verilerinizi aktardığımız üçüncü kişilere bildirilmesini isteme,
            <br />
            <br />
            -Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara
            uğramanız halinde bu zararın giderilmesini talep etme
            <br />
            <br />
            haklarına sahipsiniz.
          </Typography>

          <SectionTitle variant="h5">
            Haklarınızı Nasıl Kullanabilirsiniz?
          </SectionTitle>
          <Typography
            variant="body1"
            sx={{ marginBottom: 2, textAlign: "justify" }}
          >
            Kişisel verileriniz ile ilgili başvuru ve taleplerinizi dilerseniz
            İlgili Kişi Başvuru Formu aracılığıyla;
            <br />
            <br />
            -Islak imzalı ve kimlik fotokopisi ile Kardemir Karabük Demir Çelik
            Sanayi ve Ticaret A.Ş. Fabrika Sahası 78170 Merkez / Karabük
            adresine göndererek,
            <br />
            <br />
            -Geçerli bir kimlik belgesi ile birlikte Kardemir Karabük Demir
            Çelik Sanayi ve Ticaret A.Ş’ye bizzat başvurarak,
            <br />
            <br />
            -Kayıtlı elektronik posta (KEP) adresi ve güvenli elektronik imza ya
            da mobil imza kullanmak suretiyle kardemir.gm@hs01.kep.tr kayıtlı
            elektronik posta adresimize göndererek,
            <br />
            <br />
            -İlgili Kişi tarafından Kardemir Karabük Demir Çelik Sanayi ve
            Ticaret A.Ş.’ye daha önce bildirilen ve sistemimizde kayıtlı bulunan
            elektronik posta adresinden kardemir.gm@hs01.kep.tr adresimize
            göndererek
            <br />
            <br />
            A Kardemir Karabük Demir Çelik Sanayi ve Ticaret A.Ş.’ye
            iletebilirsiniz.
            <br />
            <br />
            Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ uyarınca,
            İlgili Kişi’nin, başvurusunda isim, soy isim, başvuru yazılı ise
            imza, T.C. kimlik numarası, (başvuruda bulunan kişinin yabancı
            olması halinde pasaport numarası), tebligata esas yerleşim yeri veya
            iş yeri adresi, varsa bildirime esas elektronik posta adresi,
            telefon numarası ve faks numarası ile talep konusuna dair bilgilerin
            bulunması zorunludur.
            <br />
            <br />
            İlgili Kişi, yukarıda belirtilen hakları kullanmak için yapacağı ve
            kullanmayı talep ettiği hakka ilişkin açıklamaları içeren başvuruda
            talep edilen hususu açık ve anlaşılır şekilde belirtmelidir.
            Başvuruya ilişkin bilgi ve belgelerin başvuruya eklenmesi
            gerekmektedir.
            <br />
            <br />
            Talep konusunun başvuranın şahsı ile ilgili olması gerekmekle
            birlikte, başkası adına hareket ediliyor ise başvuruyu yapanın bu
            konuda özel olarak yetkili olması ve bu yetkinin belgelendirilmesi
            (özel vekâletname) gerekmektedir. Ayrıca başvurunun kimlik ve adres
            bilgilerini içermesi ve başvuruya kimliği doğrulayıcı belgelerin
            eklenmesi gerekmektedir.
            <br />
            <br />
            Yetkisiz üçüncü kişilerin başkası adına yaptığı talepler
            değerlendirmeye alınmayacaktır.
          </Typography>

          <SectionTitle variant="h5">
            Kişisel Verilerinizin İşlenmesine İlişkin Talepleriniz Ne Kadar
            Sürede Cevaplanır?
          </SectionTitle>
          <Typography
            variant="body1"
            sx={{ marginBottom: 2, textAlign: "justify" }}
          >
            Kişisel verilerinize ilişkin hak talepleriniz değerlendirilerek,
            bize ulaştığı tarihten itibaren en geç 30 gün içerisinde cevaplanır.
            Başvurunuzun olumsuz değerlendirilmesi halinde gerekçeli ret
            sebepleri başvuruda belirttiğiniz adrese elektronik posta veya posta
            yolu başta olmak üzere İlgili Kişi Başvuru Formu ’nda seçilen
            usullerinden biri ile gönderilir.
          </Typography>
        </StyledPaper>
      </Box>
    </>
  );
}

export default Kvkk;
