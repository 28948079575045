import React, { useEffect, useState } from "react";
import image from "../../images/medyaBanner.jpg";
import {
  Box,
  Breadcrumbs,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import HaberlerContents from "./HaberlerContents";
import GaleriContents from "./GaleriContents";

function SolYapiMedya({ page, setPage }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [currentContent, setCurrentContent] = useState();
  const navigate = useNavigate();

  const handleItemClick = (pageName, content) => {
    setPage(pageName);
    setCurrentContent(content);

    if (pageName === "Haberler") {
      navigate("/haberler");
    } else if (pageName === "Galeri") {
      navigate("/galeri");
    }
  };

  return (
    <>
      <Box
        component="img"
        alt=""
        src={image}
        style={{
          marginTop: "130px",
          width: "100%",
          height: "30rem",
          objectFit: "cover",
          objectPosition: "center",
        }}
      />

      <Box
        sx={{
          borderBottom: "1px solid lightgray",
          height: "5rem",
          display: "flex",
          alignItems: "center",
          paddingLeft: "1rem",
          justifyContent: "center",
        }}
      >
        {" "}
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link
            href="/"
            style={{
              color: "gray",
              padding: "8px",
              textDecoration: "none",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            Ana Sayfa
          </Link>
          <Link
            href=""
            style={{
              color: "gray",
              padding: "8px",
              textDecoration: "none",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            Medya
          </Link>
          <Link
            href=""
            style={{
              color: "black",
              padding: "8px",
              textDecoration: "none",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            {page}
          </Link>
        </Breadcrumbs>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: "space-between",
          padding: { xs: "1rem", lg: "1rem 0 1rem 1rem" },
        }}
      >
        <Box
          sx={{
            borderRight: { xs: "none", lg: "1px solid gray" },
            marginBottom: "2rem",
            paddingRight: { xs: "0rem", lg: "3rem" },
          }}
        >
          <Paper
            sx={{
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              padding: "2rem",
              width: { xs: "auto", lg: "80%" },
            }}
          >
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "1.5rem",
                textDecoration: "underline",
              }}
            >
              Medya
            </Typography>

            <List>
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleItemClick("Haberler")}>
                  <ListItemText primary="Haberler" />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton onClick={() => handleItemClick("Galeri")}>
                  <ListItemText primary="Galeri" />
                </ListItemButton>
              </ListItem>
            </List>
          </Paper>
        </Box>

        <Box
          sx={{
            marginLeft: { xs: "0", lg: "2rem" },
            flex: 1,
          }}
        >
          {page === "Haberler" && (
            <>
              <HaberlerContents content={currentContent} />
            </>
          )}

          {page === "Galeri" && (
            <>
              <GaleriContents content={currentContent} />
            </>
          )}
        </Box>
      </Box>
    </>
  );
}

export default SolYapiMedya;
