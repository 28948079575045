import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Link,
  IconButton,
  Card,
  CardMedia,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import analiz from "../../images/analiz.jpg";
import deney from "../../images/deney.jpg";
import celik from "../../images/celik.png";
import LinkIcon from "@mui/icons-material/Link";
import { styled } from "@mui/material/styles";

const certificates = [
  {
    title: "TS EN ISO /IEC 17025",
    details: [
      {
        name: "Türkak Akreditasyon Belgemiz",
        link: "https://www.kardemir.com/dosyalar/sayfalar/30/23062023/2023062314065554_sayfalar_30_23062023.pdf?v=0db5553e_857c_ae1d_300b_0416cc3d968d",
      },
      {
        name: "TSE Laboratuvar Belgesi",
        link: "https://www.kardemir.com/dosyalar/kalite/30/04072024/2024070411533868_kalite_30_04072024.pdf?v=20832baf_a5ed_435b_23de_f1b29a1e87de",
      },
    ],
  },
  {
    title: "EN 10025 CE",
    details: [
      {
        name: "CE Sertifikası, AB Yapı Malzemeleri Yönetmeliği (Direktif No: 89/106/EEC, Üye Devletlerin Yapı Malzemeleri ile İlgili Kanun, Yönetmelik ve İdari Hükümlerinin Yakınlaştırılması)",
        link: "https://www.kardemir.com/dosyalar/kalite/30/03032023/2023030315094721_kalite_30_03032023.pdf?v=45cbf931_a8a7_82ba_40eb_5f6b38c5c323",
      },
      {
        name: "Performans Deklarasyonu",
        link: "https://www.kardemir.com/dosyalar/kalite/30/18032021/2021031818114041_kalite_30_18032021.pdf?v=13b86483_e435_1618_bdd2_f526133b60ca",
      },
    ],
  },
  {
    title: "EN 13674-1 Demiryolu Rayları TSI Belgeleri",
    details: [
      {
        name: "TSI Sertifikası, TSI Demiryolu Sisteminin Topluluk İçinde Birlikte Çalışabilirliği (Direktif No: 2014/106/EU, 2008/57/EC Direktifine Ek V ve VI'nın Değiştirilmesi)",
        link: "https://www.kardemir.com/dosyalar/kalite/30/25012024/2024012511142691_kalite_30_25012024.pdf?v=eae9d33e_7407_1844_09d7_35ac427f2a24",
      },
      {
        name: "Uygunluk Beyanı",
        link: "https://www.kardemir.com/dosyalar/kalite/30/28022024/2024022814560919_kalite_30_28022024.pdf?v=c91c3a50_caac_6dc6_65ca_416d7fb48a72",
      },
    ],
  },
  {
    title: "EN 13262-1 BA 303 Tip Demiryolu Tekeri TSI Belgeleri",
    details: [
      {
        name: "BA 303 EC Tip İnceleme Sertifikası, TSI Demiryolu Sisteminin Topluluk İçinde Birlikte Çalışabilirliği (Direktif No: 2014/106/EU, 2008/57/EC Direktifine Ek V ve VI'nın Değiştirilmesi)",
        link: "https://www.kardemir.com/dosyalar/kalite/30/08012024/2024010813164675_kalite_30_08012024.pdf?v=0565204b_16e5_670a_5969_358ee3c2ed2d",
      },
      {
        name: "BA 303 Kalite Yönetim Sistemi Uygunluğu, TSI Demiryolu Sisteminin Topluluk İçinde Birlikte Çalışabilirliği (Direktif No: 2014/106/EU, 2008/57/EC Direktifine Ek V ve VI'nın Değiştirilmesi)",
        link: "https://www.kardemir.com/dosyalar/kalite/30/08012024/2024010813171000_kalite_30_08012024.pdf?v=6ddb1658_e89b_a2de_1bbb_5a913a37236f",
      },
      {
        name: "Uygunluk Beyanı",
        link: "https://www.kardemir.com/dosyalar/kalite/30/08012024/2024010813173755_kalite_30_08012024.pdf?v=48af49f5_0a1a_0e91_5ad8_1dc85fb0bf35",
      },
    ],
  },
  {
    title: "EN 13262-1 BA 004 Tip Demiryolu Tekeri TSI Belgeleri",
    details: [
      {
        name: "BA 004 EC Tip İnceleme Sertifikası, TSI Demiryolu Sisteminin Topluluk İçinde Birlikte Çalışabilirliği (Direktif No: 2014/106/EU, 2008/57/EC Direktifine Ek V ve VI'nın Değiştirilmesi)",
        link: "https://www.kardemir.com/dosyalar/kalite/30/15102021/2021101519030650_kalite_30_15102021.pdf?v=ff61a6bb_6532_2d74_7e03_22f4db26a77e",
      },
      {
        name: "BA 004 Kalite Yönetim Sistemi Uygunluğu, TSI Demiryolu Sisteminin Topluluk İçinde Birlikte Çalışabilirliği (Direktif No: 2014/106/EU, 2008/57/EC Direktifine Ek V ve VI'nın Değiştirilmesi)",
        link: "https://www.kardemir.com/dosyalar/kalite/30/08012024/2024010813231809_kalite_30_08012024.pdf?v=a6466c8c_d19d_53f8_199e_5bb2d7ba71f4",
      },
      {
        name: "Uygunluk Beyanı",
        link: "https://www.kardemir.com/dosyalar/kalite/30/08012024/2024010813235261_kalite_30_08012024.pdf?v=fc4138d9_43cb_a329_e2a7_f33a9fa6f7ce",
      },
    ],
  },
  {
    title: "EN 13260 Tekerlek Seti TSI Belgeleri",
    details: [
      {
        name: "EC Tip İnceleme Sertifikası, TSI Demiryolu Sisteminin Topluluk İçinde Birlikte Çalışabilirliği (Direktif No: 2014/106/EU, 2008/57/EC Direktifine Ek V ve VI'nın Değiştirilmesi)",
        link: "https://www.kardemir.com/dosyalar/kalite/30/03052023/2023050308560722_kalite_30_03052023.pdf?v=4e3482df_4843_ddce_3254_9b47093cce2d",
      },
      {
        name: "Kalite Yönetim Sistemi Uygunluğu, TSI Demiryolu Sisteminin Topluluk İçinde Birlikte Çalışabilirliği (Direktif No: 2014/106/EU, 2008/57/EC Direktifine Ek V ve VI'nın Değiştirilmesi)",
        link: "https://www.kardemir.com/dosyalar/kalite/30/03052023/2023050308553639_kalite_30_03052023.pdf?v=f3d9c8a6_a621_b622_72a1_f7754189db36",
      },
      {
        name: "Uygunluk Beyanı",
        link: "https://www.kardemir.com/dosyalar/kalite/30/18052023/2023051809255771_kalite_30_18052023.pdf?v=64c8a707_6d02_3a72_c8d6_f0b55f945486",
      },
    ],
  },
  {
    title: "Cares Kalite Yönetim Sistemi ve Ürün Sertifikaları",
    details: [
      {
        name: "BS 5896 PT6 Kalite 5.5-16mm Düz yüzeyli kangal",
        link: "https://www.kardemir.com/dosyalar/kalite/30/16042024/2024041614495607_kalite_30_16042024.pdf?v=0e730623_f31c_49df_8ec1_b6a14b8c1005",
      },
      {
        name: "BS 4449:2005 (İngiltere) B500B Kalite 12-40mm Çubuk – BS 4482 ve BS 4449 5.5 - 25mm Düz yüzeyli kangal",
        link: "https://www.kardemir.com/dosyalar/kalite/30/16042024/2024041614503649_kalite_30_16042024.pdf?v=fe7804f9_060b_7997_2fda_e541bcce60ea",
      },
      {
        name: "SS560:2016 (Singapur) B500B Kalite 12-40mm Çubuk",
        link: "https://www.kardemir.com/dosyalar/kalite/30/16042024/2024041614505496_kalite_30_16042024.pdf?v=3bf4ccbe_0811_b817_08d6_29c6e341ac50",
      },
      {
        name: "CS2:2012 (Hong Kong) 500B Kalite 12-40mm Çubuk",
        link: "https://www.kardemir.com/dosyalar/kalite/30/16042024/2024041614510896_kalite_30_16042024.pdf?v=26f31daf_31b2_4f60_862a_a6ee2c331f72",
      },
    ],
  },
  {
    title:
      "TÜV AUSTRIA - Kangal, Garret, SBQ Mamulleri İmalata Yeterlilik Belgeleri",
    details: [
      {
        name: "EN 10108 & EN 10017 Ø5,5-25 mm Kangal",
        link: "https://www.kardemir.com/dosyalar/kalite/30/11042022/2022041114042101_kalite_30_11042022.pdf?v=489b2e72_83fe_d58b_ed0d_3bca733e1dbc",
      },
      {
        name: "EN 10108 & EN 10017 Ø20-56 mm Garret",
        link: "https://www.kardemir.com/dosyalar/kalite/30/11042022/2022041114043835_kalite_30_11042022.pdf?v=2a037805_ad47_ebab_89d2_c65f7190b43b",
      },
      {
        name: "EN 10060 & EN 10017 Ø20-100 mm SBQ",
        link: "https://www.kardemir.com/dosyalar/kalite/30/11042022/2022041114045296_kalite_30_11042022.pdf?v=802009e0_34a5_7f98_d19c_f5180cbf5206",
      },
    ],
  },
  {
    title: "TÜV AUSTRIA - Ray Mamulleri İmalata Yeterlilik Belgeleri",
    details: [
      {
        name: "EN 13674-1 46E2 (46,27kg/m), 49E1 (49,39kg/m), 50E4 (50,46kg/m), 50E6 (50,90kg/m), 54E1 (54,77kg/m), 60E1 (60,21kg/m), 60E2 (60,03kg/m) R260 kalite vignole raylar",
        link: "https://www.kardemir.com/dosyalar/kalite/30/11042022/2022041114051570_kalite_30_11042022.pdf?v=209f9d1b_85ec_2d89_fb33_f3d9f7756081",
      },
      {
        name: "EN 13674-1 49E1 (49,39kg/m), 54E1 (54,77kg/m), 60E1 (60,21kg/m) R350HT kalite vignole raylar",
        link: "https://www.kardemir.com/dosyalar/kalite/30/11042022/2022041114054015_kalite_30_11042022.pdf?v=30ca2585_d134_d4bb_70c7_5d1d347b938f",
      },
      {
        name: "EN 14811+A1 60R1 (60,59kg/m), 60R2 (59,75kg/m) R260 kalite oluklu raylar",
        link: "https://www.kardemir.com/dosyalar/kalite/30/14042022/2022041417183361_kalite_30_14042022.pdf?v=9d75fe9b_1521_6d7d_fa31_f0bb14f96538",
      },
      {
        name: "EN 14811+A1 59R2 (58,14kg/m) R220G1 ve R260 kalite oluklu raylar",
        link: "https://www.kardemir.com/dosyalar/kalite/30/06082022/2022080619453403_kalite_30_06082022.pdf?v=a105ae4a_8979_a831_b5ce_610b0f2852ec",
      },
      {
        name: "UIC 860 R UIC50 (49,43kg/m), UIC50 (50,46kg/m), UIC54 (54,43kg/m), UIC60 (60,34kg/m) 900A kalite vignole raylar",
        link: "https://www.kardemir.com/dosyalar/kalite/30/06082022/2022080619475341_kalite_30_06082022.pdf?v=b4e5ac60_b0c9_5b1e_4a73_91fea4723388",
      },
      {
        name: "UIC 860 R UIC49 (49,43kg/m), UIC60 (60,34kg/m) 900B kalite vignole raylar",
        link: "https://www.kardemir.com/dosyalar/kalite/30/11042022/2022041114062007_kalite_30_11042022.pdf?v=160e5603_c8a0_a2fe_7ca8_e1188b96f6d6",
      },
    ],
  },
  {
    title: "TÜV AUSTRIA - Kütük Yarı Mamulleri İmalata Yeterlilik Belgeleri",
    details: [
      {
        name: "TS 9016 Genel yapı ve otomat çelikleri için kare kesitli çelik kütükler",
        link: "https://www.kardemir.com/dosyalar/kalite/30/11042022/2022041112460203_kalite_30_11042022.pdf?v=e48b031f_2ee9_13f0_63ce_0c609741db0d",
      },
      {
        name: "TS 9914 Nervürlü ve düz yuvarlak beton çelik çubuklar için kare kesitli çelik kütükler",
        link: "https://www.kardemir.com/dosyalar/kalite/30/11042022/2022041112482145_kalite_30_11042022.pdf?v=c6a05361_1631_1807_2d29_82c295f96c67",
      },
    ],
  },
  {
    title:
      "TSE - Nervürlü Çelik Çubuklar Türk Standartlarına Uygunluk Belgeleri  ",
    details: [
      {
        name: "TS 708 ve G Uygunluk Nervürlü Çelik Çubuklar",
        link: "https://www.kardemir.com/dosyalar/kalite/30/08012024/2024010812120940_kalite_30_08012024.pdf?v=9424afab_918d_048a_7d8d_7d2510f6c190",
      },
    ],
  },
  {
    title:
      "TSE - Akkuyu NGS Projesi Nervürlü Çelik Çubuklar Türk Standartlarına Uygunluk Belgeleri    ",
    details: [
      {
        name: "Akkuyu NGS Projesi Ürün Uygunluk Sertifikası",
        link: "https://www.kardemir.com/dosyalar/kalite/30/23082023/2023082310050001_kalite_30_23082023.pdf?v=06b58195_7922_4dbe_e87b_d8c13140be90",
      },
      {
        name: "TSE K 591 Nervürlü Çelik Çubuklar",
        link: "https://www.kardemir.com/dosyalar/kalite/30/23082023/2023082310041961_kalite_30_23082023.pdf?v=065ed4a2_8a56_2ef3_181f_52c5f674f59a",
      },
    ],
  },
  {
    title: "TSE - NPI ve NPU Profiller Türk Standartlarına Uygunluk Belgeleri ",
    details: [
      {
        name: "TS 910 NPI Profiller",
        link: "https://www.kardemir.com/dosyalar/kalite/30/23082023/2023082310011866_kalite_30_23082023.pdf?v=c053bf51_dca4_fb5d_5ca1_7a94984db8d6",
      },
      {
        name: "TS 912 NPU Profiller",
        link: "https://www.kardemir.com/dosyalar/kalite/30/23082023/2023082310014411_kalite_30_23082023.pdf?v=5c91f853_42d4_f047_79a6_cf6bfd01dcdf",
      },
    ],
  },
  {
    title: "TSE - Pik Türk Standartlarına Uygunluk Belgesi  ",
    details: [
      {
        name: "TS EN 10001 Pikler",
        link: "https://www.kardemir.com/dosyalar/kalite/30/23082023/2023082310015866_kalite_30_23082023.pdf?v=8041f18c_c49d_2994_eaa6_a1f498b6e86b",
      },
    ],
  },
  {
    title: "TSE - Türk Standartlarına Uygunluk Belgeleri  ",
    details: [
      {
        name: "TS 856 Tip-1 Kristal Amonyum Sülfat",
        link: "https://www.kardemir.com/dosyalar/sayfalar/30/02012024/2024010210314261_sayfalar_30_02012024.pdf?v=42ca0433_f172_a929_e1c7_2b30ec2d1864",
      },
      {
        name: "İşletme Kayıt Belgesi - Amonyum Sülfat",
        link: "https://www.kardemir.com/dosyalar/sayfalar/30/22062022/2022062214203737_sayfalar_30_22062022.pdf?v=cd4b8931_f66b_c7cf_0b0e_3789f0b0fefd",
      },
      {
        name: "TS 13242 +A1 Yol Agregaları",
        link: "https://www.kardemir.com/dosyalar/sayfalar/30/19122023/2023121909405380_sayfalar_30_19122023.pdf?v=f74a4fb5_2cfd_3827_a421_e52c14a6766e",
      },
      {
        name: "TS EN 13242+A1 Fabrika Üretim Kontrolü Uygunluk Belgesi (CE Belgesi)",
        link: "https://www.kardemir.com/dosyalar/kalite/30/06022019/2019020616580133_kalite_30_06022019.pdf?v=71054861_3a2e_73da_fa6d_055dc9af1148",
      },
      {
        name: "TS 2800 Oksijen-Sanayide Kullanılan",
        link: "https://www.kardemir.com/dosyalar/sayfalar/30/22052024/2024052215201356_sayfalar_30_22052024.pdf?v=46a5bccc_07e9_9e26_b672_df8bf87dbc12",
      },
      {
        name: "TS 9640 Argon-Sanayide Kullanılan",
        link: "https://www.kardemir.com/dosyalar/sayfalar/30/22052024/2024052215211604_sayfalar_30_22052024.pdf?v=ce33adeb_e5bc_9054_563b_299f728205b5",
      },
      {
        name: "TS 3192 Azot-Sanayide Kullanılan",
        link: "https://www.kardemir.com/dosyalar/sayfalar/30/22052024/2024052215205504_sayfalar_30_22052024.pdf?v=37441a7f_eb19_e35f_838f_382316ad3da7",
      },
      {
        name: "Hizmet Yeterlilik Belgesi",
        link: "https://www.kardemir.com/dosyalar/kalite/30/21062022/2022062109111127_kalite_30_21062022.pdf?v=e05df822_eab3_04f9_009e_cfa0fe1d9698",
      },
    ],
  },
  {
    title:
      "GOSTROY / GOST R 52544-2006 (A500C) / GOST 5781-82 (A600) / TU 14-1-5596-2010 (A600C)",
    details: [
      {
        name: "GOSTROY / GOST R 52544-2006 (A500C) / GOST 5781-82 (A600) / TU 14-1-5596-2010 (A600C)",
        link: "https://www.kardemir.com/dosyalar/kalite/30/07102021/2021100716313151_kalite_30_07102021.pdf?v=b1d10811_482a_7f94_6163_3ff091d15c68",
      },
    ],
  },
  {
    title:
      "Business Initiative Directions -International Star for Leadership in Quality Ödül Sertifikası 2011",
    details: [
      {
        link: "https://www.kardemir.com/dosyalar/kalite/30/06022019/2019020617002483_kalite_30_06022019.pdf?v=ebef97af_23dd_18ce_02b1_655758921f84",
        name: "Business Initiative Directions -International Star for Leadership in Quality Ödül Sertifikası 2011",
      },
    ],
  },
  {
    title:
      "Business Initiative Directions -International Star for Leadership in Quality Ödül Sertifikası 2012 ",
    details: [
      {
        link: "https://www.kardemir.com/dosyalar/kalite/30/06022019/2019020617004297_kalite_30_06022019.pdf?v=cd3f73f7_a6b6_daa7_786e_fa014df0a8a2",
        name: "Business Initiative Directions -International Star for Leadership in Quality Ödül Sertifikası 2012 ",
      },
    ],
  },
  {
    title: "BELGETÜRK EN 13674-1 49E1 Raylarda Muayene Sertifikası ",
    details: [
      {
        link: "https://www.kardemir.com/dosyalar/kalite/30/06022019/2019020616542070_kalite_30_06022019.pdf?v=34c58987_12fb_b3d9_a975_e2077bd50676",
        name: "BELGETÜRK EN 13674-1 49E1 Raylarda Muayene Sertifikası ",
      },
    ],
  },
  {
    title: "BELGETÜRK EN 13674-1 60E1 Raylarda Muayene Sertifikası",
    details: [
      {
        link: "https://www.kardemir.com/dosyalar/kalite/30/06022019/2019020616543463_kalite_30_06022019.pdf?v=e1c18b2b_3fbb_8b2c_7034_7b2256ef2ed6",
        name: "BELGETÜRK EN 13674-1 60E1 Raylarda Muayene Sertifikası",
      },
    ],
  },
];

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

function SertifikalarContents() {
  return (
    <>
      {/* <Typography variant="h4">Kalite Sertifikalarımız</Typography> */}

      <Header variant="h4" color="#1f2e5e">
        Kalite Sertifikalarımız
      </Header>

      <Box sx={{ margin: { xs: "0", md: "2rem " } }}>
        {certificates.map((section, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
            >
              <Paper
                elevation={2}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: 2,
                  width: "100%",
                  mb: 1,
                  backgroundColor: "#1f2e5e",
                }}
              >
                <Typography sx={{ fontWeight: 600, color: "#fff" }}>
                  {section.title}
                </Typography>
              </Paper>
            </AccordionSummary>
            <AccordionDetails>
              <Paper
                elevation={2}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  p: 2,
                  mb: 1,
                  backgroundColor: "#1f2e5e",
                }}
              >
                {section.details.map((detail, idx) => (
                  <Paper
                    key={idx}
                    elevation={2}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      p: 2,
                      mb: 1,
                    }}
                  >
                    <Typography>
                      <Link
                        href={detail.link}
                        target="_blank"
                        rel="noopener"
                        sx={{
                          fontWeight: 600,
                          color: "#1f2e5e",
                          textDecoration: "underline",
                        }}
                      >
                        {detail.name}
                      </Link>
                    </Typography>
                    <IconButton
                      href={detail.link}
                      target="_blank"
                      rel="noopener"
                    >
                      <PictureAsPdfIcon />
                    </IconButton>
                  </Paper>
                ))}
              </Paper>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>

      <Box pb={3} sx={{ mt: 5 }}>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3} mx={"1rem"}>
            <a
              href="https://www.kardemir.com/dosyalar/KimyasalAnaliz/analiz.pdf"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Card
                sx={{
                  width: "100%",
                  height: 250,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "&:hover": {
                    backgroundColor: "#1f2e5e",
                  },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    overflow: "hidden",
                    "&:hover": {
                      color: "#fff ",
                      transform: "scale(1.1) ",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      textAlign: "center",
                      height: "30%",
                      padding: "1rem 1rem 0 1rem",
                    }}
                  >
                    <Typography
                      sx={{
                        zIndex: "888",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "30%",
                      }}
                    >
                      ÇELİK KALİTELERİMİZ
                    </Typography>
                    <Link
                      style={{
                        textAlign: "center",
                        color: "blue",
                        padding: "4px",
                        textDecoration: "none",
                        fontSize: "10px",
                      }}
                      target="_blank"
                      href="https://www.kardemir.com/dosyalar/kalite/31/27022024/2024022712333622_kalite_31_27022024.pdf?v=20b48c73_320c_0561_7e7d_b7aae30d0e92"
                    >
                      <LinkIcon
                        fontSize="medium"
                        sx={{
                          color: "black",
                          "&:hover": {
                            color: "white",
                          },
                        }}
                      />
                    </Link>
                  </Box>
                  <CardMedia
                    component="img"
                    image={celik}
                    alt=""
                    sx={{
                      width: "100%",
                      height: "100%",
                      transition: "0.5s",
                      objectFit: "contain",
                      "&:hover": {
                        transform: "scale(1.1) ",
                      },
                    }}
                  />
                </Box>
              </Card>
            </a>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={3} xl={3} mx={"1rem"}>
            <a
              href="https://www.kardemir.com/dosyalar/kalite/31/27022024/2024022712333622_kalite_31_27022024.pdf?v=20b48c73_320c_0561_7e7d_b7aae30d0e92"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Card
                sx={{
                  width: "100%",
                  height: 250,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "&:hover": {
                    backgroundColor: "#1f2e5e",
                  },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    overflow: "hidden",
                    "&:hover": {
                      color: "#fff ",
                      transform: "scale(1.1) ",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      textAlign: "center",
                      height: "30%",
                      padding: "1rem 1rem 0 1rem",
                    }}
                  >
                    <Typography
                      sx={{
                        zIndex: "888",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "30%",
                      }}
                    >
                      TEST VE ANALİZ BİLGİLERİ
                    </Typography>
                    <Link
                      style={{
                        textAlign: "center",
                        color: "blue",
                        padding: "4px",
                        textDecoration: "none",
                        fontSize: "10px",
                      }}
                      target="_blank"
                      href="https://www.kardemir.com/dosyalar/kalite/31/27022024/2024022712333622_kalite_31_27022024.pdf?v=20b48c73_320c_0561_7e7d_b7aae30d0e92"
                    >
                      <LinkIcon
                        fontSize="medium"
                        sx={{
                          color: "black",
                          "&:hover": {
                            color: "white",
                          },
                        }}
                      />
                    </Link>
                  </Box>
                  <CardMedia
                    component="img"
                    image={analiz}
                    alt=""
                    sx={{
                      width: "100%",
                      height: "100%",
                      transition: "0.5s",
                      objectFit: "contain",
                      "&:hover": {
                        transform: "scale(1.1) ",
                      },
                    }}
                  />
                </Box>
              </Card>
            </a>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={3} xl={3} mx={"1rem"}>
            <a
              href="https://www.kardemir.com/dosyalar/sayfalar/32/10012023/2023011016164326_sayfalar_32_10012023.pdf?v=890c15aa_75ca_0fb0_57e9_b50b4083fa7a"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Card
                sx={{
                  width: "100%",
                  height: 250,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "&:hover": {
                    backgroundColor: "#1f2e5e",
                  },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    overflow: "hidden",
                    "&:hover": {
                      color: "#fff ",
                      transform: "scale(1.1) ",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      textAlign: "center",
                      height: "30%",
                      padding: "1.5rem 1rem 0 1rem",
                    }}
                  >
                    <Typography
                      sx={{
                        zIndex: "888",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      TEST VE DENEY TALEP FORMU
                    </Typography>
                    <Link
                      style={{
                        textAlign: "center",
                        color: "blue",
                        padding: "4px",
                        textDecoration: "none",
                        fontSize: "10px",
                      }}
                      target="_blank"
                      href="https://www.kardemir.com/dosyalar/sayfalar/32/10012023/2023011016164326_sayfalar_32_10012023.pdf?v=890c15aa_75ca_0fb0_57e9_b50b4083fa7a"
                    >
                      <LinkIcon
                        fontSize="medium"
                        sx={{
                          color: "black",
                          "&:hover": {
                            color: "white",
                          },
                        }}
                      />
                    </Link>
                  </Box>
                  <CardMedia
                    component="img"
                    image={deney}
                    alt=""
                    sx={{
                      width: "100%",
                      height: "100%",
                      transition: "0.5s",
                      objectFit: "contain",
                      "&:hover": {
                        transform: "scale(1.1) ",
                      },
                    }}
                  />
                </Box>
              </Card>
            </a>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default SertifikalarContents;
