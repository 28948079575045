import SolYapiSurdurulebilirlik from "./SolYapiSurdurulebilirlik";
import React, { useState } from "react";

function EnerjiYonetimi() {
  const [page, setPage] = useState("Enerji Yönetimi");

  return (
    <>
      <SolYapiSurdurulebilirlik setPage={setPage} page={page} />
    </>
  );
}

export default EnerjiYonetimi;
