import React, { useState } from "react";
import SolYapiFiyatlar from "./SolYapiFiyatlar";

function Fiyatlar() {
  const [page, setPage] = useState("Fiyatlar");

  return (
    <>
      <SolYapiFiyatlar setPage={setPage} page={page} />
    </>
  );
}

export default Fiyatlar;
