import {
  Box,
  Paper,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
  overflowX: "auto",
  maxWidth: 900, // Genişliği sınırlamak için maxWidth ayarlandı
  margin: "0 auto", // Merkezde hizalama sağlamak için margin kullanıldı
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

const Table = styled("table")(({ theme }) => ({
  width: "100%",
  borderCollapse: "collapse",
  "& th, & td": {
    padding: theme.spacing(2),
    textAlign: "left",
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  "& th": {
    backgroundColor: "#f5f5f5",
    // color: "#1f2e5e",
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  "& td": {
    backgroundColor: "#f5f5f5",
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1f2e5e",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

function KrediContents() {
  function createData(firma, derecelendirme, yil1, yil2, yil3, yil4, yil5) {
    return { firma, derecelendirme, yil1, yil2, yil3, yil4, yil5 };
  }

  const rows = [
    createData(
      "JCR Eurasia Rating",
      "Uzun Vadeli Ulusal Notu",
      "A + (Trk)/ Stabil",
      "A + (Trk)/ Stabil",
      "A (Trk)/ Pozitif",
      "A (Trk)/ Pozitif",
      "A (Trk)/ Pozitif"
    ),
    createData(
      "JCR Eurasia Rating",
      "Uzun Vadeli Uluslararası Yabancı Para Notu",
      "BB+/Negatif",
      "BBB-/Stabil",
      "	BBB-/Stabil",
      "BBB-(Trk)/Stabil",
      "BBB-(Trk)/Stabil"
    ),
  ];

  return (
    <Box sx={{ flexGrow: 1, padding: 1 }}>
      <Header variant="h4" color="#1f2e5e">
        Kredi Derecelendirme
      </Header>

      <StyledPaper elevation={3}>
        <Box>
          <Typography
            sx={{
              borderBottom: "1px solid #19467d",

              color: "#1f2e5e",
              fontSize: "2rem",
              fontWeight: "bold",
            }}
          >
            Kredi Derecelendirme
          </Typography>
          <Box sx={{ margin: { xs: "0", md: "2rem " } }}>
            <Box sx={{ marginTop: "1rem", width: "100%" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Rating Firması</StyledTableCell>
                      <StyledTableCell>Derecelendirme</StyledTableCell>
                      <StyledTableCell>2019</StyledTableCell>
                      <StyledTableCell>2018</StyledTableCell>
                      <StyledTableCell>2017</StyledTableCell>
                      <StyledTableCell>2016</StyledTableCell>
                      <StyledTableCell>2015</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow>
                        <StyledTableCell align="right">
                          {row.firma}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.derecelendirme}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.yil1}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.yil2}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.yil3}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.yil4}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.yil5}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </StyledPaper>
    </Box>
  );
}

export default KrediContents;
