import React, { useState } from "react";
import SolYapiYatirimci from "./SolYapiYatirimci";

function OzetMali() {
  const [page, setPage] = useState("Özet Mali Tablolar");

  return <SolYapiYatirimci setPage={setPage} page={page} />;
}

export default OzetMali;
