import React, { useState } from "react";
import SolYapiYatirimci from "./SolYapiYatirimci";

function HisseDetay() {
  const [page, setPage] = useState("Hisse Detay Grafik");

  return <SolYapiYatirimci setPage={setPage} page={page} />;
}

export default HisseDetay;
