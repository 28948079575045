import React from "react";
import { Typography, Paper, Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
  overflowX: "auto",
  maxWidth: 600, // Genişliği sınırlamak için maxWidth ayarlandı
  margin: "0 auto", // Merkezde hizalama sağlamak için margin kullanıldı
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

const ContentLink = styled("a")(({ theme }) => ({
  display: "block",
  marginBottom: theme.spacing(2),
  textDecoration: "none",
}));

function TedarikciIslemleriContents() {
  return (
    <Box sx={{ flexGrow: 1, padding: 1 }}>
      <Header variant="h4" color="#1f2e5e">
        Tedarikçi İşlemleri
      </Header>

      <StyledPaper elevation={3}>
        <ContentLink
          href="https://www.kardemir.com/dosyalar/tedarikci/tedarikciyetkilisiaydinlatmametni.pdf"
          target="_blank"
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#1f2e5e",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#1f2e5e",
              },
            }}
            fullWidth
          >
            Kardemir Tedarikçi Yetkilisi Aydınlatma Metni’ne buradan
            ulaşabilirsiniz.
          </Button>
        </ContentLink>

        <ContentLink href="https://app.pratis.net/logon.aspx" target="_blank">
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#1f2e5e",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#1f2e5e",
              },
            }}
            fullWidth
          >
            Tedarikçi sistemine giriş yapmak için tıklayın.
          </Button>
        </ContentLink>

        <ContentLink
          href="https://app.pratis.net/FY/FY_FirmaKayit_1.aspx?Val=06E04606907206D06104B06F06407503D035036036038035041034044035037035038033032035033037033037031036031036044036045035032036041037036034046034032037037035034033031034031033044033044#)"
          target="_blank"
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#1f2e5e",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#1f2e5e",
              },
            }}
            fullWidth
          >
            Şirketimize tedarikçi olmak için tıklayın.
          </Button>
        </ContentLink>

        <ContentLink
          href="https://www.kardemir.com/dosyalar/tedarikci/tedarikcibasvurusureci.pdf"
          target="_blank"
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#1f2e5e",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#1f2e5e",
              },
            }}
            fullWidth
          >
            Sistemin başvuru kılavuzunu görüntülemek için tıklayın.
          </Button>
        </ContentLink>

        <ContentLink
          href="https://www.kardemir.com/dosyalar/sayfalar/1157/10112022/2022111011493653_sayfalar_1157_10112022.pdf?v=4b57c956_8037_029e_25e1_e0ab461e4df1"
          target="_blank"
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#1f2e5e",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#1f2e5e",
              },
            }}
            fullWidth
          >
            Tedarikçi İş Etiği Kurallarını görüntülemek için tıklayın.
          </Button>
        </ContentLink>
      </StyledPaper>
    </Box>
  );
}

export default TedarikciIslemleriContents;
