import { useState } from "react";
import SolYapiUrunler from "./SolYapiUrunler";

const Sertifikalar = () => {
  const [page, setPage] = useState("Sertifikalarımız");

  return (
    <>
      <SolYapiUrunler setPage={setPage} page={page} />
    </>
  );
};

export default Sertifikalar;
