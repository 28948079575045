import React, { useState } from "react";
import SolYapiYatirimci from "./SolYapiYatirimci";

function AnaSozlesme() {
  const [page, setPage] = useState("Ana Sözleşme");

  return <SolYapiYatirimci setPage={setPage} page={page} />;
}

export default AnaSozlesme;
