import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Link,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  Collapse,
  Typography,
  Slide,
  useTheme,
} from "@mui/material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import PhoneIcon from "@mui/icons-material/Phone";
import logoyuvarlak from "../../images/logoyuvarlak.png";
import logoyatay from "../../images/logotr.png";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import urun1 from "../../images/duzyuvarlaklar.png";
import urun2 from "../../images/gıthmadendirekleri.png";
import urun3 from "../../images/profiller.png";
import urun4 from "../../images/heaprofiller.png";
import kapakImage from "../../images/katalogsayfa.png";
import SellIcon from "@mui/icons-material/Sell";
import VerifiedIcon from "@mui/icons-material/Verified";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import InventoryIcon from "@mui/icons-material/Inventory";
import Info from "../Info";
import talep from "../../images/talep.png";
import headersollogo from "../../images/sson.png";
import boslukluel from "../../images/headerboslukluel.png";
import EmailIcon from "@mui/icons-material/Email";
import { LinkedIn, X, Instagram, YouTube } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function HeaderTcdd({ infoVisible }) {
  const [openPopover, setOpenPopover] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const [openItems, setOpenItems] = useState({});
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isScrollingUp, setIsScrollingUp] = useState(true);
  const theme = useTheme();
  const navigate = useNavigate();

  const handleMouseEnter = (event, title) => {
    if (title !== "Fiyatlar") {
      setOpenPopover(title);
    } else {
      setOpenPopover(null); // Fiyatlar başlığına gelince popover'ı kapat
    }
  };

  const handleMouseLeave = () => {
    setOpenPopover(null);
  };

  const handleClick = () => {
    window.location.href =
      "https://bulut.kardemir.com/app/tr-TR/App/Transfer/OnlineTalep";
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY < lastScrollY) {
        setIsScrollingUp(true); // Yukarı kaydırınca göster
      } else {
        setIsScrollingUp(false); // Aşağı kaydırınca gizle
      }
      setLastScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  const navItemsLeft = [
    {
      title: "Kurumsal",
      content: [
        "Hakkımızda",
        "Kilometre Taşları",
        "Kilometre Taşları 1",
        "Kilometre Taşları 2",
        "Kilometre Taşları 3",
        "Yönetim Kurulu",
        "Üst Yönetim",
        "Vizyon ve Misyon",
        // "Ortaklık Yapısı",
      ],
      href: [
        "/hakkımızda",
        "/kilometre-taslari",
        "/kilometre-taslari-1",
        "/kilometre-taslari-2",
        "/kilometre-taslari-3",
        "/yonetim-kurulu",
        "/ust-yonetim",
        "/vizyon-misyon",
        // "/ortaklik-yapisi",
      ],
    },
    {
      title: "İlişkiler",
      content: ["Yatırımcı İlişkileri", "Tedarikçi İlişkileri"],
      href: ["/ortaklik-yapisi-yatirim", "/tedarikci-islemleri"],
    },
    {
      title: "Ürünler ve Hizmetler",
      content: [
        "Ürünler ",
        "Ürün Kataloğumuz",
        "Sertifikalarımız",
        "Satış İşlemleri",
      ],
      href: [
        "/urunler",
        "/urun-katalogumuz",
        "/sertifikalarimiz",
        "/satis-islemleri",
      ],
      image: urun1, // Buraya resim URL'si ekleyin
      specialPopover: true, // Özel popover için bir işaret ekleyelim
    },
    {
      title: "Fiyatlar",
      content: [],
      href: ["/fiyatlar"],
    },
    {
      title: "Sürdürülebilirlik",
      content: [
        "Sürdürülebilirlik Yönetimi",
        "Rapor ve Sertifikalarımız",
        "AR-GE ve İnovasyon",
        "Enerji Yönetimi",
        "Çevre ve İklim",
        "İş Devamlılığı ve Yatırımlar",
      ],
      href: [
        "/surdurulebilirlik-yonetimi",
        "/rapor-ve-sertifikalarimiz",
        "/arge-ve-inovasyon",
        "/enerji-yonetimi",
        "/cevre-ve-iklim",
        "/is-devamliliği-ve-yatirimlar",
      ],
    },
    {
      title: "Kariyer",
      content: [
        "Neden Kardemir?",
        "İş Başvuru Formu",
        "Personel Hakları",
        "Kurumsal Kimlik",
        "Sıkça Sorulan Sorular",
      ],
      href: [
        "/kariyer",
        "/is-basvuru-formu",
        "/personel-haklari",
        "/kurumsal-kimlik",
        "/sikca-sorulan-sorular",
      ],
    },
    {
      title: "Medya",
      content: ["Haberler", "Galeri"],
      href: ["/haberler", "/galeri"],
    },
  ];

  const navItemsRight = [
    {
      title: "Sürdürülebilirlik",
      content: [
        "Sürdürülebilirlik Yönetimi",
        "Rapor ve Sertifikalarımız",
        "AR-GE ve İnovasyon",
        "Enerji Yönetimi",
        "Çevre ve İklim",
        "İş Devamlılığı ve Yatırımlar",
      ],
      href: [
        "/surdurulebilirlik-yonetimi",
        "/rapor-ve-sertifikalarimiz",
        "/arge-ve-inovasyon",
        "/enerji-yonetimi",
        "/cevre-ve-iklim",
        "/is-devamliliği-ve-yatirimlar",
      ],
    },
    {
      title: "Kariyer",
      content: [
        "Neden Kardemir?",
        "İş Başvuru Formu",
        "Personel Hakları",
        "Kurumsal Kimlik",
        "Sıkça Sorulan Sorular",
      ],
      href: [
        "/kariyer",
        "/is-basvuru-formu",
        "/personel-haklari",
        "/kurumsal-kimlik",
        "/sikca-sorulan-sorular",
      ],
    },
    {
      title: "Medya",
      content: ["Haberler", "Galeri"],
      href: ["/haberler", "/galeri"],
    },
  ];

  const renderSpecialPopover = (item) => {
    if (item.title !== "Fiyatlar") {
      // Kutucuklar için resimler
      const urunImages = [urun1, urun2, urun3, urun4];

      // Kutucuklar için ikonlar
      const urunIcons = [
        <InventoryIcon sx={{ fontSize: 40, color: "#1f2e5e" }} />,
        <LibraryBooksIcon sx={{ fontSize: 40, color: "#1f2e5e" }} />,
        <VerifiedIcon sx={{ fontSize: 40, color: "#1f2e5e" }} />,
        <SellIcon sx={{ fontSize: 40, color: "#1f2e5e" }} />,
      ];

      return (
        <Box
          onMouseEnter={(e) => handleMouseEnter(e, item.title)} // Popover'ın üzerine gelindiğinde açık kalmasını sağlar
          onMouseLeave={handleMouseLeave} // Popover'ın dışına çıkıldığında kapanmasını sağlar
          sx={{
            position: "absolute",
            top: "3.5rem", // Başlığa daha yakın
            left: "-15rem",
            backgroundColor: "#f5f5f5",
            width: { md: "50rem", lg: "60rem" },
            padding: "20px",
            zIndex: 999900,
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "12px",
            display: "flex",
            justifyContent: "space-between",
            gap: "2rem",
            opacity: 0,
            animation: "fadeIn 0.5s forwards",
            "@keyframes fadeIn": {
              "0%": { opacity: 0, transform: "translateY(-10px)" },
              "100%": { opacity: 1, transform: "translateY(0)" },
            },
          }}
        >
          {/* Sol Taraf: 4 Kutucuk */}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "1rem",
              width: "50%",
            }}
          >
            {item.content.map((subItem, index) => (
              <Box
                key={index}
                component="a"
                href={item.href[index]}
                sx={{
                  backgroundColor: "#fff",
                  padding: "20px",
                  borderRadius: "12px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  textAlign: "center",
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                  position: "relative",
                  overflow: "hidden",
                  minHeight: "100px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textDecoration: "none",
                  ":hover": {
                    transform: "translateY(-5px)",
                    boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.2)",
                  },
                  "&:hover .hover-image": {
                    opacity: 1,
                  },
                }}
              >
                {/* Arka Plan Resmi (Hover ile Görünecek) */}
                <Box
                  className="hover-image"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${urunImages[index]})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    opacity: 0.5,
                    transition: "opacity 0.3s ease",
                    zIndex: 0,
                  }}
                />

                {/* İkon ve Metin */}
                <Box
                  sx={{
                    zIndex: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  {urunIcons[index]}
                  <Typography
                    sx={{
                      color: "#1f2e5e",
                      fontSize: "16px",
                      // fontWeight: "600",
                      textDecoration: "none",
                      zIndex: 2,
                    }}
                  >
                    {subItem}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>

          {/* Sağ Taraf: Büyük Resim */}
          <Box
            sx={{
              width: "55%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "12px",
              overflow: "hidden",
              position: "relative",
            }}
          >
            {/* Başlık */}
            <Typography
              sx={{
                fontSize: "20px",
                // fontWeight: "bold",
                color: "#1f2e5e",
                marginBottom: "10px",
              }}
            >
              Ürün Kataloğumuz
            </Typography>

            {/* Resmi Tıklanabilir Yap */}
            <a
              href="https://www.kardemir.com/dosyalar/satispazarlama/urunkatalogu/kardemir-urunler.pdf"
              target="_blank"
              rel="noopener noreferrer"
              style={{ width: "100%" }} // Link alanı genişletildi
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "12px",
                  overflow: "hidden",
                  position: "relative",
                  ":hover img": {
                    transform: "scale(1.1)",
                  },
                }}
              >
                <img
                  src={kapakImage}
                  alt="Ürünler ve Hizmetler"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "12px",
                    transition: "transform 0.3s ease",
                  }}
                />
                {/* Resim Üzerine Gradient Efekti */}
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background:
                      "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1))",
                  }}
                />
              </Box>
            </a>
          </Box>
        </Box>
      );
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleItemClick = (title) => {
    setOpenItems((prevState) => ({
      ...prevState,
      [title]: !prevState[title],
    }));
  };

  return (
    <Box>
      <AppBar
        onMouseLeave={handleMouseLeave}
        sx={{
          mt: 0,
          boxShadow: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "transform 0.3s ease-in-out",
          transform: isScrollingUp ? "translateY(0)" : "translateY(-100%)",
          willChange: "transform, height",
          position: "fixed",
          // backgroundImage: `url(${headersollogo})`,
          backgroundImage: `url(${headersollogo})`, // Varsayılan olarak büyük ekran için
          [theme.breakpoints.down("md")]: {
            backgroundImage: "none",
            backgroundColor: "white",
          },
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundColor: "transparent",
          paddingTop: { md: "0", lg: "20px" },
          //   paddingLeft: "-120px",
          top: infoVisible ? "40px" : "-40px",
          zIndex: 1000,
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          zIndex: "999999",
          height: "140px",
          "@media (max-width: 1630px) and (min-width: 1360px)": {
            height: "115px",
          },
          "@media (max-width: 1360px) and (min-width: 1180px)": {
            height: "100px",
          },
          "@media (max-width: 1180px) and (min-width: 1100px)": {
            height: "95px",
          },
          "@media (max-width: 1100px) and (min-width: 1000px)": {
            height: "85px",
          },
          "@media (max-width: 1000px) and (min-width: 900px)": {
            height: "80px",
          },
          // "@media (max-width: 900px) and (min-width: 800px)": {
          //   height: "75px",
          // },
          //   backgroundColor: "yellow",
          // Eğer ekran genişliği md boyutundan küçükse arka plan beyaz olacak
          [theme.breakpoints.down("md")]: {
            backgroundImage: "none",
            backgroundColor: "white",
            height: "110px", // Arkaplan beyazsa yükseklik 170px
          },
        }}
      >
        {/* <Box
          onClick={() => navigate("/")} // Anasayfaya yönlendir
          sx={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "20%", // Sadece 10px genişliğinde tıklanabilir alan
            height: "100%",
            cursor: "pointer",
            backgroundColor: "rgba(255, 0, 0, 0.3)", // Test amaçlı kırmızı (sonra kaldırabilirsin)
          }}
        /> */}

        <Box
          // onClick={() => navigate("/")} // Sol taraftaki geniş alan tıklanabilir
          sx={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "20%", // 20% genişliğinde tıklanabilir alan
            height: "100%",
            marginLeft: "-0.3%",
            cursor: "pointer",
            display: "flex", // İçerikleri hizalamak için
            alignItems: "center", // Dikeyde ortalamak için
            justifyContent: "center", // Yatayda ortalamak için
            backgroundColor: "transparent", // Test için kırmızı (sonra kaldırabilirsin)
          }}
        >
          <Box
            onClick={(e) => {
              e.stopPropagation(); // Üstteki tıklanma olayını durdur
              navigate("/"); // Anasayfaya yönlendir
            }}
            sx={{
              width: "50%", // Ortadaki tıklanabilir alanın genişliği
              height: "100%", // Ortadaki tıklanabilir alanın yüksekliği
              backgroundColor: "transparent", // Test için mavi (sonra kaldırabilirsin)
              cursor: "pointer",
              position: "relative", // Mutlaka eklenmeli!
              zIndex: 2, // İçteki Box’ın tıklanmasını garanti eder
            }}
          />
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
            // height: "40px",
          }}
        >
          <Toolbar
            sx={{
              width: { xs: "100%", md: "84%" },
            }}
          >
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  // backgroundColor: "purple",
                }}
              >
                {/* <Box
                  onClick={handleClick}
                  sx={{ display: { md: "flex", lg: "none" } }}
                >
                  <img
                    src={logoyuvarlak}
                    alt="img"
                    style={{
                      // height: "100%",
                      marginBottom: "50px",
                      // objectFit: "cover",
                      width: "80px",
                      height: "80px",
                    }}
                  />
                </Box> */}

                <Box
                  sx={{
                    display: "flex",
                    gap: 4,
                    justifyContent: "start",
                  }}
                >
                  {navItemsLeft.map((item) => (
                    <Box
                      key={item.title}
                      onClick={() => {
                        if (item.title === "Fiyatlar") {
                          window.location.href = "/fiyatlar";
                        }
                      }}
                      onMouseEnter={(e) => handleMouseEnter(e, item.title)}
                      sx={{
                        position: "relative",
                        cursor: "pointer",
                        // backgroundColor: "pink",
                        marginBottom: "50px",
                        color: "#1f2e5e",
                        fontSize: {
                          sx: "13px",
                          md: "13px",
                          lg: "17px",
                          xl: "19px",
                        },
                        // fontWeight: "600",
                        transition: "all 0.3s ease",
                        ":hover": {
                          color: "#d11f25",
                          transform: "translateY(-5px)",
                        },
                      }}
                    >
                      {item.title}
                      {openPopover === item.title &&
                        (item.specialPopover ? (
                          renderSpecialPopover(item)
                        ) : (
                          <Box
                            sx={{
                              position: "absolute",
                              top: "3.5rem",
                              left: 0,
                              backgroundColor: "#f5f5f5",
                              width: "15rem",
                              padding: "15px",
                              zIndex: 1200,
                              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                              borderRadius: "8px",
                              display: "flex",
                              flexDirection: "column",
                              gap: "20px",
                              opacity: 0,
                              animation: "fadeIn 0.5s forwards",
                              "@keyframes fadeIn": {
                                "0%": {
                                  opacity: 0,
                                  transform: "translateY(-10px)",
                                },
                                "100%": {
                                  opacity: 1,
                                  transform: "translateY(0)",
                                },
                              },
                            }}
                          >
                            {item.content.map((subItem, index) => (
                              <Link
                                key={index}
                                href={item.href[index]}
                                sx={{
                                  padding: "5px 0",
                                  color: "#1f2e5e",
                                  textDecoration: "none",
                                  fontSize: "15px",
                                  ":hover": {
                                    color: "#d11f25",
                                    transform: "translateX(5px)",
                                    transition: "all 0.3s ease",
                                  },
                                }}
                              >
                                {subItem}
                              </Link>
                            ))}
                          </Box>
                        ))}
                    </Box>
                  ))}
                </Box>

                <Box
                  onClick={handleClick}
                  sx={{
                    height: { md: "22px", lg: "30px", xl: "40px" },
                    ml: 2,
                    // display: { md: "none", lg: "flex" },
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={talep}
                    alt="img"
                    style={{
                      height: "100%",
                      marginBottom: "50px",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              </Box>
            </Box>

            {/* --------------------------------------------------------------------------------------------------- */}
            {/* Mobil görünüm için menü butonu */}
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                justifyContent: "space-between",
                alignItems: "center",
                // backgroundColor: "red",
                width: "100vw",
                padding: "0 8%",
                // padding: "0 1%",
                // marginBottom: "50px",
              }}
            >
              <IconButton
                href="/iletisim"
                disableRipple
                disableFocusRipple
                sx={{
                  display: { md: "none" },
                  "&:hover": {
                    backgroundColor: "transparent", // Hover rengini kaldır
                  },
                }}
              >
                <Box>
                  <PhoneIcon fontSize="large" sx={{ color: "#1f2e5e" }} />
                </Box>
              </IconButton>

              <Box
                component="a"
                href="/"
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={logoyuvarlak}
                  alt="Logo"
                  style={{
                    width: "80px",
                    height: "80px",
                    // borderRadius: "50%",
                  }}
                />
              </Box>

              <IconButton
                onClick={toggleMenu}
                disableRipple
                disableFocusRipple
                sx={{
                  display: { md: "none" },
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                aria-label="menu"
              >
                <Box>
                  <MenuOpenIcon
                    fontSize="large"
                    style={{ color: "#1f2e5e", fontWeight: "bold" }}
                  />
                </Box>
              </IconButton>
            </Box>

            <Drawer
              anchor="right"
              open={menuOpen}
              onClose={toggleMenu}
              sx={{
                maxWidth: 300,
                zIndex: "9999999",
              }}
            >
              <List
                sx={{
                  backgroundColor: "#0e2542",
                  height: "100%",
                  width: "300px",
                }}
              >
                {navItemsLeft.map((item) => (
                  <React.Fragment key={item.title}>
                    <ListItem
                      button
                      onClick={() => {
                        if (item.title === "Fiyatlar") {
                          window.location.href = "/fiyatlar";
                        } else {
                          handleItemClick(item.title);
                        }
                      }}
                      sx={{
                        color: "#fff",
                        p: 1.5,
                        cursor: "pointer",
                        textTransform: "capitalize",
                        fontWeight: "400",
                        ":hover": {
                          color: " #fff",
                          backgroundColor: "#0e2542",
                          borderBottom: "1px solid #ff",
                        },
                      }}
                    >
                      {item.title}
                      {openItems[item.title] ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse
                      in={openItems[item.title]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {item.content.map((subItem, index) => (
                          <ListItem
                            key={subItem}
                            sx={{
                              pl: 4,
                              color: "#fff",
                              ":hover": { color: "red" },
                            }}
                            component="a"
                            href={item.href[index]}
                          >
                            {subItem}
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </React.Fragment>
                ))}
              </List>
            </Drawer>
          </Toolbar>
        </Box>
      </AppBar>
    </Box>
  );
}

export default HeaderTcdd;

// Kurumsal, ilişkiler, ürünler ve hizmetler , sürdürülebilirlik, kariyer ve madya benim header başlıklarım. bu başlıkların 3'ü sağda diğer 3'ü solda konumlanmış durumda ve hepsinin ücerine gelince aynı tarzda bir popover açılıyor. Benim istediğim ise şu: örneğin ürünler ve hizmetler başlığına tıkladığımda başka bir tasarıma shaip bir popover açılsın. Bu tasarım şöyle olabilir. popoverın sol tarafında 2 altta 2 üstte olacak şekilde 4 kutucuğun içine başlığa ait content içerikleri olur sol tarafında ise bir resim olur. bunu yapabilir misin?
