import React, { useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Typography,
  Paper,
  Box,
} from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
} from "recharts";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
  overflowX: "auto",
  maxWidth: 800, // Genişliği sınırlamak için maxWidth ayarlandı
  margin: "0 auto", // Merkezde hizalama sağlamak için margin kullanıldı
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

const KarsilastirmaliGrafikContents = () => {
  const [selectedStock, setSelectedStock] = useState("KRDMA");
  const [selectedUpperIndicator, setSelectedUpperIndicator] = useState("");
  const [selectedLowerIndicator, setSelectedLowerIndicator] = useState("");

  const handleStockChange = (event) => {
    setSelectedStock(event.target.value);
    // Hisse senedine göre indikatör değerlerini güncelle
  };

  const data = [
    { date: "09.07.24", value: 21.0, volume: 30 },
    { date: "10.07.24", value: 22.5, volume: 50 },
    { date: "16.07.24", value: 24.5, volume: 50 },
    { date: "18.07.24", value: 25.5, volume: 50 },
    { date: "22.07.24", value: 22.5, volume: 50 },
    { date: "25.07.24", value: 24.5, volume: 50 },
    { date: "28.07.24", value: 22.5, volume: 50 },
    { date: "02.08.24", value: 23.5, volume: 50 },
    { date: "07.08.24", value: 24.5, volume: 50 },
    { date: "10.08.24", value: 23.5, volume: 50 },
    // Veriler buraya gelecek
  ];

  return (
    <Box sx={{ flexGrow: 1, padding: 1 }}>
      <Header variant="h4" color="#1f2e5e">
        Karşılaştırmalı Grafik
      </Header>

      <StyledPaper elevation={3} sx={{ marginBottom: "2rem" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="stock-label">Hisse Senedi</InputLabel>
              <Select
                labelId="stock-label"
                value={selectedStock}
                onChange={handleStockChange}
              >
                <MenuItem value="KRDMA">KRDMA</MenuItem>
                <MenuItem value="KRDMB">KRDMB</MenuItem>
                <MenuItem value="KRDMD">KRDMD</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="upper-indicator-label">Üst İndikatör</InputLabel>
              <Select
                labelId="upper-indicator-label"
                value={selectedUpperIndicator}
                onChange={(event) =>
                  setSelectedUpperIndicator(event.target.value)
                }
              >
                <MenuItem value="Bollinger">Bollinger</MenuItem>
                <MenuItem value="Envelope EMA">Envelope EMA</MenuItem>
                <MenuItem value="Envelope SMA">Envelope SMA</MenuItem>
                <MenuItem value="Envelope WMA">Envelope WMA</MenuItem>
                <MenuItem value="Parabolic SAR">Parabolic SAR</MenuItem>
                <MenuItem value="Simple Moving Average">
                  Simple Moving Average
                </MenuItem>
                <MenuItem value="Exponential Moving Average">
                  Exponential Moving Average
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="lower-indicator-label">Alt İndikatör</InputLabel>
              <Select
                labelId="lower-indicator-label"
                value={selectedLowerIndicator}
                onChange={(event) =>
                  setSelectedLowerIndicator(event.target.value)
                }
              >
                <MenuItem value="Bollinger">Bollinger</MenuItem>
                <MenuItem value="Envelope EMA">Envelope EMA</MenuItem>
                <MenuItem value="Envelope SMA">Envelope SMA</MenuItem>
                <MenuItem value="Envelope WMA">Envelope WMA</MenuItem>
                <MenuItem value="Parabolic SAR">Parabolic SAR</MenuItem>
                <MenuItem value="Simple Moving Average">
                  Simple Moving Average
                </MenuItem>
                <MenuItem value="Exponential Moving Average">
                  Exponential Moving Average
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginTop: "20px" }}>
          <Grid item xs={12}>
            <LineChart
              width={800}
              height={400}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="1 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="value"
                stroke="#d11f25"
                activeDot={{ r: 8 }}
              />
              <BarChart dataKey="volume" fill="#d11f25" />
            </LineChart>
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>
  );
};

export default KarsilastirmaliGrafikContents;
