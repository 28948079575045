import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Link,
  Drawer,
  IconButton,
  List,
  ListItem,
  Collapse,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../images/logotr.png";
import logoyuvarlak from "../../images/logoyuvarlak.png";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

function Header() {
  const [openPopover, setOpenPopover] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [openItems, setOpenItems] = useState({});

  const handleMouseEnter = (event, title) => {
    setOpenPopover(title);
  };

  const handleMouseLeave = () => {
    setOpenPopover(null);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navItems = [
    {
      title: "Kurumsal",
      content: [
        "Hakkımızda",
        "Kilometre Taşları",
        "Kilometre Taşları 1",
        "Kilometre Taşları 2",
        "Kilometre Taşları 3",
        "Yönetim Kurulu",
        "Üst Yönetim",
        "Vizyon ve Misyon",
        // "Ortaklık Yapısı",
      ],
      href: [
        "/hakkımızda",
        "/kilometre-taslari",
        "/kilometre-taslari-1",
        "/kilometre-taslari-2",
        "/kilometre-taslari-3",
        "/yonetim-kurulu",
        "/ust-yonetim",
        "/vizyon-misyon",
        // "/ortaklik-yapisi",
      ],
    },
    {
      title: "İlişkiler",
      content: ["Yatırımcı İlişkileri", "Tedarikçi İlişkileri"],
      href: ["/ortaklik-yapisi-yatirim", "/tedarikci-islemleri"],
    },
    {
      title: "Ürünler ve Hizmetler",
      content: [
        "Ürünler ",
        "Ürün Kataloğumuz",
        "Sertifikalarımız",
        "Satış İşlemleri",
      ],
      href: [
        "/urunler",
        "/urun-katalogumuz",
        "/sertifikalarimiz",
        "/satis-islemleri",
      ],
    },
    {
      title: "Sürdürülebilirlik",
      content: [
        "Sürdürülebilirlik Yönetimi",
        "Rapor ve Sertifikalarımız",
        "AR-GE ve İnovasyon",
        "Enerji Yönetimi",
        "Çevre ve İklim",
        "İş Devamlılığı ve Yatırımlar",
      ],
      href: [
        "/surdurulebilirlik-yonetimi",
        "/rapor-ve-sertifikalarimiz",
        "/arge-ve-inovasyon",
        "/enerji-yonetimi",
        "/cevre-ve-iklim",
        "/is-devamliliği-ve-yatirimlar",
      ],
    },
    {
      title: "Kariyer",
      content: [
        "Neden Kardemir?",
        "İş Başvuru Formu",
        "Personel Hakları",
        "Kurumsal Kimlik",
        "Sıkça Sorulan Sorular",
      ],
      href: [
        "/kariyer",
        "/is-basvuru-formu",
        "/personel-haklari",
        "/kurumsal-kimlik",
        "/sikca-sorulan-sorular",
      ],
    },
    {
      title: "Medya",
      content: ["Haberler", "Galeri"],
      href: ["/haberler", "/galeri"],
    },
  ];

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleItemClick = (title) => {
    setOpenItems((prevState) => ({
      ...prevState,
      [title]: !prevState[title],
    }));
  };

  return (
    <Box>
      <AppBar
        onMouseLeave={handleMouseLeave}
        sx={{
          mt: 3,
          boxShadow: "none",
          p: { xs: 1, md: 2 },
          backgroundColor:
            scrollPosition > 100 ? "rgba(255, 255, 255, 0.8)" : "#fff",
          transition: "background-color 0.3s ease",
          padding: "1.3rem 3.4rem",
          position: "fixed",
          zIndex: 1100,
          top: scrollPosition > 100 ? "-40px" : "0",
          // overflow: "hidden",
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
            boxSizing: "border-box",
            px: { xs: 2, md: 3 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              component="a"
              href="/"
              style={{
                display: "flex",
                alignItems: "center",
                width: "auto",
                height: "40px",
                cursor: "pointer",
              }}
            >
              <img
                src={logo}
                alt="Logo"
                style={{
                  width: "90%",
                  height: "auto",
                }}
              />
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
            }}
          >
            {navItems.map((item) => (
              <Box
                key={item.title}
                onMouseEnter={(e) => handleMouseEnter(e, item.title)}
                sx={{
                  position: "relative",
                  margin: "10px",
                  cursor: "pointer",
                  // textTransform: "capitalize",
                  color: "#1f2e5e",
                  fontSize: { sx: "15px", md: "18px", lg: "20px" },
                  fontWeight: "400",
                  transition: "all 0.3s ease",
                  ":hover": {
                    color: "#d11f25",
                    transform: "translateY(-5px)",
                  },
                }}
              >
                {item.title}
                {openPopover === item.title && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "2.5rem",
                      left: 0,
                      backgroundColor: "#f5f5f5",
                      width: "15rem",

                      padding: "15px",
                      zIndex: 1200,
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      opacity: 0,
                      animation: "fadeIn 0.5s forwards",
                      "@keyframes fadeIn": {
                        "0%": {
                          opacity: 0,
                          transform: "translateY(-10px)",
                        },
                        "100%": {
                          opacity: 1,
                          transform: "translateY(0)",
                        },
                      },
                    }}
                  >
                    {item.content.map((subItem, index) => (
                      <Link
                        key={index}
                        href={item.href[index]}
                        sx={{
                          padding: "5px 0",
                          color: "#1f2e5e",

                          textDecoration: "none",
                          fontSize: "15px",
                          ":hover": {
                            color: "#d11f25",
                            transform: "translateX(5px)",
                            transition: "all 0.3s ease",
                          },
                        }}
                      >
                        {subItem}
                      </Link>
                    ))}
                  </Box>
                )}
              </Box>
            ))}
          </Box>
          {/* Mobil görünüm için menü butonu */}
          <IconButton
            onClick={toggleMenu}
            sx={{ display: { md: "none" }, mr: { xs: 1, md: 3 } }}
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor="right"
            open={menuOpen}
            onClose={toggleMenu}
            sx={{
              maxWidth: 500,
            }}
          >
            <List
              sx={{
                backgroundColor: "#0e2542",
                height: "100%",
                width: "13rem",
              }}
            >
              {navItems.map((item) => (
                <React.Fragment key={item.title}>
                  <ListItem
                    button
                    onClick={() => handleItemClick(item.title)}
                    sx={{
                      color: "#fff",
                      p: 1.5,
                      cursor: "pointer",
                      textTransform: "capitalize",
                      fontWeight: "400",

                      ":hover": {
                        color: " #fff",
                        backgroundColor: "#0e2542",
                        borderBottom: "1px solid #ff",
                      },
                    }}
                  >
                    {item.title}
                    {openItems[item.title] ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse
                    in={openItems[item.title]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {item.content.map((subItem, index) => (
                        <ListItem
                          key={subItem}
                          sx={{
                            pl: 4,
                            color: "#fff",
                            ":hover": { color: "red" },
                          }}
                          component="a"
                          href={item.href[index]}
                        >
                          {subItem}
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </React.Fragment>
              ))}
            </List>
          </Drawer>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Header;
