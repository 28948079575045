import React from "react";
import {
  Container,
  Typography,
  Box,
  Link,
  Card,
  CardContent,
  Grid,
  CardMedia,
} from "@mui/material";
import foto1 from "../../images/KurumsalKimlik/1.png";
import foto2 from "../../images/KurumsalKimlik/2.png";
import foto3 from "../../images/KurumsalKimlik/3.png";
import foto4 from "../../images/KurumsalKimlik/4.png";
import foto5 from "../../images/KurumsalKimlik/5.png";
import { styled } from "@mui/material/styles";

const kurumsalKimlikData = [
  {
    title: "Logomuzun Tanımı",
    content: `Karabük Demir Çelik Fabrikaları 1937 yılında devlet yatırımı olarak kurulmuştur. 5 Nisan 1994 tarihinde açıklanan "Ekonomik Önlemler Uygulama Planı" kapsamında özelleştirilemediği takdirde kapatılmasına karar verilen fabrika; çalışanların, yöre sanayicisinin ve halkının girişimleri sonucu Özelleştirme Yüksek Kurulunun 30.12.1994 tarih ve 94/16 sayılı kararı ile özelleştirme kapsam ve programına alınmış ve Müteşebbis heyet tarafından 17.02.1995 tarihli sözleşme ile devredilmiştir.`,
    image: foto1,
  },
  {
    title: "Logoda Kullanılan Renklerin Anlamı",
    content: `Kardemir entegre bir demir çelik fabrikası olarak kok fabrikalarına ve yüksek fırınlara sahiptir. Logoda kullanılan mavi renk; yüksek fırınlarda üretim esnasında elde edilen yüksek fırın gazının yanması sonucu ortaya çıkan "Mavi" rengi simgelemektedir. Kırmızı ise kok fabrikalarında elde edilen kok gazının yanması sonucu ortaya çıkan "Kırmızı" rengi simgeler. Her iki gazın bacalarda oluşturduğu "Kırmızı-Mavi" renk üretimi de simgelemesi amacıyla kullanılmıştır.`,
    image: foto2,
  },
  {
    title: "El Figürlerinin Anlamı",
    content: `Logonun altında ve üstünde yer alan ve DÇ harflerini kapsayacak şekilde oluşturulan iki el figürü fabrikanın özelleştirilmesi sürecinde ortaya konan birlikteliği ve sahiplenmeyi simgelemektedir.`,
    image: foto3,
  },
  {
    title: "Yuvarlak DÇ Harfleri ve Yatay Çizgilerin Anlamı",
    content: `Kardemir, Türkiye'nin ilk entegre demir çelik fabrikasıdır. Özelleştiği 1995 yılına kadar, logosu kırmızı daire içinde D ve Ç harfleri ile yatay 6 çizgiden oluşmaktadır. Özelleştirme sonrası oluşturulan yeni logoda bu simgeler korunarak tarihi mirasa sahip çıkışmıştır. Aynı zamanda 6 yatay çizgi Kardemir'in önceliklerini ifade etmektedir.`,
    image: foto4,
  },
  {
    title: "KARDEMİR Logotype Anlamı",
    content: `Firmamızın ismi; Karabük ve Demir kelimelerinin birleşimi ile oluşturulmuştur. Başta yer alan Kar hecesini Karabük kelimesinin ilk hecesinden almaktadır. Logotype'ın karakteristiği demir ve çeliğin sağlamlığını temsil etmesi için sert ve köşeli karakterlerden oluşturulmuştur.`,
    image: foto5,
  },
];

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

const CardContainer = styled(Card)(({ theme }) => ({
  backgroundColor: "#1f2e5e",
  color: "white",
  borderRadius: "15px",
  overflow: "hidden",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.3s, box-shadow 0.3s",
  "&:hover": {
    transform: "scale(1.01)",
    boxShadow: "0 8px 40px rgba(0, 0, 0, 0.2)",
  },
}));

function KurumsalKimlikContents() {
  return (
    <Container sx={{ mt: 4 }}>
      <Header variant="h4" color="#1f2e5e">
        Kurumsal Kimlik
      </Header>

      {kurumsalKimlikData.map((item, index) => (
        <CardContainer key={index} sx={{ mb: 4 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom sx={{ color: "#fff" }}>
              {item.title}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                textAlign: "justify",
                padding: { xs: "1rem 0", lg: "1rem 3rem 1rem 0" },
              }}
            >
              {item.content}
            </Typography>
            {item.image && (
              <CardMedia
                component="img"
                image={item.image}
                alt={item.title}
                sx={{
                  mt: 2,
                  borderRadius: 1,
                  height: "104px",
                  width: "88px",
                  border: "1px solid #fff",
                }}
              />
            )}
          </CardContent>
        </CardContainer>
      ))}
      <CardContainer sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom sx={{ color: "#fff" }}>
            Logomuz
          </Typography>
          <Typography variant="body2">
            Logomuzu aşağıdaki linklerden indirebilirsiniz.
          </Typography>
          <Grid container spacing={2} mt={2}>
            <Grid item>
              <Link
                href="https://www.kardemir.com/dosyalar/basinodasi/kurumsalkimlik/logotype.pdf"
                target="_blank"
                sx={{ color: "#d11f25" }}
              >
                Baskı Kullanımı - Vektörel (AI, PDF)
              </Link>
            </Grid>
            <Grid item>
              <Link
                href="https://www.kardemir.com/dosyalar/basinodasi/kurumsalkimlik/logotype.pdf"
                target="_blank"
                sx={{ color: "#d11f25" }}
              >
                Ekran Kullanımı - Dijital (JPG, PNG)
              </Link>
            </Grid>
          </Grid>
        </CardContent>
      </CardContainer>
      <CardContainer sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom sx={{ color: "#fff" }}>
            Logo Kullanımı İle İlgili Bilgiler
          </Typography>
          <Typography variant="body2">
            Logomuzun kullanımına ilişkin dökümanı aşağıdaki linkten
            indirebilirsiniz.
          </Typography>
          <Box mt={2}>
            <Link
              href="https://www.kardemir.com/dosyalar/basinodasi/kurumsalkimlik/logotype.pdf"
              target="_blank"
              sx={{ color: "#d11f25" }}
            >
              Logo & Logotype Kullanımı
            </Link>
          </Box>
        </CardContent>
      </CardContainer>
    </Container>
  );
}

export default KurumsalKimlikContents;
