import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Typography, Box } from "@mui/material";
import foto1 from "../../images/Haberler/1.jpg";
import foto2 from "../../images/Haberler/2.jpg";
import foto3 from "../../images/Haberler/3.jpg";
import foto4 from "../../images/Haberler/4.jpg";
import foto5 from "../../images/Haberler/5.jpg";
import foto6 from "../../images/Haberler/6.jpg";
import foto7 from "../../images/Haberler/7.jpg";
import foto8 from "../../images/Haberler/8.jpg";
import foto9 from "../../images/Haberler/9.jpg";
import foto10 from "../../images/Haberler/10.jpg";
import foto11 from "../../images/Haberler/11.jpg";
import { styled } from "@mui/material/styles";

const newsData = [
  {
    id: "arge-muhendisi-ise-alim-sonuclari-hakkinda-kamuoyu-bilgilendirmesi",
    title:
      "”AR-GE Mühendisi” İşe Alım Sonuçları Hakkında Kamuoyu Bilgilendirmesi",
    date: " 24.07.2024",
    image: foto1,
    content: [
      {
        type: "paragraph",
        text: "Şirketimizde AR-GE Mühendisi pozisyonu için yapılan işe alım sürecine ilişkin ilgili işlemler tamamlanmış olup, asil ve yedek aday bilgilerinin yer aldığı liste aşağıda sunulmuştur.",
      },

      {
        type: "paragraph",
        text: "• Asil ve yedek aday listesine buradan ulaşabilirsiniz.",
      },

      {
        type: "paragraph",
        text: "Çalışma arkadaşlarımıza KARDEMİR A.Ş. ailesine hoş geldiniz der, başarılar dileriz.",
      },
    ],
  },
  {
    id: "tedarik-zinciri-direktorlugu-is-ilani",
    title: "Tedarik Zinciri Direktörlüğü - İş İlanı",
    date: "14.06.2024",
    image: foto2,
    content: [
      {
        type: "heading",
        text: "Karabük Demir Çelik Sanayi ve Ticaret A.Ş.",
      },
      {
        type: "paragraph",
        text: "KARDEMİR Tedarik Zinciri Direktörlüğü bünyesinde, aşağıdaki kriterler çerçevesinde yedi (7) MÜHENDİS personel istihdam edilecektir.",
      },

      {
        type: "heading",
        text: "GENEL ŞARTLAR",
      },
      {
        type: "paragraph",
        text: "• Türkiye Cumhuriyeti vatandaşı,",
      },

      {
        type: "paragraph",
        text: "• Kamu haklarından mahrumiyeti bulunmayan,",
      },
      {
        type: "paragraph",
        text: "• Kasten işlenen bir suçtan dolayı, bir yıl veya daha fazla süreyle hapis cezasına ya da affa uğramış olsa bile devletin güvenliğine karşı suçlar, Anayasal düzene ve bu düzenin işleyişine karşı suçlar, zimmet, irtikâp, rüşvet, hırsızlık, dolandırıcılık, sahtecilik, güveni kötüye kullanma, hileli iflas, ihaleye fesat karıştırma, edimin ifasına fesat karıştırma, suçtan kaynaklanan malvarlığı değerlerini aklama veya kaçakçılık suçlarından mahkûm olmamış,",
      },
      {
        type: "paragraph",
        text: "•  01/06/2024 tarihi itibariyle 35 yaşını doldurmamış olan (01/06/1989 ve sonraki tarihlerde doğanlar),",
      },
      {
        type: "paragraph",
        text: "• Erkek adaylar için askerlikle ilişiği olmayan,",
      },
      {
        type: "paragraph",
        text: "• Sağlık muayenesinde çok tehlikeli iş sınıflarında çalışabilmeye uygunluk ve görevlerini yapmaya engel olabilecek herhangi bir bedensel veya ruhsal rahatsızlığı bulunmayan,",
      },
      {
        type: "paragraph",
        text: "• Üniversitelerin en az dört yıllık eğitim veren fakülteleri ile denkliği yetkili makamlarca kabul edilmiş, yurtdışındaki eğitim kurumlarının aşağıda belirtilen bölümlerinden mezun olan,",
      },

      {
        type: "heading",
        text: "ÖĞRENİM ŞARTI",
      },
      {
        type: "paragraph",
        text: "Üniversitelerin; ",
      },

      {
        type: "paragraph",
        text: "• Endüstri Mühendisliği,",
      },
      {
        type: "paragraph",
        text: "• İşletme Mühendisliği, branşlarından mezun olmak.",
      },

      {
        type: "heading",
        text: "BAŞVURU",
      },
      {
        type: "paragraph",
        text: "Başvurular KARDEMİR’in www.kardemir.com adresindeki internet sitesi üzerinden yapılacaktır. Adayların 14/06/2024 tarihinden itibaren ve en geç 30/06/2024 Pazar günü saat 23.59’a kadar başvuru işlemlerini tamamlamaları gerekmektedir.",
      },
      {
        type: "heading",
        text: "DEĞERLENDİRME",
      },
      {
        type: "paragraph",
        text: "İlan kriterlerini sağlayan adaylar Genel Yetenek ve Yabancı Dil (İngilizce) sınavına tabi tutulacaktır. Sınav ve mülakat tarihleri adaylara ayrıca bildirilecektir. Yukarıda belirtilen pozisyonlar için sıralamaya esas toplam puanları dikkate alınarak, ilan edilen kontenjanın dört katı kadar aday, KARDEMİR Seçme ve Değerlendirme Kurulu tarafından mülakata tabi tutulacaktır.",
      },
    ],
  },
  {
    id: "dijital-donusum-ve-bilgi-teknolojileri-direktorlugu-is-ilani",
    title: "Dijital Dönüşüm ve Bilgi Teknolojileri Direktörlüğü - İş İlanı",
    date: "14.06.2024",
    image: foto3,
    content: [
      {
        type: "heading",
        text: "Karabük Demir Çelik Sanayi ve Ticaret A.Ş.",
      },
      {
        type: "paragraph",
        text: "KARDEMİR Dijital Dönüşüm ve Bilgi Teknolojileri Direktörlüğü bünyesinde, aşağıdaki kriterler çerçevesinde on yedi (17) MÜHENDİS personel istihdam edilecektir.",
      },

      {
        type: "heading",
        text: "GENEL ŞARTLAR",
      },
      {
        type: "paragraph",
        text: "• Türkiye Cumhuriyeti vatandaşı,",
      },

      {
        type: "paragraph",
        text: "• Kamu haklarından mahrumiyeti bulunmayan,",
      },
      {
        type: "paragraph",
        text: "• Kasten işlenen bir suçtan dolayı, bir yıl veya daha fazla süreyle hapis cezasına ya da affa uğramış olsa bile devletin güvenliğine karşı suçlar, Anayasal düzene ve bu düzenin işleyişine karşı suçlar, zimmet, irtikâp, rüşvet, hırsızlık, dolandırıcılık, sahtecilik, güveni kötüye kullanma, hileli iflas, ihaleye fesat karıştırma, edimin ifasına fesat karıştırma, suçtan kaynaklanan malvarlığı değerlerini aklama veya kaçakçılık suçlarından mahkûm olmamış,",
      },
      {
        type: "paragraph",
        text: "•  01/06/2024 tarihi itibariyle 35 yaşını doldurmamış olan (01/06/1989 ve sonraki tarihlerde doğanlar),",
      },
      {
        type: "paragraph",
        text: "• Erkek adaylar için askerlikle ilişiği olmayan,",
      },
      {
        type: "paragraph",
        text: "• Sağlık muayenesinde çok tehlikeli iş sınıflarında çalışabilmeye uygunluk ve görevlerini yapmaya engel olabilecek herhangi bir bedensel veya ruhsal rahatsızlığı bulunmayan,",
      },
      {
        type: "paragraph",
        text: "• Üniversitelerin en az dört yıllık eğitim veren fakülteleri ile denkliği yetkili makamlarca kabul edilmiş, yurtdışındaki eğitim kurumlarının aşağıda belirtilen bölümlerinden mezun olan,",
      },

      {
        type: "heading",
        text: "ÖĞRENİM ŞARTI",
      },
      {
        type: "paragraph",
        text: "Üniversitelerin; ",
      },

      {
        type: "paragraph",
        text: "• Bilgisayar  Mühendisliği,",
      },
      {
        type: "paragraph",
        text: "• Yazılım  Mühendisliği,",
      },
      {
        type: "paragraph",
        text: "• Bilgisayar ve Bilişim Sistemleri  Mühendisliği,",
      },
      {
        type: "paragraph",
        text: "• Yapay Zeka Mühendisliği,",
      },
      {
        type: "paragraph",
        text: "• Elektronik   Mühendisliği,",
      },
      {
        type: "paragraph",
        text: "• Elektrik-Elektronik  Mühendisliği,",
      },
      {
        type: "paragraph",
        text: "• Elektronik Haberleşme  Mühendisliği,",
      },
      {
        type: "paragraph",
        text: "• Endüstri Mühendisliği, Bölümlerinden birinden mezun olmak.",
      },
      {
        type: "paragraph",
        text: "*Endüstri Mühendisliği bölümü mezunlarının SAP(FI, HR, MM, PP, CO), ERP, ABAP, kod analizi ve hata ayıklama yetkinliklerine sahip olması gerekmektedir.",
      },

      {
        type: "heading",
        text: "BAŞVURU",
      },
      {
        type: "paragraph",
        text: "Başvurular KARDEMİR’in www.kardemir.com adresindeki internet sitesi üzerinden yapılacaktır. Adayların 14/06/2024 tarihinden itibaren ve en geç 30/06/2024 Pazar günü saat 23.59’a kadar başvuru işlemlerini tamamlamaları gerekmektedir.",
      },
      {
        type: "heading",
        text: "DEĞERLENDİRME",
      },
      {
        type: "paragraph",
        text: "İlan kriterlerini sağlayan adaylar Genel Yetenek ve Yabancı Dil (İngilizce) sınavına tabi tutulacaktır. Sınav ve mülakat tarihleri adaylara ayrıca bildirilecektir. Yukarıda belirtilen pozisyonlar için sıralamaya esas toplam puanları dikkate alınarak, ilan edilen kontenjanın dört katı kadar aday, KARDEMİR Seçme ve Değerlendirme Kurulu tarafından mülakata tabi tutulacaktır.",
      },
    ],
  },
  {
    id: "2024-yili-ikili-mesleki-egitim-on-kayitlari-basladi",
    title: "2024 Yılı İkili Mesleki Eğitim Ön Kayıtları Başladı",
    date: " 11.06.2024",
    image: foto4,
    content: [
      {
        type: "paragraph",
        text: "Şirketimizin sürdürmekte olduğu İkili Mesleki Eğitim Programı için 2024 yılı ön kayıt başvuruları başlıyor.",
      },

      {
        type: "paragraph",
        text: "Aşağıdaki bağlantıdan ön kayıt formuna erişim sağlayabilirsiniz. Ön kayıtlara başvuru 14-30/06/2024 tarihleri arasında açık olacaktır.",
      },

      {
        type: "paragraph",
        text: "Başvuruda bulunmak için tıklayınız.",
      },
    ],
  },
  {
    id: "olagan-genel-kurul-toplantisi",
    title: "Olağan Genel Kurul Toplantısı ",
    date: "13.05.2024",
    image: foto5,
    content: [
      {
        type: "paragraph",
        text: "Şirketimizin 2023 yılı faaliyetlerinin ele alınacağı Olağan Genel Kurul Toplantısı 30.05.2024 tarihinde saat 10:00'da Kardemir Eğitim ve Kültür Merkezi'nde gerçekleştirilecektir.",
      },

      {
        type: "paragraph",
        text: "• irketimize ait Mali Tablolar ve 12 aylık Faaliyet Raporuna buradan ulaşabilirsiniz. ",
      },
    ],
  },
  {
    id: "2024-yili-kisa-donem-yaz-staj-kayitlari-hakkinda-bilgilendirme",
    title: "2024 Yılı Kısa Dönem Yaz Staj Kayıtları Hakkında Bilgilendirme",
    date: "12.05.2024",
    image: foto6,
    content: [
      {
        type: "paragraph",
        text: "• 2024 Kısa Dönem Yaz Stajı Kabul Listesine buradan ulaşabilirsiniz.",
      },
      {
        type: "paragraph",
        text: "Şirketimizde üretim, yönetim organizasyon ve atölye stajı yapacak stajyer adaylarımızın staj kabul evrakları aşağıda yer almaktadır.",
      },
      {
        type: "paragraph",
        text: "Kesin Kayıtlar;",
      },
      {
        type: "paragraph",
        text: "Kesin kayıtların yapılabilmesi için listedeki stajyer adaylarının aşağıda yer alan belgeleri 20 Mayıs 2024 Pazartesi - 4 Haziran 2024 Salı gününe kadar, hafta içi 08:30–11:00 / 13:30-16:30 saatleri arasında KARDEMİR A.Ş. Eğitim Müdürlüğü'ne elden teslim edilmesi gerekir.",
      },
      {
        type: "paragraph",
        text: "Kayıt için gerekli evraklar;",
      },

      {
        type: "paragraph",
        text: "• 1 Adet Nüfus Cüzdanı Fotokopisi",
      },
      {
        type: "paragraph",
        text: "• 1 Adet Vesikalık Fotoğraf",
      },
      {
        type: "paragraph",
        text: "• Öğrenci Belgesi",
      },
      {
        type: "paragraph",
        text: "• Transkript (Not Ortalaması)",
      },
      {
        type: "paragraph",
        text: "• 1 Adet Adli Sicil Kaydı Belgesi (Geri verilecektir.)",
      },
      {
        type: "paragraph",
        text: "• Kan Grubu Gösterir Belge",
      },
      {
        type: "paragraph",
        text: "• Dil Belgesi (Sertifikalar) var ise",
      },
      {
        type: "paragraph",
        text: "• Teknik Yetkinlik Belgeleri (Sertifikalar) var ise",
      },
      {
        type: "paragraph",
        text: "• Okul tarafından verilen Staj Zorunluluk/ İş Kazası ve Meslek Hastalığı Sigortası yaptırılacağına dair resmi yazı",
      },
      {
        type: "paragraph",
        text: "• Okul tarafından verilen Staj Başvuru Formu (mümkünse okul tarafından imzalı kaşeli)",
      },
      {
        type: "paragraph",
        text: "• Üniversite Staj İşleri (Devlet katkı payı ödeme) Sorumlusu ve İletişim (telefon, e-posta) Bilgileri",
      },
      {
        type: "paragraph",
        text: "• Banka hesap numarası (IBAN NO) gösterir hesap cüzdan fotokopisi (TEB Karabük Şubesi)",
      },
      {
        type: "paragraph",
        text: "• Sağlık Kurulu (Heyet) Raporu yada Devlet Hastanesinden alınan durum bildirir raporu",
      },
      {
        type: "paragraph",
        text: "Bu raporu almak için gerekli tetkikler de evrak teslim sürecinde getirilmelidir.",
      },
      {
        type: "paragraph",
        text: "Gerekli tetkikler; Kan Testi (Aç Karnına), Akciğer Filmi, EKG, Odyometri Testi, Göz Muayenesi",
      },

      {
        type: "paragraph",
        text: "Staj Dönemleri;",
      },
      {
        type: "paragraph",
        text: "Stajın başladığı gün saat 08:00’de KARDEMİR A.Ş. ana kapıda olunması gerekir.",
      },

      {
        type: "paragraph",
        text: "Kendi periyodunda staja başlarken stajyerin yanında getirmesi gereken belgeler aşağıda yer almaktadır;",
      },
      {
        type: "paragraph",
        text: "• Sigorta yapıldığını gösterir Sigorta Bildirim Belgesi",
      },
      {
        type: "paragraph",
        text: "•  Staj Sicil Fişi",
      },
      {
        type: "paragraph",
        text: "• Staj Defteri",
      },
    ],
  },
  {
    id: "basin-ve-kamuoyuna-bilgilendirme",
    title: "Basın ve Kamuoyuna Bilgilendirme",
    date: "05.04.2024",
    image: foto7,
    content: [
      {
        type: "paragraph",
        text: "Şirketimizin yatırım planı kapsamında, yurtiçi ve yurtdışında faaliyet göstermek ve yük taşımacılığı esaslı olmak üzere, her türlü demiryolu, karayolu, denizyolu ve havayolu taşımacılığı ile bu amaçlara matuf olmak veya bu hususlarla ilgili olmak kaydıyla ilave faaliyetlerde bulunmak maksadıyla şirketimizin tek ortağı olduğu, 200.000.000 TL sermayeli, KARLİMTAŞ KARDEMİR Liman İşletmeciliği ve Taşımacılık Anonim Şirketi unvanında yeni bir şirket kurulmuştur.",
      },

      {
        type: "paragraph",
        text: "Kamuoyuna saygıyla duyurulur.",
      },
    ],
  },
  {
    id: "is-ilani",
    title: "İş İlanı",
    date: "29.03.2024",
    image: foto8,
    content: [
      {
        type: "paragraph",
        text: "KARDEMİR Tedarik Zinciri Direktörlüğü bünyesinde, aşağıdaki kriterler çerçevesinde yedi (7) MÜHENDİS personel istihdam edilecektir.",
      },

      {
        type: "heading",
        text: "GENEL ŞARTLAR",
      },
      {
        type: "paragraph",
        text: "• Türkiye Cumhuriyeti vatandaşı,",
      },

      {
        type: "paragraph",
        text: "• Kamu haklarından mahrumiyeti bulunmayan,",
      },
      {
        type: "paragraph",
        text: "• Kasten işlenen bir suçtan dolayı, bir yıl veya daha fazla süreyle hapis cezasına ya da affa uğramış olsa bile devletin güvenliğine karşı suçlar, Anayasal düzene ve bu düzenin işleyişine karşı suçlar, zimmet, irtikâp, rüşvet, hırsızlık, dolandırıcılık, sahtecilik, güveni kötüye kullanma, hileli iflas, ihaleye fesat karıştırma, edimin ifasına fesat karıştırma, suçtan kaynaklanan malvarlığı değerlerini aklama veya kaçakçılık suçlarından mahkûm olmamış,",
      },
      {
        type: "paragraph",
        text: "•  01/06/2024 tarihi itibariyle 35 yaşını doldurmamış olan (01/06/1989 ve sonraki tarihlerde doğanlar),",
      },
      {
        type: "paragraph",
        text: "• Erkek adaylar için askerlikle ilişiği olmayan,",
      },
      {
        type: "paragraph",
        text: "• Sağlık muayenesinde çok tehlikeli iş sınıflarında çalışabilmeye uygunluk ve görevlerini yapmaya engel olabilecek herhangi bir bedensel veya ruhsal rahatsızlığı bulunmayan,",
      },
      {
        type: "paragraph",
        text: "• Üniversitelerin en az dört yıllık eğitim veren fakülteleri ile denkliği yetkili makamlarca kabul edilmiş, yurtdışındaki eğitim kurumlarının aşağıda belirtilen bölümlerinden mezun olan,",
      },

      {
        type: "heading",
        text: "ÖĞRENİM ŞARTI",
      },
      {
        type: "paragraph",
        text: "Üniversitelerin; ",
      },

      {
        type: "paragraph",
        text: "• Metalurji ve Malzeme Mühendisliği,",
      },
      {
        type: "paragraph",
        text: "• Makine Mühendisliği,",
      },
      {
        type: "paragraph",
        text: "• Elektrik - Elektronik Mühendisliği,",
      },
      {
        type: "paragraph",
        text: "• Elektronik  Mühendisliği,",
      },
      {
        type: "paragraph",
        text: "• Endüstri Mühendisliği,",
      },
      {
        type: "paragraph",
        text: "• Kimya Mühendisliği, branşlarından mezun olmak.",
      },

      {
        type: "heading",
        text: "BAŞVURU",
      },
      {
        type: "paragraph",
        text: "Başvurular KARDEMİR’in www.kardemir.com adresindeki internet sitesi üzerinden yapılacaktır. Adayların 14/06/2024 tarihinden itibaren ve en geç 30/06/2024 Pazar günü saat 23.59’a kadar başvuru işlemlerini tamamlamaları gerekmektedir.",
      },
      {
        type: "heading",
        text: "DEĞERLENDİRME",
      },
      {
        type: "paragraph",
        text: "İlan kriterlerini sağlayan adaylar Genel Yetenek ve Yabancı Dil (İngilizce) sınavına tabi tutulacaktır. Sınav ve mülakat tarihleri adaylara ayrıca bildirilecektir. Yukarıda belirtilen pozisyonlar için sıralamaya esas toplam puanları dikkate alınarak, ilan edilen kontenjanın dört katı kadar aday, KARDEMİR Seçme ve Değerlendirme Kurulu tarafından mülakata tabi tutulacaktır.",
      },
    ],
  },
  {
    id: "yaz-donemi-staj-basvurulari-basladi",
    title: "Yaz Dönemi Staj Başvuruları Başladı",
    date: "11.03.2024",
    image: foto9,
    content: [
      {
        type: "paragraph",
        text: "Şirketimizde staj yapmak isteyen üniversite ve yüksekokullarda öğrenim gören öğrenciler, üretim ve yönetim stajı kapsamında planladığımız yaz dönemi staj ön kayıtlarını, 5 Nisan 2024 tarihine kadar yapabilirler.",
      },

      {
        type: "paragraph",
        text: "Staj başvuru kitapçığında yer alan bilgilerin dikkatle okunup formun bu bilgiler ışığında doldurulması önem arz etmektedir.",
      },

      {
        type: "paragraph",
        text: "Başvuruda bulunmak için tıklayınız.",
      },
    ],
  },
  {
    id: "yonetim-kurulu-baskanimiz-sayin-prof-dr-ismail-demirin-basin-aciklamasi",
    title:
      "Yönetim Kurulu Başkanımız Sayın Prof. Dr. İsmail Demir’in Basın Açıklaması",
    date: "15.02.2024",
    image: foto10,
    content: [
      {
        type: "paragraph",
        text: "Şirketimiz Yönetim Kurulu Başkanı Prof. Dr. İsmail Demir, şirketimizin çizdiği stratejik plan, yatırım hedefi, üretim tonajı, Yeşil Mutabakat ve Sınırda Karbon Düzenlemesi ve yeşil çelik yatırımlarında atılabilecek yerli ve milli teknoloji adımları hakkında basın mensuplarıyla bir araya gelerek açıklamalar yaptı..",
      },

      {
        type: "paragraph",
        text: "“Kardemir demir – çelik sektöründe yerli ve milli üretim stratejileriyle; sektörel bağımsızlığı sağlayacak ve küresel bir güç olmak vizyonuyla yoluna devam edecektir. Bu kapsamda yenilikçi teknoloji ve sürekli iyileştirme anlayışının vurgulandığı, çevresel – sosyal sürdürülebilirliğin ve paydaş değerlerinin ön planda tutulduğu mevcut üretiminin ötesinde değer katan ve fark oluşturan operasyonları ile değişime liderlik etmeyi hedeflemektedir.",
      },

      {
        type: "paragraph",
        text: "Hazırlanan 5 yıllık Stratejik Plandan ve Türkiye Yüzyılının ilkelerinden hareketle; Uzun vadeli ve sürdürülebilir iş modelleri ile şirketimizin rekabet avantajı elde edebileceği alanların oluşturulmasına ağırlık verilerek, stratejik yatırım, ürün alaşım ve teknolojilerine yönelik yatırım tercihleri ile fark oluşturmak önceliğimizdir. Stratejik planlarımız doğrultusunda; önümüzdeki 5 yıl içerisinde 1,5 milyar doları bulacak yeni yatırım hedefimizi paylaşmak istiyorum.",
      },
      {
        type: "paragraph",
        text: "Ham çelik üretim tonajımızı kısa vadede yeni yatırım planlarımızla beraber 2,5 milyon ton seviyelerinden 3,5 milyon tona çıkartmayı hedeflemekteyiz. Katma değerli çelik üretimini önümüzdeki 5 yıl içerisinde iki katına çıkaracak planlamalar içindeyiz. Süreç boyunca uygulanacak kontrol mekanizmaları ile yarı mamul ve nihai mamul kaliteli teknolojiler ve nitelikleri sürekli iyileştirilecek bunu destekleyen operasyonel verimlilik artırılacaktır.",
      },
      {
        type: "paragraph",
        text: "Yatırımlarımızın tamamında önceliğimiz; işimize uygun, doğaya mümkün olan en saygılı teknik ve teknolojiyi seçmektir. Yenilikçilik ve değişimin öncüsü olma anlayışıyla yeni teknolojiler doğrultusunda ürün ve prosesleri geliştirmeyi planlıyoruz. Bu kapsamda halihazırda görüşmeleri yürütülen yeni teknoloji ile uyumlu çevreci uygulamaları bulan ve uygulayan öncü bir kuruluş olarak yeni bir yüksek fırın yatırımı hayata geçirilecektir.",
      },
      {
        type: "paragraph",
        text: "Bilindiği gibi kapasite açısından lojistik kısıtlamalarımızın aşılması gerekmektedir. Bu bağlamda lojistik master planı hazırlanacak, alternatif taşıma kanalları ortaya konacak. Lojistik master planı dahilinde görüşülen liman işletmeciliği ve demiryolu işletmeciliği projeleri hayata geçirilecek.",
      },
      {
        type: "paragraph",
        text: "Yük taşımacılığı hizmetlerimizin verimliliğini artırmaya ve daha yeşil ulaşım seçeneklerine geçişi desteklemeye yönelik başladığımız bu serüvende büyüklüğümüzü sürdürecek atılımların bir parçası olarak gördüğümüz Özel Demiryolu Taşımacılık İşletmesi olma yolunda alınan kararlar ile başlanılan projede onay sürecinin iki yıl içinde tamamlanması planlıyoruz. Bu konuları stratejik iş birliği yapılabilecek paydaşlarla yapmakta planlarımızı bir parçası olabilir. Bu formülle liman ve demiryolu projeleri ile kritik stok seviyeleri etkin olarak takip edilecek, fırsat alımları değerlendirilecek ve stok maliyetleri kontrol altında tutulacaktır.",
      },
      {
        type: "paragraph",
        text: "Cumhurbaşkanımızın Kardemir'e vermiş olduğu değer, ülkemizin sanayi ve ekonomik kalkınması açısından kritik bir role sahiptir. Bu destek, Kardemir'in sadece bir sanayi kuruluşu olmanın ötesinde, ulusal ve uluslararası alanda rekabet edebilirlik gücünü artırarak Türkiye'nin sanayi potansiyelini daha da geliştirmesine katkı sağladığı gibi bu desteği, yerli üretimin teşvik edilmesi ve istihdamın artırılması gibi stratejik hedeflerimizi ve ülkemizin sanayideki önemli konumunu korumasına yardımcı olmaktadır.",
      },
      {
        type: "paragraph",
        text: "Günümüz şartlarında şirketlerin mali yapılarını güçlendirmesi sürdürülebilirlik açısından en önemli konulardan bir tanesidir. Şirketimizin sürdürülebilir rekabet gücünü artıracak etkin finansal sistem yapısı geliştirilecek, maliyet azaltıcı ve karlılık artırıcı faaliyetler ile “Mali Yapıyı Güçlendirmek” amaçlanmaktadır. Katma değer ve karlılık konuları önceliğimiz olacaktır. Bu sayede karlılığımız yeni ve yenilikçi yatırımları mümkün kılacağı gibi temettü dağıtımı ile hissedarlarımıza değere ortak etmeyi sürdürmek isteriz. Hisselerinin tamamı Borsa İstanbul’da işlem gören Şirketimiz yaptığı yatırımlar ve karşılığındaki kazanç potansiyelini ortaya koyarak yatırımcı çekmeye devam edecektir.",
      },
      {
        type: "paragraph",
        text: "Demir-Çelik şirketlerinin önünde artık AB Yeşil Mutabakat ve Sınırda Karbon Düzenlemesi vardır. Şirketimizde bu düzenlemeye uygun bir şekilde 2053 hedefleri doğrultusunda yola devam edecektir.",
      },
      {
        type: "paragraph",
        text: "Şirketimiz; “Sağlıklı Çevre ve Verimli Üretim” ilkesinden hareketle, tüm üretim ve yatırım faaliyetlerinde, çevreye duyarlı olmayı ve sürekli geliştirmeyi temel prensipler olarak kabul etmektedir. Şirketimiz bu noktada 2006 yılından 2023 yılı sonuna kadar yaklaşık 160 milyon Dolar harcama yapılmış olup, 2024 yılı için çevre yatırımlarımıza yaklaşık 40 milyon Dolar bütçe ayrılmıştır. Önümüzdeki yıllarda da çevreci yatırımlarımız hızla artarak devam edecek insana ve çevreye saygılı olmayı şiar edinmeye devam edeceğiz.",
      },
      {
        type: "paragraph",
        text: "Bu kapsamda Haziran 2023 döneminde Pota Fırını 2 ve Pota Fırını 3 Toz Toplama, Mayıs 2023 döneminde Kok Bataryaları Deşarj Emisyonu Düşürülmesi, Kasım 2023 döneminde Kok Bataryaları Şarj Emisyonu Düşürülmesi, Aralık 2023 döneminde Mevcut Sahanın Rehabilitasyonu-Etap 1 gibi büyük çevre yatırımlarımız tamamlanmıştır.",
      },
      {
        type: "paragraph",
        text: "Üzerimizdeki misyonlardan bir tanesi de efektif bir Demir - Çelik ekosistemini kurmaktır. Bu kapsamda rekabet öncesi iş birliği geliştirilerek ürünlerin sektörler arası kullanımı artırılacak ve demir çelik geleceğini inşa edecek ARGE projeleri sektör firmaları ile geliştirmeyi planlamaktayız. Demiryolu sektöründe ray ve tekerde tek üretici konumunda olan şirketimiz, geliştirmekte olduğu ürünlerde de yol haritasını sektör temsilcileriyle birlikte oluşturacaktır. Demir çelik sanayiini geliştirecek ve sürdürülebilirliği sağlayacak yeşil çelik stratejilerini ortaya koyacak Yeşil Çelik Strateji Yol Haritası son haline getirilecektir. Burada önemle altını çizdiğimiz konu ise yeşil çelik konusundaki teknolojiyi yerli ve milli firmalarla ortak payda da buluşarak geliştirmeyi planlıyoruz.",
      },
      {
        type: "paragraph",
        text: "Karbon Nötr yolunda karbon emisyonlarımızı 2030 yılına kadar %15 azaltmayı ve 2053 yılına kadar Karbon nötr bir tesis olmayı hedeflemekteyiz. Yine enerji tüketimlerinin verimli yönetilmesi için enerji verimliliği projeleri geliştirilecek, alternatif yenilenebilir enerji kaynakları yatırımları yapılacaktır. Böylece ton ham çelik başına düşen enerji tüketim miktarı düşürülecektir. Değişen çevre koşulları ve iklim krizine bağlı olarak yüzey suyu ve yeraltı sularının azalarak tehlike arz ediyor olmasından dolayı toplam tüketilen su miktarı %15 oranında düşürülecektir.",
      },
      {
        type: "paragraph",
        text: "Yenilenebilir enerji kaynakları yatırım projemiz ile faaliyetlerimiz için ihtiyaç duyduğumuz elektriğin önemli bir kısmının fosil yakıtlar olmadan, iklim dostu, rüzgâr ve güneş potansiyelinden yararlanılarak sürdürülebilir bir şekilde üretilmesine olanak sağlayacağız. 2026 yılı sonuna kadar 250 MW yenilenebilir enerji üretimine ulaşılmasını hedeflemekteyiz. Böylelikle ülkemizi de dünyanın en büyük yenilenebilir enerji piyasasındaki öncül devletler arasında olmasına önemli bir katkı sağlamış olacağız.",
      },
      {
        type: "paragraph",
        text: "Kurumsal yapıyı güçlendirmek ise sürdürülebilir bir Kardemir için önemli bir adımdır. Kardemir, mevcut iş, finans ve operasyon modelleri, teknoloji yol haritası yeniden tanımlanarak, hangi alanlarda nasıl kazanımlar elde edilmesi gerektiği ve dönüşümün hangi boyutta desteklenmesi gerektiği vurgulanacaktır.",
      },
      {
        type: "paragraph",
        text: "Dijital dönüşümü sağlamak için kurumsal kaynak planlama çalışmaları üst seviyeye çıkarılacak ve Seviye 3 sistemlerine geçiş hızlandırılacak. Entegre ölçme ve değerlendirme sistemi kurularak başta stratejik plan ve performans programı olmak üzere entegre raporlamalar yapılacaktır.",
      },
      {
        type: "paragraph",
        text: "Kurumsal risk ve denetim mekanizmalarının etkinliği artırılacaktır. Kurumsal Risk Yönetimi süreçlerinin etkililiğini, etkinliğini ve sürekliliğini geliştirmeye katkıda bulunmaya yönelik olarak Kardemir A.Ş. Kurumsal Risk Yönetimi Projesi başlatılmıştır.",
      },
      {
        type: "paragraph",
        text: "Temel değerlerimizden “İnsan İçin İnsanla” ilkesinden yola çıkarak önce çalışanların güvenliğini sağlamak amacıyla sıfır iş kazası hedefi ortaya konmaktadır. Stratejik insan kaynakları uygulamalarını etkinleştirmek ve organizasyon yapısını geliştirmek için işgücü planlaması yapılacaktır. Yaklaşık 5 bin çalışanın üstünde şirketimize başladığımız günden itibaren Kardemir’in ihtiyaçları doğrultusunda istihdam artırma hedefine yönelik 527 yeni personel bünyemize kattık. Bu ay içerisinde sayıyı 600’ün üzerine çıkaracağız. Yine bu doğrultuda önümüzdeki yıllarda da Kardemir ülkeye ve bölgeye istidam sağlayan bir kuruluş olmaya devam edecektir. 2024 yılı içinde toplam 200 personelin daha bünyemize katılması planlanmaktadır.",
      },
      {
        type: "paragraph",
        text: "Kardemir, bağlı ortaklık yapısı yeniden değerlendirilerek iş modeli tasarlanacak, şirketlerimizin mühendislik kabiliyetlerini ön plana çıkaran ve pazarlayan kuruluşlar haline getireceğiz. Kardökmak’ı Demir -Çelik sektörünün Ar-Ge mühendislik, tasarım ve Ür-Ge firması haline getirmeyi, Enbatı’yı yeni ve yenilenebilir enerji teknolojileri alanında uzmanlaştırmayı, Karçel’i ise yapısal çelikte imalat montaj ve tesis kurulumunda bir çelik servis merkezi kurulumuyla beraber daha da etkin ve yetkin hale getirmeyi hedefliyoruz.",
      },
      {
        type: "paragraph",
        text: "Kardemir, demir ve çelik üretimindeki uzmanlığıyla başta savunma sanayi olmak üzere nitelikli alaşım ihtiyacı olan kritik sanayi alanlarının ihtiyaç duyduğu yüksek kaliteli malzemelerin tedarikinde önemli bir role sahiptir. Kardemir'in büyük ölçekli üretim kapasitesi ve esnek üretim yetenekleri, bağlı ortaklıklar ile sağladığı esneklik ile kritik ve stratejik sanayimizin taleplerini karşılamak için ideal bir altyapı sunmaktadır. Kardemir'in bu katkıları; yerli ve milli üretimdeki güçlü adımlarımızı destekleyerek ülke güvenliğimizi daha da sağlamlaştırabilir. Savunma sanayiinde kazandığımız uzun yıllara dayanan deneyimleri, Kardemir'in operasyonlarına entegre ederek hem ulusal hem de uluslararası alanda rekabet gücünü artırmayı hedefliyoruz.”",
      },
      {
        type: "paragraph",
        text: "Kamuoyuna saygıyla duyurulur.",
      },
      {
        type: "paragraph",
        text: "KARDEMİR A.Ş.",
      },
    ],
  },
  {
    id: "olaganustu-genel-kurulda-belirlenen-yonetim-kurulu-kadromuz",
    title: "Olağanüstü Genel Kurul'da Belirlenen Yönetim Kurulu Kadromuz",
    date: "24.10.2023",
    image: foto11,
    content: [
      {
        type: "paragraph",
        text: "23/10/2023 Pazartesi, şirketimiz Eğitim ve Kültür Merkezinde, Olağanüstü Genel Kurulumuz gerçekleştirilmiştir. Hissedarlarımızın fiziki ve çevrimiçi katılımları ile düzenlenen olağanüstü genel kurulumuzda, yeni dönem Kardemir A.Ş. Yönetim Kurulumuz aşağıdaki gibi belirlenmiştir.",
      },

      {
        type: "paragraph",
        text: "• Yönetim Kurulu Başkanı Prof. Dr. İsmail Demir",
      },

      {
        type: "paragraph",
        text: "• Yönetim Kurulu Başkan Vekili Ömer Demirhan",
      },
      {
        type: "paragraph",
        text: "• Prof. Dr. Ercüment Arvas",
      },
      {
        type: "paragraph",
        text: "• Fatih Mehmet Erkoç",
      },
      {
        type: "paragraph",
        text: "• Kamil Güleç",
      },
      {
        type: "paragraph",
        text: "• Muhammed Ali Oflaz",
      },
      {
        type: "paragraph",
        text: "• Mustafa Aydın",
      },
      {
        type: "paragraph",
        text: "• Yusuf Canbolat",
      },
      {
        type: "paragraph",
        text: "• Prof. Dr. Arif Nihat Güllüoğlu",
      },
      {
        type: "paragraph",
        text: "• Hacı Abdullah Kaya",
      },
      {
        type: "paragraph",
        text: "• Prof. Dr. Mehmet Cahit Ensari",
      },
      {
        type: "paragraph",
        text: "Ayrıca, Yönetim Kurulu'muzun 23.10.2023 tarihinde yapılan toplantısında;",
      },
      {
        type: "paragraph",
        text: "Denetimden Sorumlu Komite Başkanlığı'na Sn. H. Abdullah KAYA, Üyeliklere Sn. Prof. Dr. Arif Nihat GÜLLÜOĞLU ve Sn. Prof. Dr. Mehmet Cahit ENSARİ'nin",
      },
      {
        type: "paragraph",
        text: "Riskin Erken Saptanması Komitesi Başkanlığı'na Sn. H. Abdullah KAYA, Üyeliklere Sn. Mustafa AYDIN ve Sn. Muhammed Ali OFLAZ'ın",
      },
      {
        type: "paragraph",
        text: "Kurumsal Yönetim Komitesi Başkanlığı'na Sn. Prof. Dr. Ercümend ARVAS, Üyeliklere Sn. Ömer DEMİRHAN , Sn. Fatih Mehmet ERKOÇ , Sn. Yusuf CANBOLAT ve Yatırımcı İlişkileri Yöneticisi Sn. Burcay YAVUZ'un",
      },
      {
        type: "paragraph",
        text: "Aday Belirleme Komitesi Başkanlığı'na Sn. Prof. Dr. Mehmet Cahit ENSARİ, Üyeliklere Sn. Prof. Dr. İsmail DEMİR ve Sn. Kamil GÜLEÇ'in atanmalarına karar verilmiştir.",
      },

      {
        type: "paragraph",
        text: "Saygılarımızla,",
      },
      {
        type: "paragraph",
        text: "KARDEMİR A.Ş.",
      },
    ],
  },
];

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

const HaberIcerik = () => {
  const { id } = useParams();
  const newsItem = newsData.find((news) => news.id === id);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!newsItem) {
    return <Typography variant="h5">Haber Bulunamadı</Typography>;
  }

  return (
    <Container sx={{ mt: 18 }}>
      <Box>
        {/* <Header variant="h4" color="#1f2e5e">
          {newsItem.title}
        </Header> */}

        {/* <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
          {newsItem.title}
        </Typography> */}

        <img
          src={newsItem.image}
          alt={newsItem.title}
          style={{
            width: "100%",
            height: "20rem",
            objectFit: "cover",
            marginBottom: "20px",
          }}
        />
        <Header variant="h4" color="#1f2e5e">
          {newsItem.title}
        </Header>
        <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
          {newsItem.date}
        </Typography>
        {newsItem.content.map((section, index) => (
          <Box key={index} sx={{ mb: 3 }}>
            {section.type === "heading" && (
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
                {section.text}
              </Typography>
            )}
            {section.type === "paragraph" && (
              <Typography variant="body1">{section.text}</Typography>
            )}
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export default HaberIcerik;
