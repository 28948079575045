import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Divider,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import image from "../../images/image2.jpg";

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
  overflowX: "auto",
}));
const SectionTitle = styled(Typography)(({ theme }) => ({
  color: "#1f2e5e",
  fontWeight: "bold",
  marginBottom: theme.spacing(2),
}));

const Table = styled("table")(({ theme }) => ({
  width: "100%",
  borderCollapse: "collapse",
  "& th, & td": {
    padding: theme.spacing(2),
    textAlign: "left",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  "& th": {
    backgroundColor: "#1f2e5e",
    color: "#ffffff",
  },
}));

function CerezPolitikasi() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box
        component="img"
        alt=""
        src={image}
        style={{
          width: "100%",
          height: "30rem",
          cursor: "pointer",
        }}
      />
      <Box sx={{ flexGrow: 1, padding: 1, marginTop: "1rem" }}>
        <Header variant="h4">Yasal Uyarılar ve Kullanım Şartları</Header>

        <StyledPaper sx={{ padding: "1rem", margin: "0.5rem" }}>
          <SectionTitle variant="h6" component="h2" gutterBottom>
            Çerez Nedir?
          </SectionTitle>
          <Typography paragraph>
            Çerezler bir internet sitesi tarafından cihazınızda oluşturulan ve
            isim-değer formatında veri barındıran küçük metin dosyalarıdır.
            Çerezler, ziyaret ettiğiniz internet sitesinin cihazınızda bilgi
            saklamasını ve bu bilgileri sonraki ziyaretleriniz sırasında
            kullanmasını mümkün kılmaktadır. Bir internet sitesi tarafından
            oluşturulan çerezler siteye erişim için kullandığınız internet
            tarayıcısı tarafından saklanmakta olup, bu çerezlerin içerdiği
            bilgilere sadece çerezi oluşturan alan adı (örn. www.kardemir.com)
            altında sunulan internet siteleri tarafından ve aynı tarayıcıyı
            kullandığınız takdirde uzaktan erişim mümkündür.
          </Typography>
          <Typography paragraph>
            Çerezler, günümüzde, internet teknolojilerinin önemli bir parçası
            haline gelmiştir ve temel işlevleri ziyaretçinin ve tercihlerinin
            hatırlanması ve cihazın tanınmasıdır; neredeyse her internet
            sitesinde çerez kullanımı söz konusudur.
          </Typography>
          <SectionTitle variant="h6" component="h2" gutterBottom>
            Çerez Çeşitleri Nelerdir?
          </SectionTitle>
          <Table sx={{ marginBottom: 2 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Çerez Türü</strong>
                </TableCell>
                <TableCell>
                  <strong>Açıklama</strong>
                </TableCell>
                <TableCell>
                  <strong>Çerez Süresi</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Oturum Çerezleri</TableCell>
                <TableCell>
                  İnternet sitesini kullanımınız sırasında geçerli olan çerezler
                  olup internet tarayıcısı kapatılıncaya kadar geçerliliklerini
                  korurlar.
                </TableCell>
                <TableCell>Oturum (tarayıcınızı kapatana kadar)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Kalıcı Çerezler</TableCell>
                <TableCell>
                  Tarayıcınızda saklanan ve tarafınızca silininceye dek veya son
                  kullanım tarihine kadar geçerliliğini koruyan çerezlerdir.
                </TableCell>
                <TableCell>Belirtilmiş Son Kullanım Tarihi</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Birinci Taraf Çerezleri</TableCell>
                <TableCell>
                  Şirket internet sitesi tarafından oluşturulan ve sadece bu
                  internet sitesi tarafından okunabilen çerezlerdir. Bu çerezler
                  oturum çerezleri veya kalıcı çerezler olabilirler.
                </TableCell>
                <TableCell>Çerez Türüne Göre</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Üçüncü Taraf Çerezleri</TableCell>
                <TableCell>
                  Şirket internet sitesinde sunulan bir içeriğin Şirket’e ait
                  internet adresi dışında başka alan adları üzerinden de
                  sağlanması halinde oluşturulan çerezlerdir.
                </TableCell>
                <TableCell>Genellikle Kalıcı Çerezler</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <SectionTitle variant="h6" component="h2" gutterBottom>
            Internet Sitemizde Kullanılan Çerezlere İlişkin Bilgiler
          </SectionTitle>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Çerez İsmi</strong>
                </TableCell>
                <TableCell>
                  <strong>Çerez Tanımı</strong>
                </TableCell>
                <TableCell>
                  <strong>Çerez Tipi</strong>
                </TableCell>
                <TableCell>
                  <strong>Çerez Süresi</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>_ga</TableCell>
                <TableCell>
                  Kullanıcıların site üzerinde geçirdiği vakit, nereden geldiği,
                  ziyaret sayısı ve zamanını tanımak için oluşturulan Google
                  Analytics çerezidir.
                </TableCell>
                <TableCell>Performans Çerezi</TableCell>
                <TableCell>2 yıl</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>_gat</TableCell>
                <TableCell>
                  Herhangi bir bilgi tutmaz, bazı sitelerden gelen yoğun
                  tıklanma taleplerini filtrelemek için oluşturulan Google
                  Analytics çerezidir.
                </TableCell>
                <TableCell>Performans Çerezi</TableCell>
                <TableCell>1 dakika</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>_gid</TableCell>
                <TableCell>
                  Kullanıcıları birbirinden ayırmak için oluşturulan Google
                  Analytics çerezidir.
                </TableCell>
                <TableCell>Performans Çerezi</TableCell>
                <TableCell>24 saat</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>ASP.NET_SessionId</TableCell>
                <TableCell>
                  Web sitesinde gezinti sırasında sınırlı bir oturum süresi
                  içerisinde aynı tarayıcıdan gelen talepleri tanımlamak için
                  gereklidir.
                </TableCell>
                <TableCell>Kullanılması Zorunlu Olan Çerezler</TableCell>
                <TableCell>Oturum (tarayıcınızı kapatana kadar)</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Divider sx={{ my: 2 }} />
          <SectionTitle variant="h6" component="h2" gutterBottom>
            Çerezlerin Yönetimi
          </SectionTitle>
          <Typography paragraph>
            Tarayıcılar genellikle çerezleri otomatik olarak kabul etmektedir.
            İnternet sitemizi kullanabilmek için çerez kullanımı zorunlu
            değildir, fakat tarayıcınızı çerezleri kabul etmemeye ayarlamanız
            halinde kullanıcı deneyiminizin kalitesi düşebilir ve sitelerimizin
            çeşitli işlevleri bozulabilir.
          </Typography>
          <Typography paragraph>
            Tarayıcınızı; çerezleri tüm siteler veya belirli siteler için
            engelleyecek şekilde, çerez oluşturulduğunda uyarı verecek şekilde,
            üçüncü taraf çerezleri engelleyecek şekilde veya tüm çerezleri
            oturum çerezi gibi sayacak şekilde yapılandırabilirsiniz. Ek olarak,
            tarayıcınız üzerinden çerezleri silebilir veya tarayıcınızda
            saklanan çerezlerin listesini ve değerlerini görebilirsiniz.
            Tarayıcınızın çerez yönetimi işlevleri hakkında detaylı bilgi almak
            için tarayıcınızın yardımla ilgili menülerini ziyaret edebilirsiniz.
          </Typography>
        </StyledPaper>
      </Box>
    </>
  );
}

export default CerezPolitikasi;
