import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";

const faqs = [
  {
    question: "İş Başvurusu Öncesi Mevcut İşimden İstifa Etmeli Miyim?",
    answer:
      "İşe alımlarımız uygun kriterleri taşıyan adaylarımız arasından noter huzurunda yapılacak çekiliş ile olacağından, Şirketimize iş başvurusu yapacakların çekiliş öncesinde mevcut işlerinden istifa etmelerine gerek yoktur. Noter huzurunda yapılacak çekilişin ardından, Şirketimizde iş başı yapmaya hak kazananların mevcut işlerinden istifa etmeleri yeterli olacaktır.",
  },
  {
    question:
      "Kardemir hisseleri hangi borsalarda işlem görmektedir ve hisse sembolleri nelerdir?",
    answer:
      "Kardemir hisseleri Borsa İstanbul'da işlem görmektedir ve hisse sembolleri KRDMA, KRDMB, KRDMD'dir.",
  },
  {
    question: "Kardemir hisselerinin halka açıklık oranı nedir?",
    answer: "%100",
  },
  {
    question: "Kardemir'in ortakları kimlerdir?",
    answer: "Ortaklık yapısı için tıklayınız.",
  },
  {
    question:
      "Kardemir Genel Kurul Toplantıları hangi tarihlerde yapılmaktadır?",
    answer:
      "Olağan Genel Kurul Toplantıları TTK'nın ilgili maddesi çerçevesinde her faaliyet dönemi sonundan itibaren üç ay içerisinde yapılmaktadır. Olağanüstü durumlarda ise herhangi bir tarihte gerekli prosedürler izlenerek Olağanüstü Genel Kurul Toplantısı yapılmaktadır.",
  },
  {
    question:
      "Elimizde fiziki olarak bulunan Kardemir A.Ş. hisse senetleri ile ilgili uzun zamandır hiçbir işlem yaptırmadık. Ne yapmalıyız?",
    answer:
      "30 Aralık 2012 tarihli 28513 sayılı Resmi Gazete'de yayınlanan 6362 sayılı Yeni Sermaye Piyasası Kanunun 13. maddesinin 4. fıkrası uyarınca 31 Aralık 2012 tarihine kadar kaydileştirmek üzere şirketimize teslim edilmeyen tüm hisseler, bu tarihte kanunen bir kamu tüzel kişiliği olan Yatırımcı Tazmin Merkezine intikal edecek ve pay sahiplerinin söz konusu hisseler üzerindeki tüm hakları da anılan tarihte kendiliğinden sona ermiş sayılacaktır. Konu ile ilgili duyuru için tıklayınız.",
  },
  {
    question: "Kardemir finansal sonuçlarını hangi dönemlerde açıklamaktadır?",
    answer:
      "Kardemir mali yılı Ocak - Aralık dönemini kapsamaktadır ve SPK düzenlemelerine uygun şekilde finansal bilgiler 3 ayda bir açıklanmaktadır.",
  },
  {
    question: "Kardemir mali tablo/raporlarına nereden ulaşabilirim?",
    answer: "Mali tablo ve raporlar için tıklayınız.",
  },
  {
    question:
      "Hissedar olarak bilgi almak için hangi departman ile iletişime geçmeliyim?",
    answer: "İletişim bilgileri için tıklayınız.",
  },
  {
    question: "Kardemir'in Kar Dağıtım Politikası nedir?",
    answer: "Kardemir Kar Dağıtım Politikası için tıklayınız.",
  },
  {
    question: "Ürünlerinizle ilgili detaylı bilgiye nasıl ulaşabilirim?",
    answer:
      "Ürünlerimiz ile ilgili detay bilgi için ürün katalogunu tıklayınız.",
  },
  {
    question: "Üretim tesisleriniz ve satış ofisiniz/bayiniz nerededir?",
    answer:
      "Üretim tesislerimiz ve satış ofisimiz Karabük'tedir. Fabrika Adresimiz: Kardemir Karabük Demir Çelik San. ve Tic. A.Ş. Öğlebeli Mh. Ankara-Karabük Bulvarı No:2 78170 Karabük/Türkiye Tesislerimiz İstanbul'dan araçla 4 saat, Ankara'dan 2 saat uzaklıktadır.",
  },
  {
    question: "Sipariş talebimi hangi üniteye ve nasıl ileteceğim?",
    answer:
      "Siparişlerinizi 0370 418 20 85 telefon numaramızdan ulaşabileceğiniz ilgili Satış ve Pazarlama Müdürlüğü çalışanlarımızla görüşerek ya da talep etmiş olduğunuz ürünlerin listesini 0370 419 10 56 numaralı faxımıza ve satis@kardemir.com e-mail adresimize göndererek iletebilirsiniz.",
  },
  {
    question: "Satın almak istediğim ürünün fiyatını nasıl öğrenebilirim?",
    answer: "Fiyat listemiz için tıklayınız.",
  },
  {
    question:
      "Ödeme seçenekleriniz nelerdir, alternatif ödeme planları sunuluyor mu?",
    answer: "Ayrıntılı bilgi için tıklayınız.",
  },
  {
    question:
      "Satış ve teslimat konuları ile ilgili daha detaylı bilgiye nasıl ulaşabilirim?",
    answer:
      "satis@kardemir.com e-mail adresimizden detaylı bilgi isteyebilirsiniz.",
  },
  {
    question:
      "Satın almış olduğum ürünlerle ilgili şikayetlerimi nasıl iletebilirim?",
    answer:
      "Şikayetinizi satis@kardemir.com adresine e-mail olarak ve 0370 419 10 56 numarasındna fax ile iletebilirsiniz.",
  },
  {
    question:
      "İş başvurusu yapmak için özgeçmişimizi elden teslim edebilir miyiz?",
    answer:
      "Şirketimize yapılan iş başvurularının daha etkin değerlendirilmesi amacıyla; web sitemizde yer alan iş başvurusu linkinden genel başvurular ve kariyer.net vasıtasıyla güncel iş ilanlarımıza yapılan başvurular elektronik ortamda aday veri tabanımızda toplanmaktadır.",
  },
  {
    question: "İş başvurusunu nereden yapabiliriz?",
    answer:
      "İş başvurusunu web sitemiz üzerinden yapabilirsiniz. Başvuru için tıklayınız. Ayrıca açık pozisyonlar için kariyer.net'deki sayfamızı tıklayınız.",
  },
  {
    question: "Şirkete ait iletişim bilgilerine nereden erişebilirim?",
    answer: "İletişim sayfamız için tıklayınız.",
  },
  {
    question: "Kardemir hangi kalite belgelerine sahiptir?",
    answer: "Sahip olduğumuz kalite belgelerini görmek için tıklayınız.",
  },
  {
    question: "Analizimi hangi laboratuvara yaptırabilirim?",
    answer: "Test ve Analiz Rehberi için tıklayınız.",
  },
  {
    question: "Numuneyi nasıl teslim edebilirim?",
    answer: "Test ve Analiz Rehberi için tıklayınız.",
  },
  {
    question: "Numunenin ambalajı nasıl olmalıdır?",
    answer: "Test ve Analiz Rehberi için tıklayınız.",
  },
  {
    question: "Ne kadar numune göndermeliyim?",
    answer: "Test ve Analiz Rehberi için tıklayınız.",
  },
  {
    question: "Analiz raporu bana nasıl ulaşır?",
    answer: "Test ve Analiz Rehberi için tıklayınız.",
  },
  {
    question: "Analiz süresi yaklaşık ne kadardır?",
    answer: "Test ve Analiz Rehberi için tıklayınız.",
  },
  {
    question: "Laboratuvarla nasıl irtibata geçebilirim?",
    answer: "Test ve Analiz Rehberi için tıklayınız.",
  },
  {
    question: "Analiz ücretini nasıl hesaplayabilirim?",
    answer: "Test ve Analiz Rehberi için tıklayınız.",
  },
  {
    question: "Analiz ücretini ne zaman yatırmalıyım?",
    answer: "Test ve Analiz Rehberi için tıklayınız.",
  },
  {
    question: "Analiz metotları nelerdir?",
    answer: "Test ve Analiz Rehberi için tıklayınız.",
  },
  {
    question: "Evsafı bilinmeyen numuneye analiz yaptırabilir miyim?",
    answer: "Test ve Analiz Rehberi için tıklayınız.",
  },
  {
    question: "Yabancı dilde analiz raporu alabilir miyim?",
    answer: "Test ve Analiz Rehberi için tıklayınız.",
  },
  {
    question: "Şahit numune saklanmakta mıdır?",
    answer: "Test ve Analiz Rehberi için tıklayınız.",
  },
  {
    question: "Şahit numuneden alabilir miyim?",
    answer: "Test ve Analiz Rehberi için tıklayınız.",
  },
  {
    question: "Laboratuvarlar Akredite midir?",
    answer: "Test ve Analiz Rehberi için tıklayınız.",
  },
  {
    question: "Laboratuvarların Akreditasyon kapsamı nelerdir?",
    answer: "Test ve Analiz Rehberi için tıklayınız.",
  },
];

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

function SikcaSorulanSorularContents() {
  return (
    <Container sx={{ mt: 1 }}>
      <Box
        sx={{
          backgroundColor: "#fff",
          color: "#1f2e5e",
          // padding: { xs: "0.1rem", sm: "2rem", md: "4rem" },
        }}
      >
        <Header variant="h4" color="#1f2e5e">
          Sıkça Sorulan Sorular
        </Header>

        <Box
          sx={{
            margin: "0 auto",
          }}
        >
          {faqs.map((faq, index) => (
            <Accordion
              key={index}
              sx={{
                border: "1px solid #1f2e5e",
                borderRadius: "8px",
                marginBottom: "1rem",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  backgroundColor: "#1f2e5e",
                  color: "#fff",
                  borderBottom: "1px solid #fff",
                }}
              >
                <Typography variant="h6" sx={{ color: "#fff" }}>
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  backgroundColor: "#fff",
                  color: "#1f2e5e",
                  padding: "1rem",
                }}
              >
                <Typography>{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </Container>
  );
}

export default SikcaSorulanSorularContents;
