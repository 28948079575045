import React from "react";
import { Box, Typography, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import foto1 from "../../images/sustainability/hamcelik.png";
import foto2 from "../../images/sustainability/sucekimi.png";
import foto3 from "../../images/sustainability/atıkyonetimi.png";
import foto4 from "../../images/sustainability/sifiratik.jpg";
import foto5 from "../../images/sustainability/havakalitesi.png";
import foto6 from "../../images/sustainability/havakalitesi2.png";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
  overflowX: "auto",
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: "#1f2e5e",
  fontWeight: "bold",
  marginBottom: theme.spacing(2),
}));

function CevreVeIklimContents() {
  return (
    <Box sx={{ flexGrow: 1, padding: 1 }}>
      <Header variant="h4" color="#1f2e5e">
        Çevre ve İklim
      </Header>

      <StyledPaper elevation={3}>
        <Typography
          variant="body1"
          sx={{ marginBottom: 2, textAlign: "justify" }}
        >
          Türkiye’nin ilk entegre demir çelik üreticisi olarak geniş bir
          endüstriyel ekosisteme hitap ediyoruz. Dolayısıyla faaliyetlerimizde
          örnek bir tavır sergileyerek “sağlıklı çevre, verimli üretim”
          ilkesiyle hareket ederek çevre ve iklim üzerindeki etkilerimizi
          dikkatle yönetiyor ve azaltmaya çalışıyoruz.
          <br />
          <br />
          Çevre ve iklim üzerindeki etkilerin yönetimi kapsamında su
          kaynaklarının korunması ve su tüketiminin korunması, katı atıkların
          yönetimi, sera gazı ve baca gazı emisyonlarının yönetimi ve çevre
          bilincini geliştirmeye yönelik çalışmalar ana odak noktalarımızı
          oluşturuyor.
          <br />
          <br />
          Kardemir’in iş stratejisinin ana boyutlarından biri olarak
          değerlendirilen çevre ve iklim etkileri kurumsal risk yönetimi
          süreçlerinde de değerlendirilmektedir. Sürekli iyileştirme alanı
          olarak değerlendirilen bu konuların yönetimi, şirket üst yönetiminin
          stratejik yönlendirmeleri doğrultusunda gerçekleştirilmektedir. Genel
          Müdür ve Ünite Yöneticileri’nin oluşturduğu Çevre Kurulu, çevre ve
          iklimle ilgili odak alanlarda uygulama ve yatırım stratejilerini
          gerçekleştirilecek çalışmaları ve hedefleri belirler. Oluşturulan
          çalışma planı İşletme ve Çevre Başmühendisliği liderliğinde Çevre
          Müdürlüğü tarafından hayata geçirilmektedir. Ele alınan her bir odak
          konu için ayrı bir uzman yönetici görevlendirilmiştir. Diğer taraftan
          çevre performansını artırmaya yönelik ünite yatırımları söz konusu
          olduğunda ilgili ünite yönetimleri ve mali birimler de görev
          almaktadır.
          <br />
          <br />
          Kardemir Çevre Politikası, faaliyetlerimiz genelinde çevre yönetiminde
          takip edeceğimiz ana ilkeleri kapsayan temel kurumsal politikamızdır.
          Hem Kardemir Çevre Politikası hem şirket süreç yönetim uluslararası
          ölçekte kabul gören ISO 14001 Çevre Yönetim Standardı’na uyumlu olarak
          tasarlanmıştır.
          <br />
          <br />
          Şirket genelinde çevre ve iklim performansını artırıcı çalışmalar
          öncelikle uzun vadeli üretim ve yatırım planları doğrultusunda
          planlanmaktadır. Sonrasında ise bu planlar kısa orta ve uzun vadeli
          performans hedeflerine dönüştürülür ve kurumsal performans
          değerlendirme sistemi çerçevesinde takip edilir.
          <br />
          <br />
          Gerçekleştirdiğimiz faaliyetler iç denetim sürecine tabi iken aynı
          zamanda yıllık olarak ISO 14001 belgelendirmesine yönelik bağımsız dış
          denetime de tabidir. Diğer taraftan şirket faaliyetleri çevre ve iklim
          üzerindeki etkiler bakımından T.C. Çevre ve Şehircilik Bakanlığı ve
          T.C. Tarım ve Orman Bakanlığı’nın denetimlerine tabidir.
          Faaliyetlerimiz sonucunda elde ettiğimiz performans değerleri iç
          raporlar vasıtasıyla üst yönetime raporlanmanın yanında çeşitli
          kanallar aracılığıyla T.C. Çevre ve Şehircilik Bakanlığı, T.C. Enerji
          Bakanlığı ve T.C. Tarım ve Orman Bakanlığı’na raporlanmaktadır.
        </Typography>
        <img
          src={foto1}
          alt="cevreveiklim"
          style={{
            width: "50%",
            height: "40%",
            display: "flex",
            margin: "0 auto",
          }}
        />

        <Typography
          variant="body1"
          sx={{
            marginBottom: 2,
            textAlign: "justify",
            padding: { xs: "0.5rem", lg: "2rem 8rem" },
          }}
        >
          İklim Değişikliği <br />
          <br />
          Kurumsal vatandaşlık anlayışımız ve özellikle de faaliyet alanımızın
          enerji yoğun niteliğinden ötürü iklim değişikliği etkilerinin yönetimi
          Kardemir’de en büyük önemin verildiği çevre yönetimi konularından
          biridir. Bu alanda gerçekleştirdiğimiz çalışmaların büyük bölümü
          enerji yönetimi kapsamında gerçekleştirdiğimiz verimlilik ve proses
          tasarımı çalışmaları aracılığıyla sera gazı emisyonlarının
          azaltılmasına odaklanmaktadır. İklim değişikliği etkilerinin
          yönetiminin ilk etabını faaliyetlerimizden kaynaklanan sera gazı
          emisyonlarının kaynağının tespiti ve envanterinin çıkarılması
          oluşturmaktadır. Sonrasında ise sera gazı emisyonlarının hesaplanması
          ve doğrulatılması, gerçekleştirdiğimiz proje ve yatırımlarla emisyon
          miktarının azaltılması bu alanda gerçekleştirdiğimiz faaliyetlerin
          temellerini oluşturmaktadır. Bu kapsamda Kardemir, 2015 yılından beri
          her yıl Sera Gazı Emisyonlarının Takibi Yönetmeliği şartlarına uygun
          olarak sera gazı emisyonlarına yönelik izleme planı oluşturulmakta,
          kapsam 1 ve kapsam 2 emisyonlar hesaplanmakta ve akredite laboratuvar
          tarafından doğrulanan emisyon raporu Bakanlığın Çevre Bilgi Sistemine
          yüklenmektedir.
        </Typography>

        <SectionTitle variant="h5">Karbon Saydamlık Projesi</SectionTitle>

        <Typography
          variant="body1"
          sx={{ marginBottom: 2, textAlign: "justify" }}
        >
          Kardemir küresel bir sorun olan iklim değişikliği etkilerinin
          azaltılması amacıyla küresel iklim ajandasını yakından takip etmekte
          bu kapsamda öncelikle kamu kuruluşları nezdinde yürütülen çalışmalara
          ve uluslararası örgütlerin çalışmalarına destek vermektedir. Bu
          kapsamda Kardemir, uluslararası yatırım kuruluşlarının desteğiyle
          iklim riskleri yönetiminin geliştirilmesini ve bu alanda şirket
          strateji, uygulama ve performanslarının değerlendirilmesini amaçlayan
          Karbon Saydamlık Projesi’ne (CDP) gönüllü olarak katılmaktadır. Bu
          kapsamda her yıl iklim değişikliği yönünden şirketimizde yapılan
          çalışmalar ve elde edilen sonuçlar raporlanmaktadır.
        </Typography>

        <SectionTitle variant="h5">Su Yönetimi</SectionTitle>
        <Typography
          variant="body1"
          sx={{ marginBottom: 2, textAlign: "justify" }}
        >
          Günümüzün en öncelikli sorunlarından bir olan su kaynaklarının
          korunması ve verimli korunması, iklim değişikliği konusuyla da son
          derece ilişkili bir çevre etki alanıdır. Demir çelik üretiminde önemli
          ölçüde kullanılan bir doğal kaynak olması nedeniyle Kardemir,
          faaliyetlerinden doğan su ihtiyacını dikkatle yönetmekte, toplum
          açısından son derece önem arz eden bu kaynağın mümkün olduğunca
          verimli kullanılmasını sağlamak için çalışmalar yürütmektedir.
        </Typography>
        <img
          src={foto2}
          alt="cevreveiklim"
          style={{
            width: "50%",
            height: "70%",
            display: "flex",

            margin: "0 auto",
          }}
        />

        <SectionTitle variant="h5">Atık Yönetimi</SectionTitle>
        <Typography
          variant="body1"
          sx={{ marginBottom: 2, textAlign: "justify" }}
        >
          Atık yönetiminin ve döngüsel ekonomi uygulamalarının güçlendirilmesi,
          doğal kaynakların sürdürülebilir kullanımı, çevre ve insan sağlığının
          korunması yaşamsal önemde olup, mevcut ve gelecek kuşaklara daha
          kaliteli ve sürdürülebilir bir yaşam ortamı sağlanmasında temel
          faktördür. Bu bilinçle Kardemir operasyonları neticesinde oluşan
          atıklar niteliklerine göre ve yasal düzenlemelerde belirtilen
          koşullarda yönetilmektedir.
          <br />
          <br />
          Kardemir faaliyetlerinin çeşitli süreçlerinde oluşan atıklar,
          öncelikle şirket içinde veya şirket dışında yeniden kullanım olanağı
          olup olmaması durumuna göre ayrılır. Yeniden kullanım olanağı olan
          süreç çıktıları kullanım alanına yönlendirilir. Geri kazanılamayan
          atıklar kaynağında sınıflandırılarak ayrıştırılır, atık türüne uygun
          koşullarda geri dönüştürülür. Sadece geri dönüştürülemeyen atıklar ise
          yasalarda düzenlenen teknik ve koşullara uygun olarak deşarj ya da
          bertaraf edilir.
        </Typography>
        <img
          src={foto3}
          alt="cevreveiklim"
          style={{
            width: "80%",
            height: "70%",
            display: "flex",

            margin: "0 auto",
          }}
        />

        <SectionTitle variant="h5">Kardemir’de Sıfır Atık</SectionTitle>
        <Typography
          variant="body1"
          sx={{ marginBottom: 2, textAlign: "justify" }}
        >
          Çevre ve Şehircilik Bakanlığı tarafından; sürdürülebilir kalkınma
          ilkeleri çerçevesinde oluşan atıkları kontrol altına almak, gelecek
          nesillere daha temiz ve yaşanabilir bir dünya bırakmak adına Sayın
          Emine Erdoğan Hanımefendi’nin himayelerinde “Sıfır Atık Projesi”
          hayata geçirilmiştir. “Sıfır Atık”; israfın önlenmesini, kaynakların
          daha verimli kullanılmasını, atık oluşum sebeplerinin gözden
          geçirilerek atık oluşumunun engellenmesi veya minimize edilmesi,
          atığın oluşması durumunda ise kaynağında ayrı toplanması ve geri
          kazanımının sağlanmasını kapsayan atık yönetim felsefesi olarak
          tanımlanan bir hedeftir.
          <br />
          <br />
          Şirketimizde, sıfır atık yönetimine ilişkin çalışmaların sistemli ve
          verimli biçimde yürütülmesi için “Sıfır Atık Projesi kapsamında; geri
          kazanım ve geri dönüşümün yaygınlaştırması amacıyla 90 noktada (90
          set) iç ve dış mekan geri dönüşüm kutuları, tehlikeli atıkların
          toplanabilmesi amacıyla 42 noktada atık toplama alanları
          bulunmaktadır. Toplanan tüm atıklar Fabrika Geçici Depolama Atık
          Sahasında depolanarak Çevre ve Şehircilik Bakanlığı’ndan lisanslı geri
          kazanım ve bertaraf firmalarına gönderilmektedir.
          <br />
          <br />
          Çevresel farkındalığın artırılması ve Sıfır Atık Projesi kapsamında;
          2019 yılında başlanan Çevre Eğitimlerinde tüm şirket çalışanlarına
          Sıfır Atık Eğitimleri verilmeye başlanmıştır. Eğitimlerde proje ile
          ilgili yapılan çalışmalar ve sıfır atık kapsamında bireylere düşen
          sorumluluklar anlatılarak “SIFIR ATIK” konusunda bilincin artırılması
          hedeflenmektedir.
          <br />
          <br />
          Şirketimiz Sıfır Atık Sistemine geçmiş olup yapılan tüm çalışmalarla
          ilgili veri girişleri Entegre Çevre Bilgi Sistemi Sıfır Atık Bilgi
          Sistemi üzerinden yapılmaktadır.
          <br />
          <br />
          Daha yeşil, daha temiz bir dünya için, israfı önlemek, kaynakları daha
          verimli kullanmak, atık miktarını azaltmak ve atıkları geri kazanmak,
          yarınlara temiz bir dünya bırakmak için; SIFIR ATIK İÇİN BİZ DE VARIZ.
          <br />
          <br />
          Şirketimize ait SIFIR ATIK BELGESİ için{" "}
          <a
            href="https://www.kardemir.com/dosyalar/sayfalar/1180/17112022/2022111709153231_sayfalar_1180_17112022.pdf?v=ab59fdf4_c410_27a6_1e91_d5c69721a46d"
            style={{ textDecoration: "underline", color: "inherit" }}
            target="_blank"
          >
            tıklayınız
          </a>
          .
        </Typography>
        <img
          src={foto4}
          alt="cevreveiklim"
          style={{
            width: "30%",
            height: "20%",
            display: "flex",
            margin: "1rem 0",
          }}
        />

        <SectionTitle variant="h5">Hava Kalitesi</SectionTitle>
        <Typography
          variant="body1"
          sx={{ marginBottom: 2, textAlign: "justify" }}
        >
          Ülkemizdeki entegre demir ve çelik fabrikaları gereksinimleri olan
          demir cevherini ağırlıklı olarak ithalat yoluyla karşılamaktadır. Buna
          karşın, Kardemir ülke ekonomisine katkıları nedeniyle Sinter
          tesislerinde yaklaşık %80 oranında yüksek kükürt ihtiva eden yerli
          cevher kullanmaktadır.
          <br />
          <br />
          Bu kapsamda “Sanayi Kaynaklı Hava Kirliliğinin Kontrolü
          Yönetmeliği’ne” uygun olarak %16 oksijen düzeltilmesi yapılarak Sinter
          tesisi baca gazında SO2 emisyonlarının 500 mg/Nm3 sınır değeri
          aşmaması gerekmektedir.
          <br />
          <br />
          Çevre faaliyetlerimizle ilgili daha detaylı bilgi için{" "}
          <a
            href="https://www.kardemir.com/dosyalar/sayfalar/1180/17112022/2022111708185605_sayfalar_1180_17112022.pdf?v=704d5f0a_7edd_76c2_49ee_960bc55018f7"
            style={{ textDecoration: "underline", color: "inherit" }}
            target="_blank"
          >
            tıklayınız
          </a>
          .
        </Typography>
        <img
          src={foto5}
          alt="cevreveiklim"
          style={{
            width: "50%",
            height: "70%",
            display: "flex",

            margin: "0 auto",
          }}
        />
        <img
          src={foto6}
          alt="cevreveiklim"
          style={{
            width: "50%",
            height: "70%",
            display: "flex",

            margin: "0 auto",
          }}
        />

        {/* <Divider sx={{ marginY: 3 }} /> ÇİZGİ ÇEKME */}
      </StyledPaper>
    </Box>
  );
}

export default CevreVeIklimContents;
