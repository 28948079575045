import React from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import CommuteIcon from "@mui/icons-material/Commute";
import SecurityIcon from "@mui/icons-material/Security";
import SchoolIcon from "@mui/icons-material/School";
import { styled } from "@mui/material/styles";

const olanaklar = [
  {
    icon: <HomeIcon fontSize="large" style={{ color: "#1f2e5e" }} />,
    title: "Lojman",
    description: `Şirketimiz mülkiyetinde olan ve Yenişehir Bölgesinde bulunan lojmanlar 
                  Şirketimizde yürürlükte bulunan AİLE TİPİ LOJMANLAR, BEKAR LOJMANLARI, 
                  STÜDYO TİPİ LOJMANLAR VE MİSAFİRHANELERDEN YARARLANMA USULLERİ PROSEDÜRÜ 
                  çerçevesinde ve Kurul aracılığı ile tüm personelin talep ve puan sıralaması 
                  sonucu açık usulde dağıtım yapılarak faydalandırılır.`,
  },
  {
    icon: <RestaurantIcon fontSize="large" style={{ color: "#1f2e5e" }} />,
    title: "Yemek",
    description: `Müdürlüğümüze bağlı Sosyal Hizmetler Şefliği tarafından şirket içerisindeki 
                  7 yemekhanede 3 vardiya sistemi ile şirketimiz bünyesinde çalışan tüm personel 
                  ile şirketimize bağlı yan şirketlerin ve müteahhit firmaların personellerine yemek 
                  hizmeti verilmektedir. Bu yemek hizmeti yerinde üretim yapan profesyonel yemek firması 
                  tarafından şirketimiz mutfağında hazırlanmaktadır.`,
  },
  {
    icon: <CommuteIcon fontSize="large" style={{ color: "#1f2e5e" }} />,
    title: "Servis",
    description: `Şirketimizde çalışan tüm personele 3 vardiya mesai saatleri dahilinde ve önceden tespit 
                  edilen güzergahlarda servis hizmeti verilmektedir.`,
  },
  {
    icon: <SecurityIcon fontSize="large" style={{ color: "#1f2e5e" }} />,
    title: "Kişisel Koruyucu Donanımlar",
    description: `Şirketimizde çalışan tüm personele, yürürlükte bulunan Kişisel Koruyucu Donanım (KKD) Prosedürü 
                  çerçevesinde ve Üniteler bazında işyeri ve çalışma şartlarına uygun koruyucu malzemeler Kişisel 
                  Koruyucu Donanım Kurulu tarafından belirlenir ve KKD’lerin dağıtımları yapılır.`,
  },
  {
    icon: <SchoolIcon fontSize="large" style={{ color: "#1f2e5e" }} />,
    title: "Beceri Eğitimleri ve Fakülte / Meslek Yüksekokulları Stajları",
    description: `Meslek Lisesi son sınıf öğrencilerine ve Üniversite öğrencilerine staj imkanı sunulmaktadır.`,
  },
];

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

function PersonelHaklariContents() {
  return (
    <Container sx={{ mt: 4 }}>
      <Header variant="h4" color="#1f2e5e">
        Personelimize Sağladığımız Olanaklar
      </Header>
      <Grid container spacing={4}>
        {olanaklar.map((olanak, index) => (
          <Grid item xs={12} md={6} lg={4} key={index}>
            <Card
              elevation={4}
              sx={{
                height: "100%",
                backgroundColor: "#f5f5f5",
              }}
            >
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  {olanak.icon}
                  <Typography variant="h6" sx={{ ml: 2 }}>
                    {olanak.title}
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  sx={{ textAlign: "justify", px: 3 }}
                >
                  {olanak.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default PersonelHaklariContents;
