import React, { useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Box, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
  overflowX: "auto",
  maxWidth: 800, // Genişliği sınırlamak için maxWidth ayarlandı
  margin: "0 auto", // Merkezde hizalama sağlamak için margin kullanıldı
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

function HurdaAlimFiyatContents() {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleClick = (date) => {
    setSelectedDate(date);
  };

  const data = {
    "29/05/2024": {
      title:
        "Çelik hurdası şartnamemiz esaslarına göre satın alınmakta olan hurda demir çeliğin fiyatları 29.05.2024 tarihinden itibaren uygulanmak üzere aşağıdaki şekilde belirlenmiştir.",
      prices: {
        "Özel Ekstra": "13.170 TL/Ton",
        "DKP Hurda": "13.150 TL/Ton",
        Extra: "12.915 TL/Ton",
        "1.Sınıf": "12.770 TL/Ton",
        "2.Sınıf": "12.610 TL/Ton",
        "Büyük Parça İskontosu": "400 TL/Ton",
      },
    },
    "08/05/2024": {
      title:
        "Çelik hurdası şartnamemiz esaslarına göre satın alınmakta olan hurda demir çeliğin fiyatları 08.05.2024 tarihinden itibaren uygulanmak üzere aşağıdaki şekilde belirlenmiştir.",
      prices: {
        "Özel Ekstra": "13.320 TL/Ton",
        "DKP Hurda": "13.300 TL/Ton",
        Extra: "13.065 TL/Ton",
        "1.Sınıf": "12.920 TL/Ton",
        "2.Sınıf": "12.760 TL/Ton",
        "Büyük Parça İskontosu": "400 TL/Ton",
      },
    },
    "15/04/2024": {
      title:
        "Çelik hurdası şartnamemiz esaslarına göre satın alınmakta olan hurda demir çeliğin fiyatları 15.04.2024 tarihinden itibaren uygulanmak üzere aşağıdaki şekilde belirlenmiştir.",
      prices: {
        "Özel Ekstra": "13.170 TL/Ton",
        "DKP Hurda": "13.150 TL/Ton",
        Extra: "12.915 TL/Ton",
        "1.Sınıf": "12.770 TL/Ton",
        "2.Sınıf": "12.610 TL/Ton",
        "Büyük Parça İskontosu": "400 TL/Ton",
      },
    },
    "06/02/2024": {
      title:
        "Çelik hurdası şartnamemiz esaslarına göre satın alınmakta olan hurda demir çeliğin fiyatları 06.02.2024 tarihinden itibaren uygulanmak üzere aşağıdaki şekilde belirlenmiştir.",
      prices: {
        "Özel Ekstra": "13.170 TL/Ton",
        "DKP Hurda": "13.150 TL/Ton",
        Extra: "12.915 TL/Ton",
        "1.Sınıf": "12.770 TL/Ton",
        "2.Sınıf": "12.610 TL/Ton",
        "Büyük Parça İskontosu": "400 TL/Ton",
      },
    },
  };

  const getDotStyle = (date) => ({
    backgroundColor: selectedDate === date ? "#d1111a" : "grey",
  });

  const getContentStyle = (date) => ({
    color: selectedDate === date ? "#d1111a" : "black",
    fontWeight: "bold",
  });

  return (
    <Box sx={{ flexGrow: 1, padding: 1 }}>
      <Header variant="h4" color="#1f2e5e">
        Hurda Alım Fiyatları
      </Header>

      <StyledPaper elevation={3}>
        <Box
          sx={{
            padding: "3rem",
            display: "flex",
          }}
        >
          <Box sx={{ display: "flex", width: "20%" }}>
            <Timeline position="left" style={{ margin: 0, padding: 0 }}>
              {Object.keys(data).map((date) => (
                <TimelineItem key={date} onClick={() => handleClick(date)}>
                  <TimelineSeparator>
                    <TimelineDot style={getDotStyle(date)} />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent style={getContentStyle(date)}>
                    {date}
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </Box>
          <Box sx={{ marginLeft: "2rem", flex: 1 }}>
            {selectedDate && (
              <>
                <h3>{data[selectedDate].title}</h3>
                <ul>
                  {Object.entries(data[selectedDate].prices).map(
                    ([key, value]) => (
                      <li key={key}>
                        {key}: {value}
                      </li>
                    )
                  )}
                </ul>
              </>
            )}
          </Box>
        </Box>
      </StyledPaper>
    </Box>
  );
}

export default HurdaAlimFiyatContents;
