import { useState } from "react";
import SolYapiMedya from "./SolYapiMedya";

export default function Haberler() {
  const [page, setPage] = useState("Haberler");

  return (
    <>
      <SolYapiMedya setPage={setPage} page={page} />
    </>
  );
}
