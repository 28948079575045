import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
  overflowX: "auto",
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

function SunumlarContents() {
  const sections = [
    {
      title: "2024",
      details: (
        <>
          <Box sx={{ margin: { xs: "0", md: "2rem " } }}>
            {[
              {
                title: "Yatırımcı Sunumları",
                details: "2024 yılı 1. Çeyrek Yatırımcı Sunumu",
              },
              {
                title: "Mali Tablo ve Raporlar",
                details:
                  "2024 Yılı 03 Aylık SPK II.14.1 sayılı tebliğine göre hazırlanan Konsolide",
              },
              {
                title: "Faaliyet Raporları",
                details: "2024 Yılı 3 Aylık Dönemi",
              },
            ].map((section, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index + 1}-content`}
                  id={`panel${index + 1}-header`}
                >
                  <Paper
                    elevation={2}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      p: 2,
                      width: "100%",
                      mb: 1,
                      backgroundColor: "#1f2e5e",
                    }}
                  >
                    <Typography sx={{ fontWeight: 600, color: "#fff" }}>
                      {section.title}
                    </Typography>
                  </Paper>
                </AccordionSummary>
                <AccordionDetails>
                  <Paper
                    elevation={2}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      p: 2,
                      mb: 1,
                      backgroundColor: "#fff",
                    }}
                  >
                    <Typography>{section.details}</Typography>
                  </Paper>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </>
      ),
    },
    {
      title: "2023",
      details: (
        <>
          <Box sx={{ margin: { xs: "0", md: "2rem " } }}>
            {[
              {
                title: "Yatırımcı Sunumları",
                details: "2023 Yılı Yatırımcı Sunumu",
              },
              {
                title: "Mali Tablo ve Raporlar",
                details:
                  "2023 Yılı 12 Aylık SPK II.14.1 sayılı tebliğine göre hazırlanan Konsolide",
              },
              {
                title: "Faaliyet Raporları",
                details: "31.12.2023 Yılı Yıllık",
              },
            ].map((section, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index + 1}-content`}
                  id={`panel${index + 1}-header`}
                >
                  <Paper
                    elevation={2}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      p: 2,
                      width: "100%",
                      mb: 1,
                      backgroundColor: "#1f2e5e",
                    }}
                  >
                    <Typography sx={{ fontWeight: 600, color: "#fff" }}>
                      {section.title}
                    </Typography>
                  </Paper>
                </AccordionSummary>
                <AccordionDetails>
                  <Paper
                    elevation={2}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      p: 2,
                      mb: 1,
                      backgroundColor: "#fff",
                    }}
                  >
                    <Typography>{section.details}</Typography>
                  </Paper>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </>
      ),
    },
    {
      title: "2022",
      details: (
        <>
          <Box sx={{ margin: { xs: "0", md: "2rem " } }}>
            {[
              {
                title: "Yatırımcı Sunumları",
                details: "2022 Yılı Yatırımcı Sunumu",
              },
              {
                title: "Mali Tablo ve Raporlar",
                details:
                  "2022 Yılı 12 Aylık SPK II.14.1 sayılı tebliğine göre hazırlanan Konsolide",
              },
              { title: "Faaliyet Raporları", details: "2022 yılı Yıllık" },
            ].map((section, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index + 1}-content`}
                  id={`panel${index + 1}-header`}
                >
                  <Paper
                    elevation={2}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      p: 2,
                      width: "100%",
                      mb: 1,
                      backgroundColor: "#1f2e5e",
                    }}
                  >
                    <Typography sx={{ fontWeight: 600, color: "#fff" }}>
                      {section.title}
                    </Typography>
                  </Paper>
                </AccordionSummary>
                <AccordionDetails>
                  <Paper
                    elevation={2}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      p: 2,
                      mb: 1,
                      backgroundColor: "#fff",
                    }}
                  >
                    <Typography>{section.details}</Typography>
                  </Paper>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </>
      ),
    },
  ];

  return (
    <Box sx={{ flexGrow: 1, padding: 1 }}>
      <Header variant="h4" color="#1f2e5e">
        Sunumlar Raporlar ve Tablolar
      </Header>

      <StyledPaper elevation={3}>
        <Box sx={{ margin: { xs: "0", md: "2rem " } }}>
          {sections.map((section, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index + 1}-content`}
                id={`panel${index + 1}-header`}
              >
                <Paper
                  elevation={2}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    p: 2,
                    width: "100%",
                    backgroundColor: "#1f2e5e",
                  }}
                >
                  <Typography sx={{ fontWeight: 600, color: "#fff" }}>
                    {section.title}
                  </Typography>
                </Paper>
              </AccordionSummary>
              <AccordionDetails>
                <Paper
                  elevation={2}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    mb: 1,
                    backgroundColor: "#fff",
                  }}
                >
                  <Typography>{section.details}</Typography>
                </Paper>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </StyledPaper>
    </Box>
  );
}

export default SunumlarContents;
