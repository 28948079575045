import React, { useState } from "react";
import SolYapiYatirimci from "./SolYapiYatirimci";

function GenelKurul() {
  const [page, setPage] = useState("Genel Kurul");

  return <SolYapiYatirimci setPage={setPage} page={page} />;
}

export default GenelKurul;
