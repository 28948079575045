import SolYapiSurdurulebilirlik from "./SolYapiSurdurulebilirlik";
import React, { useState } from "react";

function SurdurulebilirlikYonetimi() {
  const [page, setPage] = useState("Sürdürülebilirlik Yönetimi");

  return (
    <>
      <SolYapiSurdurulebilirlik setPage={setPage} page={page} />
    </>
  );
}

export default SurdurulebilirlikYonetimi;
