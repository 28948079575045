import {
  Box,
  Breadcrumbs,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import image from "../../../images/image2.jpg";
import YatirimciIliskileri from "./YatirimciIliskileri";
import TedarikciIliskileri from "./TedarikciIliskileri";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

function Iliskiler() {
  const [open, setOpen] = useState({});
  const [page, setPage] = useState("");
  const [collapse, setCollapse] = useState("");

  const handleClick = (name, pageName = "") => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [name]: !prevOpen[name],
    }));
    setPage(pageName || name);
    if (pageName) setCollapse("");
  };

  const handleCollapseClick = (name) => {
    setCollapse(name);
  };

  return (
    <>
      <Box
        component="img"
        alt=""
        src={image}
        style={{
          width: "100%",
          height: "30rem",
          cursor: "pointer",
        }}
      />

      <Box
        sx={{
          borderBottom: "1px solid lightgray",
          height: "5rem",
          display: "flex",
          alignItems: "center",
          paddingLeft: "1rem",
          justifyContent: "center",
        }}
      >
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link
            to="/"
            style={{
              color: "gray",
              padding: "8px",
              textDecoration: "none",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            Ana Sayfa
          </Link>
          <Typography
            style={{
              color: "gray",
              padding: "8px",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            İlişkiler
          </Typography>
          {page && (
            <Typography
              style={{
                color: "black",
                padding: "8px",
                fontSize: "12px",
                fontWeight: "500",
              }}
            >
              {page}
            </Typography>
          )}
          {collapse && (
            <Typography
              style={{
                color: "black",
                padding: "8px",
                fontSize: "12px",
                fontWeight: "500",
              }}
            >
              {collapse}
            </Typography>
          )}
        </Breadcrumbs>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: "space-between",
          padding: { xs: "1rem 0", lg: "1rem 2rem 1rem 1rem" },
        }}
      >
        <Box
          sx={{
            borderRight: { xs: "none", lg: "1px solid gray" },
            // paddingRight: { xs: "0rem", lg: "3rem" },

            marginBottom: "2rem",
            // width: { lg: "10rem" },
          }}
        >
          <Paper
            sx={{
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              padding: "2rem",
              width: { xs: "80%", lg: "70%" },
            }}
          >
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "1.5rem",
                textDecoration: "underline",
              }}
            >
              İlişkiler
            </Typography>

            <List>
              <ListItemButton
                onClick={() => handleClick("yatirimci", "Yatırımcı İlişkileri")}
              >
                <ListItemText primary="Yatırımcı İlişkileri" />
                {open.yatirimci ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={open.yatirimci} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => handleCollapseClick("Ortaklık Yapısı")}
                  >
                    <ListItemText primary="Ortaklık Yapısı" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() =>
                      handleClick("kurumsal", "Yatırımcı İlişkileri")
                    }
                  >
                    <ListItemText primary="Kurumsal Yönetim" />
                    {open.kurumsal ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={open.kurumsal} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 6 }}
                        onClick={() => handleCollapseClick("Komiteler")}
                      >
                        <ListItemText primary="Komiteler" />
                      </ListItemButton>
                      <ListItemButton
                        sx={{ pl: 6 }}
                        onClick={() => handleCollapseClick("Ana Sözleşme")}
                      >
                        <ListItemText primary="Ana Sözleşme DEĞİLŞECEK" />
                      </ListItemButton>

                      <ListItemButton
                        sx={{ pl: 6 }}
                        onClick={() => handleCollapseClick("Genel Kurul")}
                      >
                        <ListItemText primary="Genel Kurul" />
                      </ListItemButton>
                      <ListItemButton
                        sx={{ pl: 6 }}
                        onClick={() =>
                          handleCollapseClick("Özel Durum Açıklamaları")
                        }
                      >
                        <ListItemText primary="Özel Durum Açıklamaları BOŞ" />
                      </ListItemButton>
                      <ListItemButton
                        sx={{ pl: 6 }}
                        onClick={() => handleCollapseClick("Politikalar")}
                      >
                        <ListItemText primary="Politikalar DEĞİŞECEK" />
                      </ListItemButton>
                    </List>
                  </Collapse>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => handleCollapseClick("Özet Mali Tablolar")}
                  >
                    <ListItemText primary="Özet Mali Tablolar BOŞ" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => handleClick("hisse", "Yatırımcı İlişkileri")}
                  >
                    <ListItemText primary="Hisse Verileri" />
                    {open.hisse ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={open.hisse} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 6 }}
                        onClick={() =>
                          handleCollapseClick("Karşılaştırmalı Grafik")
                        }
                      >
                        <ListItemText primary="Karşılaştırmalı Grafik" />
                      </ListItemButton>
                      <ListItemButton
                        sx={{ pl: 6 }}
                        onClick={() => handleCollapseClick("Ekonomik Takvim")}
                      >
                        <ListItemText primary="Ekonomik Takvim" />
                      </ListItemButton>
                      <ListItemButton
                        sx={{ pl: 6 }}
                        onClick={() =>
                          handleCollapseClick("Hisse Detay Grafik")
                        }
                      >
                        <ListItemText primary="Hisse Detay Grafik" />
                      </ListItemButton>
                      <ListItemButton
                        sx={{ pl: 6 }}
                        onClick={() => handleCollapseClick("Hisse Hesaplayıcı")}
                      >
                        <ListItemText primary="Hisse Hesaplayıcı" />
                      </ListItemButton>
                    </List>
                  </Collapse>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => handleCollapseClick("Analist Bilgileri")}
                  >
                    <ListItemText primary="Analist Bilgileri" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() =>
                      handleCollapseClick("Sunumlar, Raporlar ve Tablolar")
                    }
                  >
                    <ListItemText primary="Sunumlar, Raporlar ve Tablolar" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => handleCollapseClick("Kredi Derecelendirme")}
                  >
                    <ListItemText primary="Kredi Derecelendirme" />
                  </ListItemButton>
                </List>
              </Collapse>

              {/* TEDARİKÇİ İLİŞKİLERİ */}

              <ListItemButton
                onClick={() => handleClick("tedarikci", "Tedarikçi İlişkileri")}
              >
                <ListItemText primary="Tedarikçi İlişkileri" />
                {open.tedarikci ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={open.tedarikci} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => handleCollapseClick("Tedarikçi İşlemleri")}
                  >
                    <ListItemText primary="Tedarikçi İşlemleri" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => handleClick("hurda", "Tedarikçi İlişkileri")}
                  >
                    <ListItemText primary="Hurda Alım İşlemleri" />
                    {open.hurda ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={open.hurda} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 6 }}
                        onClick={() =>
                          handleCollapseClick("Hurda Alım Şartnamesi")
                        }
                      >
                        <ListItemText primary="Hurda Alım Şartnamesi" />
                      </ListItemButton>
                      <ListItemButton
                        sx={{ pl: 6 }}
                        onClick={() =>
                          handleCollapseClick("Hurda Alım Fiyatları")
                        }
                      >
                        <ListItemText primary="Hurda Alım Fiyatları" />
                      </ListItemButton>
                    </List>
                  </Collapse>
                </List>
              </Collapse>
            </List>
          </Paper>
        </Box>

        <Box sx={{ width: "100%" }}>
          {page === "Yatırımcı İlişkileri" && (
            <YatirimciIliskileri collapse={collapse} />
          )}
          {page === "Tedarikçi İlişkileri" && (
            <TedarikciIliskileri collapse={collapse} />
          )}
        </Box>
      </Box>
    </>
  );
}

export default Iliskiler;
