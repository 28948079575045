import React, { useState } from "react";
import SolYapiTedarikci from "./SolYapiTedarikci";

function HurdaAlim() {
  const [page, setPage] = useState("Hurda Alım Şartnamesi");

  return <SolYapiTedarikci setPage={setPage} page={page} />;
}

export default HurdaAlim;

// if (pageName === "Ortaklık Yapısı") {
//   navigate("/ortaklik-yapisi");
// } else if (pageName === "Komiteler") {
//   navigate("/komiteler");
// } else if (pageName === "Özet Mali Tablolar") {
//   navigate("/ozet-mali-tablolar");
// } else if (pageName === "Karşılaştırmalı Grafik") {
//   navigate("/karsilastirmali-grafik");
// } else if (pageName === "Analist Bilgileri") {
//   navigate("/analist-bilgileri");
// } else if (pageName === "Sunumlar Raporlar ve Tablolar") {
//   navigate("/sunumlar-raporlar-tablolar");
// } else if (pageName === "Kredi Derecelendirme") {
//   navigate("/kredi-derecelendirme");
// }
// };
