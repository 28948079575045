import React, { useState } from "react";
import SolYapiKariyer from "./SolYapiKariyer";

function PersonelHaklari() {
  const [page, setPage] = useState("Personel Hakları");

  return (
    <>
      <SolYapiKariyer setPage={setPage} page={page} />
    </>
  );
}

export default PersonelHaklari;
