import SolYapiSurdurulebilirlik from "./SolYapiSurdurulebilirlik";
import React, { useState } from "react";

function ArgeVeInovasyon() {
  const [page, setPage] = useState("AR-GE ve İnovasyon");

  return (
    <>
      <SolYapiSurdurulebilirlik setPage={setPage} page={page} />
    </>
  );
}

export default ArgeVeInovasyon;
