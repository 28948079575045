import React, { useState } from "react";
import SolYapiYatirimci from "./SolYapiYatirimci";

function Sunumlar() {
  const [page, setPage] = useState("Sunumlar Raporlar ve Tablolar");

  return <SolYapiYatirimci setPage={setPage} page={page} />;
}

export default Sunumlar;
