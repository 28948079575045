import React, { useState } from "react";
import SolYapiTedarikci from "./SolYapiTedarikci";

function TedarikciIslemleri() {
  const [page, setPage] = useState("Tedarikçi İşlemleri");

  return <SolYapiTedarikci setPage={setPage} page={page} />;
}

export default TedarikciIslemleri;
