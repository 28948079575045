import {
  Box,
  Paper,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
  overflowX: "auto",
  maxWidth: 900, // Genişliği sınırlamak için maxWidth ayarlandı
  margin: "0 auto", // Merkezde hizalama sağlamak için margin kullanıldı
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: "#1f2e5e",
  fontWeight: "bold",
  marginBottom: theme.spacing(2),
}));

const Table = styled("table")(({ theme }) => ({
  width: "100%",
  borderCollapse: "collapse",
  "& th, & td": {
    padding: theme.spacing(2),
    textAlign: "left",
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  "& th": {
    backgroundColor: "#f5f5f5",
    // color: "#1f2e5e",
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  "& td": {
    backgroundColor: "#f5f5f5",
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1f2e5e",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

function OrtaklikYapisiYatirimContents() {
  function createData(hissedar, pay, oran) {
    return { hissedar, pay, oran };
  }

  const rows = [
    createData("A Grubu", "240.303.646", "	21,08"),
    createData("B Grubu", "119.470.352,230", "10,48"),
    createData("D Grubu", "780.226.001,636", "68,44"),
    createData("TOPLAM", "1.140.000.000,000", "100,00"),
  ];

  return (
    <Box sx={{ flexGrow: 1, padding: 1 }}>
      <Header variant="h4" color="#1f2e5e">
        Ortaklık Yapısı
      </Header>

      <StyledPaper elevation={3}>
        <Box>
          <Typography
            sx={{
              borderBottom: "1px solid #19467d",
              color: "#1f2e5e",
              fontSize: "2rem",
              fontWeight: "bold",
              marginBottom: "2rem",
            }}
          >
            Kardemir Sermaye ve Ortaklık Yapısı
          </Typography>
          <SectionTitle>Kayıtlı Sermaye</SectionTitle>
          <Typography>3.000.000.000.- TL</Typography>
          <SectionTitle sx={{ marginTop: "2rem" }}>
            Çıkarılmış Sermaye
          </SectionTitle>
          <Typography>1.140.000.000.- TL</Typography>

          <Box sx={{ margin: { xs: "0", md: "2rem " } }}>
            <Box sx={{ marginTop: "1rem", width: "100%" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Hissedar</StyledTableCell>
                      <StyledTableCell>Pay</StyledTableCell>
                      <StyledTableCell>Oran</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow>
                        <StyledTableCell align="right">
                          {row.hissedar}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.pay}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.oran}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>

          <SectionTitle>
            Sermayeyi Temsil Eden Hisse Senetlerine Tanınan İmtiyazlar
          </SectionTitle>
          <Typography>
            A Grubu hissedarlar Yönetim Kurulu'na 4 üye, B Grubu hissedarlar
            Yönetim Kurulu'na 2 üye, D Grubu hissedarlar Yönetim Kurulu'na 1 üye
            seçme hakkına sahiptirler. Bunun dışında herhangi bir imtiyaz
            bulunmamaktadır.
          </Typography>
        </Box>
      </StyledPaper>
    </Box>
  );
}

export default OrtaklikYapisiYatirimContents;
