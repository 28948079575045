import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinkIcon from "@mui/icons-material/Link";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
  overflowX: "auto",
  maxWidth: 800, // Genişliği sınırlamak için maxWidth ayarlandı
  margin: "0 auto", // Merkezde hizalama sağlamak için margin kullanıldı
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

const ContentLink = styled("a")(({ theme }) => ({
  display: "block",
  marginBottom: theme.spacing(2),
  textDecoration: "none",
}));

function GenelKurulContents() {
  const sections = [
    {
      title: "2022",
      details: (
        <>
          <Box sx={{ margin: { xs: "0", md: "2rem " } }}>
            {[
              {
                title: "25.03.2022",
                details: (
                  <>
                    <Typography
                      sx={{
                        borderBottom: "1px solid #1f2e5e",
                        color: "#1f2e5e",
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      DUYURULAR
                    </Typography>
                    <ContentLink
                      style={{
                        display: "flex",
                        color: "#1f2e5e",
                        padding: "4px",
                        textDecoration: "none",
                        fontSize: "10px",
                      }}
                      href="https://www.kardemir.com/dosyalar/yatirimci/genelkurul/03032022/2022030313233894_yatirimci_genelkurul_03032022.pdf?v=9691b3b9_08d6_ceb4_ab5a_ce7a34e62db1"
                    >
                      <Typography sx={{ mr: 1, fontSize: "14px" }}>
                        25 Mart 2022 Tarihli Genel Kurul hakkındaki 1. duyuru
                        için
                      </Typography>
                      <LinkIcon fontSize="small" />
                    </ContentLink>{" "}
                    <ContentLink
                      style={{
                        display: "flex",
                        color: "#1f2e5e",
                        padding: "4px",
                        textDecoration: "none",
                        fontSize: "10px",
                      }}
                      href="https://www.kardemir.com/dosyalar/yatirimci/genelkurul/03032022/2022030313274183_yatirimci_genelkurul_03032022.pdf?v=9201623d_3109_f2fd_9002_9def4b5094ed"
                    >
                      <Typography sx={{ mr: 1, fontSize: "14px" }}>
                        25 Mart 2022 Tarihli Genel Kurul hakkındaki 2. duyuru
                        için
                      </Typography>
                      <LinkIcon fontSize="small" />
                    </ContentLink>{" "}
                    <Typography
                      sx={{
                        borderBottom: "1px solid #1f2e5e",
                        color: "#1f2e5e",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        mt: 2,
                      }}
                    >
                      TUTANAKLAR
                    </Typography>
                    <ContentLink
                      style={{
                        display: "flex",
                        color: "#1f2e5e",
                        padding: "4px",
                        textDecoration: "none",
                        fontSize: "10px",
                      }}
                      href="https://www.kardemir.com/dosyalar/yatirimci/genelkurul/05042022/2022040508054702_yatirimci_genelkurul_05042022.pdf?v=c3a493e0_94ae_4e8f_b033_b0c163c9aaa7"
                    >
                      <Typography sx={{ mr: 1, fontSize: "14px" }}>
                        25 Mart 2022 Tarihli Olağan Genel Kurul Toplantı
                        Tutanağı
                      </Typography>
                      <LinkIcon fontSize="small" />
                    </ContentLink>{" "}
                    <ContentLink
                      style={{
                        display: "flex",
                        color: "#1f2e5e",
                        padding: "4px",
                        textDecoration: "none",
                        fontSize: "10px",
                      }}
                      href="https://www.kardemir.com/dosyalar/yatirimci/genelkurul/05042022/2022040508063336_yatirimci_genelkurul_05042022.pdf?v=60ce5ef4_5132_f4e1_61b0_5f55b15fd11a"
                    >
                      <Typography sx={{ mr: 1, fontSize: "14px" }}>
                        25 Mart 2022 Tarihli Olağan Genel Kurul Toplantısı
                        Hazirun Cetveli
                      </Typography>
                      <LinkIcon fontSize="small" />
                    </ContentLink>{" "}
                  </>
                ),
              },
            ].map((section, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index + 1}-content`}
                  id={`panel${index + 1}-header`}
                >
                  <Paper
                    elevation={2}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      p: 2,
                      width: "100%",
                      mb: 1,
                      backgroundColor: "#1f2e5e",
                    }}
                  >
                    <Typography sx={{ fontWeight: 600, color: "#fff" }}>
                      {section.title}
                    </Typography>
                  </Paper>
                </AccordionSummary>
                <AccordionDetails>
                  <Paper
                    elevation={2}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      p: 2,
                      mb: 1,
                      backgroundColor: "#fff",
                    }}
                  >
                    <Typography>{section.details}</Typography>
                  </Paper>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </>
      ),
    },

    {
      title: "2023",
      details: (
        <>
          <Box sx={{ margin: { xs: "0", md: "2rem " } }}>
            {[
              {
                title: "03.04.2023",
                details: (
                  <>
                    <Typography
                      sx={{
                        borderBottom: "1px solid #1f2e5e",
                        color: "#1f2e5e",
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      DUYURULAR
                    </Typography>
                    <ContentLink
                      style={{
                        display: "flex",
                        color: "#1f2e5e",
                        padding: "4px",
                        textDecoration: "none",
                        fontSize: "10px",
                      }}
                      href="https://www.kardemir.com/dosyalar/yatirimci/genelkurul/08032023/2023030817165506_yatirimci_genelkurul_08032023.pdf?v=eeaf7beb_722d_18c8_0e46_eede514c39cc"
                    >
                      <Typography sx={{ mr: 1, fontSize: "14px" }}>
                        3 Nisan 2023 Tarihli Genel Kurul hakkındaki 1. duyuru
                        için
                      </Typography>
                      <LinkIcon fontSize="small" />
                    </ContentLink>{" "}
                    <ContentLink
                      style={{
                        display: "flex",
                        color: "#1f2e5e",
                        padding: "4px",
                        textDecoration: "none",
                        fontSize: "10px",
                      }}
                      href="https://www.kardemir.com/dosyalar/yatirimci/genelkurul/08032023/2023030817113035_yatirimci_genelkurul_08032023.pdf?v=bd916ad7_d24b_01c0_96a9_a890ab70d838"
                    >
                      <Typography sx={{ mr: 1, fontSize: "14px" }}>
                        3 Nisan 2023 Tarihli Genel Kurul hakkındaki 2. duyuru
                        için
                      </Typography>
                      <LinkIcon fontSize="small" />
                    </ContentLink>{" "}
                    <Typography
                      sx={{
                        borderBottom: "1px solid #1f2e5e",
                        color: "#1f2e5e",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        mt: 2,
                      }}
                    >
                      GÜNDEMLER
                    </Typography>
                    <ContentLink
                      style={{
                        display: "flex",
                        color: "#1f2e5e",
                        padding: "4px",
                        textDecoration: "none",
                        fontSize: "10px",
                      }}
                      href="https://www.kardemir.com/dosyalar/yatirimci/genelkurul/08032023/2023030817260252_yatirimci_genelkurul_08032023.pdf?v=3ffb13e8_33a9_c523_0151_5a7057319542"
                    >
                      <Typography sx={{ mr: 1, fontSize: "14px" }}>
                        3 Nisan 2023 Tarihli Genel Kurul Toplantı Gündemi
                      </Typography>
                      <LinkIcon fontSize="small" />
                    </ContentLink>{" "}
                    <Typography
                      sx={{
                        borderBottom: "1px solid #1f2e5e",
                        color: "#1f2e5e",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        mt: 2,
                      }}
                    >
                      TUTANAKLAR
                    </Typography>
                    <ContentLink
                      style={{
                        display: "flex",
                        color: "#1f2e5e",
                        padding: "4px",
                        textDecoration: "none",
                        fontSize: "10px",
                      }}
                      href="https://www.kardemir.com/dosyalar/yatirimci/genelkurul/04042023/2023040410125830_yatirimci_genelkurul_04042023.pdf?v=5d75aed5_1242_a5f9_77c0_065a732866b9"
                    >
                      <Typography sx={{ mr: 1, fontSize: "14px" }}>
                        3 Nisan 2023 Tarihli Olağan Genel Kurul Toplantı
                        Tutanağı
                      </Typography>
                      <LinkIcon fontSize="small" />
                    </ContentLink>{" "}
                    <ContentLink
                      style={{
                        display: "flex",
                        color: "#1f2e5e",
                        padding: "4px",
                        textDecoration: "none",
                        fontSize: "10px",
                      }}
                      href="https://www.kardemir.com/dosyalar/yatirimci/genelkurul/04042023/2023040410124422_yatirimci_genelkurul_04042023.pdf?v=3ade717c_b121_801b_d708_84adb93cdd4e"
                    >
                      <Typography sx={{ mr: 1, fontSize: "14px" }}>
                        3 Nisan 2023 Tarihli Olağan Genel Kurul Toplantısı
                        Hazirun Cetveli
                      </Typography>
                      <LinkIcon fontSize="small" />
                    </ContentLink>{" "}
                  </>
                ),
              },
              {
                title: "23.10.2023",
                details: (
                  <>
                    <Typography
                      sx={{
                        borderBottom: "1px solid #1f2e5e",
                        color: "#1f2e5e",
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      DUYURULAR
                    </Typography>
                    <ContentLink
                      style={{
                        display: "flex",
                        color: "#1f2e5e",
                        padding: "4px",
                        textDecoration: "none",
                        fontSize: "10px",
                      }}
                      href="https://www.kardemir.com/dosyalar/yatirimci/genelkurul/28092023/2023092817364090_yatirimci_genelkurul_28092023.pdf?v=34869710_7780_e6f9_888b_a5c9bd455d0b"
                    >
                      <Typography sx={{ mr: 1, fontSize: "14px" }}>
                        23 Ekim 2023 Tarihli Genel Kurul hakkındaki 1. duyuru
                        için
                      </Typography>
                      <LinkIcon fontSize="small" />
                    </ContentLink>{" "}
                    <Typography
                      sx={{
                        borderBottom: "1px solid #1f2e5e",
                        color: "#1f2e5e",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        mt: 2,
                      }}
                    >
                      TUTANAKLAR
                    </Typography>
                    <ContentLink
                      style={{
                        display: "flex",
                        color: "#1f2e5e",
                        padding: "4px",
                        textDecoration: "none",
                        fontSize: "10px",
                      }}
                      href="https://www.kardemir.com/dosyalar/yatirimci/genelkurul/24102023/2023102410514801_yatirimci_genelkurul_24102023.pdf?v=2af3c8c9_8415_0723_3492_485bdab6d3c2"
                    >
                      <Typography sx={{ mr: 1, fontSize: "14px" }}>
                        23 Ekim 2023 Tarihli Olağanüstü Genel Kurul Toplantı
                        Tutanağı
                      </Typography>
                      <LinkIcon fontSize="small" />
                    </ContentLink>{" "}
                    <ContentLink
                      style={{
                        display: "flex",
                        color: "#1f2e5e",
                        padding: "4px",
                        textDecoration: "none",
                        fontSize: "10px",
                      }}
                      href="https://www.kardemir.com/dosyalar/yatirimci/genelkurul/24102023/2023102411103564_yatirimci_genelkurul_24102023.pdf?v=2d330d93_f4b0_036d_8070_82fdbacb85a8"
                    >
                      <Typography sx={{ mr: 1, fontSize: "14px" }}>
                        23 Ekim 2023 Tarihli Olağanüstü Genel Kurul Toplantısı
                        Hazirun Cetveli
                      </Typography>
                      <LinkIcon fontSize="small" />
                    </ContentLink>{" "}
                  </>
                ),
              },
            ].map((section, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index + 1}-content`}
                  id={`panel${index + 1}-header`}
                >
                  <Paper
                    elevation={2}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      p: 2,
                      width: "100%",
                      mb: 1,
                      backgroundColor: "#1f2e5e",
                    }}
                  >
                    <Typography sx={{ fontWeight: 600, color: "#fff" }}>
                      {section.title}
                    </Typography>
                  </Paper>
                </AccordionSummary>
                <AccordionDetails>
                  <Paper
                    elevation={2}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      p: 2,
                      mb: 1,
                      backgroundColor: "#fff",
                    }}
                  >
                    <Typography>{section.details}</Typography>
                  </Paper>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </>
      ),
    },

    {
      title: "2024",
      details: (
        <>
          <Box sx={{ margin: { xs: "0", md: "2rem " } }}>
            {[
              {
                title: "30.05.2024",
                details: (
                  <>
                    <Typography
                      sx={{
                        borderBottom: "1px solid #1f2e5e",
                        color: "#1f2e5e",
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      DUYURULAR
                    </Typography>
                    <ContentLink
                      style={{
                        display: "flex",
                        color: "#1f2e5e",
                        padding: "4px",
                        textDecoration: "none",
                        fontSize: "10px",
                      }}
                      href="https://www.kardemir.com/dosyalar/yatirimci/genelkurul/08052024/2024050816145778_yatirimci_genelkurul_08052024.pdf?v=58fabd59_0ff5_01c4_2bcc_616482f64270"
                    >
                      <Typography sx={{ mr: 1, fontSize: "14px" }}>
                        30 Mayıs 2024 Tarihli Genel Kurul hakkındaki 1. duyuru
                        için
                      </Typography>
                      <LinkIcon fontSize="small" />
                    </ContentLink>{" "}
                    <ContentLink
                      style={{
                        display: "flex",
                        color: "#1f2e5e",
                        padding: "4px",
                        textDecoration: "none",
                        fontSize: "10px",
                      }}
                      href="https://www.kardemir.com/dosyalar/yatirimci/genelkurul/08052024/2024050816165026_yatirimci_genelkurul_08052024.pdf?v=4076e790_09dd_29fc_2980_6e0a2187864b"
                    >
                      <Typography sx={{ mr: 1, fontSize: "14px" }}>
                        30 Mayıs 2024 Tarihli Genel Kurul hakkındaki 2. duyuru
                        için
                      </Typography>
                      <LinkIcon fontSize="small" />
                    </ContentLink>{" "}
                    <Typography
                      sx={{
                        borderBottom: "1px solid #1f2e5e",
                        color: "#1f2e5e",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        mt: 2,
                      }}
                    >
                      TUTANAKLAR
                    </Typography>
                    <ContentLink
                      style={{
                        display: "flex",
                        color: "#1f2e5e",
                        padding: "4px",
                        textDecoration: "none",
                        fontSize: "10px",
                      }}
                      href="https://www.kardemir.com/dosyalar/yatirimci/genelkurul/04062024/2024060411593960_yatirimci_genelkurul_04062024.pdf?v=29445b9f_9ba3_9ee7_d441_d46aea627d59"
                    >
                      <Typography sx={{ mr: 1, fontSize: "14px" }}>
                        30 Mayıs 2024 Tarihli Olağan Genel Kurul Toplantı
                        Tutanağı
                      </Typography>
                      <LinkIcon fontSize="small" />
                    </ContentLink>{" "}
                    <ContentLink
                      style={{
                        display: "flex",
                        color: "#1f2e5e",
                        padding: "4px",
                        textDecoration: "none",
                        fontSize: "10px",
                      }}
                      href="https://www.kardemir.com/dosyalar/yatirimci/genelkurul/04062024/2024060412012949_yatirimci_genelkurul_04062024.pdf?v=b66012fc_35e2_7d6a_305a_ef65cbc57353"
                    >
                      <Typography sx={{ mr: 1, fontSize: "14px" }}>
                        30 Mayıs 2024 Tarihli Olağan Genel Kurul Toplantısı
                        Hazirun Cetveli
                      </Typography>
                      <LinkIcon fontSize="small" />
                    </ContentLink>{" "}
                  </>
                ),
              },
            ].map((section, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index + 1}-content`}
                  id={`panel${index + 1}-header`}
                >
                  <Paper
                    elevation={2}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      p: 2,
                      width: "100%",
                      mb: 1,
                      backgroundColor: "#1f2e5e",
                    }}
                  >
                    <Typography sx={{ fontWeight: 600, color: "#fff" }}>
                      {section.title}
                    </Typography>
                  </Paper>
                </AccordionSummary>
                <AccordionDetails>
                  <Paper
                    elevation={2}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      p: 2,
                      mb: 1,
                      backgroundColor: "#fff",
                      // color: "black",
                    }}
                  >
                    <Typography>{section.details}</Typography>
                  </Paper>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </>
      ),
    },
  ];
  return (
    <Box sx={{ flexGrow: 1, padding: 1 }}>
      <Header variant="h4" color="#1f2e5e">
        Genel Kurul
      </Header>

      <StyledPaper elevation={3} sx={{ marginBottom: "2rem" }}>
        <Typography
          sx={{
            borderBottom: "1px solid #19467d",
            color: "#1f2e5e",
            fontSize: "2rem",
            fontWeight: "bold",
          }}
        >
          Genel Kurullar
        </Typography>
        <Box sx={{ margin: { xs: "0", md: "2rem " } }}>
          {sections.map((section, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index + 1}-content`}
                id={`panel${index + 1}-header`}
              >
                <Paper
                  elevation={2}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    p: 2,
                    width: "100%",
                    backgroundColor: "#1f2e5e",
                  }}
                >
                  <Typography sx={{ fontWeight: 600, color: "#fff" }}>
                    {section.title}
                  </Typography>
                </Paper>
              </AccordionSummary>
              <AccordionDetails>
                <Paper
                  elevation={2}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    mb: 1,
                    backgroundColor: "#fff",
                  }}
                >
                  <Typography>{section.details}</Typography>
                </Paper>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </StyledPaper>

      <StyledPaper elevation={3}>
        <Typography
          sx={{
            borderBottom: "1px solid #19467d",

            color: "#1f2e5e",
            fontSize: "2rem",
            fontWeight: "bold",
            marginBottom: "2rem",
          }}
        >
          Genel Kurul Soru ve Cevapları{" "}
        </Typography>

        <ContentLink
          href="https://www.kardemir.com/Dosyalar/yatirimciiliskileri/Politikalar/BilgilendirmePolitikasi.pdf"
          target="_blank"
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#1f2e5e",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#1f2e5e",
              },
            }}
            fullWidth
          >
            2019 Yılına Ait Genel Kurulda Sorulan Sorular ve Verilen Cevaplar
          </Button>
        </ContentLink>
      </StyledPaper>
    </Box>
  );
}

export default GenelKurulContents;
