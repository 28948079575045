import React, { useState } from "react";
import SolYapiYatirimci from "./SolYapiYatirimci";

function Politikalar() {
  const [page, setPage] = useState("Politikalar ve Tahvil İhraç Belgeleri");

  return <SolYapiYatirimci setPage={setPage} page={page} />;
}

export default Politikalar;
