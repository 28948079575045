import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import kariyerfoto from "../../images/kariyerfoto.jpg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const infoBoxes = [
  {
    imgSrc:
      "https://www.kardemir.com/dosyalar/slider/28042020/2020042816123007_slider_28042020.jpg?v=13bba5b0_60df_c50b_4094_6fe9f07a11bf",
    title: "2024 Yılı İkili Mesleki Eğitim Ön Kayıtları Başladı",
    description:
      " Şirketimizin sürdürmekte olduğu İkili Mesleki Eğitim Programı için 2024 yılı ön kayıt başvuruları başlıyor...",
  },
  {
    imgSrc:
      "https://www.kardemir.com/dosyalar/slider/28042020/2020042816123007_slider_28042020.jpg?v=13bba5b0_60df_c50b_4094_6fe9f07a11bf",
    title: "”AR-GE Mühendisi” İşe Alım Sonuçları Kamuoyu Bilgilendirmesi",
    description:
      "Şirketimizde AR-GE Mühendisi pozisyonu için yapılan işe alım sürecine ilişkin ilgili işlemler...",
  },
  {
    imgSrc:
      "https://www.kardemir.com/dosyalar/slider/28042020/2020042816123007_slider_28042020.jpg?v=13bba5b0_60df_c50b_4094_6fe9f07a11bf",
    title: "Dijital Dönüşüm ve Bilgi Teknolojileri Direktörlüğü - İş İlanı",
    description:
      "KARDEMİR Dijital Dönüşüm ve Bilgi Teknolojileri Direktörlüğü bünyesinde, on yedi (17)...",
  },
  {
    imgSrc:
      "https://www.kardemir.com/dosyalar/slider/28042020/2020042816123007_slider_28042020.jpg?v=13bba5b0_60df_c50b_4094_6fe9f07a11bf",
    title: "Olağan Genel Kurul Toplantısı",
    description:
      "Şirketimizin 2023 yılı faaliyetlerinin ele alınacağı Olağan Genel Kurul Toplantısı 30.05.2024 tarihinde saat 10:00'da Kardemir Eğitim...",
  },
];

function Medya() {
  const handleClick = () => {
    window.location.href = "/haberler";
  };
  const handleClick2 = () => {
    window.location.href = "/galeri";
  };
  return (
    <>
      <Grid sx={{ margin: "1rem 6.5%" }}>
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          sx={{ color: "#d11f25" }}
        >
          Medya Merkezi
        </Typography>
        <Typography align="center">
          Kardemir Medya Merkezi'ne hoş geldiniz! Burada, şirketimizin en güncel
          ve ilgi çekici içeriklerini keşfedebilirsiniz. Galeri bölümümüzde,
          Kardemir'in çeşitli projelerinden ve etkinliklerinden fotoğraflarla
          dolu zengin bir koleksiyon bulacaksınız. Haberler bölümünde ise,
          sektörle ilgili önemli gelişmeler ve Kardemir'in başarılarına dair
          güncel bilgileri takip edebilirsiniz. Medya Merkezi, Kardemir'in
          dinamik dünyasını ve topluma katkılarını yakından görme fırsatı sunar.
        </Typography>
      </Grid>

      <Grid sx={{ margin: "1rem 4.5%" }}>
        <Box
          sx={{
            position: "relative",
            mb: 4,
            mt: 5,
            mx: { xl: 5 },
            textTransform: "uppercase",
          }}
        >
          {/* <Typography variant="h3" component="span">
            HABERLER
          </Typography> */}
        </Box>

        <Box sx={{ pt: 5, pb: 3, mt: 5 }}>
          <Grid container spacing={4} px={{ xl: 5 }}>
            {infoBoxes.map((box, index) => (
              <Grid item lg={3} md={6} sm={12} key={index} mb={2}>
                <Box
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "32rem",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                    transition: "transform 0.3s",
                    "&:hover": {
                      transform: "scale(1.07)",
                      border: "none",
                    },
                  }}
                >
                  <img
                    src="https://www.kardemir.com/images/anasayfa/bgtopmenuleft.png"
                    alt="img"
                    style={{ width: "100%", marginBottom: "10px" }}
                  />

                  <img
                    src={box.imgSrc}
                    alt={box.title}
                    style={{ width: "80%", marginBottom: "10px" }}
                  />
                  <Typography variant="h5" sx={{ mb: 1 }}>
                    {box.title}
                  </Typography>
                  <Typography variant="body1">{box.description}</Typography>
                  <ArrowForwardIcon sx={{ mt: 2 }} />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Button
          variant="contained"
          sx={{
            p: 2,
            display: "flex",
            margin: "auto",
            backgroundColor: "#d11f25",
            color: "#fff",
            transition: "transform 0.3s",
            "&:hover": {
              backgroundColor: "#1f2e5e",
              transform: "scale(1.1) ",
            },
          }}
          onClick={handleClick}
        >
          Tüm Haber ve Bültenler
        </Button>
      </Grid>

      <Box
        sx={{
          backgroundColor: "#1f2e5e",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          margin: "8rem 0 2rem 0",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: { xs: "95%", lg: "40%" },
            color: "#fff",
            display: "flex",
            flexDirection: "column",
            padding: { xs: "0.5rem", lg: "8rem 1rem" },
          }}
        >
          <Typography variant="h4" textAlign={"center"}>
            KARDEMİR GALERİMİZ
          </Typography>
          <br />
          <Typography sx={{ textAlign: "justify" }}>
            Kardemir olarak, sektördeki liderliğimizi ve başarılarımızı sizlerle
            paylaşmaktan büyük mutluluk duyuyoruz. Galerimizde, hem şirketimizin
            tarihine hem de güncel projelerimize dair önemli anları ve başarı
            hikayelerini bulabilirsiniz. İnovatif projelerimiz, etkileyici
            altyapılarımız ve sosyal sorumluluk projelerimiz hakkında daha fazla
            bilgi edinmek için galerimizi keşfedin. Her bir fotoğraf,
            Kardemir’in gelişim sürecini ve topluma katkısını yansıtıyor. <br />{" "}
            <br />
            Daha fazla bilgi ve güncel projelerimiz hakkında detaylı içerik için
            galerimizi düzenli olarak ziyaret edin ve Kardemir’in dinamizmini ve
            başarısını yakından görün.
          </Typography>
          <Box
            sx={{
              width: "100%",
              marginTop: "2rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                p: 2,
                display: "flex",

                margin: "0",
                borderRadius: "40px",
                backgroundColor: "#d11f25",
                color: "#fff",
                transition: "transform 0.3s",
                "&:hover": {
                  backgroundColor: "#1f2e5e",
                  transform: "scale(1.1) ",
                },
              }}
              onClick={handleClick2}
            >
              GALERİ
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            margin: { xs: "2rem", lg: "4rem 4rem 4rem 2rem" },
            height: "20%",
            width: { xs: "85%", lg: "60%" },
            // backgroundColor: "yellow",
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            style={{ height: { xs: "40%", lg: "60%" }, width: "100%" }}
            src={kariyerfoto}
            alt="Kardemirde Çalışmak"
          />
        </Box>
      </Box>
    </>
  );
}

export default Medya;
