import React, { useEffect, useState } from "react";
import image from "../../images/sürdürülebilirlikBanner.jpg";
import {
  Box,
  Breadcrumbs,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import SurdurulebilirlikYonetimiContents from "./SurdurulebilirlikYonetimiContents";
import RaporveSertifikaContents from "./RaporveSertifikaContents";
import ArgeVeInovasyonContents from "./ArgeVeInovasyonContents";
import EnerjiYonetimiContents from "./EnerjiYonetimiContents";
import CevreVeIklimContents from "./CevreVeIklimContents";
import IsDevamliligiVeYatirimlarContents from "./IsDevamliligiVeYatirimlarContents";

function SolYapiSurdurulebilirlik({ page, setPage }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [currentContent, setCurrentContent] = useState();
  const navigate = useNavigate();

  const handleItemClick = (pageName, content) => {
    setPage(pageName);
    setCurrentContent(content);

    if (pageName === "Sürdürülebilirlik Yönetimi") {
      navigate("/surdurulebilirlik-yonetimi");
    } else if (pageName === "Rapor ve Sertifikalarımız") {
      navigate("/rapor-ve-sertifikalarimiz");
    } else if (pageName === "AR-GE ve İnovasyon") {
      navigate("/arge-ve-inovasyon");
    } else if (pageName === "Enerji Yönetimi") {
      navigate("/enerji-yonetimi");
    } else if (pageName === "Çevre ve İklim") {
      navigate("/cevre-ve-iklim");
    } else if (pageName === "İş Devamlılığı ve Yatırımlar") {
      navigate("/is-devamliliği-ve-yatirimlar");
    }
  };
  return (
    <>
      <Box
        component="img"
        alt=""
        src={image}
        style={{
          marginTop: "130px",
          width: "100%",
          height: "30rem",
          objectFit: "cover",
          objectPosition: "bottom",
        }}
      />

      <Box
        sx={{
          borderBottom: "1px solid lightgray",
          height: "5rem",
          display: "flex",
          alignItems: "center",
          paddingLeft: "1rem",
          justifyContent: "center",
        }}
      >
        {" "}
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link
            href="/"
            style={{
              color: "gray",
              padding: "8px",
              textDecoration: "none",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            Ana Sayfa
          </Link>
          <Link
            href=""
            style={{
              color: "gray",
              padding: "8px",
              textDecoration: "none",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            Sürdürülebilirlik
          </Link>
          <Link
            href=""
            style={{
              color: "black",
              padding: "8px",
              textDecoration: "none",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            {page}
          </Link>
        </Breadcrumbs>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: "space-between",
          padding: { xs: "1rem", lg: "1rem 0 1rem 1rem" },
        }}
      >
        <Box
          sx={{
            borderRight: { xs: "none", lg: "1px solid gray" },
            marginBottom: "2rem",
            paddingRight: { xs: "0rem", lg: "2rem" },
          }}
        >
          <Paper
            sx={{
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              padding: "2rem",
              width: { xs: "auto", lg: "80%" },
            }}
          >
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "1.5rem",
                textDecoration: "underline",
              }}
            >
              Sürdürülebilirlik
            </Typography>

            <List>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleItemClick("Sürdürülebilirlik Yönetimi")}
                >
                  <ListItemText primary="Sürdürülebilirlik Yönetimi" />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleItemClick("Rapor ve Sertifikalarımız")}
                >
                  <ListItemText primary="Rapor ve Sertifikalarımız" />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleItemClick("AR-GE ve İnovasyon")}
                >
                  <ListItemText primary="AR-GE ve İnovasyon" />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleItemClick("Enerji Yönetimi")}
                >
                  <ListItemText primary="Enerji Yönetimi" />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleItemClick("Çevre ve İklim")}
                >
                  <ListItemText primary="Çevre ve İklim" />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton
                  onClick={() =>
                    handleItemClick("İş Devamlılığı ve Yatırımlar")
                  }
                >
                  <ListItemText primary="İş Devamlılığı ve Yatırımlar" />
                </ListItemButton>
              </ListItem>
            </List>
          </Paper>
        </Box>

        <Box
          sx={{
            marginLeft: { xs: "0", lg: "2rem" },
            flex: 1,
          }}
        >
          {page === "Sürdürülebilirlik Yönetimi" && (
            <>
              {/* <Typography variant="h4">Sürdürülebilirlik Yönetimi</Typography> */}
              <SurdurulebilirlikYonetimiContents content={currentContent} />
            </>
          )}

          {page === "Rapor ve Sertifikalarımız" && (
            <>
              {/* <Typography variant="h4">Rapor ve Sertifikalarımız</Typography> */}
              <RaporveSertifikaContents content={currentContent} />
            </>
          )}

          {page === "AR-GE ve İnovasyon" && (
            <ArgeVeInovasyonContents content={currentContent} />
          )}

          {page === "Enerji Yönetimi" && (
            <EnerjiYonetimiContents content={currentContent} />
          )}

          {page === "Çevre ve İklim" && (
            <CevreVeIklimContents content={currentContent} />
          )}

          {page === "İş Devamlılığı ve Yatırımlar" && (
            <IsDevamliligiVeYatirimlarContents content={currentContent} />
          )}
        </Box>
      </Box>
    </>
  );
}

export default SolYapiSurdurulebilirlik;
