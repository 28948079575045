import { Box, Typography, Link } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

function AltSatir() {
  return (
    <Box
      sx={{
        backgroundColor: " #1f2e5e",
        height: "5rem",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        // marginTop: "2rem",
        textAlign: "center",
      }}
    >
      <Typography sx={{ color: "#e5e5e5" }}>
        <Link
          component={RouterLink}
          to="/kvkk"
          sx={{
            color: "#e5e5e5",
            textTransform: "none",
            padding: 0,

            textDecoration: "none",
          }}
        >
          KVKK
        </Link>
        {" | "}
        {/* <Link
          component={RouterLink}
          to="/bagli-ortakliklar-ve-istirakler"
          sx={{
            color: "#e5e5e5",
            textTransform: "none",
            padding: 0,

            textDecoration: "none",
          }}
        >
          Bağlı Ortaklıklar ve İştirakler
        </Link>
        {" | "} */}
        <Link
          component={RouterLink}
          to="/yasal-uyarilar"
          sx={{
            color: "#e5e5e5",
            textTransform: "none",
            padding: 0,

            textDecoration: "none",
          }}
        >
          Yasal Uyarılar
        </Link>
        {" | "}
        <Link
          component={RouterLink}
          to="/cerez-politikasi"
          sx={{
            color: "#e5e5e5",
            textTransform: "none",
            padding: 0,

            textDecoration: "none",
          }}
        >
          Çerez Politikası
        </Link>
        {" | "}
        <Link
          component={RouterLink}
          to="/site-haritasi"
          sx={{
            color: "#e5e5e5",
            textTransform: "none",
            padding: 0,

            textDecoration: "none",
          }}
        >
          Site Haritası
        </Link>
        {" | "}
        <Link
          component={RouterLink}
          to="/iletisim"
          sx={{
            color: "#e5e5e5",
            textTransform: "none",
            padding: 0,

            textDecoration: "none",
          }}
        >
          İletişim
        </Link>
      </Typography>
    </Box>
  );
}

export default AltSatir;
