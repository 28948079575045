import React, { useState } from "react";
import SolYapiKurumsal from "./SolYapiKurumsal";

function KilometreTaslari1() {
  const [page, setPage] = useState("Kilometre Taşları 1");

  return (
    <>
      <SolYapiKurumsal setPage={setPage} page={page} />
    </>
  );
}

export default KilometreTaslari1;
