import { Box, Button, Paper, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
  overflowX: "auto",
  maxWidth: 600, // Genişliği sınırlamak için maxWidth ayarlandı
  margin: "0 auto", // Merkezde hizalama sağlamak için margin kullanıldı
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

const ContentLink = styled("a")(({ theme }) => ({
  display: "block",
  marginBottom: theme.spacing(2),
  textDecoration: "none",
}));

function PolitikalarContents() {
  return (
    <Box sx={{ flexGrow: 1, padding: 1 }}>
      <Header variant="h4" color="#1f2e5e">
        Politikalar ve Tahvil İhraç Belgeleri
      </Header>

      <StyledPaper elevation={3} sx={{ marginBottom: "2rem" }}>
        <Typography
          sx={{
            borderBottom: "1px solid #19467d",
            color: "#1f2e5e",
            fontSize: "2rem",
            fontWeight: "bold",
            marginBottom: "2rem",
          }}
        >
          Politikalar
        </Typography>

        <ContentLink
          href="https://www.kardemir.com/Dosyalar/yatirimciiliskileri/Politikalar/BilgilendirmePolitikasi.pdf"
          target="_blank"
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#1f2e5e",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#1f2e5e",
              },
            }}
            fullWidth
          >
            Bilgilendirme Politikası’na buradan ulaşabilirsiniz.
          </Button>
        </ContentLink>

        <ContentLink
          href="https://www.kardemir.com/Dosyalar/yatirimciiliskileri/Politikalar/UcretPolitikasi.pdf"
          target="_blank"
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#1f2e5e",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#1f2e5e",
              },
            }}
            fullWidth
          >
            Ücret Politikasını görüntülemek için tıklayın.
          </Button>
        </ContentLink>

        <ContentLink
          href="https://www.kardemir.com/Dosyalar/yatirimciiliskileri/Politikalar/KarDagitimPolitikasi.pdf"
          target="_blank"
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#1f2e5e",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#1f2e5e",
              },
            }}
            fullWidth
          >
            Kar Dağıtım Politikasını görüntülemek için tıklayın.
          </Button>
        </ContentLink>

        <ContentLink
          href="https://www.kardemir.com/Dosyalar/yatirimciiliskileri/Politikalar/BagisYardimPolitikasi.pdf"
          target="_blank"
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#1f2e5e",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#1f2e5e",
              },
            }}
            fullWidth
          >
            Bağış ve Yardım Politikasını görüntülemek için tıklayın.
          </Button>
        </ContentLink>
      </StyledPaper>

      <StyledPaper elevation={3}>
        <Typography
          sx={{
            borderBottom: "1px solid #19467d",

            color: "#1f2e5e",
            fontSize: "2rem",
            fontWeight: "bold",
            marginBottom: "2rem",
          }}
        >
          Tahvil İhraç Belgeleri
        </Typography>
        <ContentLink
          href="https://www.kardemir.com/Dosyalar/Yatirimci/Tahvil/TahvilIhracBelgesi_28042015.pdf"
          target="_blank"
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#1f2e5e",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#1f2e5e",
              },
            }}
            fullWidth
          >
            28.04.2015 Tarihli Tahvil İhraç Belgelerine buradan ulaşabilirsiniz.
          </Button>
        </ContentLink>

        <ContentLink
          href="https://www.kardemir.com/Dosyalar/Yatirimci/Tahvil/TahvilIhracBelgesi_7102016.pdf"
          target="_blank"
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#1f2e5e",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#1f2e5e",
              },
            }}
            fullWidth
          >
            7.10.2016 Tarihli Tahvil İhraç Belgelerine buradan ulaşabilirsiniz.
          </Button>
        </ContentLink>
      </StyledPaper>
    </Box>
  );
}

export default PolitikalarContents;
