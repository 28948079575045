import React, { useState } from "react";
import SolYapiKurumsal from "./SolYapiKurumsal";

function Hakkımızda() {
  const [page, setPage] = useState("Hakkımızda");

  return (
    <>
      <SolYapiKurumsal setPage={setPage} page={page} />
    </>
  );
}

export default Hakkımızda;
