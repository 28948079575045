import React, { useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Link } from "react-router-dom";
import demiryolutekeri from "../../images/demiryolutekeri.jpg";
import demiryoluraylari from "../../images/demiryoluraylari.png";
import profiller from "../../images/profiller.png";
import heaprofiller from "../../images/heaprofiller.png";
import hebprofiller from "../../images/hebprofiller.png";
import ıpeprofiller from "../../images/ıpeprofiller.png";
import npuprofiller from "../../images/npuprofiller.png";
import gıthmadendirekleri from "../../images/gıthmadendirekleri.png";
import platinalar from "../../images/platinalar.jpg";
import kangallar from "../../images/kangallar.png";
import duzyuvarlaklar from "../../images/duzyuvarlaklar.png";
import nervurlubetondonati from "../../images/nervurlubetondonati.png";
import kutukler from "../../images/kutukler.png";
import kokurunleri from "../../images/kokurunleri.png";
import granuleyuksekfirincurufu from "../../images/granuleyuksekfirincurufu.png";
import merosatigi from "../../images/merosatigi.png";
import metalurjkkirec from "../../images/metalurjkkirec.jpg";
import havaayristirmatesisiurunleri from "../../images/havaayristirmatesisiurunleri.png";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  height: "20rem",
  objectFit: "cover",
  // margin: "2rem",
  backgroundColor: "#1f2e5e",
  color: theme.palette.text.secondary,
  perspective: "1000px",
  overflow: "hidden",
  "&:hover .inner": {
    transform: "rotateY(180deg) translateY(-5%)",
  },
  "&:hover": {
    backgroundColor: " #1f2e5e",
  },
}));

const Inner = styled("div")({
  position: "relative",
  width: "100%",
  height: "100%",
  textAlign: "center",
  transition: "transform 0.6s",
  transformStyle: "preserve-3d",
});

const Front = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  position: "absolute",
  width: "100%",
  height: "100%",
  backfaceVisibility: "hidden",
}));

const Back = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  position: "absolute",
  width: "100%",
  height: "100%",
  transform: "rotateY(180deg)",
  backfaceVisibility: "hidden",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const ImageContainer = styled("div")({
  position: "relative",
  width: "100%",
  height: "100%",
  overflow: "hidden",
});

const ImageText = styled(Typography)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  width: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  color: "#fff",
  textAlign: "center",
  padding: theme.spacing(1),
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

const urunlerContent = [
  {
    img: demiryolutekeri,
    ad: "Demiryolu Tekerleği",
    kaliteStandardi: "EN 13262+A2",
    celikKalitesi: ["ER7", "ER8"],
    boyutStandardi: "",
    description: "Boyut ve ölçü standartları tablosuna ulaşmak için tıklayın.",
    link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_0001.pdf",
  },
  {
    img: demiryoluraylari,
    ad: "Demiryolu Raylari",
    kaliteStandardi: "",
    celikKalitesi: [],
    description: "Boyut ve ölçü standartları tablosuna ulaşmak için tıklayın.",
    link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00002.pdf",
  },
  {
    img: profiller,
    ad: "I Profiller",
    kaliteStandardi: "TS EN 10025-1/2",
    boyutStandardi: "TS EN 10024",
    description: "Boyut ve ölçü standartları tablosuna ulaşmak için tıklayın.",
    link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00003.pdf",
  },
  {
    img: heaprofiller,
    ad: "HEA Profiller",
    kaliteStandardi: "EN 10025-1/2",
    boyutStandardi: ["EN 10034"],
    description: "Boyut ve ölçü standartları tablosuna ulaşmak için tıklayın.",
    link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00004.pdf",
  },

  {
    img: hebprofiller,
    ad: "HEB Profiller",
    boyutStandardi: "EN 10034",
    celikKalitesi: ["EN 10025-1/2"],
    description: "Boyut ve ölçü standartları tablosuna ulaşmak için tıklayın.",
    link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00005.pdf",
  },
  {
    img: ıpeprofiller,
    ad: "IPE Profiller-Orta Geniş I Profiller/Takviyeli",
    boyutStandardi: "EN 10034",
    celikKalitesi: "",
    kaliteStandardi: "EN 10025-1/2",
    description: "Boyut ve ölçü standartları tablosuna ulaşmak için tıklayın.",
    link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00006.pdf",
  },
  {
    img: npuprofiller,
    ad: "NPU Profiller",
    boyutStandardi: "EN 10279",
    celikKalitesi: "",
    kaliteStandardi: "EN 10025-1/2",
    description: "Boyut ve ölçü standartları tablosuna ulaşmak için tıklayın.",
    link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00007.pdf",
  },
  {
    img: gıthmadendirekleri,
    ad: "GI ve TH Maden Direkleri",
    boyutStandardi: "DIN 21530",
    celikKalitesi: "",
    kaliteStandardi: "EN 10025",
    description: "Boyut ve ölçü standartları tablosuna ulaşmak için tıklayın.",
    link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00008.pdf",
  },
  {
    img: platinalar,
    ad: "Platinalar",
    boyutStandardi: "DIN 59200 / TS 3061 ISO 9034",
    celikKalitesi: "",
    kaliteStandardi: "EN 10025 1/2",
    description: "Boyut ve ölçü standartları tablosuna ulaşmak için tıklayın.",
    link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00009.pdf",
  },
  {
    img: kangallar,
    ad: "Kangallar",
    boyutStandardi: "TS EN 10060 / TS EN 10221 / Müşteri talebine bağlı",
    yuzeyKalitesiStandardi: "TS EN 10221 / Müşteri talebine bağlı",
    kaliteStandardi: "Müşteri talebine bağlı",
    description: "Boyut ve ölçü standartları tablosuna ulaşmak için tıklayın.",
    link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00010.pdf",
  },
  {
    img: duzyuvarlaklar,
    ad: "Düz Yuvarlaklar",
    boyutStandardi: "",
    celikKalitesi: "",
    kaliteStandardi: "",
    description: "Boyut ve ölçü standartları tablosuna ulaşmak için tıklayın.",
    link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00011.pdf",
  },
  {
    img: nervurlubetondonati,
    ad: "Nervürlü - Beton Donatı Çelikleri",
    boyutStandardi: "TS 708",
    celikKalitesi: "",
    kaliteStandardi: "TS 708",
    description: "Boyut ve ölçü standartları tablosuna ulaşmak için tıklayın.",
    link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00012.pdf",
  },
  {
    img: kutukler,
    ad: "Kütükler",
    boyutStandardi: "TS 9914 / TS 9016",
    celikKalitesi: "",
    kaliteStandardi: "EN 10025 / TS 708 / Müşteri talebine bağlı",
    description: "Boyut ve ölçü standartları tablosuna ulaşmak için tıklayın.",
    link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00013.pdf",
  },
  {
    img: kokurunleri,
    ad: "Kok Ürünleri",
    boyutStandardi: "",
    celikKalitesi: "",
    kaliteStandardi: "",
    ilgiliStandartlar: "DIN 59200",
    description: "Kimyasal analiz tablolarına ulaşmak için tıklayın.",
    link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00014.pdf",
  },
  {
    img: granuleyuksekfirincurufu,
    ad: "Granüle Yüksek Fırın Cürufu",
    boyutStandardi: "",
    celikKalitesi: "",
    kaliteStandardi: "",
    ilgiliStandartlar: "",
    description: "Teknik özellikler tablosuna ulaşmak için tıklayın.",
    link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00015.pdf",
  },
  {
    img: merosatigi,
    ad: "MEROS Atığı (Sodyum Sülfat)",
    boyutStandardi: "",
    celikKalitesi: "",
    kaliteStandardi: "",
    ilgiliStandartlar: "",
    description: "Teknik özellikler tablosuna ulaşmak için tıklayın.",
    link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00016.pdf",
  },
  {
    img: metalurjkkirec,
    ad: "Metalürjik Kireç",
    boyutStandardi: "",
    celikKalitesi: "",
    kaliteStandardi: "",
    ilgiliStandartlar: "",
    description: "Kimyasal analiz tablosuna ulaşmak için tıklayın.",
    link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00023.pdf",
  },
  {
    img: havaayristirmatesisiurunleri,
    ad: "Hava Ayrıştırma Tesisi Ürünleri",
    boyutStandardi: "",
    celikKalitesi: "",
    kaliteStandardi: "",
    ilgiliStandartlar: "",
    description: "Teknik özellikler tablosuna ulaşmak için tıklayın.",
    link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00021.pdf",
  },
  // Add more products here
];

function UrunlerContents() {
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);
  const [open9, setOpen9] = useState(false);
  const [open10, setOpen10] = useState(false);

  const handleToggle1 = () => {
    setOpen1(!open1);
  };
  const handleToggle2 = () => {
    setOpen2(!open2);
  };
  const handleToggle3 = () => {
    setOpen3(!open3);
  };
  const handleToggle4 = () => {
    setOpen4(!open4);
  };
  const handleToggle5 = () => {
    setOpen5(!open5);
  };
  const handleToggle6 = () => {
    setOpen6(!open6);
  };
  const handleToggle7 = () => {
    setOpen7(!open7);
  };
  const handleToggle8 = () => {
    setOpen8(!open8);
  };
  const handleToggle9 = () => {
    setOpen9(!open9);
  };
  const handleToggle10 = () => {
    setOpen10(!open10);
  };

  if (!urunlerContent || urunlerContent.length === 0) {
    return <Typography variant="body1">İçerik bulunamadı.</Typography>;
  }

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            margin: { xs: "1rem 0", lg: "1rem 2rem 1rem 0 " },
          }}
        >
          <Header variant="h4" color="#1f2e5e">
            Ürünlerimiz
          </Header>

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 6 }}
          >
            {urunlerContent.map((urun, index) => (
              <Grid item xs={12} sm={6} lg={3} key={index}>
                <Item>
                  <Inner className="inner">
                    <Front>
                      <ImageContainer>
                        <img
                          src={urun.img}
                          alt={`Ürün ${index + 1}`}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                        <ImageText variant="h6">{urun.ad}</ImageText>
                      </ImageContainer>
                    </Front>
                    <Back>
                      {urun.kaliteStandardi && (
                        <>
                          <Typography variant="h6">Kalite Standardı</Typography>
                          <Typography>{urun.kaliteStandardi}</Typography>
                        </>
                      )}

                      {urun.boyutStandardi && (
                        <>
                          <Typography variant="h6">Boyut Standardı</Typography>
                          <Typography>{urun.boyutStandardi}</Typography>
                        </>
                      )}

                      {urun.yuzeyKalitesiStandardi && (
                        <>
                          <Typography variant="h6">
                            Yüzey Kalitesi Standardı
                          </Typography>
                          <Typography>{urun.yuzeyKalitesiStandardi}</Typography>
                        </>
                      )}
                      {urun.ilgiliStandartlar && (
                        <>
                          <Typography variant="h6">
                            İlgili Standartlar
                          </Typography>
                          <Typography>{urun.ilgiliStandartlar}</Typography>
                        </>
                      )}
                      {urun.celikKalitesi && (
                        <>
                          <Typography variant="h6">Çelik Kalitesi</Typography>
                          {urun.celikKalitesi.map((kalite, idx) => (
                            <Typography key={idx}>{kalite}</Typography>
                          ))}
                        </>
                      )}
                      <Typography variant="body2" sx={{ mt: 2 }}>
                        <a
                          href={urun.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                            fontWeight: "bold",
                          }}
                        >
                          {urun.description}
                        </a>
                      </Typography>
                    </Back>
                  </Inner>
                </Item>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      <Typography variant="h4" gutterBottom>
        Fiyat Listemiz
      </Typography>

      <Box sx={{ padding: { xs: "0", md: "1rem 2rem 1rem 0" } }}>
        <Paper
          sx={{
            backgroundColor: "#1b3457",
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Box>
            <Box display="flex" alignItems="center" color={"#fff"}>
              <IconButton onClick={handleToggle1} sx={{ color: "#fff" }}>
                {open1 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
              <Typography variant="h5" gutterBottom>
                KÜTÜK
              </Typography>
            </Box>

            <Collapse in={open1}>
              <Typography variant="body1" paragraph color={"#fff"}>
                25.07.2024 09:30 TARİHİNDEN GEÇERLİ MAMULLERİMİZİN TON
                FİYATLARINI GÖSTEREN LİSTEDİR.
              </Typography>
              <Typography variant="body1" paragraph color={"#fff"}>
                FİYAT KODU : 32
              </Typography>
              <Typography variant="body1" paragraph color={"#fff"}>
                Aşağıdaki fiyatlara KDV dahil değildir.
              </Typography>

              <TableContainer component={Paper} sx={{ mb: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>KÜTÜK</TableCell>
                      <TableCell>Fiyat</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>150X150 S235JR</TableCell>
                      <TableCell>540 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>150X150 B420</TableCell>
                      <TableCell>555 USD</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </Box>
        </Paper>

        <Paper
          sx={{
            backgroundColor: "#1b3457",
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Box>
            <Box display="flex" alignItems="center" color={"#fff"}>
              <IconButton onClick={handleToggle2} sx={{ color: "#fff" }}>
                {open2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
              <Typography variant="h5" gutterBottom>
                KANGAL(5,5mm)
              </Typography>
            </Box>

            <Collapse in={open2}>
              <Typography variant="body1" paragraph color={"#fff"}>
                31.07.2024 09:30 TARİHİNDEN GEÇERLİ MAMULLERİMİZİN TON
                FİYATLARINI GÖSTEREN LİSTEDİR.
              </Typography>
              <Typography variant="body1" paragraph color={"#fff"}>
                FİYAT KODU : 33
              </Typography>
              <Typography variant="body1" paragraph color={"#fff"}>
                Aşağıdaki fiyatlara KDV dahil değildir.
              </Typography>

              <TableContainer component={Paper} sx={{ mb: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>KANGAL(5,5mm)</TableCell>
                      <TableCell>Fiyat</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>SAE 1006-00</TableCell>
                      <TableCell>615 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>SAE 1008-1010</TableCell>
                      <TableCell>605 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>SAE 1018-1020</TableCell>
                      <TableCell>650 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell> SAE 1022-1073</TableCell>
                      <TableCell>660 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>SAE 1076-1082</TableCell>
                      <TableCell>670 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>C4C-C17C</TableCell>
                      <TableCell>660 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>C4D_01</TableCell>
                      <TableCell>630 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell> 20MnB4-30MnB4</TableCell>
                      <TableCell>760 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>SG2</TableCell>
                      <TableCell>785 USD</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </Box>
        </Paper>

        <Paper
          sx={{
            backgroundColor: "#1b3457",
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Box>
            <Box display="flex" alignItems="center" color={"#fff"}>
              <IconButton onClick={handleToggle3} sx={{ color: "#fff" }}>
                {open3 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
              <Typography variant="h5" gutterBottom>
                KANGAL(6-27mm)
              </Typography>
            </Box>

            <Collapse in={open3}>
              <Typography variant="body1" paragraph color={"#fff"}>
                31.07.2024 09:30 TARİHİNDEN GEÇERLİ MAMULLERİMİZİN TON
                FİYATLARINI GÖSTEREN LİSTEDİR.
              </Typography>
              <Typography variant="body1" paragraph color={"#fff"}>
                FİYAT KODU : 33
              </Typography>
              <Typography variant="body1" paragraph color={"#fff"}>
                Aşağıdaki fiyatlara KDV dahil değildir.
              </Typography>

              <TableContainer component={Paper} sx={{ mb: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>KANGAL(6-27mm)</TableCell>
                      <TableCell>Fiyat</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell> SAE 1006</TableCell>
                      <TableCell>600 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell> SAE 1008-1010</TableCell>
                      <TableCell>590 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>SAE 1018-1020</TableCell>
                      <TableCell>635 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell> SAE1022-1073-355JR</TableCell>
                      <TableCell>645 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>SAE 1076-1082</TableCell>
                      <TableCell>655 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>C4C-C17C</TableCell>
                      <TableCell>645 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>C4D_01</TableCell>
                      <TableCell>615 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell> 1082PC</TableCell>
                      <TableCell>675 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>37-41Cr4(5140)</TableCell>
                      <TableCell>785 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>41CrS4_00 </TableCell>
                      <TableCell>795 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell> 42CrMo4(4140)</TableCell>
                      <TableCell>995 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>54SiCr6</TableCell>
                      <TableCell>890 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>16MnCr5</TableCell>
                      <TableCell>795 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>20MnCr5</TableCell>
                      <TableCell>795 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>17MnB3</TableCell>
                      <TableCell>735 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>20MnB4-30MnB4</TableCell>
                      <TableCell>735 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell> 36CrB4+Nb</TableCell>
                      <TableCell>1040 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>38Si7</TableCell>
                      <TableCell>820 USD</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>32CrB4</TableCell>
                      <TableCell>880 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>C35R</TableCell>
                      <TableCell>670 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>C35E</TableCell>
                      <TableCell>650 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>S235J2_01</TableCell>
                      <TableCell>700 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>S275JR</TableCell>
                      <TableCell>620 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>S275JR_02/04</TableCell>
                      <TableCell>645 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>E335_01/02</TableCell>
                      <TableCell>645 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>S355J2</TableCell>
                      <TableCell>705 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>080A15-42</TableCell>
                      <TableCell>695 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell> 1018_03/05</TableCell>
                      <TableCell>670 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>1018_04</TableCell>
                      <TableCell>780 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>S275JR_05</TableCell>
                      <TableCell>630 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>31CrV3 </TableCell>
                      <TableCell>1025 USD</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </Box>
        </Paper>

        <Paper
          sx={{
            backgroundColor: "#1b3457",
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Box>
            <Box display="flex" alignItems="center" color={"#fff"}>
              <IconButton onClick={handleToggle4} sx={{ color: "#fff" }}>
                {open4 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
              <Typography variant="h5" gutterBottom>
                DÜZ YUVARLAK (20-100 mm)
              </Typography>
            </Box>

            <Collapse in={open4}>
              <Typography variant="body1" paragraph color={"#fff"}>
                31.07.2024 09:30 TARİHİNDEN GEÇERLİ MAMULLERİMİZİN TON
                FİYATLARINI GÖSTEREN LİSTEDİR.
              </Typography>
              <Typography variant="body1" paragraph color={"#fff"}>
                FİYAT KODU : 33
              </Typography>
              <Typography variant="body1" paragraph color={"#fff"}>
                Aşağıdaki fiyatlara KDV dahil değildir.
              </Typography>

              <TableContainer component={Paper} sx={{ mb: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>DÜZ YUVARLAK (20-100 mm)</TableCell>
                      <TableCell>Fiyat</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>235JR</TableCell>
                      <TableCell>650 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>275JR</TableCell>
                      <TableCell>685 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>355JR</TableCell>
                      <TableCell>705 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>355J2</TableCell>
                      <TableCell>755 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>E335</TableCell>
                      <TableCell>695 USD</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>1020-1070</TableCell>
                      <TableCell>705 USD</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </Box>
        </Paper>

        <Paper
          sx={{
            backgroundColor: "#1b3457",
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Box>
            <Box display="flex" alignItems="center" color={"#fff"}>
              <IconButton onClick={handleToggle5} sx={{ color: "#fff" }}>
                {open5 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
              <Typography variant="h5" gutterBottom>
                NERVÜRLÜ İNŞAAT ÇELİĞİ (12m)
              </Typography>
            </Box>

            <Collapse in={open5}>
              <Typography variant="body1" paragraph color={"#fff"}>
                24.07.2024 TARİHİNDEN GEÇERLİ MAMULLERİMİZİN TON FİYATLARINI
                GÖSTEREN LİSTEDİR.
              </Typography>
              <Typography variant="body1" paragraph color={"#fff"}>
                FİYAT KODU : 31
              </Typography>
              <Typography variant="body1" paragraph color={"#fff"}>
                Aşağıdaki fiyatlara KDV dahil değildir.
              </Typography>

              <TableContainer component={Paper} sx={{ mb: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>NERVÜRLÜ İNŞAAT ÇELİĞİ (12m)</TableCell>
                      <TableCell>Fiyat</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>12-32 mm</TableCell>
                      <TableCell>19.550 TL/ton</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>36-40 mm</TableCell>
                      <TableCell>19.700 TL/ton</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>MUHTELİF BOY</TableCell>
                      <TableCell>17.750 TL/ton</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </Box>
        </Paper>

        <Paper
          sx={{
            backgroundColor: "#1b3457",
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Box>
            <Box display="flex" alignItems="center" color={"#fff"}>
              <IconButton onClick={handleToggle6} sx={{ color: "#fff" }}>
                {open6 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
              <Typography variant="h5" gutterBottom>
                PROFİL
              </Typography>
            </Box>

            <Collapse in={open6}>
              <Typography variant="body1" paragraph color={"#fff"}>
                03.07.2024 TARİHİNDEN GEÇERLİ MAMULLERİMİZİN TON FİYATLARINI
                GÖSTEREN LİSTEDİR.
              </Typography>
              <Typography variant="body1" paragraph color={"#fff"}>
                FİYAT KODU : 28
              </Typography>
              <Typography variant="body1" paragraph color={"#fff"}>
                Aşağıdaki fiyatlara KDV dahil değildir.
              </Typography>

              <TableContainer component={Paper} sx={{ mb: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>PROFİL</TableCell>
                      <TableCell>Fiyat</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell> IPE - NPI - NPU (180-200) </TableCell>
                      <TableCell>680 USD/ton</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>IPE - NPI - NPU (220-300) </TableCell>
                      <TableCell>700 USD/ton</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell> IPE - NPI - NPU (320-550) </TableCell>
                      <TableCell>790 USD/ton</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell> IPE (600) </TableCell>
                      <TableCell>875 USD/ton</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell> HEA/HEB (120-200) </TableCell>
                      <TableCell>705 USD/ton</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell> HEA/HEB (220-260) </TableCell>
                      <TableCell>790 USD/ton</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell> HEA/HEB (280-300) </TableCell>
                      <TableCell>835 USD/ton</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell> HEA/HEB (320-450) </TableCell>
                      <TableCell>860 USD/ton</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell> HEA/HEB 500 </TableCell>
                      <TableCell>875 USD/ton</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </Box>
        </Paper>

        <Paper
          sx={{
            backgroundColor: "#1b3457",
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Box>
            <Box display="flex" alignItems="center" color={"#fff"}>
              <IconButton onClick={handleToggle7} sx={{ color: "#fff" }}>
                {open7 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
              <Typography variant="h5" gutterBottom>
                KOK FAB.YAN ÜRÜNLERİ
              </Typography>
            </Box>

            <Collapse in={open7}>
              <Typography variant="body1" paragraph color={"#fff"}>
                26.07.2024 TARİHİNDEN GEÇERLİ MAMULLERİMİZİN TON FİYATLARINI
                GÖSTEREN LİSTEDİR.
              </Typography>
              <Typography variant="body1" paragraph color={"#fff"}>
                FİYAT KODU : 26
              </Typography>
              <Typography variant="body1" paragraph color={"#fff"}>
                Aşağıdaki fiyatlara KDV dahil değildir.
              </Typography>

              <TableContainer component={Paper} sx={{ mb: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>KOK FAB.YAN ÜRÜNLERİ</TableCell>
                      <TableCell>Fiyat</TableCell>
                      <TableCell>Güvenlik Bilgi Formu(SDS)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell> KATRAN BOYASI</TableCell>
                      <TableCell>550 USD/ton</TableCell>
                      <TableCell>
                        <Link
                          href="https://www.kardemir.com/dosyalar/satispazarlama/adrurunleri/Katran_Boyasi.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <PictureAsPdfIcon sx={{ marginRight: "0.5rem" }} />
                          Güvenlik Bilgi Formu
                        </Link>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell> KREOZOT</TableCell>
                      <TableCell>575 USD/ton</TableCell>
                      <TableCell>
                        <Link
                          href="https://www.kardemir.com/dosyalar/satispazarlama/adrurunleri/Kreozot.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <PictureAsPdfIcon sx={{ marginRight: "0.5rem" }} />
                          Güvenlik Bilgi Formu
                        </Link>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>YOL KATRANI (RT 2)</TableCell>
                      <TableCell>555 USD/ton</TableCell>
                      <TableCell>
                        <Link
                          href="https://www.kardemir.com/dosyalar/satispazarlama/adrurunleri/Soguk_Yol_Katrani.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <PictureAsPdfIcon sx={{ marginRight: "0.5rem" }} />
                          Güvenlik Bilgi Formu
                        </Link>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>YOL KATRANI (RT 9)</TableCell>
                      <TableCell>545 USD/ton</TableCell>
                      <TableCell>
                        <Link
                          href="https://www.kardemir.com/dosyalar/satispazarlama/adrurunleri/Sicak_Yol_Katrani.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <PictureAsPdfIcon sx={{ marginRight: "0.5rem" }} />
                          Güvenlik Bilgi Formu
                        </Link>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell> PRES NAFTALİN</TableCell>
                      <TableCell>600 USD/ton</TableCell>
                      <TableCell>
                        <Link
                          href="https://www.kardemir.com/dosyalar/satispazarlama/adrurunleri/Naftalin.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <PictureAsPdfIcon sx={{ marginRight: "0.5rem" }} />
                          Güvenlik Bilgi Formu
                        </Link>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </Box>
        </Paper>

        <Paper
          sx={{
            backgroundColor: "#1b3457",
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Box>
            <Box display="flex" alignItems="center" color={"#fff"}>
              <IconButton onClick={handleToggle8} sx={{ color: "#fff" }}>
                {open8 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
              <Typography variant="h5" gutterBottom>
                PLATİNA
              </Typography>
            </Box>

            <Collapse in={open8}>
              <Typography variant="body1" paragraph color={"#fff"}>
                19.07.2024 TARİHİNDEN GEÇERLİ MAMULLERİMİZİN TON FİYATLARINI
                GÖSTEREN LİSTEDİR.
              </Typography>
              <Typography variant="body1" paragraph color={"#fff"}>
                FİYAT KODU : 25
              </Typography>
              <Typography variant="body1" paragraph color={"#fff"}>
                Aşağıdaki fiyatlara KDV dahil değildir.
              </Typography>

              <TableContainer component={Paper} sx={{ mb: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>PLATİNA</TableCell>
                      <TableCell>Fiyat</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>25-80 x 200-405 mm (S235JR)</TableCell>
                      <TableCell>725 USD/ton</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell> 25-80 x 410-520 mm (S235JR)</TableCell>
                      <TableCell>730 USD/ton</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell> 25-80 x 200-405 mm (C40,C45)</TableCell>
                      <TableCell>740 USD/ton</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>25-80 x 410-520 mm (C40,C45)</TableCell>
                      <TableCell>750 USD/ton</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </Box>
        </Paper>

        <Paper
          sx={{
            backgroundColor: "#1b3457",
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Box>
            <Box display="flex" alignItems="center" color={"#fff"}>
              <IconButton onClick={handleToggle9} sx={{ color: "#fff" }}>
                {open9 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
              <Typography variant="h5" gutterBottom>
                OKSİJEN FAB ÜRÜNLERİ
              </Typography>
            </Box>

            <Collapse in={open9}>
              <Typography variant="body1" paragraph color={"#fff"}>
                18.04.2024 TARİHİNDEN GEÇERLİ MAMULLERİMİZİN TON FİYATLARINI
                GÖSTEREN LİSTEDİR.
              </Typography>
              <Typography variant="body1" paragraph color={"#fff"}>
                FİYAT KODU : 18
              </Typography>
              <Typography variant="body1" paragraph color={"#fff"}>
                Aşağıdaki fiyatlara KDV dahil değildir.
              </Typography>

              <TableContainer component={Paper} sx={{ mb: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>OKSİJEN FAB ÜRÜNLERİ</TableCell>
                      <TableCell>Fiyat</TableCell>
                      <TableCell>Güvenlik Bilgi Formu(SDS)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>SIVI OKSİJEN</TableCell>
                      <TableCell>85 USD/ton</TableCell>
                      <TableCell>
                        <Link
                          href="https://www.kardemir.com/dosyalar/satispazarlama/adrurunleri/Oksijen_Sivi.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <PictureAsPdfIcon sx={{ marginRight: "0.5rem" }} />
                          Güvenlik Bilgi Formu
                        </Link>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell> SIVI AZOT</TableCell>
                      <TableCell>85 USD/ton</TableCell>
                      <TableCell>
                        <Link
                          href="https://www.kardemir.com/dosyalar/satispazarlama/adrurunleri/Azot_Sivi.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <PictureAsPdfIcon sx={{ marginRight: "0.5rem" }} />
                          Güvenlik Bilgi Formu
                        </Link>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell> SIVI ARGON</TableCell>
                      <TableCell>700 USD/ton</TableCell>
                      <TableCell>
                        <Link
                          href="https://www.kardemir.com/dosyalar/satispazarlama/adrurunleri/Argon_Sivi.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <PictureAsPdfIcon sx={{ marginRight: "0.5rem" }} />
                          Güvenlik Bilgi Formu
                        </Link>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>OKSİJEN AZOT TÜP/ADET</TableCell>
                      <TableCell>70 USD/ton</TableCell>
                      <TableCell>
                        <Link
                          href="https://www.kardemir.com/dosyalar/satispazarlama/adrurunleri/Azot_Basincli_Gaz.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <PictureAsPdfIcon sx={{ marginRight: "0.5rem" }} />
                          Güvenlik Bilgi Formu
                        </Link>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>ARGON TÜP/ADET</TableCell>
                      <TableCell>335 USD/ton</TableCell>
                      <TableCell>
                        <Link
                          href="https://www.kardemir.com/dosyalar/satispazarlama/adrurunleri/Argon_Gaz.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <PictureAsPdfIcon sx={{ marginRight: "0.5rem" }} />
                          Güvenlik Bilgi Formu
                        </Link>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </Box>
        </Paper>

        <Paper
          sx={{
            backgroundColor: "#1b3457",
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Box>
            <Box display="flex" alignItems="center" color={"#fff"}>
              <IconButton onClick={handleToggle10} sx={{ color: "#fff" }}>
                {open10 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
              <Typography variant="h5" gutterBottom>
                CURUF DEĞ.TESİSİ
              </Typography>
            </Box>

            <Collapse in={open10}>
              <Typography variant="body1" paragraph color={"#fff"}>
                14.02.2024 TARİHİNDEN GEÇERLİ MAMULLERİMİZİN TON FİYATLARINI
                GÖSTEREN LİSTEDİR.
              </Typography>
              <Typography variant="body1" paragraph color={"#fff"}>
                FİYAT KODU : 07
              </Typography>
              <Typography variant="body1" paragraph color={"#fff"}>
                Aşağıdaki fiyatlara KDV dahil değildir.
              </Typography>

              <TableContainer component={Paper} sx={{ mb: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>CURUF DEĞ.TESİSİ</TableCell>
                      <TableCell>Fiyat</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>SUNİ KUM(0-10 mm/ÇH)</TableCell>
                      <TableCell>40 TL/ton</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell> SUNİ MICIR(10-50 mm/ÇH)</TableCell>
                      <TableCell>80 TL/ton</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell> PARÇA CURUF(0-10 mm/YF)</TableCell>
                      <TableCell>800 TL/ton</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>PARÇA CURUF(10-50 mm/YF)</TableCell>
                      <TableCell>900 TL/ton</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>PARÇA CURUF(50-150 mm/YF)</TableCell>
                      <TableCell>1000 TL/ton</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </Box>
        </Paper>
      </Box>
    </>
  );
}

export default UrunlerContents;
