import React from "react";
import {
  Box,
  Typography,
  Paper,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import foto1 from "../../images/sustainability/sürdürülebilirlik-öncelikleri.png";
import dernek1 from "../../images/dernekLogo/1.jpg";
import dernek2 from "../../images/dernekLogo/Kurumsal Risk Yöneticileri Derneği.png";
import dernek3 from "../../images/dernekLogo/Bağlantı Elemanları Sanayici ve İş Adamları Derneği.jfif";
import dernek4 from "../../images/dernekLogo/Müstakil Sanayici ve İş Adamları Derneği.jpg";
import dernek5 from "../../images/dernekLogo/Çelik Dış Ticaret Derneği.jpg";
import dernek6 from "../../images/dernekLogo/Savunma, Havacılık ve Uzay Kümelenmesi Derneği.webp";
import dernek7 from "../../images/dernekLogo/Çelik İhracatçıları Birliği.png";
import dernek8 from "../../images/dernekLogo/TEGEP Eğitim ve Gelişim Platformu Derneği.png";
import dernek9 from "../../images/dernekLogo/Demiryolu Taşımacıları Derneği.png";
import dernek10 from "../../images/dernekLogo/Türk Yapısal Çelik Derneği.jpg";
import dernek11 from "../../images/dernekLogo/karabük ticaret ve sanayi odası.jfif";
import dernek12 from "../../images/dernekLogo/Türkiye Çelik Üreticileri Derneği.png";
import dernek13 from "../../images/dernekLogo/kbü.png";
import dernek14 from "../../images/dernekLogo/Türkiye Kalite Derneği.png";
import dernek15 from "../../images/dernekLogo/Türkiye Yatırımcı İlişkileri Derneği.jfif";
import dernek16 from "../../images/dernekLogo/Karbon Saydamlık Projesi.png";
import dernek17 from "../../images/dernekLogo/Uludağ Otomotiv Endüstrisi İhracatçıları Birliği.jfif";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
  overflowX: "auto",
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: "#1f2e5e",
  fontWeight: "bold",
  marginBottom: theme.spacing(2),
}));

const Table = styled("table")(({ theme }) => ({
  width: "100%",
  borderCollapse: "collapse",
  "& th, & td": {
    padding: theme.spacing(2),
    textAlign: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  "& th": {
    backgroundColor: "#f5f5f5",
    color: "#1f2e5e",
  },
  "& td": {
    backgroundColor: "#f5f5f5",
  },
}));

function SurdurulebilirlikYonetimiContents() {
  function createData(name, paragraph) {
    return { name, paragraph };
  }

  const rows = [
    createData(
      "Çalışanlar",
      "Sürdürülebilirlik Raporu (yıllık), Faaliyet Raporu (yıllık), Çalışan Memnuniyeti Araştırması (yıllık), İş Sağlığı ve Emniyeti Kurulları (aylık), İç Bilgilendirme Duyuruları (anlık), Kardemir İş Etiği Kuralları (sürekli), Toplu İş Sözleşmesi (sürekli), Çalışan Eğitimleri (sürekli), İnternet Sitesi (sürekli)"
    ),
    createData(
      "Pay Sahipleri ve Yatırımcılar",
      "Sürdürülebilirlik Raporu (yıllık), Faaliyet Raporu (yıllık), Genel Kurul Toplantıları (yıllık), Ara Dönem Faaliyet Raporları (üç aylık), Özel Durum Açıklamaları (anlık), Yatırımcı Sunumları (talep üzerine), Toplantı ve Görüşmeler (talep üzerine), Analist Sunumları ve Telekonferanslar (talep üzerine), Kardemir İş Etiği Kuralları (sürekli), İnternet Sitesi (sürekli),"
    ),
    createData(
      "Müşteriler",
      "Sürdürülebilirlik Raporu (yıllık), Müşteri Memnuniyeti Araştırması (yıllık), Toplantı ve Görüşmeler (talep üzerine), Kardemir İş Etiği Kuralları (sürekli)"
    ),
    createData(
      "İş Ortakları ve Tedarikçiler",
      "Sürdürülebilirlik Raporu (yıllık), Tedarikçi Memnuniyet Araştırması (yıllık), İş Sağlığı ve Emniyeti Kurulları (aylık), Toplantı ve Görüşmeler (talep üzerine), Kardemir İş Etiği Kuralları (sürekli), İnternet Sitesi (sürekli)"
    ),
    createData(
      "Dolaylı Çalışanlar",
      "İş Sağlığı ve Emniyeti Kurulları (aylık), Toplantı ve Görüşmeler (talep üzerine), Kardemir İş Etiği Kuralları (sürekli), Çalışan Eğitimleri (sürekli), İnternet Sitesi (sürekli)"
    ),
    createData(
      "Sendikalar",
      "Sürdürülebilirlik Raporu (yıllık), Faaliyet Raporu (yıllık), İş Sağlığı ve Emniyeti Kurulları (aylık), Toplantı ve Görüşmeler (sürekli), Toplu İş Sözleşmesi (sürekli), İnternet Sitesi (sürekli)"
    ),
    createData(
      "Kamu Kurum ve Kuruluşları",
      "Sürdürülebilirlik Raporu (yıllık), Faaliyet Raporu (yıllık), Denetimler (periyodik/anlık), Toplantı ve Görüşmeler (talep üzerine), Teşvikler (talep üzerine), İnternet Sitesi (sürekli)"
    ),
    createData(
      "Sivil Toplum Kuruluşları",
      "Sürdürülebilirlik Raporu (yıllık), Faaliyet Raporu (yıllık), Toplantı ve Görüşmeler (talep üzerine), Ortak Projeler (talep üzerine), Bağış ve Sponsorluklar (talep üzerine), Üyelikler (sürekli), İnternet Sitesi (sürekli)"
    ),
    createData(
      "Eğitim Kurumları",
      "Ar-Ge Ortaklıkları (proje bazında), Bağış ve Sponsorluklar (talep üzerine), Altyapı ve Eğitmen Destekleri (talep üzerine), İnternet Sitesi (sürekli)"
    ),
    createData(
      "Medya",
      "Sürdürülebilirlik Raporu (yıllık), Faaliyet Raporu (yıllık), Toplantı ve Görüşmeler (talep üzerine), Basın Toplantıları ve Bültenleri (anlık), İnternet Sitesi (sürekli)"
    ),
    createData(
      "Toplum",
      "Toplantı ve Görüşmeler (talep üzerine), Toplumsal Projeler ve Altyapı Destekleri (sürekli), İnternet Sitesi (sürekli)"
    ),
  ];

  const rows2 = [
    { logo: dernek1, url: "https://www.anadoluraylisistemler.org/" },
    { logo: dernek2, url: "https://kryd.org/" },
    { logo: dernek3, url: "https://www.besiadturkey.com/" },
    { logo: dernek4, url: "https://www.musiad.org.tr/" },
    { logo: dernek5, url: "https://celikdisticaret.org/" },
    { logo: dernek6, url: "https://www.hukd.org.tr/" },
    { logo: dernek7, url: "https://www.cib.org.tr/tr/default.html" },
    { logo: dernek8, url: "https://www.tegep.org/" },
    { logo: dernek9, url: "https://dtd.org.tr/" },
    { logo: dernek10, url: "https://www.tucsa.org/tr/" },
    { logo: dernek11, url: "https://karabuktso.org.tr/" },
    { logo: dernek12, url: "https://celik.org.tr/" },
    { logo: dernek13, url: "https://demircelik.karabuk.edu.tr/" },
    { logo: dernek14, url: "https://www.kalder.org/" },
    { logo: dernek15, url: "https://www.tuyid.org/tr/" },
    { logo: dernek16, url: "#" },
    {
      logo: dernek17,
      url: "https://uib.org.tr/tr/birliklerimiz-uludag-otomotiv-endustrisi-ihracatcilari-birligi-oib-hakkinda.html",
    },
  ];

  return (
    <Box sx={{ flexGrow: 1, padding: 1 }}>
      <Header variant="h4" color="#1f2e5e">
        Sürdürülebilirlik Yönetimi
      </Header>

      <StyledPaper elevation={3}>
        {/* <SectionTitle variant="h6">Kayıtlı Sermaye</SectionTitle> */}
        <Typography
          variant="body1"
          sx={{ marginBottom: 2, textAlign: "justify" }}
        >
          Türkiye’nin ilk entegre demir çelik üreticisi olarak kurulduğumuz
          günden beri Türk ekonomisi için değer üretmeye devam ediyoruz. 1995
          yılında gerçekleşen özelleştirmeyle birlikte başlattığımız dönüşümün
          en önemli ayağını ise büyük ölçekli yatırımlarla büyümek ve
          ürettiğimiz değeri sürekli artırmak oluşturuyor. Bu dönüşüm vizyonu
          içinde en önemli boyutu da sürdürülebilirlik oluşturmaktadır. Bu
          kapsamda Kardemir, dönüşüm stratejisini sadece operasyonel anlamda
          büyümeye değil, değer zinciri genelinde ekonomik ve sosyal gelişime,
          etkin çevresel etki yönetimine ve etik iş değerlerinin yüceltilmesine
          odaklamakta; pozitif etkimizi sadece şirketimize değil toplum geneline
          de yansıtmayı hedeflemektedir.
        </Typography>

        <SectionTitle variant="h5">
          Sürdürülebilir Kalkınma Amaçları
        </SectionTitle>
        <Typography
          variant="body1"
          sx={{ marginBottom: 2, textAlign: "justify" }}
        >
          Kardemir uluslararası sürdürülebilirlik ajandasını yakinen takip
          etmekte ve çalışmalarını bu doğrultuda şekillendirmektedir. Bu nedenle
          iş stratejimizin sürdürülebilirlik boyutunda Birleşmiş Milletler
          himayesinde oluşturulan Sürdürülebilir Kalkınma Amaçları’nı ana
          bileşen olarak değerlendirmektedir. Sürdürülebilir Kalkınma Amaçları,
          2015-2030 tarihleri arasında dünya genelinde gelişimin hedeflendiği ve
          aralarında Türkiye’nin de bulunduğu 192 ülkenin kabulüyle hayata
          geçirilmiş 17 ana amaç ve bunların altında tanımlanmış 169 hedeften
          oluşmaktadır. <br />
          <br />
          Bu çerçevede Kardemir, sürdürülebilirlik faaliyetlerinde şirket
          sürdürülebilirlik öncelikleri doğrultusunda bu ana amaçlara ulaşmaya
          katkı sağlamayı hedeflemektedir. Tüm bu unsurları bir araya getirerek
          oluşturduğumuz sürdürülebilirlik yaklaşımımız, yatırım ve Ar-Ge
          çalışmalarımızla bir taraftan faaliyetlerimizden doğan çevresel etkiyi
          mümkün olan en düşük düzeye indirirken; toplum ve ekonomi üzerinde
          pozitif etkimizi artırmaya; bunu da iş etiği ve insana saygıyı ön
          plana çıkaran kurum kültürümüzle başarmaya odaklanmaktadır. Bu
          yaklaşımı hayata geçirmek için ilk olarak şirket genelinde
          sürdürülebilirlik bakış açısını yaygınlaştırmak amacıyla gerekli karar
          alma ve uygulama süreçlerini hayata geçirecek iş birimlerinin
          temsilcilerinden oluşan bir çalışma ekibi oluşturduk. Çalışma
          ekibimizi hayata geçirdiğimiz eğitimlerle sürdürülebilirlik ve
          uygulama alanlarıyla ilgili bilgiyle donattık. Sonrasında ise çalışma
          ekibimizin de katılımıyla sürdürülebilirlik önceliklerimizi
          belirlemeye yönelik çalışmaları başlattık.
        </Typography>

        <SectionTitle variant="h5">
          Kardemir Sürdürülebilirlik Öncelikleri
        </SectionTitle>
        <Typography
          variant="body1"
          sx={{ marginBottom: 2, textAlign: "justify" }}
        >
          Kardemir’in sürdürülebilirlik önceliklerini belirlemeye yönelik
          çalışmalar, Global Reporting Initiative tarafından yayınlanan,
          uluslararası ölçekte yaygınca kullanılan GRI Standartları’nın önerdiği
          önceliklendirme kriterlerine uyumlu biçimde gerçekleştirilmiştir.
          Çalışma kapsamında küresel megatrendler, Sürdürülebilir Kalkınma
          Amaçları ve sektörel etki alanları, Kardemir operasyonlarından doğan
          sosyal, çevresel ve ekonomik etkilerin ve paydaş beklentilerinin
          büyüklüğü ana parametrelerinde değerlendirilmiştir. Bu değerlendirmeye
          90’a yakın şirket yöneticisi ve 60’a yakın şirket dışı paydaş
          görüşleriyle katılmış karar alma surecine katkı sağlamıştır. <br />
          <br />
        </Typography>
        <img
          src={foto1}
          alt="surdurulebilirlik"
          style={{
            width: "80%",
            height: "70%",
            display: "flex",

            margin: "0 auto",
          }}
        />

        <SectionTitle variant="h5">Tedarik İzlenebilirliği</SectionTitle>
        <Typography
          variant="body1"
          sx={{ marginBottom: 2, textAlign: "justify" }}
        >
          Satın aldığımız yerli hammaddeleri yükleme bölgesinden ve ithal
          hammaddeleri yükleme limanından fabrika stok sahamıza kadarki tedarik
          sürecinde izlemekteyiz.
        </Typography>

        <SectionTitle variant="h5"> Paydaş Katılımı</SectionTitle>
        <Typography
          variant="body1"
          sx={{ marginBottom: 2, textAlign: "justify" }}
        >
          Başarının ortak akılla şekillendirilen düşünceler ve karşılıklı
          faydayı merkeze alan iş birlikleriyle sağlanabileceğine inanırız. Bu
          nedenle paydaşlarımızın görüş ve önerilerini şirket karar alma ve
          faaliyet süreçlerinde değerlendiririz. Bu paylaşımın sağlanması için
          her paydaş grubuyla farklı iletişim kanallarından faydalanırız.
        </Typography>

        <SectionTitle variant="h5">Paydaş İletişim Araçları</SectionTitle>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  // sx={{
                  //   "&:last-child td, &:last-child th": { border: 0 },
                  // }}
                >
                  <TableCell
                    sx={{ borderRight: "1px solid lightgray" }}
                    component="th"
                    scope="row"
                  >
                    <Typography sx={{ fontWeight: "600", fontSize: "15px" }}>
                      {row.name}
                    </Typography>
                  </TableCell>
                  <TableCell align="justify">{row.paragraph}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <SectionTitle variant="h5" mt={4}>
          Sivil Toplum Kuruluşu Üyelikleri
        </SectionTitle>

        <TableContainer
          component={Paper}
          sx={{
            overflowX: "hidden", // Yatay kaydırmayı engelle
            width: "100%", // Tabloyu ekranın tamamına yay
          }}
        >
          <Table
            sx={{
              minWidth: 300,
              "@media (max-width: 600px)": {
                "& td": {
                  display: "block",
                  width: "100%",
                },
                "& tr": {
                  display: "block",
                },
              },
            }}
            aria-label="logos table"
          >
            <TableBody>
              {rows2.map((row, index) => {
                if (index % 2 === 0) {
                  return (
                    <TableRow key={index}>
                      {/* Sol sütun */}
                      <TableCell
                        sx={{
                          textAlign: "left",
                          borderRight: "1px solid lightgray",
                        }}
                      >
                        <a
                          href={row.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={row.logo}
                            alt="Logo"
                            style={{ width: "auto", height: "80px" }}
                          />
                        </a>
                      </TableCell>

                      {/* Sağ sütun, eğer varsa */}
                      {rows2[index + 1] ? (
                        <TableCell sx={{ textAlign: "left" }}>
                          <a
                            href={rows2[index + 1].url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={rows2[index + 1].logo}
                              alt="Logo"
                              style={{ width: "auto", height: "80px" }}
                            />
                          </a>
                        </TableCell>
                      ) : (
                        <TableCell />
                      )}
                    </TableRow>
                  );
                }
                return null;
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {/* <Divider sx={{ marginY: 3 }} /> ÇİZGİ ÇEKME */}
      </StyledPaper>
    </Box>
  );
}

export default SurdurulebilirlikYonetimiContents;
