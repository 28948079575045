import {
  Box,
  List,
  ListItem,
  Grid,
  ListItemIcon,
  ListItemText,
  Typography,
  Paper,
} from "@mui/material";
import React from "react";
import CircleIcon from "@mui/icons-material/Check";
import { styled } from "@mui/material/styles";

function VizyonMisyonContents() {
  const maddeler = [
    "Etik ve Güvenilir",
    "Kalite Odaklı",
    "Sorumlu Üretim ve Tüketim",
    "İnsan İçin İnsanlar",
    "Çevreye Duyarlı",
    "Müşteri Odaklı",
  ];

  const StyledPaper = styled(Paper)(({ theme }) => ({
    overflowX: "auto",
    // maxWidth: 1450,
    margin: "0 auto",
    padding: "2rem",
    // width: "80%",
  }));

  const Header = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: theme.spacing(3),
  }));

  return (
    <>
      <Box sx={{ padding: "1rem 0.2rem" }}>
        <Box sx={{ flexGrow: 1, padding: 1 }}>
          <Header variant="h4" color="#1f2e5e">
            {" "}
            Vizyon-Misyon ve Değerlerimiz
          </Header>

          <StyledPaper>
            <Grid container spacing={0} justifyContent="center">
              <Box sx={{ padding: { xs: "0", lg: "0 4rem 1rem 0" } }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h4"
                    sx={{ margin: "1rem 0", color: "#1f2e5e" }}
                  >
                    Vizyonumuz
                  </Typography>
                </Box>
                <Typography sx={{ textAlign: "justify" }}>
                  Demir Çelik sektörüne yerli ve milli üretim stratejileriyle
                  katma değer sağlayan sektörel bağımsızlığı ve Türkiye Yüzyılı
                  İlkelerini benimseyen küresel güç olmak.
                </Typography>
                <br />

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h4"
                    sx={{ margin: "1rem 0", color: "#1f2e5e" }}
                  >
                    Misyonumuz
                  </Typography>
                </Box>
                <Typography sx={{ textAlign: "justify" }}>
                  Yenilikçi teknoloji ve sürekli iyileştirme anlayışıyla katma
                  değerli çelik üretimi yapan, çevresel ve sosyal
                  sürdürülebilirliği benimseyerek paydaş değerlerini ön planda
                  tutan ve sektörel derinliği sağlayan bir kuruluş olmak.
                </Typography>
                <br />

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h4"
                    sx={{ margin: "1rem 0", color: "#1f2e5e" }}
                  >
                    Değerlerimiz
                  </Typography>
                </Box>
                <List>
                  {maddeler.map((madde, index) => (
                    <ListItem key={index} sx={{ padding: "0.5rem 0" }}>
                      <ListItemIcon>
                        <CircleIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={madde} />
                    </ListItem>
                  ))}
                </List>
                <br />
              </Box>
            </Grid>
          </StyledPaper>
        </Box>
      </Box>
    </>
  );
}

export default VizyonMisyonContents;
