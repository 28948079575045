import React, { useState } from "react";
import { Box, Grid, Typography, Paper } from "@mui/material";
import foto1 from "../../images/yonetimkurulu/idemir5.png";
import foto2 from "../../images/yonetimkurulu/omerdemirhan.png";
import foto3 from "../../images/yonetimkurulu/angulluoglu.png";
import foto4 from "../../images/yonetimkurulu/ercümendarvas.png";
import foto5 from "../../images/yonetimkurulu/fatihmehmeterkoc.png";
import foto6 from "../../images/yonetimkurulu/hakaya.png";
import foto7 from "../../images/yonetimkurulu/kamilgülec.png";
import foto8 from "../../images/yonetimkurulu/mcensari.png";
import foto9 from "../../images/yonetimkurulu/muhammetalioflaz.png";
import foto10 from "../../images/yonetimkurulu/mustafaaydın.png";
import foto11 from "../../images/yonetimkurulu/yusufcanbolat.png";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

const yonetimKurulu = [
  {
    name: "Prof. Dr. İsmail Demir",
    title: "Yönetim Kurulu Başkanı",
    image: foto1,
    link: "#",
    summary: (
      <>
        <Typography>
          1960 Elazığ doğumlu İlk öğrenimini Ankara ve Eskişehir, Orta ve Lise
          öğrenimini Eskişehir'de tamamladı. 1977 yılında İTÜ Uçak Mühendisliği
          bölümünde başladığı üniversite eğitimini 1982 yılında Uçak Mühendisi
          olarak mezun oldu. Akabinde Yüksek Lisans öğrenimine başlarken Uludağ
          Üniversitesi Makina Mühendisliği bölümünde Araştırma Görevlisi olarak
          vazife aldı. Bir yıl sonra İTÜ Uçak ve Uzay Bilimleri Fakültesi’nde
          Araştırma Görevlisi olarak çalıştı. Bir yıldan az süren bu görevi
          sırasında kazandığı MEB bursu ile ABD'ye yüksek lisans ve doktora
          öğrenimi için gitti. <br />
          <br />
        </Typography>
        <Typography>
          1987 yılında Michigan Üniversitesi Uygulamalı Mekanik bölümünden
          Yüksek Lisans diploması aldı. Akabinde Purdue Üniversitesi Uzay ve
          Havacılık dalında Yüksek Lisans diploması alarak mezun oldu. Kendisine
          uzmanlık alanı olarak belirlediği katı cisim mekaniği çatlak mekaniği
          ve malzeme konularında çalışmak üzere Washington State Universitesi
          Malzeme ve Makina Mühendisliği bölümüne transfer olarak “En iyi Lisans
          Üstü Araştırmacı” ödülünü aldığı bu bölümden 1992 yılında doktorasını
          tamamladı. Kanada, British Columbia, Victoria Üniversitesinde Doktora
          sonrası araştırmacı olarak yarı iletken tekil kristal büyütme (Single
          Crystal Growth) konusunda çalışmalar gerçekleştirdi. 1993 yılında
          Suudi Arabistan Kral Suud Üniversitesi Makina Mühendisliği bölümünde
          Yardımcı Doçent olarak göreve başladı. Akademik hayatına 9 yıl bu
          bölümde Yardımcı Doçent ve Doçent olarak sürdürdü. 1997 yılında İTÜ
          Uçak ve Uzay Bilimleri Fakültesi Uçak Elemanları ve Malzemesi dalında
          Doçentlik ünvanı aldı. <br />
          <br />
        </Typography>
        <Typography>
          Bu süre zarfında kısa dönemlerle çeşitli araştırma projelerine
          katılmak üzere ABD’de Livermore milli laboratuvarları, Kuzeybatı
          Pasifik Milli Laboratuvarları, Washington State Üniversitesi Malzeme
          araştırma merkezi ve Makina mühendisliği bölümlerinde kısmi zamanlı
          araştırmacı olarak yer aldı. Bu çalışmalarında malzeme, nümerik
          modelleme, mikro ve mezomekanik ile Nanoteknoloji konularında
          araştırmalar yürüttü. 2002 yılında araştırmacı olarak Washington State
          Üniversitesi Malzeme Araştırmaları Merkezi’nde çalışmaya başlayarak
          DARPA projelerinde görev aldı ve Mikro elektromekanik sistemler
          üzerine araştırmalar yaptı. <br />
          <br />
        </Typography>
        <Typography>
          2003 yılı sonunda yönetimin daveti üzerine Türkiye'ye dönerek Türk
          Hava Yolları Anonim Ortaklığı bünyesinde Eğitim Başkan'ı olarak göreve
          başladı. Nisan 2005 tarihinde THY’de Teknikten Sorumlu Genel Müdür
          yardımcılığı görevine getirildi. Mayıs 2006’da THY Bakım Onarım
          biriminin THY’den ayrılarak ayrı tüzel kişiliği olan bir şirket olarak
          yapılanmasını gerçekleştirdi ve şirketin ilk Genel Müdürü oldu. Bu
          görevde THY Teknik (Turkish Tecnic) olarak marka oluşturup, çeşitli
          yatırım ve projelerle THY dışında diğer şirketlere de hizmet veren
          dünya çapında bir uçak bakım onarım şirketi yapılandırdı. Bu çabalar
          neticesi THY Teknik kısa sürede dünyadaki en iyi 10 uçak bakım onarım
          şirketi (MRO) arasına girmeyi başardı ve Avrupa'nın en iyi uçak
          bakım-onarım şirketi (MRO) seçilme başarısını gösterdi. <br />
          <br />
        </Typography>
        <Typography>
          2014 yılına kadar THY Tekniğin Genel Müdürü ve Yönetim Kurulu Başkan
          Vekili olarak devam ettirdiği bu görevi sırasında Amerikalı
          Pratt&Whitney Motor şirketi ile 150 Milyon ABD doları sermayeli Türk
          Motor Merkezi (Turkish Engine Center), Amerikalı Goodrich
          AeroStructures firması ile uçak motor kaportası ve ters tepki
          ünitelerinin bakım onarımı şirketi (Goodrich Turkish Tecnic Service
          Center (GTTSC)), Zorlu Enerji ile TURKBINE adlı Uçak Motorları ve Gaz
          Tirbünleri bakım onarım şirketi, TUSAŞ ile ile Uçak Kabin İçi
          Ekipmanları İmalat şirketi (Turkish Cabin Interior TCI), Kibar Holding
          ile Uçak Koltuk İmalat şirketi (Turkish Seat Industries-TSI) gibi
          ortaklıkların kurulmasını sağladı ve yönetimlerinde yer aldı. <br />
          <br />
        </Typography>
        <Typography>
          Nisan 2014’te kendisine teklif edilen Savunma Sanayii Müsteşarlığı
          görevini kabul ederek Müsteşarlık görevine başladı. Savunma Sanayi
          Müsteşarlığını yürüttüğü sürede Türkiye’de birçok savunma sanayi
          projesinin tamamlanmasını ve başlatılmasını gerçekleştirerek tavizsiz
          bir yerli ve milli savunma sanayii idealini hayata geçirmek üzere
          çalışmalar yürüttü. 9 yılı aşkın bir süre yürüttüğü bu görev sırasında
          Korkut, HİSAR, SİPER gibi hava savunma sistemleri, HÜRKUŞ, HÜRJET,
          KAAN gibi uçaklar, TB2, Akıncı, Kızılelma, Anka, Aksungur, Anka 3 gibi
          SİHA’lar, SİHA gemisi, Milli Korvet ve Fırkateynler, Denizaltılar,
          muhtelif büyüklük ve kabiliyette botlar, İnsansız Deniz Araçları, Tank
          ve Muhtelif Zırhlı Araçlar, insansız sistemler, Muhtelif kalibre ve
          yeteneklerde füze, silah ve mühimmatlar, elektronik harp ve radar
          kabiliyetleri geliştirme konusunda milli projelere öncülük etti ve
          başarılı ürünler ortaya konulması sürecini yönetti. İnsan
          kaynaklarımız konusunda Teknofest, Vizyoner Genç, Kök, Kalfa
          programları, Savunma Sanayi Akademi gibi faaliyetleri başlattı.
          Savunma Sanayii ekosisteminin geliştirilmesi konusunda projeler ortaya
          koyarak EYDEP, YETEN gibi programları başlattı. HAB Havacılık ve Uzay
          İhtisas Sanayi bölgesi ve İstanbul Teknopark gibi yapılanmalarla
          Savunma Sanayiinde yeni ufuklar açılmasını sağladı. Savunma Sanayii
          Başkanlığı’nın kurduğu yatırım şirketi SSTEK altında Savunma
          Sanayiinde önemli işler yapan DELTA-V, TRAnten, TR Motor, ULAK,
          DATABOSS, TEKNOHAB, ÜRTEM vb. 15 civarında şirketin kurulması ve
          desteklenmesini gerçekleştirdi. Bütün bu faaliyetlerle proje sayısını
          200’lerden 800’lere, savunma sanayii çalışmalarında bulunan şirket
          sayısını 200’lerden 3 bin civarına çıkardığı bu sürede savunma
          sanayiindeki yerlilik oranını %80’lere taşıdı. Bütün bu süreçleri
          “istiklal ve istikbalimiz” için sloganı ile yürütmeye devam ettirerek
          savunma sanayii konusunda büyük bir özgüvenle yerli ve milli ürünlere
          güveni arttırdı. 2018’den sonra görev süresinin sonuna doğru Savunma
          Sanayii İcra Komitesi Üyesi olarak da görev yaptı. <br />
          <br />
        </Typography>
        <Typography>
          Görevi sırasında İstanbul Ticaret Üniversitesi, Türk Hava Kurumu
          Üniversitesi Mütevelli Heyeti ve Türk Silahlı Kuvvetleri Güçlendirme
          Vakfı Mütevelli Heyeti ve Yönetim Kurulu Üyeliğinde görev aldı. Son 4
          yıldır da uluslararası anlaşma ile kurulan Türk Japon Üniversitesi
          Konsey Başkanlığını sürdürmektedir. <br />
          <br />
        </Typography>
        <Typography>
          Haziran 2023 tarihinde Cumhurbaşkanlığı Savunma Sanayii Başkanlığı
          görevinden ayrıldıktan sonra KARDEMİR Yönetim Kurulu Başkanı seçilerek
          görevini yürütmektedir. <br />
          <br />
        </Typography>
      </>
    ),
  },
  {
    name: "Ömer Demirhan",
    title: "Yönetim Kurulu Başkan V.",
    image: foto2,
    link: "#",
    summary: (
      <>
        <Typography>
          İş Yatırım, Çalık Holding, KPMG ve EWE gibi özel sektör şirketlerinde
          iş geliştirme, proje finansmanı, kurumsal finans gibi alanlarda
          yönetici pozisyonunda çalışan Ömer Demirhan, Enerji ve Tabi kaynaklar
          Bakanlığı ve sorasında da Hazine ve Maliye Bakanlığı’nda beş yıl görev
          yapmıştır. <br />
          <br />
        </Typography>
        <Typography>
          Lisans eğitimini Boğaziçi Üniversitesi İşletme Bölümü’nde tamamlayan
          Ömer Demirhan, yüksek lisansını ise Bilgi Üniversitesi Ekonomi ve
          Finans Bölümü’nde yapmıştır. <br />
          <br />
        </Typography>
      </>
    ),
  },
  {
    name: "Prof. Dr. Arif Nihat Güllüoğlu",
    title: "Yönetim Kurulu Üyesi",
    image: foto3,
    link: "#",
    summary: (
      <>
        <Typography>
          Alpha Sigma Mu'a seçildi. 1988 yılında Los Alamos National
          Laboratory’da Ziyaretçi Araştırmacı olarak bulundu. 1991-1992 yılları
          arasında Florida Atlantic University’de doktora sonrası araştırmacı
          olarak görev yaptıktan sonra yurda dönerek İTÜ Uçak ve Uzay Bilimleri
          Fakültesi Uçak Mühendisliği Bölümü’nde göreve başladı. 1993 yılında
          Doçent oldu. Aynı Fakültede Bölüm Başkan Yardımcılığı ve Dekan
          Yardımcılığı görevlerini yaptı. 1997 yılında Marmara Üniversitesi
          Mühendislik Fakültesine geçerek, Metalurji ve Malzeme Mühendisliği
          Bölümü’nün kuruluş çalışmalarında bulundu. 1998 yılında Fulbright
          Bursunu alarak ABD’de araştırmalarda bulundu.
          <br />
          <br />
        </Typography>
        <Typography>
          1999 yılında Profesör olan Güllüoğlu, 2000-2016 yılları arasında
          Marmara Üniversitesinde Çeşitli Kurul ve Komisyon Üyelikleri, Anabilim
          Dalı Başkanlığı, Bölüm Başkanı, Dekan Yardımcısı, Mühendislik
          Fakültesi Senatörü, Rektör Danışmanı ve Rektör Yardımcılığının da
          aralarında bulunduğu birçok idari görev üstlendi. 2018-2020 yılları
          arasında TUSAŞ da Malzeme Mühendisliği Müdürü görevinde bulundu.
          Malzemelerin Matematik Modellemesi, Kompozitler, Malzeme Testi ve
          Kontrolü, Toz Teknolojisi, Isısal İşlem, Şekillendirme, Mekanik
          Özellikler, Birleştirme ve Kesme ilgilendiği başlıca araştırma
          konularıdır. Bu konularda yayınlanmış çok sayıda uluslararası bilimsel
          makalesi vardır ve yayınları kitap ve değişik alanlardaki makalelerden
          çok sayıda atıf almıştır. Çeşitli mesleki dernek ve vakıf üyesidir.
          Prof. Dr. Arif Nihat Güllüoğlu, evli ve iki çocuk babasıdır. <br />
          <br />
        </Typography>
      </>
    ),
  },
  {
    name: "Prof. Dr. Ercümend Arvas",
    title: "Yönetim Kurulu Üyesi",
    image: foto4,
    link: "#",
    summary: (
      <>
        <Typography>
          Prof. Dr. Ercümend Arvas, 2014 yılından bu yana İstanbul Medipol
          Üniversitesi Elektrik Mühendisliği Bölümü’nde Profesör olarak
          çalışmalarını sürdürmektedir.
          <br />
          <br />
        </Typography>
        <Typography>
          1992-1994 yılları arasında Modern Ent’te Genel Müdür olarak görev alan
          Prof. Dr. Ercümend Arvas, 1994-2014 yılları arasında Profesör;
          1988-1994 yılları arasında Doçent olarak Syracuse Unversity’de görev
          almıştır. 1987-1988 yılları arasında Syracuse University’de; 1984-1987
          yılları arasında Rochester Institute of Technology’de; 1983-1984
          yıllarında ise Yıldız Üniversitesi’nde Yardımcı Doçent görevlerinde
          bulunmuştur. <br />
          <br />
        </Typography>
        <Typography>
          Prof. Dr. Ercümend Arvas’ın çok sayıda ulusa ve uluslararası kongre,
          konferans, sempozyumda sunulmuş ve yayınlanmış makalesi ve
          bildirileri, kitapları ve kitap bölümleri bulunmaktadır. Force Office
          of Scientific Research Digital Equipment Co., Philips Broadband, RIT
          Research Corp., Syracuse Research Corp, ANAREN Microwaves Inc, General
          Electric Co. ve TUBİTAK danışmanlık verdiği firmalar arasında yer
          almaktadır. <br />
          <br />
        </Typography>
        <Typography>
          Prof. Dr. Ercümend Arvas, lisans ve yüksek lisans eğitimlerini Orta
          Doğu Teknik Üniversitesi Elektrik Mühendisliği Bölümünde, doktora
          eğitimini ise New York ABD’de Syracuse University’de 1983 yılında
          tamamlamıştır. <br />
          <br />
        </Typography>
      </>
    ),
  },
  {
    name: "Fatih Mehmet Erkoç",
    title: "Yönetim Kurulu Üyesi",
    image: foto5,
    link: "#",
    summary: (
      <>
        <Typography>
          2014 yılında AK Parti Kahramanmaraş Büyükşehir Belediye Başkanlığına
          seçilen Fatih Mehmet Erkoç, 2009-2014 yılları arasında AK Parti
          Kahramanmaraş İl Başkanlığı; 2003-2008 yılları arasında Merkez İlçe
          Başkanlığı; 2001 yılında ise Merkez İlçe Yönetim Kurulu Üyeliği ve
          Merkez İlçe Başkan Yardımcılığı görevlerinde bulundu.
          <br />
          <br />
        </Typography>
        <Typography>
          Yüksek lisans eğitimini Niğde Üniversitesi Fen Bilimleri Enstitüsünde
          tamamlayan Fatih Mehmet Erkoç, Kahramanmaraş’ta serbest mühendislik ve
          müşavirlik bürosu açarak iş hayatına başladı. <br />
          <br />
        </Typography>
      </>
    ),
  },
  {
    name: "Hacı Abdullah Kaya",
    title: "Yönetim Kurulu Üyesi",
    image: foto6,
    link: "#",
    summary: (
      <>
        <Typography>
          H. Abdullah KAYA, 1965 yılında Kayseri / Bünyan ilçesinde doğdu. 1987
          Yılında Bursa Uludağ Üniversitesi İktisadi ve İdari Bilimler Fakültesi
          Kamu Yönetimi bölümünden mezun oldu. 1998-2000 yılları arasında
          A.B.D’de Carnegie Mellon Üniversitesinde Kamu Yönetimi dalında yüksek
          lisansını tamamladı.
          <br />
          <br />
        </Typography>
        <Typography>
          Çalışma hayatına 1987 yılında Bursa’da özel sektörde başladı,
          1988-1989 yılları arasında İçişleri Bakanlığı Uzman Yardımcısı,
          1989-2003 yıllarında Ankara ve İstanbul’da Maliye Müfettişi, 1997
          yılında İstanbul Defterdar Yardımcısı, 2003 yılında Maliye Teftiş
          Kurulu Başkan Yardımcısı, 2004 yılında Bütçe ve Mali Kontrol Genel
          Müdürlüğü Daire Başkanı olarak çeşitli görevlerde bulundu. <br />
          <br />
        </Typography>
        <Typography>
          Bu Genel Müdürlükte 2007 yılına kadar Daire Başkanı ve Genel Müdür
          Yardımcısı olarak görev yaptıktan sonra 2007 yılında Devlet Malzeme
          Ofisi Genel Müdürü olarak atandı. 2008- 2016 yılları arasında Milli
          Emlak Genel Müdürlüğü görevini yürüttü. Aynı yıl Bütçe ve Mali Kontrol
          Genel Müdürü ve sonrasında Maliye Müsteşarı olarak atandı. 2018 yılına
          kadar aktif olarak Müsteşarlık görevini sürdürdü ve 2023’e kadar Şahsa
          Bağlı Maliye Müsteşar kadrosunda bulundu. Ekim 2023 ayında emekli oldu
          ve halen özel sektörde çalışmasını sürdürmektedir. <br />
          <br />
        </Typography>
        <Typography>
          2016- 2022 yılları arasında Yükseköğretim Kurulu Üyeliği görevini,
          2019-2023 yıllarında da Sulh Hukuk Mahkemesi kararıyla Türk Hava
          Kurumu Kayyum Heyetinde Başkan Vekilliği ve Başkanlık görevlerini
          yürüttü. <br />
          <br />
        </Typography>
        <Typography>
          Evli ve üç çocuk babası olan H. Abdullah KAYA, iyi derece İngilizce ve
          orta derece Arapça bilmektedir. <br />
          <br />
        </Typography>
      </>
    ),
  },
  {
    name: "Kamil Güleç",
    title: "Yönetim Kurulu Üyesi",
    image: foto7,
    link: "#",
    summary: (
      <>
        <Typography>
          2018-2019 yılları arasında Kardemir A.Ş.’de Yönetim Kurulu Başkanlığı;
          1995-2017 yılları arasında Yönetim Kurulu Başkan Vekili görevlerinde
          bulunan Kamil Güleç, Çağ Çelik A.Ş.’nin kurucusu ve Yönetim Kurulu
          Başkanı’dır. 1995 yılına kadar Karabük Sanayi ve Ticaret Odası
          Başkanlığı’nı sürdüren Kamil Güleç, Karabük Demir ve Çelik
          Fabrikaları’nın özelleştirilmesi sürecinde Müteşebbis Heyet Başkanlığı
          da yapmıştır.
          <br />
          <br />
        </Typography>
        <Typography>
          1978-1979 yılları arasında yatırımını tamamlayarak kendisine ait sıcak
          haddeleme tesisini devreye alan Kamil Güleç, 1980’li yıllarda başlayan
          ihracat girişimleri ile hafif ticari çubuk üretimine başlamıştır.{" "}
          <br />
          <br />
        </Typography>
        <Typography>
          Gerçekleştirdiği önemli sosyal sorumluluk projeleri ile (Güleç Sevgi
          Evleri ve Kamil Güleç Kütüphanesi) nedeniyle 2010 yılında Türkiye
          Büyük Millet Meclisi Başkanlığı tarafından “Üstün Hizmet Ödülü’ne”
          layık görülmüştür. <br />
          <br />
        </Typography>
        <Typography>
          2016- 2022 yılları arasında Yükseköğretim Kurulu Üyeliği görevini,
          2019-2023 yıllarında da Sulh Hukuk Mahkemesi kararıyla Türk Hava
          Kurumu Kayyum Heyetinde Başkan Vekilliği ve Başkanlık görevlerini
          yürüttü. <br />
          <br />
        </Typography>
        <Typography>
          Kamil Güleç, 1969 yılında Ankara Üniversitesi İktisadi ve İdari
          Bilimleri Fakültesi Sanayi İşletmeciliği ve İktisat Bölümü’nden mezun
          olmuştur. <br />
          <br />
        </Typography>
      </>
    ),
  },
  {
    name: "Prof. Dr. Mehmet Cahit Ensari",
    title: "Yönetim Kurulu Üyesi",
    image: foto8,
    link: "#",
    summary: (
      <>
        <Typography>
          1948 yılında Mardin’ de doğdu.
          <br />
          <br />
        </Typography>
        <Typography>
          1967 yılında İstanbul Saint Joseph Fransız Erkek Lisesinden, 1972
          yılında İ.T.Ü. Maden Fakültesi Metalürji Mühendisliği Bölümünden mezun
          oldu.
          <br />
          <br />
        </Typography>
        <Typography>
          Aynı yıl mezun olduğu bölüme asistan olarak girdi ve 1977 yılında
          Doktor ünvanını aldı. 1979 -1981 yılları arasında, Tübitak
          post-doktora bursu ile gittiği Kanada’ nın Waterloo Üniversitesinde,
          iki yıl süreyle araştırmalarda bulundu. 1982 yılında Mekanik Metalürji
          dalında Doçent ünvanını aldı. <br />
          <br />
        </Typography>
        <Typography>
          1982 – 2010 yılları arasında, sırasıyla Trakya Döküm San. Tic. A.Ş.,
          ABC Danışmanlık Mümessillik Tic. A.Ş. ve METEK Mühendislik Mümessillik
          Tic. Ltd. Şti. özel sektör kuruluşlarında üst düzey yöneticilik yaptı.{" "}
          <br />
          <br />
        </Typography>
        <Typography>
          Şubat 2011 de Yalova Üniversitesinde Profesör kadrosuna atandı. Kimya
          ve Süreç Mühendisliği Bölüm Başkanı, Fen Bilimleri Enstitüsü Müdür
          Yardımcısı, Kalite Koordinatörü görevlerinde bulundu. Ağustos 2020
          tarihinde emekliye ayrıldı. <br />
          <br />
        </Typography>
        <Typography>
          Evli, iki çocuğu bulunan M.Cahit ENSARİ, Fransızca ve İngilizce
          bilmektedir. <br />
          <br />
        </Typography>
      </>
    ),
  },
  {
    name: "Muhammed Ali Oflaz",
    title: "Yönetim Kurulu Üyesi",
    image: foto9,
    link: "#",
    summary: (
      <>
        <Typography>
          T.C. Enerji ve Tabii Kaynaklar Bakan Danışmanı olarak görevini
          sürdüren Muhammed Ali Oflaz, 2019-2020 yıllarında Zonguldak, Karabük
          ve Bartın illerinde faaliyet gösteren Batı Karadeniz Kalkınma
          Ajansı’nda (BAKKA) Genel Sekreterlik yaptı. Ankara Kalkınma Ajansı’nda
          çeşitli birimlerde uzman, İş Geliştirme ve Strateji Birim Başkanı ve
          Yatırım Destek Ofisi Koordinatörü olarak çalıştı. 2010 yılında Hazine
          Müsteşarlığı Merkezi Finans ve İhale Birimi’nde İhale Yöneticisi,
          2006-2010 yılları arasında ise Kültür ve Turizm Bakanlığı’nda önce
          Telif Hakları ve Sinemalar Genel Müdürlüğü'nde, ardından Yatırım ve
          İşletmeler Genel Müdürlüğü’nde Kültür ve Turizm Uzmanı olarak görev
          aldı.
          <br />
          <br />
        </Typography>
        <Typography>
          2006 yılında Hak-İş Konfederasyonu’na bağlı Öz İplik-İş Sendikası’nda
          Genel Başkan Danışmanlığı yapan Muhammed Ali Oflaz, 2004-2006 yılları
          arasında ise internet teknolojileri alanında özel sektörde yer aldı.
          <br />
          <br />
        </Typography>
        <Typography>
          Gazi Üniversitesi İşletme Bölümü’nden 2004 yılında mezun olan Muhammed
          Ali Oflaz, İstanbul Ticaret Üniversitesi Sanayi Politikaları ve
          Teknoloji Yönetimi Programı'nda yüksek lisans eğitimine devam
          etmektedir. İyi derecede İngilizce; başlangıç seviyesinde ise Arapça
          ve Rusça bilmektedir. <br />
          <br />
        </Typography>
      </>
    ),
  },
  {
    name: "Mustafa Aydın",
    title: "Yönetim Kurulu Üyesi",
    image: foto10,
    link: "#",
    summary: (
      <>
        <Typography>
          2017 yılından itibaren belirli büyüklüğe sahip şirketlere yurtiçi ve
          yurtdışı finansman temini, birleşme ve satın alma konularında
          danışmanlık hizmeti veren Mustafa Aydın, 2014 yılından itibaren,
          Türkiye Halk Bankası A.Ş.’de Genel Müdür Yardımcılığı, Türk Halk
          Bankası Emekli Sandığı Vakfı Yönetim Kurulu Üyeliği ve Başkanlığı,
          Türk P ve I Sigorta A.Ş.’de Yönetim Kurulu Başkan Yardımcılığı, Halk
          Bankası’nın Hollanda’da faaliyet gösteren iştiraki Demir Halk Bank’ta
          Yönetim Kurulu Üyeliği, Makedonya’daki iştiraki Halk Bank Skopje’nin
          Yönetim Kurulu Başkan Yardımcılığı, Halk bank Belgrade’de Yönetim
          Kurulu Başkan Yardımcılığı ve Başkanlığı görevlerinde bulunmuştur.
          <br />
          <br />
        </Typography>
        <Typography>
          2009 yılına kadar Türk Hava Yolları’nda Finansman Başkanlığı’nı
          üstlenen Mustafa Aydın, daha sonra Bosna Hersek Hava Yolları’nda CFO
          ve Yönetim Kurulu Üyeliği, Türk Hava Yolları Teknik A.Ş.’de İştirakler
          Mali Koordinatörü, Negmar Denizcilik Yatırım A.Ş.’de Grup CFO’su
          görevlerinde bulundu. 1992-2003 yılları arasında ise Türkiye
          Öğretmenler Bankası, Nahcivan Türk Bankası, AGK Dış Ticaret Ltd. Şti.,
          Albaraka Türk Finans Kurumu ve özel bankalarda Müfettiş Yardımcısı,
          Müfettiş, Şube Müdürü ve Genel Müdür olarak görev yaptı.
          <br />
          <br />
        </Typography>
        <Typography>
          1989 yılında Orta Doğu Teknik Üniversitesi İktisadi ve İdari Bilimler
          Fakültesi Kamu Yönetimi Bölümünden mezun olan Mustafa Aydın, ileri
          derecede İngilizce bilmektedir. <br />
          <br />
        </Typography>
      </>
    ),
  },
  {
    name: "Yusuf Canbolat",
    title: "Yönetim Kurulu Üyesi",
    image: foto11,
    link: "#",
    summary: (
      <>
        <Typography>
          Osmaniye ‘de doğdu. İstanbul Üniversitesi İktisat Fakültesi Maliye
          Bölümünden 1988 yılında mezun oldu. 1990 yılında Albaraka Türk Genel
          Müdürlük Fon Kullandırma Müdürlüğünde iş hayatına başlayan Canbolat,
          1990-2015 yılları arasında Katılım Bankacılığı sektöründe, Proje
          Pazarlama ile Mali Tahlil ve İstihbarat Müdürlüğünde uzman yardımcısı
          ve uzman, akabinde Adana, İzmir ve Ankara -Ulus şube müdürü, son
          olarak da Ege Bölge Müdürü olarak görev yaptı.
          <br />
          <br />
        </Typography>
        <Typography>
          2015 yılında Elazığ Belediyesi Ulaşım A.Ş.’ de Genel Müdür
          Yardımcılığı görevinde bulundu.
          <br />
          <br />
        </Typography>
        <Typography>
          Mayıs 2016 – Kasım 2019 tarihleri arasında, Ulaştırma ve Altyapı
          Bakanlığı, PTT A.Ş. Genel Müdürlüğü’nde Genel Müdür Yardımcısı olarak
          görev yaptı. PTT Biriktirme Yardım Sandığı ve PTT - İMS A.Ş.’ de
          Yönetim Kurulu Başkanlıkları görevlerinde bulundu. <br />
          <br />
        </Typography>
        <Typography>
          Aralık 2019 – Kasım 2020 döneminde PTT A.Ş. Genel Müdürlüğü’nde
          müşavir olarak görev yapan Canbolat, 2 Kasım 2020 tarihinden bu yana
          KARDEMİR A.Ş.’ de Yönetim Kurulu üyesi olarak görev yapmaktadır.{" "}
          <br />
          <br />
        </Typography>
      </>
    ),
  },
  // Diğer üyeler
];

function YonetimKuruluContents() {
  const [summary, setSummary] = useState(true);
  const [summaryInfo, setSummaryInfo] = useState({});

  const handleSummaryClick = (image, name, title, summary) => {
    setSummary(false);
    setSummaryInfo({ image, name, title, summary });
  };

  const StyledPaper = styled(Paper)(({ theme }) => ({
    // padding: theme.spacing(4),
    // backgroundColor: "#ffffff",
    // boxShadow: theme.shadows[3],
    overflowX: "auto",
    maxWidth: 1000,
    margin: "0 auto",
    padding: "2rem",
  }));

  const Header = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: theme.spacing(3),
  }));

  return (
    <>
      <Box sx={{ padding: "1rem 0.2rem" }}>
        <Box sx={{ flexGrow: 1, padding: 1 }}>
          <Header variant="h4" color="#1f2e5e">
            {" "}
            Yönetim Kurulu
          </Header>

          <StyledPaper>
            <Grid
              container
              spacing={0} // Grid elemanları arasında boşluk olmaması için spacing={0}
              justifyContent="center" // Ortaya hizalamak için
            >
              {yonetimKurulu.slice(0, 2).map((member, index) => (
                <Grid item key={index}>
                  <Box
                    onClick={() =>
                      handleSummaryClick(
                        member.image,
                        member.name,
                        member.title,
                        member.summary
                      )
                    }
                    sx={{
                      textAlign: "center",
                      borderRadius: "8px",
                      overflow: "hidden",
                      cursor: "pointer",
                      padding: "2rem 0",
                      margin: "0.1rem",
                      mx: "1rem", // Her iki kutu arasında 2rem'lik boşluk için margin-horizontal
                      height: "80%",
                      width: "15rem",
                      backgroundColor: "#f5f5f5",
                      transition: "transform 0.3s, background-color 0.3s",
                      "&:hover": {
                        transform: "scale(1.05)",
                        backgroundColor: "#1f2e5e",
                        color: "white",
                      },
                    }}
                  >
                    <Box
                      component="img"
                      src={member.image}
                      alt={member.name}
                      sx={{
                        height: "200px",
                        objectFit: "cover",
                        // borderRadius: "50%",
                      }}
                    />
                    <Box sx={{ padding: "1rem" }}>
                      <Typography variant="h6">{member.name}</Typography>
                      <Typography variant="subtitle2">
                        {member.title}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>

            <Grid
              container
              spacing={0} // Grid elemanları arasında boşluk olmaması için spacing={0}
              justifyContent="center" // Ortaya hizalamak için
            >
              {yonetimKurulu.slice(2).map((member, index) => (
                <Grid item key={index}>
                  <Box
                    onClick={() =>
                      handleSummaryClick(
                        member.image,
                        member.name,
                        member.title,
                        member.summary
                      )
                    }
                    sx={{
                      textAlign: "center",
                      borderRadius: "8px",
                      overflow: "hidden",
                      cursor: "pointer",
                      padding: "2rem 0",
                      margin: "0.5rem",
                      height: "80%",
                      width: "15rem",
                      backgroundColor: "#f5f5f5",
                      transition: "transform 0.3s, background-color 0.3s",
                      "&:hover": {
                        transform: "scale(1.05)",
                        backgroundColor: "#1f2e5e",
                        color: "white",
                      },
                    }}
                  >
                    <Box
                      component="img"
                      src={member.image}
                      alt={member.name}
                      sx={{
                        height: "200px",
                        objectFit: "cover",
                        // borderRadius: "50%",
                      }}
                    />
                    <Box sx={{ padding: "1rem" }}>
                      <Typography variant="h6">{member.name}</Typography>
                      <Typography variant="subtitle2">
                        {member.title}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </StyledPaper>
        </Box>
      </Box>

      {!summary && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 9999,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              maxWidth: "800px",
              height: "80%",
              maxHeight: "90vh",
              backgroundColor: "white",
              zIndex: 999,
              borderTop: "5px solid #9e2632",
              borderLeft: "5px solid #9e2632",
              borderRight: "5px solid #0e2542",
              borderBottom: "5px solid #0e2542",
              borderRadius: "8px",
              overflow: "hidden",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
              justifyContent: "space-between",
              mx: "10%",

              flexDirection: "row",
              "@media (max-width: 600px)": {
                flexDirection: "column",
                width: "90%",
              },
            }}
          >
            <Box
              sx={{
                flexShrink: 0,
                width: "37%",
                backgroundColor: "#0e2542",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "16px",
                boxSizing: "border-box",
                position: "relative",
                "@media (max-width: 600px)": {
                  width: "100%",
                  height: "auto",
                  padding: "8px",
                },
              }}
            >
              <CloseIcon
                onClick={() => setSummary(true)}
                sx={{
                  cursor: "pointer",
                  padding: "8px",
                  backgroundColor: "white",
                  borderRadius: "50%",
                  position: "absolute",
                  top: "16px",
                  left: "16px",
                }}
              />
              <Box
                component="img"
                src={summaryInfo.image}
                alt={summaryInfo.name}
                sx={{
                  width: "100%",
                  maxWidth: "150px",
                  height: "auto",
                  objectFit: "cover",
                  // borderRadius: "50%",
                  marginBottom: "16px",
                }}
              />
              <Typography sx={{ color: "white", textAlign: "center" }}>
                {summaryInfo.name}
              </Typography>
              <Typography
                sx={{ marginTop: "8px", color: "white", textAlign: "center" }}
              >
                {summaryInfo.title}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "63%",
                padding: "16px",
                overflowY: "auto",
                height: "100%",
                "@media (max-width: 600px)": {
                  width: "100%",
                  padding: "8px",
                },
              }}
            >
              <Box
                sx={{
                  maxHeight: "calc(100% - 40px)",
                  overflowY: "auto",
                }}
              >
                {summaryInfo.summary}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

export default YonetimKuruluContents;
