import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import video1 from "../../video/V-1.mp4";
import video2 from "../../video/V-2.mp4";
import video3 from "../../video/V-3.mp4";
import video4 from "../../video/V-4.mp4";
import video5 from "../../video/V-5.mp4";
import foto1 from "../../images/Haberler/4.jpg";
import foto2 from "../../images/Haberler/1.jpg";
import foto3 from "../../images/Haberler/3.jpg";
import foto5 from "../../images/Haberler/5.jpg";
import foto6 from "../../images/Haberler/6.jpg";
import foto7 from "../../images/Haberler/7.jpg";
import foto8 from "../../images/Haberler/8.jpg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowRightAlt";
import { styled } from "@mui/system";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Lazy } from "swiper";
import enkalin from "../../images/enkalin.png";
import tekray from "../../images/tekray.jpg";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Counter from "../Counter";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import fotolar1 from "../../images/anasayfafoto/1.jpg";
import fotolar2 from "../../images/anasayfafoto/2.jpg";
import fotolar3 from "../../images/anasayfafoto/3.jpg";
import fotolar4 from "../../images/anasayfafoto/4.jpg";
import fotolar5 from "../../images/anasayfafoto/5.jpg";
import fotolar6 from "../../images/anasayfafoto/6.jpg";
import fotolar7 from "../../images/anasayfafoto/7.jpg";
import fotolar8 from "../../images/anasayfafoto/8.jpg";
import fotolar9 from "../../images/anasayfafoto/9.jpg";
import fotolar10 from "../../images/anasayfafoto/10.jpg";
import fotolar11 from "../../images/anasayfafoto/11.jpg";
import fotolar12 from "../../images/anasayfafoto/12.jpg";
import fotolar13 from "../../images/anasayfafoto/13.jpg";
import fotolar14 from "../../images/anasayfafoto/14.jpg";
import fotolar15 from "../../images/anasayfafoto/15.jpg";
import fotolar16 from "../../images/anasayfafoto/16.jpg";
import fotolar17 from "../../images/anasayfafoto/17.jpg";
import fotolar18 from "../../images/anasayfafoto/18.jpg";
import fotolar19 from "../../images/anasayfafoto/19.jpg";
import fotolar20 from "../../images/anasayfafoto/20.jpg";
import fotolar21 from "../../images/anasayfafoto/21.jpg";
import kariyerfoto from "../../images/kariyerfoto.jpg";
import kardemir from "../../images/kardemir.jpg";
import PopupShow from "./PopupShow";
import { useNavigate } from "react-router-dom";

const infoBoxes = [
  {
    imgSrc: foto1,
    title:
      "KARDEMİR 2024 Yılı Kısa Dönem Yaz Staj Kayıtları Hakkında Bilgilendirme",
    description:
      " Şirketimizde üretim, yönetim organizasyon ve atölye stajı yapacak stajyer adaylarımızın staj kabul...",
    url: "/haberler/2024-yili-kisa-donem-yaz-staj-kayitlari-hakkinda-bilgilendirme",
  },
  {
    imgSrc: foto2,

    title: "AR-GE Mühendisi İşe Alım Sonuçları Kamuoyu Bilgilendirmesi",
    description:
      "Şirketimizde AR-GE Mühendisi pozisyonumuz için yapılan işe alım sürecine ilişkin ilgili işlemler...",
    url: "/haberler/arge-muhendisi-ise-alim-sonuclari-hakkinda-kamuoyu-bilgilendirmesi",
  },
  {
    imgSrc: foto3,

    title:
      "Kardemir Dijital Dönüşüm ve Bilgi Teknolojileri Direktörlüğü İş İlanı ",
    description:
      "KARDEMİR Dijital Dönüşüm ve Bilgi Teknolojileri Direktörlüğü bünyesinde, on yedi (17)...",
    url: "/haberler/dijital-donusum-ve-bilgi-teknolojileri-direktorlugu-is-ilani",
  },
  {
    imgSrc: foto5,
    title: "2023 Kardemir Olağan Genel Kurul Toplantısı",
    description:
      " Şirketimizin 2023 yılı faaliyetlerinin ele alınacağı Olağan Genel...",
    url: "/haberler/olagan-genel-kurul-toplantisi",
  },
  {
    imgSrc: foto6,

    title: "Basın ve Kamuoyuna Bilgilendirme",
    description:
      "Şirketimizin yatırım planı kapsamında, yurtiçi ve yurtdışında...",
    url: "/haberler/basin-ve-kamuoyuna-bilgilendirme",
  },

  {
    imgSrc: foto7,

    title:
      "Yönetim Kurulu Başkanımız Sayın Prof. Dr. İsmail Demir’in Basın Açıklaması",
    description: "Şirketimiz Yönetim Kurulu Başkanı Prof. Dr. İsmail Demir...",
    url: "/haberler/yonetim-kurulu-baskanimiz-sayin-prof-dr-ismail-demirin-basin-aciklamasi",
  },
  {
    imgSrc: foto8,

    title: "Olağanüstü Genel Kurul'da Belirlenen Yönetim Kurulu Kadromuz",
    description:
      "23/10/2023 Pazartesi, şirketimiz Eğitim ve Kültür Merkezinde...",
    url: "/haberler/olaganustu-genel-kurulda-belirlenen-yonetim-kurulu-kadromuz",
  },
];

const InfoCard = styled(Card)(({ theme }) => ({
  position: "relative",
  transition: "0.3s",
}));

const HoverContent = styled(CardContent)(({ theme }) => ({
  transition: "opacity 0.3s",
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  // background: "#1f2e5e",
  padding: theme.spacing(2),
  opacity: 1,
  // background: "linear-gradient(to bottom, #fff, #1f2e5e)",

  background: "white",
}));

const ReadMore = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  cursor: "pointer",
  transition: "transform 0.3s",
  "&:hover": {
    transform: "translateX(10px)",
  },
  color: "#1f2e5e",
}));

const Root = styled("div")(({ theme }) => ({
  backgroundImage: `url(${kardemir})`,
  backgroundSize: "cover",
  height: "60vh",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  padding: theme.spacing(4),
  color: "#fff",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  overflow: "hidden",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
    height: "100vh",
  },
}));

const handleReadMore = (url) => {
  window.location.href = url;
};

const PulsingButton = styled(Box)({
  position: "absolute",
  bottom: "40px",
  left: "50%",
  transform: "translateX(-50%)",
  width: "50px",
  height: "50px",
  backgroundColor: "#d11f25",
  color: "white",
  fontSize: "2rem",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 3,
  cursor: "pointer",
  "&:before": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    backgroundColor: "rgba(209, 31, 37, 0.5)",
    transform: "translate(-50%, -50%)",
    zIndex: -1,
    animation: "pulse 2s infinite",
  },
  "@keyframes pulse": {
    "0%": {
      width: "50px",
      height: "50px",
      opacity: 1,
    },
    "100%": {
      width: "150px",
      height: "150px",
      opacity: 0,
    },
  },
});

const handleClick = () => {
  window.location.href = "/haberler";
};

const HomeNew = () => {
  const [randomVideo, setRandomVideo] = useState("");
  const [randomText, setRandomText] = useState("");
  const [fade, setFade] = useState(false);
  // const [isBoxVisible, setBoxVisible] = useState(true);
  const [startTime, setStartTime] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [visible, setVisible] = useState(true);
  const [isClosing, setIsClosing] = useState(false);
  const navigate = useNavigate();
  const [slidesToShow, setSlidesToShow] = useState(5);
  const [inView, setInView] = useState(false);
  const videos = [video1, video2, video3, video4, video5];
  const texts = [
    "Türkiye’nin Fabrikası",
    "Fabrikalar Kuran Fabrika",
    "Köklü Geçmiş Güçlü Gelecek",
  ];

  // Ekran genişliğini kontrol eden MediaQuery kancaları
  const isXxs = useMediaQuery("(max-width:650px)"); // Mobil
  const isXs = useMediaQuery("(min-width:650px) and (max-width:950px)"); // Tablet
  const isSm = useMediaQuery("(min-width:950px) and (max-width:1250px)"); // Tablet
  const isMd = useMediaQuery("(min-width:1250px) and (max-width:1560px)"); // Küçük ekran
  const isLg = useMediaQuery("(min-width:1560px)"); // Büyük ekran

  // Ekran genişliğine göre kart sayısını ayarla
  const slidesPerView = isXxs
    ? 1
    : isXs
    ? 2
    : isSm
    ? 3
    : isMd
    ? 4
    : isLg
    ? 5
    : 6; // Varsayılan

  const imagesSet1 = [
    { src: fotolar4, alt: "foto1" },
    { src: fotolar10, alt: "foto2" },
    { src: fotolar9, alt: "foto3" },
    { src: fotolar21, alt: "foto4" },
    { src: fotolar18, alt: "foto5" },
    { src: fotolar2, alt: "foto6" },
    { src: fotolar17, alt: "foto7" },
  ];

  const imagesSet2 = [
    { src: fotolar1, alt: " Resim 8" },
    { src: fotolar13, alt: " Resim 9" },
    { src: fotolar12, alt: " Resim 10 " },
    { src: fotolar14, alt: " Resim 11" },
    { src: fotolar11, alt: " Resim 12" },
    { src: fotolar3, alt: " Resim 13" },
    { src: fotolar19, alt: " Resim 14" },
  ];

  const imagesSet3 = [
    { src: fotolar15, alt: " Resim 8" },
    { src: fotolar16, alt: " Resim 9" },
    { src: fotolar8, alt: " Resim 10 " },
    { src: fotolar5, alt: " Resim 11" },
    { src: fotolar20, alt: " Resim 12" },
    { src: fotolar6, alt: " Resim 13" },
    { src: fotolar7, alt: " Resim 14" },
  ];

  const ImageGrid = ({ images }) => {
    return (
      <Grid container spacing={1} display={"flex"}>
        <Grid item xs={12} sm={4} md={3}>
          <Box
            component="img"
            src={images[0].src}
            alt={images[0].alt}
            sx={{
              width: "100%",
              height: "30vh",
              objectFit: "cover",
            }}
          />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Box
                component="img"
                src={images[1].src}
                alt={images[1].alt}
                sx={{
                  width: "100%",
                  height: "10vh",
                  objectFit: "cover",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Box
                component="img"
                src={images[2].src}
                alt={images[2].alt}
                sx={{
                  width: "100%",
                  height: "10vh",
                  objectFit: "cover",
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={4} md={6}>
          <Box
            component="img"
            src={images[3].src}
            alt={images[3].alt}
            sx={{
              width: "100%",
              height: "40vh",
              objectFit: "cover",
              display: "flex",
              margin: "auto",
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Box
                component="img"
                src={images[4].src}
                alt={images[4].alt}
                sx={{
                  width: "100%",
                  height: "10vh",
                  objectFit: "cover",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Box
                component="img"
                src={images[5].src}
                alt={images[5].alt}
                sx={{
                  width: "100%",
                  height: "10vh",
                  objectFit: "cover",
                }}
              />
            </Grid>
          </Grid>
          <Box
            component="img"
            src={images[6].src}
            alt={images[6].alt}
            sx={{
              width: "100%",
              height: "30vh",
              objectFit: "cover",
            }}
          />
        </Grid>
      </Grid>
    );
  };

  // visible değişkenine bağlı olan useEffect
  useEffect(() => {
    if (!visible) {
      return;
    }

    const randomIndex = Math.floor(Math.random() * videos.length);
    setRandomVideo(videos[randomIndex]);
    setRandomText(
      texts[randomIndex] ||
        "Türkiye’nin Fabrikası" ||
        "Fabrikalar Kuran Fabrika" ||
        "Köklü Geçmiş Güçlü Gelecek"
    );

    // Metnin görünmesini beklemek için küçük bir gecikme ayarla
    setFade(false);
    setTimeout(() => setFade(true), 100); // 100 ms gecikme ile fade-in başlat
  }, [visible]);

  // scroll olayını izlemek için ayrı bir useEffect
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setInView(true); // Root görünür olduğunda counter başlatılır
          } else {
            setInView(false); // Root görünür olmadığında counter durdurulur
          }
        });
      },
      { threshold: 0.5 } // %50 görünürlükte tetiklenmesi için
    );

    const target = document.querySelector(".istatistik");

    if (target) observer.observe(target);

    return () => observer.disconnect();
  }, []);

  const handleClickOpen = () => {
    navigate("/urunler");
  };

  const handleClickOpen2 = () => {
    navigate("/surdurulebilirlik-yonetimi");
  };

  const MobileSwiper = () => {
    return (
      <Swiper
        modules={[Pagination, Autoplay]}
        spaceBetween={60}
        slidesPerView={1}
        pagination={{ clickable: true }}
        loop={true}
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
      >
        {infoBoxes.map((info, index) => (
          <SwiperSlide key={index}>
            <InfoCard sx={{ width: "20rem" }}>
              <CardMedia
                component="img"
                height="240"
                image={info.imgSrc}
                alt={info.title}
              />
              <CardContent>
                <Typography variant="h6" color="#1f2e5e">
                  {info.title}
                </Typography>
              </CardContent>
              <HoverContent>
                <Typography variant="h6" color="#1f2e5e">
                  {info.title}
                </Typography>
                <Typography variant="body2">{info.description}</Typography>
                <ReadMore
                  color="#1f2e5e"
                  onClick={() => handleReadMore(info.url)}
                >
                  <ArrowForwardIosIcon />
                  <Typography variant="body2" color="#010347">
                    Devamını Oku
                  </Typography>
                </ReadMore>
              </HoverContent>
            </InfoCard>
          </SwiperSlide>
        ))}
      </Swiper>
    );
  };

  const urunler = [
    { title: "Demiryolu Tekeri" },
    { title: "Demiryolu Rayları" },
    { title: "I Profiller" },
    { title: "HEA Profiller" },
    { title: "HEB Profiller" },
    { title: "IPE Profiller-Orta Geniş I Profiller/Takviyeli" },
    { title: "NPU Profiller" },
    { title: "GI ve TH Maden Direkleri" },
    { title: "Platinalar" },
    { title: "Kangallar" },
    { title: "Düz Yuvarlaklar" },
    { title: "Nervürlü - Beton Donatı Çelikleri" },
    { title: "Kütükler" },
    { title: "Kok Ürünleri" },
    { title: "Granüle Yüksek Fırın Cürufu" },
    { title: "MEROS Atığı (Sodyum Sülfat)" },
    { title: "Metalürjik Kireç" },
    { title: "Hava Ayrıştırma Tesisi Ürünleri" },
  ];

  return (
    <Box
      sx={{
        // backgroundColor: "#1f2e5e",
        backgroundColor: "#e8e8e8",
        // borderBottom: "2px solid black",
      }}
    >
      <PopupShow />
      <Box sx={{ height: "100vh" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            boxSizing: "border-box",
          }}
        >
          {randomVideo && (
            <div
              style={{ position: "relative", height: "100%", width: "100%" }}
            >
              <video
                src={randomVideo}
                autoPlay
                loop
                muted
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                  backgroundColor: "black",
                  opacity: 0.1,
                  pointerEvents: "none",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                  // backgroundImage: `linear-gradient(170deg, rgba(0, 0, 0, 0), #e5e5e5)`,

                  pointerEvents: "none",
                }}
              />
            </div>
          )}

          <Typography
            variant="h3"
            sx={{
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "#fff",
              fontWeight: "bold",
              padding: "1rem 2rem",
              zIndex: 1,
              borderRadius: "8rem",
            }}
          >
            {randomText}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          // backgroundColor: "yellow",
          mx: "5%",
        }}
      >
        <Box
          sx={{
            margin: "3rem 0",
            textAlign: "center",
            color: "#1f2e5e",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "2.5rem", lg: "3rem", fontWeight: "bold" },
            }}
          >
            Büyük Yatırım Hamlesi
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mt: 2,
              fontSize: "18px",
              // lineHeight: "1.6",
              textAlign: "justify",
            }}
          >
            Örnek bir sahiplenmeyle 30 Mart 1995’de gerçekleşen özelleştirme
            sonrasında 2 Milyar $’ın üzerindeki yatırım harcamasıyla üretim
            teknolojilerini yenileyen şirketimiz, bugün Türkiye’nin en büyük
            sanayi kuruluşlarından biri haline geldi. Demiryolu rayı üretiminde
            Türkiye’nin tek milli markasıyız. BİST 30 endeksinde yer alan
            şirketimizin hisselerinin tamamı halka açıktır. <br />
            <br />
            “Önceliğimiz insan” anlayışı ile faaliyetlerimizi sürdürüyoruz. Tüm
            süreçlerimizin omurgasını İş Sağlığı ve Güvenliği oluşturur.
            Sektörün sadece öncüsü değil aynı zamanda okulu da olan şirketimizde
            bilgi birikim ve yetkinliklerin artırılması ve çalışanların sürekli
            gelişimi için her türlü olanak sunulur. Bu amaç için kurulmuş modern
            bir eğitim kültür merkezine sahibiz.
          </Typography>
        </Box>
        <Box
          sx={{
            // margin: "0 0",
            // backgroundColor: "red",
            padding: "3rem 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            textAlign: "center",
            margin: "auto",
          }}
        >
          <Swiper
            modules={[Autoplay]}
            spaceBetween={30}
            slidesPerView={isMobile ? 2 : 5.8}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
          >
            {urunler.map((category, index) => (
              <SwiperSlide key={index}>
                <Box
                  sx={{
                    display: "flex",
                    width: "9rem",
                    height: "9rem",
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "1%",
                    borderRadius: "50%",
                    padding: "2rem",
                    backgroundColor: "#1f2e5e",
                    flexDirection: "row",
                    color: "#fff",
                    letterSpacing: "0.5",
                    border: "2px solid #fff",
                    // textTransform: "uppercase",
                  }}
                >
                  {category.title}
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>

      <Box>
        <Grid
          sx={{
            padding: { xs: 0, md: "0 5% 0 5%" },
            mt: "5rem",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
          }}
          container
        >
          <Grid item xs={12} md={6}>
            <Box
              style={{
                backgroundImage: `url(${enkalin})`,
                backgroundSize: "cover",
                position: "relative",
                height: "500px",
              }}
            >
              <Box
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  // backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), #1f2e5e)`,
                  // backgroundImage: `linear-gradient(to bottom,  #1f2e5e, rgba(0, 0, 0, 0),#1f2e5e`,
                  zIndex: 1,
                }}
              />
              <Typography
                style={{
                  color: "#e5e5e5",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 2,
                  textAlign: "center",
                  fontSize: "2.8rem",
                }}
              >
                Ürünler
              </Typography>

              <PulsingButton
                onClick={handleClickOpen}
                sx={{
                  "&:hover .arrow-icon": {
                    transform: "rotate(-45deg)",
                  },
                }}
              >
                <KeyboardDoubleArrowRightIcon
                  className="arrow-icon"
                  sx={{
                    transform: "translateX(0)",
                    transition: "transform 0.3s  ease-in-out",
                  }}
                />
              </PulsingButton>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box
              style={{
                backgroundImage: `url(${tekray})`,
                backgroundSize: "cover",
                position: "relative",
                height: "500px",
              }}
            >
              <Box
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  // backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), #1f2e5e)`,
                  zIndex: 1,
                }}
              />
              <Typography
                style={{
                  color: "#e5e5e5",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 2,
                  textAlign: "center",
                  fontSize: "3rem",
                }}
              >
                Sürdürülebilirlik
              </Typography>

              <PulsingButton
                onClick={handleClickOpen2}
                sx={{
                  "&:hover .arrow-icon": {
                    transform: "rotate(-45deg)",
                  },
                }}
              >
                <KeyboardDoubleArrowRightIcon
                  className="arrow-icon"
                  sx={{
                    transform: "translateX(0)",
                    transition: "transform 0.3s  ease-in-out",
                  }}
                />
              </PulsingButton>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          pb: 3,
          mt: "4rem",
          position: "relative",
          mx: "5%",
          // backgroundColor: "red",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            textAlign: "center",
            color: "#1f2e5e",
            fontWeight: "bold",
            // textShadow: "5px -5px 3px #000",
          }}
        >
          BİZDEN HABERLER
        </Typography>

        <Box sx={{ overflow: "hidden" }}>
          <Swiper
            modules={[Pagination, Autoplay, Lazy]}
            // spaceBetween={20}
            pagination={{ clickable: true }}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            lazy={true}
            slidesPerView={slidesPerView} // Kart sayısını burada belirliyoruz
          >
            {infoBoxes.map((item, index) => (
              <SwiperSlide key={index}>
                <Card
                  sx={{
                    width: "300px", // Sabit genişlik
                    height: "400px", // Sabit yükseklik
                    margin: "3rem auto",
                    boxShadow: 3,
                    borderRadius: "16px",
                  }}
                >
                  <CardMedia
                    component="img"
                    height="160"
                    image={item.imgSrc}
                    alt={item.title}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      {item.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item.description}
                    </Typography>
                    <Button
                      size="small"
                      sx={{
                        marginTop: 2,
                        position: "absolute",
                        bottom: "50px",
                        textTransform: "uppercase",
                      }}
                      // variant="contained"
                      color="primary"
                      href={item.url}
                    >
                      DETAYLI BİLGİ{" "}
                    </Button>
                  </CardContent>
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>

        <Button
          onClick={handleClick}
          sx={{
            mt: 4,
            mx: "auto",

            display: "block",
            padding: "0.7rem 2rem",
            fontSize: "1rem",
            fontWeight: "500",
            color: "white",
            background:
              "linear-gradient(90deg,rgba(0, 47, 255, 0.6),rgba(0, 132, 255, 0.93))",
            border: "none",
            borderTopLeftRadius: "20px",
            borderBottomRightRadius: "20px",

            position: "relative",
            overflow: "hidden",
            "&:before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: "-100%",
              width: "300%",
              height: "100%",
              background: "rgba(255, 255, 255, 0.2)",
              transform: "skewX(-45deg)",
              transition: "left 0.5s ease",
            },
            "&:hover:before": {
              left: "100%",
            },
            "&:hover": {
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
              transform: "scale(1.03) ",
            },
          }}
        >
          Tüm Haberler
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "center",
          paddingY: "2rem",
          mt: "5rem",
          // backgroundColor: "red",
          // gap: "5rem",
          mx: "5%",
        }}
      >
        {/* Sol Taraf */}
        <Box
          sx={{
            flex: 1,
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Typography
            variant="h3"
            sx={{ color: "#1f2e5e", mb: 2, fontWeight: "bold" }}
          >
            KARDEMİR'de KARİYER
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#1f2e5e",
              mb: 4,
              textAlign: "justify",
            }}
          >
            KARDEMİR'de kariyer yapmak, yalnızca profesyonel gelişiminizi
            desteklemekle kalmaz, aynı zamanda size, Türkiye’nin en köklü sanayi
            kuruluşlarından birinin parçası olma ayrıcalığını da sunar.
            Çalışanlarımızın potansiyellerini en üst düzeyde kullanmalarını
            sağlamak ve sürekli gelişimlerini desteklemek amacıyla, eğitim ve
            gelişim programlarına büyük önem veriyoruz.
          </Typography>
          <Button
            onClick={handleClick}
            sx={{
              mt: 4,
              mx: "auto",
              display: "block",
              padding: "0.7rem 2rem",
              fontSize: "1rem",
              fontWeight: "500",
              color: "white",
              background:
                "linear-gradient(90deg,rgb(234, 87, 87),rgb(255, 0, 0))",
              border: "none",
              borderTopLeftRadius: "20px",
              borderBottomRightRadius: "20px",
              position: "relative",
              overflow: "hidden",
              "&:before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: "-100%",
                width: "300%",
                height: "100%",
                background: "rgba(255, 255, 255, 0.2)",
                transform: "skewX(-45deg)",
                transition: "left 0.5s ease",
              },
              "&:hover:before": {
                left: "100%",
              },
              "&:hover": {
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            DETAYLI BİLGİ
          </Button>
        </Box>

        {/* Sağ Taraf */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            // padding: "0 0 0 20%",
            justifyContent: "center",
            marginLeft: "4rem",
          }}
        >
          <img
            src={kariyerfoto}
            alt="KARDEMİR'de Kariyer"
            style={{
              maxWidth: "100%",
              borderRadius: "10px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            }}
          />
        </Box>
      </Box>

      <Root
        className="istatistik"
        sx={{
          mt: "5rem",
          mx: "5%",
          // opacity: inView ? 1 : 0, // Görünürlük kontrolüne göre opaklık
          // transform: inView ? "translateY(0)" : "translateY(-50px)", // Görünürlük kontrolüne göre konum
          // transition: "opacity 1s ease-out, transform 1s ease-out",
        }}
      >
        <Typography
          sx={{
            fontSize: {
              xs: "3rem",
              lg: "3,8rem",
              textShadow: "5px -5px 3px #000",
            },
          }}
          gutterBottom
        >
          RAKAMLARLA KARDEMİR
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <Box textAlign="center">
              <PeopleAltIcon
                sx={{
                  fontSize: {
                    xs: "2rem",
                    lg: "5rem",
                    color: "#f5f5f5",
                  },
                }}
              />
              <Counter
                sx={{
                  fontSize: { xs: "1rem", lg: "1rem" },
                }}
                end="4,142"
                duration={2}
                startTime={startTime}
              />
              <Typography
                sx={{
                  textShadow: "5px -1px 3px #000",

                  fontSize: { xs: "1rem", lg: "1.5rem" },
                }}
              >
                Çalışan Sayısı
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <DirectionsBoatIcon
                sx={{
                  fontSize: { xs: "2rem", lg: "5rem", color: "#f5f5f5" },
                }}
              />
              <Counter
                sx={{ fontSize: { xs: "1rem", lg: "5rem" } }}
                end="2,011,959"
                duration={2}
                startTime={startTime}
              />
              <Typography
                sx={{
                  textShadow: "5px -1px 3px #000",

                  fontSize: { xs: "1rem", lg: "1.5rem" },
                }}
              >
                Ton Sıvı Çelik
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box textAlign="center">
              <PrecisionManufacturingIcon
                sx={{
                  fontSize: { xs: "2rem", lg: "5rem", color: "#f5f5f5" },
                }}
              />
              <Counter end="1,947,181" duration={2} startTime={startTime} />
              <Typography
                sx={{
                  textShadow: "5px -1px 3px #000",
                  fontSize: { xs: "1rem", lg: "1.5rem" },
                }}
              >
                Ton Ham Çelik Üretimi
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Root>

      <Box sx={{ padding: "4rem 0" }}>
        {isMobile ? (
          <Swiper
            modules={[Pagination, Autoplay]}
            spaceBetween={100}
            slidesPerView={1}
            pagination={{ clickable: true }}
            loop={true}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
          >
            {imagesSet1.map((image, index) => (
              <SwiperSlide key={index}>
                <Box
                  component="img"
                  src={image.src}
                  alt={image.alt}
                  sx={{
                    width: "100%",
                    height: "40vh",
                    objectFit: "cover",
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <Box
            sx={{
              mx: "5%",
            }}
          >
            <Swiper
              modules={[Pagination, Autoplay, Lazy]}
              spaceBetween={100}
              slidesPerView={1}
              pagination={{ clickable: true }}
              loop={true}
              autoplay={{
                delay: 7000,
                disableOnInteraction: false,
              }}
              lazy={true}
            >
              <SwiperSlide>
                <ImageGrid images={imagesSet1} />
              </SwiperSlide>
              <SwiperSlide>
                <ImageGrid images={imagesSet2} />
              </SwiperSlide>
              <SwiperSlide>
                <ImageGrid images={imagesSet3} />
              </SwiperSlide>
            </Swiper>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default HomeNew;
