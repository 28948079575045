import React from "react";
import {
  Container,
  Grid,
  CardMedia,
  CardContent,
  Typography,
  Box,
} from "@mui/material";
import foto1 from "../../images/Haberler/1.jpg";
import foto2 from "../../images/Haberler/2.jpg";
import foto3 from "../../images/Haberler/3.jpg";
import foto4 from "../../images/Haberler/4.jpg";
import foto5 from "../../images/Haberler/5.jpg";
import foto6 from "../../images/Haberler/6.jpg";
import foto7 from "../../images/Haberler/7.jpg";
import foto8 from "../../images/Haberler/8.jpg";
import foto9 from "../../images/Haberler/9.jpg";
import foto10 from "../../images/Haberler/10.jpg";
import foto11 from "../../images/Haberler/11.jpg";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";

const newsData = [
  {
    id: "arge-muhendisi-ise-alim-sonuclari-hakkinda-kamuoyu-bilgilendirmesi",
    title:
      "”AR-GE Mühendisi” İşe Alım Sonuçları Hakkında Kamuoyu Bilgilendirmesi",
    description: "Şirketimizde AR-GE Mühendisi pozisyonu için yapılan işe...",
    date: " 24.07.2024",
    image: foto1,
  },
  {
    id: "tedarik-zinciri-direktorlugu-is-ilani",
    title: "Tedarik Zinciri Direktörlüğü - İş İlanı ",
    description:
      "KARDEMİR Tedarik Zinciri Direktörlüğü bünyesinde, aşağıdaki...",
    date: "14.06.2024",
    image: foto2,
  },
  {
    id: "dijital-donusum-ve-bilgi-teknolojileri-direktorlugu-is-ilani",
    title: "Dijital Dönüşüm ve Bilgi Teknolojileri Direktörlüğü - İş İlanı",
    description:
      "KARDEMİR Dijital Dönüşüm ve Bilgi Teknolojileri Direktörlüğü...",
    date: "14.06.2024",
    image: foto3,
  },
  {
    id: "2024-yili-ikili-mesleki-egitim-on-kayitlari-basladi",
    title: "2024 Yılı İkili Mesleki Eğitim Ön Kayıtları Başladı",
    description:
      "Şirketimizin sürdürmekte olduğu İkili Mesleki Eğitim Programı...",
    date: " 11.06.2024",
    image: foto4,
  },
  {
    id: "olagan-genel-kurul-toplantisi",
    title: "Kardemir Olağan Genel Kurul Toplantısı ",
    description:
      "Şirketimizin 2023 yılı faaliyetlerinin ele alınacağı Olağan Genel...",
    date: "13.05.2024",
    image: foto5,
  },
  {
    id: "2024-yili-kisa-donem-yaz-staj-kayitlari-hakkinda-bilgilendirme",
    title: "2024 Yılı Kısa Dönem Yaz Staj Kayıtları Hakkında Bilgilendirme",
    description:
      "Şirketimizde üretim, yönetim organizasyon ve atölye stajı yapacak...",
    date: "12.05.2024",
    image: foto6,
  },
  {
    id: "basin-ve-kamuoyuna-bilgilendirme",
    title: "Basın ve Kamuoyuna Bilgilendirme",
    description:
      "Şirketimizin yatırım planı kapsamında, yurtiçi ve yurtdışında...",
    date: "05.04.2024",
    image: foto7,
  },
  {
    id: "is-ilani",
    title: "Kardemir Tedarik Zinciri Direktörlüğü - İş İlanı",
    description:
      "KARDEMİR Tedarik Zinciri Direktörlüğü bünyesinde, aşağıdaki...",
    date: "29.03.2024",
    image: foto8,
  },
  {
    id: "yaz-donemi-staj-basvurulari-basladi",
    title: "Yaz Dönemi Staj Başvuruları Başladı",
    description:
      "Şirketimizde staj yapmak isteyen üniversite ve yüksekokullarda...",
    date: "11.03.2024",
    image: foto9,
  },
  {
    id: "yonetim-kurulu-baskanimiz-sayin-prof-dr-ismail-demirin-basin-aciklamasi",
    title:
      "Yönetim Kurulu Başkanımız Sayın Prof. Dr. İsmail Demir’in Basın Açıklaması",
    description: "Şirketimiz Yönetim Kurulu Başkanı Prof. Dr. İsmail Demir...",
    date: "15.02.2024",
    image: foto10,
  },
  {
    id: "olaganustu-genel-kurulda-belirlenen-yonetim-kurulu-kadromuz",
    title: "Olağanüstü Genel Kurul'da Belirlenen Yönetim Kurulu Kadromuz",
    description:
      "23/10/2023 Pazartesi, şirketimiz Eğitim ve Kültür Merkezinde...",
    date: "24.10.2023",
    image: foto11,
  },
];

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

function HaberlerContents() {
  return (
    <>
      <Container sx={{ mt: 4 }}>
        <Header variant="h4" color="#1f2e5e">
          Haberler
        </Header>
        <Container sx={{ mt: 4 }}>
          <Grid container spacing={4}>
            {newsData.map((news, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box
                  sx={{
                    height: "100%",
                    border: "1px solid lightgrey",
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Link
                    to={`/haberler/${news.id}`}
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      height: "100%",
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="170"
                      image={news.image}
                      alt={news.title}
                      sx={{ objectFit: "cover" }}
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "bold",
                          mb: 1,
                          display: "-webkit-box",
                          WebkitLineClamp: 2, // limits title to 2 lines
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                        }}
                      >
                        {news.title}
                      </Typography>
                      <Typography
                        fontStyle={"italic"}
                        variant="body2"
                        sx={{
                          mb: 1,
                          display: "-webkit-box",
                          WebkitLineClamp: 2, // limits description to 2 lines
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                        }}
                      >
                        {news.description}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          mt: "auto",
                          textAlign: "right",
                        }}
                      >
                        {news.date}
                      </Typography>
                    </CardContent>
                  </Link>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Container>
    </>
  );
}

export default HaberlerContents;
