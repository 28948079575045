import { Paper, Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import image from "../../images/image2.jpg";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(8),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
  overflowX: "auto",
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

function YasalUyarilar() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box
        component="img"
        alt=""
        src={image}
        style={{
          width: "100%",
          height: "30rem",
          cursor: "pointer",
        }}
      />
      <Box sx={{ flexGrow: 1, padding: 1, marginTop: "1rem" }}>
        <Header variant="h4">Yasal Uyarılar ve Kullanım Şartları</Header>

        <StyledPaper sx={{ padding: "1rem", margin: "0.5rem" }}>
          <Typography
            variant="body1"
            sx={{ marginBottom: 2, textAlign: "justify" }}
          >
            Web sitemizi ziyaret etmeniz veya bu web sitesindeki herhangi bir
            bilgiyi kullanmanız aşağıdaki koşulları ve hükümleri kabul ettiğiniz
            anlamına gelmektedir. <br />
            <br /> -Bu web sitesine erişim ve sitede yer alan bilgilerin gerek
            doğrudan gerekse dolaylı kullanımından kaynaklanan doğrudan ve/veya
            dolaylı maddi ve/veya manevi menfi ve/veya müspet, velhasıl her
            türlü zarardan her nam altında olursa olsun Kardemir, Yönetim Kurulu
            Üyeleri, Yöneticileri, Çalışanları, bu sitede yer alan bilgileri
            hazırlayan kişiler ve Kardemir'in yetkilendirdiği kişi ve kuruluşlar
            sorumlu tutulamaz.
            <br />
            <br />
            -Bu web sitesinin yatırımcı ilişkileri linkinde finansal veriler
            sunulmaktadır. Bu finansal veriler yalnızca bilgi amaçlı olup,
            hiçbir ticari amaç taşımamaktadır. Kardemir, sitesinde barındırdığı
            bu finansal bilgiler ile finansal ve yatırım danışmanlığı hizmeti
            vermemektedir. Bu nedenle sadece bu finansal bilgilere dayanılarak
            yatırım kararı verilmesi beklentilerinize uygun sonuçlar
            doğurmayabilir.
            <br />
            <br />
            -Şirketimiz web sitesindeki her türlü ses, görüntü, metin içeren
            bilgi, belge, ticari marka ve her tür fikri mülkiyet hakkı,
            Kardemir'e ve bağlı ortaklık ve iştiraklerine aittir. Yalnızca
            sahipleri tarafından veya sahiplerinin izni dahilinde
            kullanılmaktadır ve telif hakları kapsamındadır.
            <br />
            <br />
            -Bu web sitesinde adı geçen diğer şirketler ve ürünleri sahiplerinin
            ticari markalarıdır ve ayrıca fikri mülkiyet hakları kapsamında
            korunmaktadır.
            <br />
            <br />
            -Bu web sitesindeki bilgileri herhangi bir ticari ve çıkar amacı
            olmadan kişisel bilgi edinmek amacıyla kullanabilir, indirebilir,
            kopyalayabilir ve yazdırabilirsiniz ya da herhangi bir ticari ve
            çıkar amacı olmadan üçüncü şahıslara sadece kişisel
            bilgilendirilmeleri amacıyla bu bilgilerin Kardemir web sitesinden
            temin edildiğini ve burada yer alan tüm koşul ve hükümlere bağlı
            olduklarını belirtmek şartıyla verebilir ve gönderebilirsiniz.
            <br />
            <br />
            -Bu web sitesinde yer alan herhangi bir bilgiyi, herhangi bir
            şekilde tahrif etmek, her türlü cezai ve hukuki takibata neden
            olacaktır.
            <br />
            <br />
            -Bu internet sitesindeki bilgilerde, ürünlerin fiyat ve teknik
            bilgilerinde yanlışlıklar, yazım hataları veya güncelleme hataları
            bulunabilir. Kardemir için bağlayıcı değildir. Bu internet sitesinde
            yer alan bilgiler her zaman önceden bildirilmeksizin
            değiştirilebilir.
            <br />
            <br />
            -Kardemir, internet sitesinden ulaşabileceğiniz diğer internet
            siteleri hakkında hiçbir garanti ve/veya görüş sunmamaktadır. Bu
            internet siteleri sadece kolaylık olması amacıyla belirtilmiştir ve
            burada belirtilmeleri Kardemir'in bu internet sitelerindeki içeriği
            ya da bunların kullanımını önerdiği ya da bunlarla ilgili herhangi
            bir sorumluluğu kabul ettiği anlamına gelmez. Buna ek olarak,
            kullanım için seçtiğiniz her şeyin virüs, solucan ve diğer zararlı
            nitelikteki öğeler içermemesini sağlamak için gerekli önlemleri
            almak kullanıcıya bağlıdır.
            <br />
            <br />
            -Web sitemiz bu tür tehditlere karşı özel yazılım ve donanımlarla
            koruma altındadır, güvenlik yazılımlarının ve donanımlarının henüz
            tehdit olarak algılamadığı yeni casus yazılımlar yine de tehdit
            oluşturabileceğinden dolayı, kullanıcı tarafında oluşan zararlardan
            Kardemir sorumlu değildir.
            <br />
            <br />
            -Web sitemizde Cookie (Çerez) kullanılmamaktadır, fakat ileride
            kullanılması durumunda internet sitemizde uyarı yapılacaktır.
            Uyarıyı dikkate almak kullanıcının inisiyatifindedir, bu nedenle
            oluşabilecek her türlü zarar kullanıcıyı bağlamaktadır, hiçbir
            şekilde Kardemir sorumlu değildir.
            <br />
            <br />
            -Gönderilecek herhangi bir bilgi, öneri, fikir ya da diğer
            iletilerin gizli olmadığı ve herhangi bir kimsenin mülkiyetinde
            olmadığı kabul edilecektir. Herhangi bir bilgi ya da malzeme
            göndererek Kardemir'e bu malzeme ve bilgileri kullanma, üretme,
            sergileme, gerçekleştirme, değiştirme, aktarma ve dağıtma hakkını
            kısıtlamasız ve geri alınamaz bir şekilde vermiş oluyorsunuz ve
            Kardemir'in herhangi bir amaçla bize göndereceğiniz fikirleri,
            kavramları, bilgileri ve teknikleri kullanmakta serbest olduğunu
            kabul etmiş olursunuz.
            <br />
            <br />
            -Bu siteyi kullanmaya başladığınız andan itibaren burada yazılı tüm
            hususları tamamen anlamış ve kabul etmiş sayılırsınız. Kardemir, bu
            maddelerde önceden haber vermeksizin değişiklik yapma hakkına
            sahiptir. Bu şartlardan bir ya da birkaçının ihlal edilmesi
            durumunda herhangi bir ihtar, uyarı ya da bilgi vermeye gerek
            kalmadan kullanımınızı kısıtlama ya da son verme hakkımız saklı
            tutulmaktadır. Siteyi kullanırken tüm işlemlerinizden kişisel olarak
            sadece kendinizin sorumlu olduğunuzu ve eylemleriniz nedeniyle zarar
            görebilecek Kardemir ve/veya grup şirketleri ile 3.şahısların
            zararlarını tazmin etmek zorunda kalacağınız hususunu önemle ihtar
            ederiz.
            <br />
            <br />
            -İnternet sitemize kişisel bilgilerini bırakan tüm kullanıcılar;
            Kardemir ve diğer bağlı ortaklıklar ve iştirakleri tarafından
            kişisel bilgilerinin kullanılarak her türlü yeniliklerinden,
            promosyonlarından, sadakat projelerinden, iş birlikteliklerinden,
            reklamlarından, duyurulardan, ürünlerinden, hizmetlerinden ve
            iletişim faaliyetleri hakkındaki olası tüm kanallardan bilgi almayı
            kabul etmiş sayılır.
            <br />
            <br />
            -Kardemir internet sitesine girmiş olduğunuz kişisel bilgiler
            Kardemir ve diğer bağlı ortaklıklar ve iştirakleri tarafından
            kullanılmasına, işlenmesine, saklanmasına 3. şahıslarla
            paylaşılmasına, anonimleştirilmesine muvafakat etmiş
            bulunmaktasınız.
            <br />
            <br />
            -Web sitemizde kayıt altına aldığınız iletişim bilgileri
            kullanılarak; Bilgilendirme, reklam, duyuru, kampanya, promosyon
            bilgileri tarafınıza gönderilebilir. Bu mesajları almak
            istemiyorsanız; profil sayfası altında bilgilerimi güncelle
            bölümünde yer alan "Bilgilendirme e-postaları almak istiyorum."
            kutucuğunda bulunan işareti kaldırmanız gerekmektedir. Sitemize
            giriş yapan kullanıcımız, işbu "Yasal Uyarı"yı okuyup aynen kabul
            etmiş sayılır.
            <br />
            <br />
            -Bu web sitesinin kullanımından doğan ve/veya yasal uyarıda yer alan
            koşul ve hükümlere ilişkin ve/veya bu web sitesi ile bağlantılı
            olarak çıkabilecek uyuşmazlıklarda Türkçe Yasal Uyarı metni esas
            olup, Karabük Mahkemeleri ve İcra ve İflas Daireleri yetkilidir.
            <br />
            <br />
            -Bu yayın, Kardemir'in izni olmadan kopyalanamaz, dağıtılamaz,
            ticari bir amaçla kaynak gösterilmek suretiyle kullanılamaz ve
            hiçbir format altında bilgisayar sistemlerine aktarılamaz.
            <br />
            <br />
            <br />
            Web sitemiz kullanmaya başladığınızdan itibaren yukarıdaki kullanım
            şartlarını okumuş, anlamış ve yükümlülüklerinize uygun şekilde
            hareket edeceğinizi taahhüt etmiş olursunuz. Aksi takdirde
            Kardemir'in uğrayacağı tüm zararı ödeyeceğinizi kabul ve taahhüt
            etmiş olursunuz.
          </Typography>
        </StyledPaper>
      </Box>
    </>
  );
}

export default YasalUyarilar;
