import React from "react";
import { Typography, Container } from "@mui/material";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import fotolar1 from "../../images/anasayfafoto/1.jpg";
import fotolar2 from "../../images/anasayfafoto/2.jpg";
import fotolar3 from "../../images/anasayfafoto/3.jpg";
import fotolar4 from "../../images/anasayfafoto/4.jpg";
import fotolar5 from "../../images/anasayfafoto/5.jpg";
import fotolar6 from "../../images/anasayfafoto/6.jpg";
import fotolar7 from "../../images/anasayfafoto/7.jpg";
import fotolar8 from "../../images/anasayfafoto/8.jpg";
import fotolar9 from "../../images/anasayfafoto/9.jpg";
import fotolar10 from "../../images/anasayfafoto/10.jpg";
import fotolar11 from "../../images/anasayfafoto/11.jpg";
import fotolar12 from "../../images/anasayfafoto/12.jpg";
import fotolar13 from "../../images/anasayfafoto/13.jpg";
import fotolar14 from "../../images/anasayfafoto/14.jpg";
import fotolar15 from "../../images/anasayfafoto/15.jpg";
import fotolar16 from "../../images/anasayfafoto/16.jpg";
import fotolar17 from "../../images/anasayfafoto/17.jpg";
import fotolar18 from "../../images/anasayfafoto/18.jpg";
import fotolar19 from "../../images/anasayfafoto/19.jpg";
import fotolar20 from "../../images/anasayfafoto/20.jpg";
import fotolar21 from "../../images/anasayfafoto/21.jpg";
import { styled } from "@mui/material/styles";

const images = [
  {
    original: fotolar1,
    thumbnail: fotolar1,
  },
  {
    original: fotolar2,
    thumbnail: fotolar2,
  },
  {
    original: fotolar3,
    thumbnail: fotolar3,
  },
  {
    original: fotolar4,
    thumbnail: fotolar4,
  },
  {
    original: fotolar5,
    thumbnail: fotolar5,
  },
  {
    original: fotolar6,
    thumbnail: fotolar6,
  },
  {
    original: fotolar7,
    thumbnail: fotolar7,
  },
  {
    original: fotolar8,
    thumbnail: fotolar8,
  },
  {
    original: fotolar9,
    thumbnail: fotolar9,
  },
  {
    original: fotolar10,
    thumbnail: fotolar10,
  },
  {
    original: fotolar11,
    thumbnail: fotolar11,
  },
  {
    original: fotolar12,
    thumbnail: fotolar12,
  },
  {
    original: fotolar13,
    thumbnail: fotolar13,
  },
  {
    original: fotolar14,
    thumbnail: fotolar14,
  },
  {
    original: fotolar15,
    thumbnail: fotolar15,
  },
  {
    original: fotolar16,
    thumbnail: fotolar16,
  },
  {
    original: fotolar17,
    thumbnail: fotolar17,
  },
  {
    original: fotolar18,
    thumbnail: fotolar18,
  },
  {
    original: fotolar19,
    thumbnail: fotolar19,
  },
  {
    original: fotolar20,
    thumbnail: fotolar20,
  },
  {
    original: fotolar21,
    thumbnail: fotolar21,
  },
];

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

function GaleriContents() {
  return (
    <>
      <Container sx={{ mt: 4 }}>
        <Header variant="h4" color="#1f2e5e">
          Galeri
        </Header>
        <ImageGallery items={images} showThumbnails={true} />
      </Container>
    </>
  );
}

export default GaleriContents;
