import React from "react";
import { Box, Typography, Divider, Paper } from "@mui/material";
import { styled } from "@mui/system";

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

const timelineData = [
  { year: "1937", events: ["Kuruluş", "Temellerin Atılması"] },
  { year: "1938", events: ["Makine Montajları"] },
  {
    year: "1939",
    events: [
      "Kuvvet Santrali",
      "Kok Fabrikası",
      "1 No.lu Yüksek Fırın",
      "İlk Türk Demiri",
    ],
  },
  { year: "1940", events: ["Çelikhane", "Trio Haddehane"] },
  { year: "1950", events: ["2 No.lu Yüksek Fırın"] },
  { year: "1953", events: ["Sinter"] },
  { year: "1955", events: ["Sümerbank’tan Ayrılması"] },
  { year: "1960", events: ["Düo Haddehane"] },
  { year: "1962", events: ["3 No.lu Yüksek Fırın"] },
  { year: "1964", events: ["Düo Profil Haddehanesi"] },
  { year: "1965", events: ["Kontinü Haddehane"] },
  { year: "1966", events: ["Çelik Konstrüksiyon"] },
  { year: "1967", events: ["Dökümhaneler"] },
  { year: "1976", events: ["Statü Değişikliği"] },
  { year: "1989", events: ["Grev"] },
  {
    year: "1994",
    events: [
      "Kapatılma Kararının Alınması",
      "Özelleştirilme Kararının Alınması",
    ],
  },
  {
    year: "1995",
    events: ["Özelleştirilmesi", "Hisselerinin Satışa Çıkarılması"],
  },
  {
    year: "1998",
    events: ["Pota Fırını", "Kütük Makinesi", "Borsaya Açılması"],
  },
  {
    year: "1999",
    events: [
      "Kireç Fabrikası ve Hava Ayrıştırma Tesisleri",
      "Çelikhane Konverter",
    ],
  },
  { year: "2000", events: ["Blum Makinesi"] },
  {
    year: "2007",
    events: ["Ray ve Profil Haddehanesi", "72 metre Boyunda İlk Ray Sevkiyatı"],
  },
  {
    year: "2008",
    events: ["Yeni Tesislerinin İşletmeye Alınması", "4 No.lu Yüksek Fırın"],
  },
  {
    year: "2011",
    events: [
      "Yeni Sinter Tesisi",
      "Kapasite Arttırımları",
      "3. Hava Ayrıştırma Tesisi",
    ],
  },
  {
    year: "2012",
    events: ["Hava Kalitesi İzleme İstasyonu", "Yeni Kireç Fabrikası"],
  },
  {
    year: "2013",
    events: [
      "3. Sürekli Döküm Makinesi",
      "Yeni Enerji Santrali",
      "Demiryolu Tekeri Üretim Tesisi",
      "Yeni Kok Fırınları",
    ],
  },
  {
    year: "2014",
    events: [
      "Eğitim ve Kültür Merkezi",
      "Merkezi Arıtma Tesisi",
      "3. Konverter",
      "Hidroelektrik Santrali",
      "Sinter Makinesi",
    ],
  },
  { year: "2015", events: ["5. Yüksek Fırın", "Üretim Rekoru"] },
  {
    year: "2016",
    events: ["İlk Kangal Üretimi", "Torpidoda Kükürt Giderme Tesisi"],
  },
  { year: "2017", events: ["TSI Sertifikası", "Kalite Ödülü"] },
  { year: "2018", events: ["IV. Hava Ayrıştırma Tesisi"] },
  {
    year: "2019",
    events: [
      "Kireç Fabrikası",
      "Yeni Sürekli Döküm Makinesi(SDM4)",
      "Sinter Tesisleri Meros Sistemi",
    ],
  },
  { year: "2020", events: ["Dijital Dönüşüm"] },
  { year: "2021", events: ["Demiryolu Tekeri Üretim Tesisi"] },
  { year: "2022", events: ["Konverter-3"] },
  { year: "2023", events: ["4 Nolu Pota Fırını ve SVC Sistemi"] },
];

const TimelineContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "72vh",
  width: "95%",

  marginTop: "2rem",
  overflowY: "auto",
  backgroundColor: "#fff",
  "&::-webkit-scrollbar": {
    width: "10px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#e0e0e0",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#1f2e5e",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#1a2334",
  },
}));

const TimelineSection = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "grid",
  gridTemplateColumns: "60px 1fr",
  padding: "15px 0",
}));

const TimelineLine = styled(Divider)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: "60px",
  height: "100%",
  width: "2px",
  backgroundColor: "#1f2e5e",
}));

const TimelineYear = styled(Typography)(({ theme }) => ({
  position: "sticky",
  top: 0,
  textAlign: "center",
  borderRadius: "5px",
  color: "#d11f25",
  fontSize: "20px",
  fontWeight: "bold",
  height: "25px",
  display: "block",
  "&::after": {
    content: '""',
    position: "absolute",
    top: "7px",
    left: "57px",
    width: "8px",
    height: "8px",
    backgroundColor: "#d11f25",
    borderRadius: "100%",
  },
}));

const TimelineText = styled(Box)(({ theme }) => ({
  color: "#1f2e5e",
  paddingRight: "15px",
  "& div": {
    position: "relative",
    marginBottom: "5px",
    paddingLeft: "20px",
    letterSpacing: "0.1px",
    fontSize: "18px",
    lineHeight: "27px",
    "&::before": {
      content: '""',
      position: "absolute",
      top: "10px",
      left: "-1px",
      width: "4px",
      height: "4px",
      backgroundColor: "#d11f25",
      borderRadius: "100%",
    },
  },
}));

const KilometreTaslari1Context = () => {
  return (
    <Box>
      <Header variant="h4" color="#1f2e5e">
        Kilometre Taşlarımız
      </Header>

      <TimelineContainer>
        {timelineData.map((entry, index) => (
          <TimelineSection key={index}>
            <TimelineLine />

            <TimelineYear>{entry.year}</TimelineYear>
            <TimelineText>
              {entry.events.map((event, i) => (
                <div key={i}>{event}</div>
              ))}
            </TimelineText>
          </TimelineSection>
        ))}
      </TimelineContainer>
    </Box>
  );
};

export default KilometreTaslari1Context;
