import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { LinkedIn, X, Instagram, YouTube } from "@mui/icons-material";

function Info({ onShow }) {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;

      // Eğer scroll pozisyonu 100vh'den büyükse, Info'yu gizle
      if (scrollPosition > windowHeight / 99) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }

      // Info bileşeni gösterildiğinde, parent'e bildir
      onShow(isVisible);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [isVisible, onShow]);
  return (
    <>
      {isVisible && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1200, // Header'dan daha büyük z-index
            // backgroundColor: "lightblue",
            // padding: "1rem",
            textAlign: "center",
            transition: "opacity 0.3s ease-in-out",
          }}
        >
          <Grid
            container
            sx={{
              padding: "0 5%",
              backgroundColor: "#11295e", //logoda renk farklılığı var
              display: "flex",
            }}
          >
            <Grid
              item
              xs={6}
              sx={{
                // backgroundColor: "yellow",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <EmailIcon
                sx={{
                  fontSize: "1rem",
                  color: "#fff",
                }}
              />
              <Typography
                sx={{
                  // letterSpacing: "5px",
                  color: "#1f2e5e",
                  color: "#dbd5d5",
                  fontSize: "0.875rem",
                  // marginBottom: "-0.9rem",
                }}
                // variant="h5"
              >
                info@kardemir.com
              </Typography>
            </Grid>

            <Grid
              item
              xs={6}
              sx={{
                // backgroundColor: "red",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <IconButton
                href="https://www.linkedin.com/company/sosyalkardemir/?originalSubdomain=tr"
                color="inherit"
              >
                <LinkedIn
                  sx={{
                    width: "15px",
                    height: "15px",
                    padding: ".3rem",
                    color: "#e5e5e5",
                    borderRadius: "50%",
                    "&:hover": {
                      backgroundColor: "#e5e5e5",
                      color: "#1f2e5e",
                    },
                  }}
                />
              </IconButton>
              <IconButton
                href="https://x.com/sosyalkardemir?lang=en"
                color="inherit"
              >
                <X
                  sx={{
                    width: "15px",
                    height: "15px",
                    padding: ".3rem",
                    color: "#e5e5e5",
                    borderRadius: "50%",
                    "&:hover": {
                      backgroundColor: "#e5e5e5",
                      color: "#1f2e5e",
                    },
                  }}
                />
              </IconButton>
              <IconButton
                href="https://www.instagram.com/SosyalKardemir/"
                color="inherit"
              >
                <Instagram
                  sx={{
                    width: "15px",
                    height: "15px",
                    padding: ".3rem",
                    color: "#e5e5e5",
                    borderRadius: "50%",
                    "&:hover": {
                      backgroundColor: "#e5e5e5",
                      color: "#1f2e5e",
                    },
                  }}
                />
              </IconButton>
              <IconButton
                href="https://www.youtube.com/+kardemir"
                color="inherit"
              >
                <YouTube
                  sx={{
                    width: "15px",
                    height: "15px",
                    padding: ".3rem",
                    color: "#e5e5e5",
                    borderRadius: "50%",
                    "&:hover": {
                      backgroundColor: "#e5e5e5",
                      color: "#1f2e5e",
                    },
                  }}
                />
              </IconButton>
              {/* </Box> */}
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}
export default Info;
