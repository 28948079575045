import React, { useEffect } from "react";
import {
  Typography,
  Grid,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  Link,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { styled } from "@mui/material/styles";
import image from "../../images/siteharita.png";

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
  overflowX: "auto",
}));

const sections = [
  {
    title: "KURUMSAL",
    items: [
      { name: "Hakkımızda", href: "/hakkımızda" },
      { name: "Kilometre Taşları", href: "/kilometre-taslari" },
      { name: "Yönetim Kurulu", href: "/yonetim-kurulu" },
      { name: "Üst Yönetim", href: "/ust-yonetim" },
      { name: "Vizyon ve Misyon", href: "/vizyon-misyon" },
      { name: "Ortaklık Yapısı", href: "/ortaklik-yapisi" },
    ],
  },
  {
    title: "İLİŞKİLER",
    items: [
      { name: "Yatırımcı İlişkileri", href: "/ortaklik-yapisi-yatirim" },
      { name: "Tedarikçi İlişkileri", href: "/tedarikci-islemleri" },
    ],
  },
  {
    title: "ÜRÜNLER ve HİZMETLER",
    items: [
      { name: "Ürünler", href: "/urunler" },
      { name: "Ürün Kataloğumuz", href: "/urun-katalogumuz" },
      { name: "Sertifikalarımız", href: "/sertifikalarimiz" },
      { name: "Satış İşlemleri", href: "/satis-islemleri" },
    ],
  },
  {
    title: "SÜRDÜRÜLEBİLİRLİK",
    items: [
      {
        name: "Sürdürülebilirlik Yönetimi",
        href: "/surdurulebilirlik-yonetimi",
      },
      { name: "Rapor ve Sertifikalarımız", href: "/rapor-ve-sertifikalarimiz" },
      { name: "AR-GE ve İnovasyon", href: "/arge-ve-inovasyon" },
      { name: "Enerji Yönetimi", href: "/enerji-yonetimi" },
      { name: "Çevre ve İklim", href: "/cevre-ve-iklim" },
      {
        name: "İş Devamlılığı ve Yatırımlar",
        href: "/is-devamliliği-ve-yatirimlar",
      },
    ],
  },
  {
    title: "KARİYER",
    items: [
      { name: "Neden Kardemir?", href: "/kariyer" },
      { name: "İş Başvuru Formu", href: "/is-basvuru-formu" },
      { name: "Personel Hakları", href: "/personel-haklari" },
      { name: "Kurumsal Kimlik", href: "/kurumsal-kimlik" },
      { name: "Sıkça Sorulan Sorular", href: "/sikca-sorulan-sorular" },
    ],
  },
  {
    title: "MEDYA",
    items: [
      { name: "Haberler", href: "/haberler" },
      { name: "Galeri", href: "/galeri" },
    ],
  },
];

function SiteHaritasi() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box
        component="img"
        alt=""
        src={image}
        style={{
          width: "100%",
          height: "25rem",
          cursor: "pointer",
        }}
      />

      <Box sx={{ flexGrow: 1, padding: 1, marginTop: "1rem" }}>
        <Header variant="h4">Site Haritası</Header>

        <StyledPaper sx={{ padding: "1rem", margin: "0.5rem" }}>
          <Box sx={{ padding: "2% 10%" }}>
            <Grid container spacing={3}>
              {sections.map((section) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={section.title}
                  color="#d11f25"
                >
                  <Typography
                    variant="h6"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "bold",
                      position: "relative",
                      color: "#1f2e5e",

                      "&:hover .MuiSvgIcon-root": {
                        transform: "translateX(10px)",
                        transition: "transform 0.3s ease",
                      },
                    }}
                  >
                    <ArrowForwardIosIcon
                      sx={{
                        marginRight: "8px",
                        transition: "transform 0.3s ease",
                        color: "#1f2e5e",
                      }}
                    />
                    {section.title}
                  </Typography>
                  <List sx={{ paddingLeft: "24px" }}>
                    {section.items.map((item) => (
                      <ListItem key={item.name} sx={{ padding: "4px 0" }}>
                        <Link href={item.href} underline="none" color="#1f2e5e">
                          <ListItemText primary={item.name} />
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              ))}
            </Grid>
          </Box>
        </StyledPaper>
      </Box>
    </>
  );
}

export default SiteHaritasi;
