import React, { useEffect } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import image from "../../images/image2.jpg";
import HomeIcon from "@mui/icons-material/Home";
import BusinessIcon from "@mui/icons-material/Business";

const adresler = [
  {
    icon: <HomeIcon fontSize="large" style={{ color: "#1f2e5e" }} />,
    title: "Merkez",
    lines: [
      "Telefon Numarası: +90 (370) 418 20 01",
      "Adres: Fabrika Sahası 78170 Merkez / Karabük",
      "Vergi Dairesi: Karabük",
      "Vergi No: 5050055358",
    ],
  },
  {
    icon: <HomeIcon fontSize="large" style={{ color: "#1f2e5e" }} />,
    title: "İstanbul Ofis",
    lines: [
      "Telefon Numarası: +90 (216) 576 20 01",
      "Faks Numarası: +90 (216) 576 20 99",
      "Adres: Küçükbakkalköy Mh. Vedat Günyol Cad. Liberty Plaza No: 20/6 Ataşehir İSTANBUL",
    ],
  },
  {
    icon: <HomeIcon fontSize="large" style={{ color: "#1f2e5e" }} />,
    title: "ARGE Merkez",
    lines: [
      "Telefon Numarası: +90 (370) 418 27 09",
      "Faks Numarası: +90 (370) 419 10 90",
      "E-posta: arge@kardemir.com",
    ],
  },
  {
    icon: <BusinessIcon fontSize="large" style={{ color: "#1f2e5e" }} />,
    title: "Satış Müdürlüğü",
    lines: [
      "Telefon Numarası: +90 (370) 418 22 85",
      "Faks Numarası: +90 (370) 419 10 56 - +90 (370) 419 10 57",
      "E-posta: satis@kardemir.com",
    ],
  },
  {
    icon: <BusinessIcon fontSize="large" style={{ color: "#1f2e5e" }} />,
    title: "Satınalma Müdürlüğü",
    lines: [
      "Telefon Numarası: +90 (370) 418 29 54 - +90 (370) 418 32 54",
      "Faks Numarası: +90 (370) 419 10 55",
      "E-posta: satinalma@kardemir.com",
    ],
  },
  {
    icon: <BusinessIcon fontSize="large" style={{ color: "#1f2e5e" }} />,
    title: "Pazarlama Müdürlüğü",
    lines: [
      "Telefon Numarası: +90 (216) 576 20 01",
      "Faks Numarası: +90 (216) 576 20 99",
      "E-posta: pazarlama@kardemir.com",
    ],
  },
  {
    icon: <BusinessIcon fontSize="large" style={{ color: "#1f2e5e" }} />,
    title: "İhracat Müdürlüğü",
    lines: [
      "Telefon Numarası: +90 (216) 576 20 01",
      "Faks Numarası: +90 (370) 419 10 94",
      "E-posta: export@kardemir.com",
    ],
  },
  {
    icon: <BusinessIcon fontSize="large" style={{ color: "#1f2e5e" }} />,
    title: "Müşteri Teknik Hizmetler Yöneticiliği",
    lines: [
      "Telefon Numarası: +90 (370) 418 38 60",
      "Faks Numarası: +90 (370) 419 10 93",
      "E-posta: musteriteknikhizmetler@kardemir.com",
    ],
  },
  {
    icon: <BusinessIcon fontSize="large" style={{ color: "#1f2e5e" }} />,
    title: "Kalite Metalurji ve Lab. Müdürlüğü",
    lines: [
      "Telefon Numarası: +90 (370) 418 22 66",
      "Faks Numarası: +90 (370) 419 10 52",
      "E-posta: kalite@kardemir.com",
    ],
  },
  {
    icon: <BusinessIcon fontSize="large" style={{ color: "#1f2e5e" }} />,
    title: "Eğitim Müdürlüğü",
    lines: [
      "Telefon Numarası: +90 (370) 418 31 00",
      "Faks Numarası: +90 (370) 419 10 47",
      "E-posta: egitim@kardemir.com",
    ],
  },
  {
    icon: <BusinessIcon fontSize="large" style={{ color: "#1f2e5e" }} />,
    title: "Yatırımcı İlişkileri",
    lines: [
      "Telefon Numarası: +90 (370) 418 35 42",
      "Faks Numarası: +90 (370) 419 10 64",
      "E-posta: finansman@kardemir.com",
    ],
  },
];

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(8),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
  overflowX: "auto",
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

function Iletisim() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box
        component="img"
        alt=""
        src={image}
        style={{
          width: "100%",
          height: "30rem",
          cursor: "pointer",
        }}
      />

      <Box sx={{ flexGrow: 1, padding: 1, marginTop: "1rem" }}>
        <Header variant="h4">İletişim Bilgilerimiz</Header>

        <StyledPaper sx={{ padding: "1rem", margin: "0.5rem" }}>
          <Container sx={{ mt: 4 }}>
            <Grid container spacing={4}>
              {adresler.map((adres, index) => (
                <Grid item xs={12} md={6} lg={4} key={index}>
                  <Card
                    elevation={4}
                    sx={{
                      height: "100%",
                      backgroundColor: "#f5f5f5",
                      color: "#1f2e5e",
                    }}
                  >
                    <CardContent>
                      <Box
                        sx={{ display: "flex", alignItems: "center", mb: 2 }}
                      >
                        {adres.icon}
                        <Typography
                          variant="h6"
                          sx={{ ml: 2, color: "#1f2e5e", fontWeight: 600 }}
                        >
                          {adres.title}
                        </Typography>
                      </Box>
                      {adres.lines.map((line, i) => (
                        <Typography
                          key={i}
                          variant="body2"
                          sx={{
                            color: "#1f2e5e",
                            textAlign: "justify",
                            px: 3,
                            mb: 1,
                          }}
                        >
                          {line}
                        </Typography>
                      ))}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </StyledPaper>
      </Box>
    </>
  );
}

export default Iletisim;
