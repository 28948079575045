import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Slide from "@mui/material/Slide";

const Cookies = () => {
  const [showBar, setShowBar] = useState(false);

  useEffect(() => {
    // Çerez kabul durumu kontrolü
    const acceptedCookies = localStorage.getItem("cookiesAccepted");
    if (!acceptedCookies) {
      setShowBar(true);
    }
  }, []);

  const handleAccept = () => {
    // Çerez kabul edildiğinde işlem
    localStorage.setItem("cookiesAccepted", "true");
    setShowBar(false);
  };

  const handleReject = () => {
    // Çerez reddedildiğinde işlem
    localStorage.setItem("cookiesAccepted", "false");
    setShowBar(false);
  };

  const handleManagePreferences = () => {
    // Tercihleri yönetmek için işlem
    alert("Tercihleri yönetmek için bir işlem tetiklendi.");
  };

  return (
    <Slide direction="up" in={showBar} mountOnEnter unmountOnExit>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          backgroundColor: "#1f2e5e",
          color: "#fff",
          padding: "1rem",
          textAlign: "center",
          zIndex: 1000,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            marginBottom: "1rem",
            fontSize: { xs: "0.9rem", sm: "1rem" },
            textAlign: "center",
          }}
        >
          KARDEMİR faaliyetlerinin geliştirilmesi ve kaliteli hizmetin sunulması
          amacıyla çerezleri kullanır. Detaylı bilgi almak ve KARDEMİR Çerez
          Politikası’na ulaşmak için lütfen{" "}
          <Link href="/cerez-politikasi" underline="hover">
            tıklayınız.
          </Link>
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "1rem",
            flexWrap: "wrap",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={handleManagePreferences}
          >
            Tercihleri Yönet
          </Button>
          <Button variant="outlined" color="primary" onClick={handleReject}>
            Reddet
          </Button>
          <Button variant="contained" color="primary" onClick={handleAccept}>
            Kabul Et
          </Button>
        </Box>
      </Box>
    </Slide>
  );
};

export default Cookies;
