import React, { useEffect, useState } from "react";
import image from "../../../../images/iliskilerBanner.jpg";
import {
  Box,
  Breadcrumbs,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import OrtaklikYapisiYatirimContents from "./OrtaklikYapisiYatirimContents";
import KomitelerContents from "./KomitelerContents";
import OzetMaliContents from "./OzetMaliContents";
import KarsilastirmaliGrafikContents from "./KarsilastirmaliGrafikContents";
import AnalistContents from "./AnalistContents";
import SunumlarContents from "./SunumlarContents";
import KrediContents from "./KrediContents";
import PolitikalarContents from "./PolitikalarContents";
import GenelKurulContents from "./GenelKurulContents";
import AnaSozlesmeContents from "./AnaSozlesmeContents";
import HisseDetayContents from "./HisseDetayContents";

function SolYapiYatirimci({ page, setPage }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [currentContent, setCurrentContent] = useState();
  const navigate = useNavigate();

  const handleItemClick = (pageName, content) => {
    setPage(pageName);
    setCurrentContent(content);

    if (pageName === "Ortaklık Yapısı") {
      navigate("/ortaklik-yapisi-yatirim");
    } else if (pageName === "Komiteler") {
      navigate("/komiteler");
    } else if (pageName === "Özet Mali Tablolar") {
      navigate("/ozet-mali-tablolar");
    } else if (pageName === "Karşılaştırmalı Grafik") {
      navigate("/karsilastirmali-grafik");
    } else if (pageName === "Analist Bilgileri") {
      navigate("/analist-bilgileri");
    } else if (pageName === "Sunumlar Raporlar ve Tablolar") {
      navigate("/sunumlar-raporlar-tablolar");
    } else if (pageName === "Kredi Derecelendirme") {
      navigate("/kredi-derecelendirme");
    } else if (pageName === "Politikalar ve Tahvil İhraç Belgeleri") {
      navigate("/politikalar-tahvil-ihrac");
    } else if (pageName === "Genel Kurul") {
      navigate("/genel-kurul");
    } else if (pageName === "Ana Sözleşme") {
      navigate("/ana-sozlesme");
    } else if (pageName === "Hisse Detay Grafik") {
      navigate("/hisse-detay-grafik");
    }
  };
  return (
    <>
      <Box
        component="img"
        alt=""
        src={image}
        style={{
          marginTop: "130px",
          width: "100%",
          height: "30rem",
          objectFit: "cover",
          objectPosition: "center",
        }}
      />

      <Box
        sx={{
          borderBottom: "1px solid lightgray",
          height: "5rem",
          display: "flex",
          alignItems: "center",
          paddingLeft: "1rem",
          justifyContent: "center",
        }}
      >
        {" "}
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link
            href="/"
            style={{
              color: "gray",
              padding: "8px",
              textDecoration: "none",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            Ana Sayfa
          </Link>
          <Link
            href=""
            style={{
              color: "gray",
              padding: "8px",
              textDecoration: "none",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            İlişkiler
          </Link>
          <Link
            href=""
            style={{
              color: "gray",
              padding: "8px",
              textDecoration: "none",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            Yatırımcı İlişkileri
          </Link>
          <Link
            href=""
            style={{
              color: "black",
              padding: "8px",
              textDecoration: "none",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            {page}
          </Link>
        </Breadcrumbs>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: "space-between",
          padding: { xs: "1rem", lg: "1rem 0 1rem 1rem" },
        }}
      >
        <Box
          sx={{
            borderRight: { xs: "none", lg: "1px solid gray" },
            paddingRight: { xs: "0rem", lg: "2rem" },

            marginBottom: "2rem",
          }}
        >
          <Paper
            sx={{
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              padding: "2rem",
              width: { xs: "auto", lg: "80%" },
            }}
          >
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "1.5rem",
                textDecoration: "underline",
              }}
            >
              Yatırımcı İlişkileri
            </Typography>

            <List>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleItemClick("Ortaklık Yapısı")}
                >
                  <ListItemText primary="Ortaklık Yapısı" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() =>
                    handleItemClick("Politikalar ve Tahvil İhraç Belgeleri")
                  }
                >
                  <ListItemText primary="Politikalar ve Tahvil İhraç Belgeleri" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleItemClick("Genel Kurul")}>
                  <ListItemText primary="Genel Kurul" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleItemClick("Ana Sözleşme")}>
                  <ListItemText primary="Ana Sözleşme" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleItemClick("Komiteler")}>
                  <ListItemText primary="Komiteler" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleItemClick("Özet Mali Tablolar")}
                >
                  <ListItemText primary="Özet Mali Tablolar" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleItemClick("Karşılaştırmalı Grafik")}
                >
                  <ListItemText primary="Karşılaştırmalı Grafik" />
                </ListItemButton>
              </ListItem>{" "}
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleItemClick("Analist Bilgileri")}
                >
                  <ListItemText primary="Analist Bilgileri" />
                </ListItemButton>
              </ListItem>{" "}
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() =>
                    handleItemClick("Sunumlar Raporlar ve Tablolar")
                  }
                >
                  <ListItemText primary="Sunumlar Raporlar ve Tablolar" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleItemClick("Kredi Derecelendirme")}
                >
                  <ListItemText primary="Kredi Derecelendirme" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleItemClick("Hisse Detay Grafik")}
                >
                  <ListItemText primary="Hisse Detay Grafik" />
                </ListItemButton>
              </ListItem>
            </List>
          </Paper>
        </Box>

        <Box
          sx={{
            marginLeft: { xs: "0", lg: "2rem" },
            flex: 1,
          }}
        >
          {page === "Ortaklık Yapısı" && (
            <>
              {/* <Typography variant="h4"> Ortaklık Yapısı</Typography> */}
              <OrtaklikYapisiYatirimContents content={currentContent} />
            </>
          )}

          {page === "Politikalar ve Tahvil İhraç Belgeleri" && (
            <>
              {/* <Typography variant="h4">
                Politikalar ve Tahvil İhraç Belgeleri
              </Typography> */}
              <PolitikalarContents content={currentContent} />
            </>
          )}
          {page === "Genel Kurul" && (
            <>
              {/* <Typography variant="h4"> Genel Kurul</Typography> */}
              <GenelKurulContents content={currentContent} />
            </>
          )}
          {page === "Ana Sözleşme" && (
            <>
              {/* <Typography variant="h4">Ana Sözleşme</Typography> */}
              <AnaSozlesmeContents content={currentContent} />
            </>
          )}

          {page === "Komiteler" && (
            <>
              {/* <Typography variant="h4">Komiteler</Typography> */}
              <KomitelerContents content={currentContent} />
            </>
          )}

          {page === "Özet Mali Tablolar" && (
            <>
              {/* <Typography variant="h4">Özet Mali Tablolar</Typography> */}
              <OzetMaliContents content={currentContent} />
            </>
          )}

          {page === "Karşılaştırmalı Grafik" && (
            <>
              {/* <Typography variant="h4">Karşılaştırmalı Grafik</Typography> */}
              <KarsilastirmaliGrafikContents content={currentContent} />
            </>
          )}
          {page === "Analist Bilgileri" && (
            <>
              {/* <Typography variant="h4">Analist Bilgileri</Typography> */}
              <AnalistContents content={currentContent} />
            </>
          )}
          {page === "Sunumlar Raporlar ve Tablolar" && (
            <>
              {/* <Typography variant="h4">
                Sunumlar Raporlar ve Tablolar
              </Typography> */}
              <SunumlarContents content={currentContent} />
            </>
          )}
          {page === "Kredi Derecelendirme" && (
            <>
              {/* <Typography variant="h4">Kredi Derecelendirme</Typography> */}
              <KrediContents content={currentContent} />
            </>
          )}

          {page === "Hisse Detay Grafik" && (
            <>
              {/* <Typography variant="h4">Kredi Derecelendirme</Typography> */}
              <HisseDetayContents content={currentContent} />
            </>
          )}
        </Box>
      </Box>
    </>
  );
}

export default SolYapiYatirimci;
