import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  useMediaQuery,
  useTheme,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Container,
  ButtonBase,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import video1 from "../../video/V-1.mp4";
import video2 from "../../video/V-2.mp4";
import video3 from "../../video/V-3.mp4";
import video4 from "../../video/V-4.mp4";
import video5 from "../../video/V-5.mp4";
import foto1 from "../../images/Haberler/4.jpg";
import foto2 from "../../images/Haberler/1.jpg";
import foto3 from "../../images/Haberler/3.jpg";
import foto5 from "../../images/Haberler/5.jpg";
import foto6 from "../../images/Haberler/6.jpg";
import foto7 from "../../images/Haberler/7.jpg";
import foto8 from "../../images/Haberler/8.jpg";
import { height, styled, width } from "@mui/system";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, FreeMode, Lazy } from "swiper";
import enkalin from "../../images/enkalin.png";
import tekray from "../../images/tekray.jpg";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Counter from "../Counter";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import fotolar1 from "../../images/anasayfafoto/1.jpg";
import fotolar2 from "../../images/anasayfafoto/2.jpg";
import fotolar3 from "../../images/anasayfafoto/3.jpg";
import fotolar4 from "../../images/anasayfafoto/4.jpg";
import fotolar5 from "../../images/anasayfafoto/5.jpg";
import fotolar6 from "../../images/anasayfafoto/6.jpg";
import fotolar7 from "../../images/anasayfafoto/7.jpg";
import fotolar8 from "../../images/anasayfafoto/8.jpg";
import fotolar9 from "../../images/anasayfafoto/9.jpg";
import fotolar10 from "../../images/anasayfafoto/10.jpg";
import fotolar11 from "../../images/anasayfafoto/11.jpg";
import fotolar12 from "../../images/anasayfafoto/12.jpg";
import fotolar13 from "../../images/anasayfafoto/13.jpg";
import fotolar14 from "../../images/anasayfafoto/14.jpg";
import fotolar15 from "../../images/anasayfafoto/15.jpg";
import fotolar16 from "../../images/anasayfafoto/16.jpg";
import fotolar17 from "../../images/anasayfafoto/17.jpg";
import fotolar18 from "../../images/anasayfafoto/18.jpg";
import fotolar19 from "../../images/anasayfafoto/19.jpg";
import fotolar20 from "../../images/anasayfafoto/20.jpg";
import fotolar21 from "../../images/anasayfafoto/21.jpg";
import kariyerfoto from "../../images/kariyerfoto.jpg";
import kardemir from "../../images/kardemir.jpg";
import ekm from "../../images/ekm.jpg";
import surdurulebilirlik from "../../images/sustainability/surdurulebilirlik.png";
import demiryolutekeri from "../../images/demiryolutekeri.jpg";
import demiryoluraylari from "../../images/demiryoluraylari.png";
import profiller from "../../images/profiller.png";
import heaprofiller from "../../images/heaprofiller.png";
import hebprofiller from "../../images/hebprofiller.png";
import ıpeprofiller from "../../images/ıpeprofiller.png";
import npuprofiller from "../../images/npuprofiller.png";
import gıthmadendirekleri from "../../images/gıthmadendirekleri.png";
import platinalar from "../../images/platinalar.jpg";
import kangallar from "../../images/kangallar.png";
import duzyuvarlaklar from "../../images/duzyuvarlaklar.png";
import nervurlubetondonati from "../../images/nervurlubetondonati.png";
import kutukler from "../../images/kutukler.png";
import kokurunleri from "../../images/kokurunleri.png";
import granuleyuksekfirincurufu from "../../images/granuleyuksekfirincurufu.png";
import merosatigi from "../../images/merosatigi.png";
import metalurjkkirec from "../../images/metalurjkkirec.jpg";
import havaayristirmatesisiurunleri from "../../images/havaayristirmatesisiurunleri.png";
import PopupShow from "./PopupShow";
import { useNavigate } from "react-router-dom";
import foto1s from "../../images/karcimsa.png";
import foto2s from "../../images/ermaden.png";
import foto3s from "../../images/vademsas.png";
import foto4s from "../../images/epias.png";
import foto5s from "../../images/teknopark.png";
import foto1ortak from "../../images/kardokmak.png";
import foto2ortak from "../../images/karcel4.png";
import foto3ortak from "../../images/kardemirenerji.png";
import foto4ortak from "../../images/karlimtas2.png";
import videoel from "../../images/altel.png";
import arkaplan from "../../images/background.webp";
import transparanel from "../../images/KurumsalKimlik/transparanel.png";
import gradient from "../../images/node.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// const infoBoxes = [
//   {
//     imgSrc: foto1,
//     title:
//       "KARDEMİR 2024 Yılı Kısa Dönem Yaz Staj Kayıtları Hakkında Bilgilendirme",
//     description:
//       " Şirketimizde üretim, yönetim organizasyon ve atölye stajı yapacak stajyer adaylarımızın staj kabul...",
//     url: "/haberler/2024-yili-kisa-donem-yaz-staj-kayitlari-hakkinda-bilgilendirme",
//   },
//   {
//     imgSrc: foto2,

//     title: "AR-GE Mühendisi İşe Alım Sonuçları Kamuoyu Bilgilendirmesi",
//     description:
//       "Şirketimizde AR-GE Mühendisi pozisyonumuz için yapılan işe alım sürecine ilişkin ilgili işlemler...",
//     url: "/haberler/arge-muhendisi-ise-alim-sonuclari-hakkinda-kamuoyu-bilgilendirmesi",
//   },
//   {
//     imgSrc: foto3,

//     title:
//       "Kardemir Dijital Dönüşüm ve Bilgi Teknolojileri Direktörlüğü İş İlanı ",
//     description:
//       "KARDEMİR Dijital Dönüşüm ve Bilgi Teknolojileri Direktörlüğü bünyesinde, on yedi (17)...",
//     url: "/haberler/dijital-donusum-ve-bilgi-teknolojileri-direktorlugu-is-ilani",
//   },
//   {
//     imgSrc: foto5,
//     title: "2023 Kardemir Olağan Genel Kurul Toplantısı",
//     description:
//       " Şirketimizin 2023 yılı faaliyetlerinin ele alınacağı Olağan Genel...",
//     url: "/haberler/olagan-genel-kurul-toplantisi",
//   },
//   {
//     imgSrc: foto6,

//     title: "Basın ve Kamuoyuna Bilgilendirme",
//     description:
//       "Şirketimizin yatırım planı kapsamında, yurtiçi ve yurtdışında...",
//     url: "/haberler/basin-ve-kamuoyuna-bilgilendirme",
//   },

//   {
//     imgSrc: foto7,

//     title:
//       "Yönetim Kurulu Başkanımız Sayın Prof. Dr. İsmail Demir’in Basın Açıklaması",
//     description: "Şirketimiz Yönetim Kurulu Başkanı Prof. Dr. İsmail Demir...",
//     url: "/haberler/yonetim-kurulu-baskanimiz-sayin-prof-dr-ismail-demirin-basin-aciklamasi",
//   },
//   {
//     imgSrc: foto8,

//     title: "Olağanüstü Genel Kurul'da Belirlenen Yönetim Kurulu Kadromuz",
//     description:
//       "23/10/2023 Pazartesi, şirketimiz Eğitim ve Kültür Merkezinde...",
//     url: "/haberler/olaganustu-genel-kurulda-belirlenen-yonetim-kurulu-kadromuz",
//   },
// ];

const newsData = [
  {
    id: "arge-muhendisi-ise-alim-sonuclari-hakkinda-kamuoyu-bilgilendirmesi",
    title:
      "”AR-GE Mühendisi” İşe Alım Sonuçları Hakkında Kamuoyu Bilgilendirmesi",
    date: " 24.07.2024",
    image: foto1,
    content: [
      {
        type: "paragraph",
        text: "Şirketimizde AR-GE Mühendisi pozisyonu için yapılan işe alım sürecine ilişkin ilgili işlemler tamamlanmış olup, asil ve yedek aday bilgilerinin yer aldığı liste aşağıda sunulmuştur.",
      },

      {
        type: "paragraph",
        text: "• Asil ve yedek aday listesine buradan ulaşabilirsiniz.",
      },

      {
        type: "paragraph",
        text: "Çalışma arkadaşlarımıza KARDEMİR A.Ş. ailesine hoş geldiniz der, başarılar dileriz.",
      },
    ],
  },
  {
    id: "tedarik-zinciri-direktorlugu-is-ilani",
    title: "Tedarik Zinciri Direktörlüğü - İş İlanı",
    date: "14.06.2024",
    image: foto2,
    content: [
      {
        type: "heading",
        text: "Karabük Demir Çelik Sanayi ve Ticaret A.Ş.",
      },
      {
        type: "paragraph",
        text: "KARDEMİR Tedarik Zinciri Direktörlüğü bünyesinde, aşağıdaki kriterler çerçevesinde yedi (7) MÜHENDİS personel istihdam edilecektir.",
      },

      {
        type: "heading",
        text: "GENEL ŞARTLAR",
      },
      {
        type: "paragraph",
        text: "• Türkiye Cumhuriyeti vatandaşı,",
      },

      {
        type: "paragraph",
        text: "• Kamu haklarından mahrumiyeti bulunmayan,",
      },
      {
        type: "paragraph",
        text: "• Kasten işlenen bir suçtan dolayı, bir yıl veya daha fazla süreyle hapis cezasına ya da affa uğramış olsa bile devletin güvenliğine karşı suçlar, Anayasal düzene ve bu düzenin işleyişine karşı suçlar, zimmet, irtikâp, rüşvet, hırsızlık, dolandırıcılık, sahtecilik, güveni kötüye kullanma, hileli iflas, ihaleye fesat karıştırma, edimin ifasına fesat karıştırma, suçtan kaynaklanan malvarlığı değerlerini aklama veya kaçakçılık suçlarından mahkûm olmamış,",
      },
      {
        type: "paragraph",
        text: "•  01/06/2024 tarihi itibariyle 35 yaşını doldurmamış olan (01/06/1989 ve sonraki tarihlerde doğanlar),",
      },
      {
        type: "paragraph",
        text: "• Erkek adaylar için askerlikle ilişiği olmayan,",
      },
      {
        type: "paragraph",
        text: "• Sağlık muayenesinde çok tehlikeli iş sınıflarında çalışabilmeye uygunluk ve görevlerini yapmaya engel olabilecek herhangi bir bedensel veya ruhsal rahatsızlığı bulunmayan,",
      },
      {
        type: "paragraph",
        text: "• Üniversitelerin en az dört yıllık eğitim veren fakülteleri ile denkliği yetkili makamlarca kabul edilmiş, yurtdışındaki eğitim kurumlarının aşağıda belirtilen bölümlerinden mezun olan,",
      },

      {
        type: "heading",
        text: "ÖĞRENİM ŞARTI",
      },
      {
        type: "paragraph",
        text: "Üniversitelerin; ",
      },

      {
        type: "paragraph",
        text: "• Endüstri Mühendisliği,",
      },
      {
        type: "paragraph",
        text: "• İşletme Mühendisliği, branşlarından mezun olmak.",
      },

      {
        type: "heading",
        text: "BAŞVURU",
      },
      {
        type: "paragraph",
        text: "Başvurular KARDEMİR’in www.kardemir.com adresindeki internet sitesi üzerinden yapılacaktır. Adayların 14/06/2024 tarihinden itibaren ve en geç 30/06/2024 Pazar günü saat 23.59’a kadar başvuru işlemlerini tamamlamaları gerekmektedir.",
      },
      {
        type: "heading",
        text: "DEĞERLENDİRME",
      },
      {
        type: "paragraph",
        text: "İlan kriterlerini sağlayan adaylar Genel Yetenek ve Yabancı Dil (İngilizce) sınavına tabi tutulacaktır. Sınav ve mülakat tarihleri adaylara ayrıca bildirilecektir. Yukarıda belirtilen pozisyonlar için sıralamaya esas toplam puanları dikkate alınarak, ilan edilen kontenjanın dört katı kadar aday, KARDEMİR Seçme ve Değerlendirme Kurulu tarafından mülakata tabi tutulacaktır.",
      },
    ],
  },
  {
    id: "dijital-donusum-ve-bilgi-teknolojileri-direktorlugu-is-ilani",
    title: "Dijital Dönüşüm ve Bilgi Teknolojileri Direktörlüğü - İş İlanı",
    date: "14.06.2024",
    image: foto3,
    content: [
      {
        type: "heading",
        text: "Karabük Demir Çelik Sanayi ve Ticaret A.Ş.",
      },
      {
        type: "paragraph",
        text: "KARDEMİR Dijital Dönüşüm ve Bilgi Teknolojileri Direktörlüğü bünyesinde, aşağıdaki kriterler çerçevesinde on yedi (17) MÜHENDİS personel istihdam edilecektir.",
      },

      {
        type: "heading",
        text: "GENEL ŞARTLAR",
      },
      {
        type: "paragraph",
        text: "• Türkiye Cumhuriyeti vatandaşı,",
      },

      {
        type: "paragraph",
        text: "• Kamu haklarından mahrumiyeti bulunmayan,",
      },
      {
        type: "paragraph",
        text: "• Kasten işlenen bir suçtan dolayı, bir yıl veya daha fazla süreyle hapis cezasına ya da affa uğramış olsa bile devletin güvenliğine karşı suçlar, Anayasal düzene ve bu düzenin işleyişine karşı suçlar, zimmet, irtikâp, rüşvet, hırsızlık, dolandırıcılık, sahtecilik, güveni kötüye kullanma, hileli iflas, ihaleye fesat karıştırma, edimin ifasına fesat karıştırma, suçtan kaynaklanan malvarlığı değerlerini aklama veya kaçakçılık suçlarından mahkûm olmamış,",
      },
      {
        type: "paragraph",
        text: "•  01/06/2024 tarihi itibariyle 35 yaşını doldurmamış olan (01/06/1989 ve sonraki tarihlerde doğanlar),",
      },
      {
        type: "paragraph",
        text: "• Erkek adaylar için askerlikle ilişiği olmayan,",
      },
      {
        type: "paragraph",
        text: "• Sağlık muayenesinde çok tehlikeli iş sınıflarında çalışabilmeye uygunluk ve görevlerini yapmaya engel olabilecek herhangi bir bedensel veya ruhsal rahatsızlığı bulunmayan,",
      },
      {
        type: "paragraph",
        text: "• Üniversitelerin en az dört yıllık eğitim veren fakülteleri ile denkliği yetkili makamlarca kabul edilmiş, yurtdışındaki eğitim kurumlarının aşağıda belirtilen bölümlerinden mezun olan,",
      },

      {
        type: "heading",
        text: "ÖĞRENİM ŞARTI",
      },
      {
        type: "paragraph",
        text: "Üniversitelerin; ",
      },

      {
        type: "paragraph",
        text: "• Bilgisayar  Mühendisliği,",
      },
      {
        type: "paragraph",
        text: "• Yazılım  Mühendisliği,",
      },
      {
        type: "paragraph",
        text: "• Bilgisayar ve Bilişim Sistemleri  Mühendisliği,",
      },
      {
        type: "paragraph",
        text: "• Yapay Zeka Mühendisliği,",
      },
      {
        type: "paragraph",
        text: "• Elektronik   Mühendisliği,",
      },
      {
        type: "paragraph",
        text: "• Elektrik-Elektronik  Mühendisliği,",
      },
      {
        type: "paragraph",
        text: "• Elektronik Haberleşme  Mühendisliği,",
      },
      {
        type: "paragraph",
        text: "• Endüstri Mühendisliği, Bölümlerinden birinden mezun olmak.",
      },
      {
        type: "paragraph",
        text: "*Endüstri Mühendisliği bölümü mezunlarının SAP(FI, HR, MM, PP, CO), ERP, ABAP, kod analizi ve hata ayıklama yetkinliklerine sahip olması gerekmektedir.",
      },

      {
        type: "heading",
        text: "BAŞVURU",
      },
      {
        type: "paragraph",
        text: "Başvurular KARDEMİR’in www.kardemir.com adresindeki internet sitesi üzerinden yapılacaktır. Adayların 14/06/2024 tarihinden itibaren ve en geç 30/06/2024 Pazar günü saat 23.59’a kadar başvuru işlemlerini tamamlamaları gerekmektedir.",
      },
      {
        type: "heading",
        text: "DEĞERLENDİRME",
      },
      {
        type: "paragraph",
        text: "İlan kriterlerini sağlayan adaylar Genel Yetenek ve Yabancı Dil (İngilizce) sınavına tabi tutulacaktır. Sınav ve mülakat tarihleri adaylara ayrıca bildirilecektir. Yukarıda belirtilen pozisyonlar için sıralamaya esas toplam puanları dikkate alınarak, ilan edilen kontenjanın dört katı kadar aday, KARDEMİR Seçme ve Değerlendirme Kurulu tarafından mülakata tabi tutulacaktır.",
      },
    ],
  },
  {
    id: "2024-yili-ikili-mesleki-egitim-on-kayitlari-basladi",
    title: "2024 Yılı İkili Mesleki Eğitim Ön Kayıtları Başladı",
    date: " 11.06.2024",
    image: foto5,
    content: [
      {
        type: "paragraph",
        text: "Şirketimizin sürdürmekte olduğu İkili Mesleki Eğitim Programı için 2024 yılı ön kayıt başvuruları başlıyor.",
      },

      {
        type: "paragraph",
        text: "Aşağıdaki bağlantıdan ön kayıt formuna erişim sağlayabilirsiniz. Ön kayıtlara başvuru 14-30/06/2024 tarihleri arasında açık olacaktır.",
      },

      {
        type: "paragraph",
        text: "Başvuruda bulunmak için tıklayınız.",
      },
    ],
  },
  {
    id: "olagan-genel-kurul-toplantisi",
    title: "Olağan Genel Kurul Toplantısı ",
    date: "13.05.2024",
    image: foto5,
    content: [
      {
        type: "paragraph",
        text: "Şirketimizin 2023 yılı faaliyetlerinin ele alınacağı Olağan Genel Kurul Toplantısı 30.05.2024 tarihinde saat 10:00'da Kardemir Eğitim ve Kültür Merkezi'nde gerçekleştirilecektir.",
      },

      {
        type: "paragraph",
        text: "• irketimize ait Mali Tablolar ve 12 aylık Faaliyet Raporuna buradan ulaşabilirsiniz. ",
      },
    ],
  },
  {
    id: "2024-yili-kisa-donem-yaz-staj-kayitlari-hakkinda-bilgilendirme",
    title: "2024 Yılı Kısa Dönem Yaz Staj Kayıtları Hakkında Bilgilendirme",
    date: "12.05.2024",
    image: foto6,
    content: [
      {
        type: "paragraph",
        text: "• 2024 Kısa Dönem Yaz Stajı Kabul Listesine buradan ulaşabilirsiniz.",
      },
      {
        type: "paragraph",
        text: "Şirketimizde üretim, yönetim organizasyon ve atölye stajı yapacak stajyer adaylarımızın staj kabul evrakları aşağıda yer almaktadır.",
      },
      {
        type: "paragraph",
        text: "Kesin Kayıtlar;",
      },
      {
        type: "paragraph",
        text: "Kesin kayıtların yapılabilmesi için listedeki stajyer adaylarının aşağıda yer alan belgeleri 20 Mayıs 2024 Pazartesi - 4 Haziran 2024 Salı gününe kadar, hafta içi 08:30–11:00 / 13:30-16:30 saatleri arasında KARDEMİR A.Ş. Eğitim Müdürlüğü'ne elden teslim edilmesi gerekir.",
      },
      {
        type: "paragraph",
        text: "Kayıt için gerekli evraklar;",
      },

      {
        type: "paragraph",
        text: "• 1 Adet Nüfus Cüzdanı Fotokopisi",
      },
      {
        type: "paragraph",
        text: "• 1 Adet Vesikalık Fotoğraf",
      },
      {
        type: "paragraph",
        text: "• Öğrenci Belgesi",
      },
      {
        type: "paragraph",
        text: "• Transkript (Not Ortalaması)",
      },
      {
        type: "paragraph",
        text: "• 1 Adet Adli Sicil Kaydı Belgesi (Geri verilecektir.)",
      },
      {
        type: "paragraph",
        text: "• Kan Grubu Gösterir Belge",
      },
      {
        type: "paragraph",
        text: "• Dil Belgesi (Sertifikalar) var ise",
      },
      {
        type: "paragraph",
        text: "• Teknik Yetkinlik Belgeleri (Sertifikalar) var ise",
      },
      {
        type: "paragraph",
        text: "• Okul tarafından verilen Staj Zorunluluk/ İş Kazası ve Meslek Hastalığı Sigortası yaptırılacağına dair resmi yazı",
      },
      {
        type: "paragraph",
        text: "• Okul tarafından verilen Staj Başvuru Formu (mümkünse okul tarafından imzalı kaşeli)",
      },
      {
        type: "paragraph",
        text: "• Üniversite Staj İşleri (Devlet katkı payı ödeme) Sorumlusu ve İletişim (telefon, e-posta) Bilgileri",
      },
      {
        type: "paragraph",
        text: "• Banka hesap numarası (IBAN NO) gösterir hesap cüzdan fotokopisi (TEB Karabük Şubesi)",
      },
      {
        type: "paragraph",
        text: "• Sağlık Kurulu (Heyet) Raporu yada Devlet Hastanesinden alınan durum bildirir raporu",
      },
      {
        type: "paragraph",
        text: "Bu raporu almak için gerekli tetkikler de evrak teslim sürecinde getirilmelidir.",
      },
      {
        type: "paragraph",
        text: "Gerekli tetkikler; Kan Testi (Aç Karnına), Akciğer Filmi, EKG, Odyometri Testi, Göz Muayenesi",
      },

      {
        type: "paragraph",
        text: "Staj Dönemleri;",
      },
      {
        type: "paragraph",
        text: "Stajın başladığı gün saat 08:00’de KARDEMİR A.Ş. ana kapıda olunması gerekir.",
      },

      {
        type: "paragraph",
        text: "Kendi periyodunda staja başlarken stajyerin yanında getirmesi gereken belgeler aşağıda yer almaktadır;",
      },
      {
        type: "paragraph",
        text: "• Sigorta yapıldığını gösterir Sigorta Bildirim Belgesi",
      },
      {
        type: "paragraph",
        text: "•  Staj Sicil Fişi",
      },
      {
        type: "paragraph",
        text: "• Staj Defteri",
      },
    ],
  },
  {
    id: "basin-ve-kamuoyuna-bilgilendirme",
    title: "Basın ve Kamuoyuna Bilgilendirme",
    date: "05.04.2024",
    image: foto7,
    content: [
      {
        type: "paragraph",
        text: "Şirketimizin yatırım planı kapsamında, yurtiçi ve yurtdışında faaliyet göstermek ve yük taşımacılığı esaslı olmak üzere, her türlü demiryolu, karayolu, denizyolu ve havayolu taşımacılığı ile bu amaçlara matuf olmak veya bu hususlarla ilgili olmak kaydıyla ilave faaliyetlerde bulunmak maksadıyla şirketimizin tek ortağı olduğu, 200.000.000 TL sermayeli, KARLİMTAŞ KARDEMİR Liman İşletmeciliği ve Taşımacılık Anonim Şirketi unvanında yeni bir şirket kurulmuştur.",
      },

      {
        type: "paragraph",
        text: "Kamuoyuna saygıyla duyurulur.",
      },
    ],
  },
  {
    id: "is-ilani",
    title: "İş İlanı",
    date: "29.03.2024",
    image: foto8,
    content: [
      {
        type: "paragraph",
        text: "KARDEMİR Tedarik Zinciri Direktörlüğü bünyesinde, aşağıdaki kriterler çerçevesinde yedi (7) MÜHENDİS personel istihdam edilecektir.",
      },

      {
        type: "heading",
        text: "GENEL ŞARTLAR",
      },
      {
        type: "paragraph",
        text: "• Türkiye Cumhuriyeti vatandaşı,",
      },

      {
        type: "paragraph",
        text: "• Kamu haklarından mahrumiyeti bulunmayan,",
      },
      {
        type: "paragraph",
        text: "• Kasten işlenen bir suçtan dolayı, bir yıl veya daha fazla süreyle hapis cezasına ya da affa uğramış olsa bile devletin güvenliğine karşı suçlar, Anayasal düzene ve bu düzenin işleyişine karşı suçlar, zimmet, irtikâp, rüşvet, hırsızlık, dolandırıcılık, sahtecilik, güveni kötüye kullanma, hileli iflas, ihaleye fesat karıştırma, edimin ifasına fesat karıştırma, suçtan kaynaklanan malvarlığı değerlerini aklama veya kaçakçılık suçlarından mahkûm olmamış,",
      },
      {
        type: "paragraph",
        text: "•  01/06/2024 tarihi itibariyle 35 yaşını doldurmamış olan (01/06/1989 ve sonraki tarihlerde doğanlar),",
      },
      {
        type: "paragraph",
        text: "• Erkek adaylar için askerlikle ilişiği olmayan,",
      },
      {
        type: "paragraph",
        text: "• Sağlık muayenesinde çok tehlikeli iş sınıflarında çalışabilmeye uygunluk ve görevlerini yapmaya engel olabilecek herhangi bir bedensel veya ruhsal rahatsızlığı bulunmayan,",
      },
      {
        type: "paragraph",
        text: "• Üniversitelerin en az dört yıllık eğitim veren fakülteleri ile denkliği yetkili makamlarca kabul edilmiş, yurtdışındaki eğitim kurumlarının aşağıda belirtilen bölümlerinden mezun olan,",
      },

      {
        type: "heading",
        text: "ÖĞRENİM ŞARTI",
      },
      {
        type: "paragraph",
        text: "Üniversitelerin; ",
      },

      {
        type: "paragraph",
        text: "• Metalurji ve Malzeme Mühendisliği,",
      },
      {
        type: "paragraph",
        text: "• Makine Mühendisliği,",
      },
      {
        type: "paragraph",
        text: "• Elektrik - Elektronik Mühendisliği,",
      },
      {
        type: "paragraph",
        text: "• Elektronik  Mühendisliği,",
      },
      {
        type: "paragraph",
        text: "• Endüstri Mühendisliği,",
      },
      {
        type: "paragraph",
        text: "• Kimya Mühendisliği, branşlarından mezun olmak.",
      },

      {
        type: "heading",
        text: "BAŞVURU",
      },
      {
        type: "paragraph",
        text: "Başvurular KARDEMİR’in www.kardemir.com adresindeki internet sitesi üzerinden yapılacaktır. Adayların 14/06/2024 tarihinden itibaren ve en geç 30/06/2024 Pazar günü saat 23.59’a kadar başvuru işlemlerini tamamlamaları gerekmektedir.",
      },
      {
        type: "heading",
        text: "DEĞERLENDİRME",
      },
      {
        type: "paragraph",
        text: "İlan kriterlerini sağlayan adaylar Genel Yetenek ve Yabancı Dil (İngilizce) sınavına tabi tutulacaktır. Sınav ve mülakat tarihleri adaylara ayrıca bildirilecektir. Yukarıda belirtilen pozisyonlar için sıralamaya esas toplam puanları dikkate alınarak, ilan edilen kontenjanın dört katı kadar aday, KARDEMİR Seçme ve Değerlendirme Kurulu tarafından mülakata tabi tutulacaktır.",
      },
    ],
  },
  {
    id: "yaz-donemi-staj-basvurulari-basladi",
    title: "Yaz Dönemi Staj Başvuruları Başladı",
    date: "11.03.2024",
    image: foto5,
    content: [
      {
        type: "paragraph",
        text: "Şirketimizde staj yapmak isteyen üniversite ve yüksekokullarda öğrenim gören öğrenciler, üretim ve yönetim stajı kapsamında planladığımız yaz dönemi staj ön kayıtlarını, 5 Nisan 2024 tarihine kadar yapabilirler.",
      },

      {
        type: "paragraph",
        text: "Staj başvuru kitapçığında yer alan bilgilerin dikkatle okunup formun bu bilgiler ışığında doldurulması önem arz etmektedir.",
      },

      {
        type: "paragraph",
        text: "Başvuruda bulunmak için tıklayınız.",
      },
    ],
  },
  {
    id: "yonetim-kurulu-baskanimiz-sayin-prof-dr-ismail-demirin-basin-aciklamasi",
    title:
      "Yönetim Kurulu Başkanımız Sayın Prof. Dr. İsmail Demir’in Basın Açıklaması",
    date: "15.02.2024",
    image: foto5,
    content: [
      {
        type: "paragraph",
        text: "Şirketimiz Yönetim Kurulu Başkanı Prof. Dr. İsmail Demir, şirketimizin çizdiği stratejik plan, yatırım hedefi, üretim tonajı, Yeşil Mutabakat ve Sınırda Karbon Düzenlemesi ve yeşil çelik yatırımlarında atılabilecek yerli ve milli teknoloji adımları hakkında basın mensuplarıyla bir araya gelerek açıklamalar yaptı..",
      },

      {
        type: "paragraph",
        text: "“Kardemir demir – çelik sektöründe yerli ve milli üretim stratejileriyle; sektörel bağımsızlığı sağlayacak ve küresel bir güç olmak vizyonuyla yoluna devam edecektir. Bu kapsamda yenilikçi teknoloji ve sürekli iyileştirme anlayışının vurgulandığı, çevresel – sosyal sürdürülebilirliğin ve paydaş değerlerinin ön planda tutulduğu mevcut üretiminin ötesinde değer katan ve fark oluşturan operasyonları ile değişime liderlik etmeyi hedeflemektedir.",
      },

      {
        type: "paragraph",
        text: "Hazırlanan 5 yıllık Stratejik Plandan ve Türkiye Yüzyılının ilkelerinden hareketle; Uzun vadeli ve sürdürülebilir iş modelleri ile şirketimizin rekabet avantajı elde edebileceği alanların oluşturulmasına ağırlık verilerek, stratejik yatırım, ürün alaşım ve teknolojilerine yönelik yatırım tercihleri ile fark oluşturmak önceliğimizdir. Stratejik planlarımız doğrultusunda; önümüzdeki 5 yıl içerisinde 1,5 milyar doları bulacak yeni yatırım hedefimizi paylaşmak istiyorum.",
      },
      {
        type: "paragraph",
        text: "Ham çelik üretim tonajımızı kısa vadede yeni yatırım planlarımızla beraber 2,5 milyon ton seviyelerinden 3,5 milyon tona çıkartmayı hedeflemekteyiz. Katma değerli çelik üretimini önümüzdeki 5 yıl içerisinde iki katına çıkaracak planlamalar içindeyiz. Süreç boyunca uygulanacak kontrol mekanizmaları ile yarı mamul ve nihai mamul kaliteli teknolojiler ve nitelikleri sürekli iyileştirilecek bunu destekleyen operasyonel verimlilik artırılacaktır.",
      },
      {
        type: "paragraph",
        text: "Yatırımlarımızın tamamında önceliğimiz; işimize uygun, doğaya mümkün olan en saygılı teknik ve teknolojiyi seçmektir. Yenilikçilik ve değişimin öncüsü olma anlayışıyla yeni teknolojiler doğrultusunda ürün ve prosesleri geliştirmeyi planlıyoruz. Bu kapsamda halihazırda görüşmeleri yürütülen yeni teknoloji ile uyumlu çevreci uygulamaları bulan ve uygulayan öncü bir kuruluş olarak yeni bir yüksek fırın yatırımı hayata geçirilecektir.",
      },
      {
        type: "paragraph",
        text: "Bilindiği gibi kapasite açısından lojistik kısıtlamalarımızın aşılması gerekmektedir. Bu bağlamda lojistik master planı hazırlanacak, alternatif taşıma kanalları ortaya konacak. Lojistik master planı dahilinde görüşülen liman işletmeciliği ve demiryolu işletmeciliği projeleri hayata geçirilecek.",
      },
      {
        type: "paragraph",
        text: "Yük taşımacılığı hizmetlerimizin verimliliğini artırmaya ve daha yeşil ulaşım seçeneklerine geçişi desteklemeye yönelik başladığımız bu serüvende büyüklüğümüzü sürdürecek atılımların bir parçası olarak gördüğümüz Özel Demiryolu Taşımacılık İşletmesi olma yolunda alınan kararlar ile başlanılan projede onay sürecinin iki yıl içinde tamamlanması planlıyoruz. Bu konuları stratejik iş birliği yapılabilecek paydaşlarla yapmakta planlarımızı bir parçası olabilir. Bu formülle liman ve demiryolu projeleri ile kritik stok seviyeleri etkin olarak takip edilecek, fırsat alımları değerlendirilecek ve stok maliyetleri kontrol altında tutulacaktır.",
      },
      {
        type: "paragraph",
        text: "Cumhurbaşkanımızın Kardemir'e vermiş olduğu değer, ülkemizin sanayi ve ekonomik kalkınması açısından kritik bir role sahiptir. Bu destek, Kardemir'in sadece bir sanayi kuruluşu olmanın ötesinde, ulusal ve uluslararası alanda rekabet edebilirlik gücünü artırarak Türkiye'nin sanayi potansiyelini daha da geliştirmesine katkı sağladığı gibi bu desteği, yerli üretimin teşvik edilmesi ve istihdamın artırılması gibi stratejik hedeflerimizi ve ülkemizin sanayideki önemli konumunu korumasına yardımcı olmaktadır.",
      },
      {
        type: "paragraph",
        text: "Günümüz şartlarında şirketlerin mali yapılarını güçlendirmesi sürdürülebilirlik açısından en önemli konulardan bir tanesidir. Şirketimizin sürdürülebilir rekabet gücünü artıracak etkin finansal sistem yapısı geliştirilecek, maliyet azaltıcı ve karlılık artırıcı faaliyetler ile “Mali Yapıyı Güçlendirmek” amaçlanmaktadır. Katma değer ve karlılık konuları önceliğimiz olacaktır. Bu sayede karlılığımız yeni ve yenilikçi yatırımları mümkün kılacağı gibi temettü dağıtımı ile hissedarlarımıza değere ortak etmeyi sürdürmek isteriz. Hisselerinin tamamı Borsa İstanbul’da işlem gören Şirketimiz yaptığı yatırımlar ve karşılığındaki kazanç potansiyelini ortaya koyarak yatırımcı çekmeye devam edecektir.",
      },
      {
        type: "paragraph",
        text: "Demir-Çelik şirketlerinin önünde artık AB Yeşil Mutabakat ve Sınırda Karbon Düzenlemesi vardır. Şirketimizde bu düzenlemeye uygun bir şekilde 2053 hedefleri doğrultusunda yola devam edecektir.",
      },
      {
        type: "paragraph",
        text: "Şirketimiz; “Sağlıklı Çevre ve Verimli Üretim” ilkesinden hareketle, tüm üretim ve yatırım faaliyetlerinde, çevreye duyarlı olmayı ve sürekli geliştirmeyi temel prensipler olarak kabul etmektedir. Şirketimiz bu noktada 2006 yılından 2023 yılı sonuna kadar yaklaşık 160 milyon Dolar harcama yapılmış olup, 2024 yılı için çevre yatırımlarımıza yaklaşık 40 milyon Dolar bütçe ayrılmıştır. Önümüzdeki yıllarda da çevreci yatırımlarımız hızla artarak devam edecek insana ve çevreye saygılı olmayı şiar edinmeye devam edeceğiz.",
      },
      {
        type: "paragraph",
        text: "Bu kapsamda Haziran 2023 döneminde Pota Fırını 2 ve Pota Fırını 3 Toz Toplama, Mayıs 2023 döneminde Kok Bataryaları Deşarj Emisyonu Düşürülmesi, Kasım 2023 döneminde Kok Bataryaları Şarj Emisyonu Düşürülmesi, Aralık 2023 döneminde Mevcut Sahanın Rehabilitasyonu-Etap 1 gibi büyük çevre yatırımlarımız tamamlanmıştır.",
      },
      {
        type: "paragraph",
        text: "Üzerimizdeki misyonlardan bir tanesi de efektif bir Demir - Çelik ekosistemini kurmaktır. Bu kapsamda rekabet öncesi iş birliği geliştirilerek ürünlerin sektörler arası kullanımı artırılacak ve demir çelik geleceğini inşa edecek ARGE projeleri sektör firmaları ile geliştirmeyi planlamaktayız. Demiryolu sektöründe ray ve tekerde tek üretici konumunda olan şirketimiz, geliştirmekte olduğu ürünlerde de yol haritasını sektör temsilcileriyle birlikte oluşturacaktır. Demir çelik sanayiini geliştirecek ve sürdürülebilirliği sağlayacak yeşil çelik stratejilerini ortaya koyacak Yeşil Çelik Strateji Yol Haritası son haline getirilecektir. Burada önemle altını çizdiğimiz konu ise yeşil çelik konusundaki teknolojiyi yerli ve milli firmalarla ortak payda da buluşarak geliştirmeyi planlıyoruz.",
      },
      {
        type: "paragraph",
        text: "Karbon Nötr yolunda karbon emisyonlarımızı 2030 yılına kadar %15 azaltmayı ve 2053 yılına kadar Karbon nötr bir tesis olmayı hedeflemekteyiz. Yine enerji tüketimlerinin verimli yönetilmesi için enerji verimliliği projeleri geliştirilecek, alternatif yenilenebilir enerji kaynakları yatırımları yapılacaktır. Böylece ton ham çelik başına düşen enerji tüketim miktarı düşürülecektir. Değişen çevre koşulları ve iklim krizine bağlı olarak yüzey suyu ve yeraltı sularının azalarak tehlike arz ediyor olmasından dolayı toplam tüketilen su miktarı %15 oranında düşürülecektir.",
      },
      {
        type: "paragraph",
        text: "Yenilenebilir enerji kaynakları yatırım projemiz ile faaliyetlerimiz için ihtiyaç duyduğumuz elektriğin önemli bir kısmının fosil yakıtlar olmadan, iklim dostu, rüzgâr ve güneş potansiyelinden yararlanılarak sürdürülebilir bir şekilde üretilmesine olanak sağlayacağız. 2026 yılı sonuna kadar 250 MW yenilenebilir enerji üretimine ulaşılmasını hedeflemekteyiz. Böylelikle ülkemizi de dünyanın en büyük yenilenebilir enerji piyasasındaki öncül devletler arasında olmasına önemli bir katkı sağlamış olacağız.",
      },
      {
        type: "paragraph",
        text: "Kurumsal yapıyı güçlendirmek ise sürdürülebilir bir Kardemir için önemli bir adımdır. Kardemir, mevcut iş, finans ve operasyon modelleri, teknoloji yol haritası yeniden tanımlanarak, hangi alanlarda nasıl kazanımlar elde edilmesi gerektiği ve dönüşümün hangi boyutta desteklenmesi gerektiği vurgulanacaktır.",
      },
      {
        type: "paragraph",
        text: "Dijital dönüşümü sağlamak için kurumsal kaynak planlama çalışmaları üst seviyeye çıkarılacak ve Seviye 3 sistemlerine geçiş hızlandırılacak. Entegre ölçme ve değerlendirme sistemi kurularak başta stratejik plan ve performans programı olmak üzere entegre raporlamalar yapılacaktır.",
      },
      {
        type: "paragraph",
        text: "Kurumsal risk ve denetim mekanizmalarının etkinliği artırılacaktır. Kurumsal Risk Yönetimi süreçlerinin etkililiğini, etkinliğini ve sürekliliğini geliştirmeye katkıda bulunmaya yönelik olarak Kardemir A.Ş. Kurumsal Risk Yönetimi Projesi başlatılmıştır.",
      },
      {
        type: "paragraph",
        text: "Temel değerlerimizden “İnsan İçin İnsanla” ilkesinden yola çıkarak önce çalışanların güvenliğini sağlamak amacıyla sıfır iş kazası hedefi ortaya konmaktadır. Stratejik insan kaynakları uygulamalarını etkinleştirmek ve organizasyon yapısını geliştirmek için işgücü planlaması yapılacaktır. Yaklaşık 5 bin çalışanın üstünde şirketimize başladığımız günden itibaren Kardemir’in ihtiyaçları doğrultusunda istihdam artırma hedefine yönelik 527 yeni personel bünyemize kattık. Bu ay içerisinde sayıyı 600’ün üzerine çıkaracağız. Yine bu doğrultuda önümüzdeki yıllarda da Kardemir ülkeye ve bölgeye istidam sağlayan bir kuruluş olmaya devam edecektir. 2024 yılı içinde toplam 200 personelin daha bünyemize katılması planlanmaktadır.",
      },
      {
        type: "paragraph",
        text: "Kardemir, bağlı ortaklık yapısı yeniden değerlendirilerek iş modeli tasarlanacak, şirketlerimizin mühendislik kabiliyetlerini ön plana çıkaran ve pazarlayan kuruluşlar haline getireceğiz. Kardökmak’ı Demir -Çelik sektörünün Ar-Ge mühendislik, tasarım ve Ür-Ge firması haline getirmeyi, Enbatı’yı yeni ve yenilenebilir enerji teknolojileri alanında uzmanlaştırmayı, Karçel’i ise yapısal çelikte imalat montaj ve tesis kurulumunda bir çelik servis merkezi kurulumuyla beraber daha da etkin ve yetkin hale getirmeyi hedefliyoruz.",
      },
      {
        type: "paragraph",
        text: "Kardemir, demir ve çelik üretimindeki uzmanlığıyla başta savunma sanayi olmak üzere nitelikli alaşım ihtiyacı olan kritik sanayi alanlarının ihtiyaç duyduğu yüksek kaliteli malzemelerin tedarikinde önemli bir role sahiptir. Kardemir'in büyük ölçekli üretim kapasitesi ve esnek üretim yetenekleri, bağlı ortaklıklar ile sağladığı esneklik ile kritik ve stratejik sanayimizin taleplerini karşılamak için ideal bir altyapı sunmaktadır. Kardemir'in bu katkıları; yerli ve milli üretimdeki güçlü adımlarımızı destekleyerek ülke güvenliğimizi daha da sağlamlaştırabilir. Savunma sanayiinde kazandığımız uzun yıllara dayanan deneyimleri, Kardemir'in operasyonlarına entegre ederek hem ulusal hem de uluslararası alanda rekabet gücünü artırmayı hedefliyoruz.”",
      },
      {
        type: "paragraph",
        text: "Kamuoyuna saygıyla duyurulur.",
      },
      {
        type: "paragraph",
        text: "KARDEMİR A.Ş.",
      },
    ],
  },
  {
    id: "olaganustu-genel-kurulda-belirlenen-yonetim-kurulu-kadromuz",
    title: "Olağanüstü Genel Kurul'da Belirlenen Yönetim Kurulu Kadromuz",
    date: "24.10.2023",
    image: foto5,
    content: [
      {
        type: "paragraph",
        text: "23/10/2023 Pazartesi, şirketimiz Eğitim ve Kültür Merkezinde, Olağanüstü Genel Kurulumuz gerçekleştirilmiştir. Hissedarlarımızın fiziki ve çevrimiçi katılımları ile düzenlenen olağanüstü genel kurulumuzda, yeni dönem Kardemir A.Ş. Yönetim Kurulumuz aşağıdaki gibi belirlenmiştir.",
      },

      {
        type: "paragraph",
        text: "• Yönetim Kurulu Başkanı Prof. Dr. İsmail Demir",
      },

      {
        type: "paragraph",
        text: "• Yönetim Kurulu Başkan Vekili Ömer Demirhan",
      },
      {
        type: "paragraph",
        text: "• Prof. Dr. Ercüment Arvas",
      },
      {
        type: "paragraph",
        text: "• Fatih Mehmet Erkoç",
      },
      {
        type: "paragraph",
        text: "• Kamil Güleç",
      },
      {
        type: "paragraph",
        text: "• Muhammed Ali Oflaz",
      },
      {
        type: "paragraph",
        text: "• Mustafa Aydın",
      },
      {
        type: "paragraph",
        text: "• Yusuf Canbolat",
      },
      {
        type: "paragraph",
        text: "• Prof. Dr. Arif Nihat Güllüoğlu",
      },
      {
        type: "paragraph",
        text: "• Hacı Abdullah Kaya",
      },
      {
        type: "paragraph",
        text: "• Prof. Dr. Mehmet Cahit Ensari",
      },
      {
        type: "paragraph",
        text: "Ayrıca, Yönetim Kurulu'muzun 23.10.2023 tarihinde yapılan toplantısında;",
      },
      {
        type: "paragraph",
        text: "Denetimden Sorumlu Komite Başkanlığı'na Sn. H. Abdullah KAYA, Üyeliklere Sn. Prof. Dr. Arif Nihat GÜLLÜOĞLU ve Sn. Prof. Dr. Mehmet Cahit ENSARİ'nin",
      },
      {
        type: "paragraph",
        text: "Riskin Erken Saptanması Komitesi Başkanlığı'na Sn. H. Abdullah KAYA, Üyeliklere Sn. Mustafa AYDIN ve Sn. Muhammed Ali OFLAZ'ın",
      },
      {
        type: "paragraph",
        text: "Kurumsal Yönetim Komitesi Başkanlığı'na Sn. Prof. Dr. Ercümend ARVAS, Üyeliklere Sn. Ömer DEMİRHAN , Sn. Fatih Mehmet ERKOÇ , Sn. Yusuf CANBOLAT ve Yatırımcı İlişkileri Yöneticisi Sn. Burcay YAVUZ'un",
      },
      {
        type: "paragraph",
        text: "Aday Belirleme Komitesi Başkanlığı'na Sn. Prof. Dr. Mehmet Cahit ENSARİ, Üyeliklere Sn. Prof. Dr. İsmail DEMİR ve Sn. Kamil GÜLEÇ'in atanmalarına karar verilmiştir.",
      },

      {
        type: "paragraph",
        text: "Saygılarımızla,",
      },
      {
        type: "paragraph",
        text: "KARDEMİR A.Ş.",
      },
    ],
  },
];

const HomeNew = () => {
  const [randomVideo, setRandomVideo] = useState("");
  const [randomText, setRandomText] = useState("");
  const [fade, setFade] = useState(false);
  // const [isBoxVisible, setBoxVisible] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [visible, setVisible] = useState(true);
  const navigate = useNavigate();
  const [inView, setInView] = useState(false);
  const videos = [video1, video2, video3, video4, video5];
  const texts = [
    "Türkiye’nin Fabrikası",
    "Fabrikalar Kuran Fabrika",
    "Köklü Geçmiş Güçlü Gelecek",
  ];
  const [selectedNews, setSelectedNews] = useState(newsData[0] || {});
  // Ekran genişliğini kontrol eden MediaQuery kancaları
  const isXxs = useMediaQuery("(max-width:650px)"); // Mobil
  const isXs = useMediaQuery("(min-width:650px) and (max-width:950px)"); // Tablet
  const isSm = useMediaQuery("(min-width:950px) and (max-width:1250px)"); // Tablet
  const isMd = useMediaQuery("(min-width:1250px) and (max-width:1560px)"); // Küçük ekran
  const isLg = useMediaQuery("(min-width:1560px)"); // Büyük ekran

  const imagesSet1 = [
    { src: fotolar4, alt: "foto1" },
    { src: fotolar10, alt: "foto2" },
    { src: fotolar9, alt: "foto3" },
    { src: fotolar21, alt: "foto4" },
    { src: fotolar18, alt: "foto5" },
    { src: fotolar2, alt: "foto6" },
    { src: fotolar17, alt: "foto7" },
  ];

  const imagesSet2 = [
    { src: fotolar1, alt: " Resim 8" },
    { src: fotolar13, alt: " Resim 9" },
    { src: fotolar12, alt: " Resim 10 " },
    { src: fotolar14, alt: " Resim 11" },
    { src: fotolar11, alt: " Resim 12" },
    { src: fotolar3, alt: " Resim 13" },
    { src: fotolar19, alt: " Resim 14" },
  ];

  const imagesSet3 = [
    { src: fotolar15, alt: " Resim 8" },
    { src: fotolar16, alt: " Resim 9" },
    { src: fotolar8, alt: " Resim 10 " },
    { src: fotolar5, alt: " Resim 11" },
    { src: fotolar20, alt: " Resim 12" },
    { src: fotolar6, alt: " Resim 13" },
    { src: fotolar7, alt: " Resim 14" },
  ];

  const ImageGrid = ({ images }) => {
    return (
      <Grid container spacing={1} display={"flex"}>
        <Grid item xs={12} sm={4} md={3}>
          <Box
            component="img"
            src={images[0].src}
            alt={images[0].alt}
            sx={{
              width: "100%",
              height: "30vh",
              objectFit: "cover",
              borderRadius: "5%",
            }}
          />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Box
                component="img"
                src={images[1].src}
                alt={images[1].alt}
                sx={{
                  width: "100%",
                  height: "10vh",
                  objectFit: "cover",
                  borderRadius: "5%",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Box
                component="img"
                src={images[2].src}
                alt={images[2].alt}
                sx={{
                  width: "100%",
                  height: "10vh",
                  objectFit: "cover",
                  borderRadius: "5%",
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={4} md={6}>
          <Box
            component="img"
            src={images[3].src}
            alt={images[3].alt}
            sx={{
              width: "100%",
              height: "40vh",
              objectFit: "cover",
              display: "flex",
              margin: "auto",
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Box
                component="img"
                src={images[4].src}
                alt={images[4].alt}
                sx={{
                  width: "100%",
                  height: "10vh",
                  objectFit: "cover",
                  borderRadius: "5%",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Box
                component="img"
                src={images[5].src}
                alt={images[5].alt}
                sx={{
                  width: "100%",
                  height: "10vh",
                  objectFit: "cover",
                  borderRadius: "5%",
                }}
              />
            </Grid>
          </Grid>
          <Box
            component="img"
            src={images[6].src}
            alt={images[6].alt}
            sx={{
              width: "100%",
              height: "30vh",
              objectFit: "cover",
            }}
          />
        </Grid>
      </Grid>
    );
  };

  // visible değişkenine bağlı olan useEffect
  useEffect(() => {
    if (!visible) {
      return;
    }

    const randomIndex = Math.floor(Math.random() * videos.length);
    setRandomVideo(videos[randomIndex]);
    setRandomText(
      texts[randomIndex] ||
        "Türkiye’nin Fabrikası" ||
        "Fabrikalar Kuran Fabrika" ||
        "Köklü Geçmiş Güçlü Gelecek"
    );

    // Metnin görünmesini beklemek için küçük bir gecikme ayarla
    setFade(false);
    setTimeout(() => setFade(true), 100); // 100 ms gecikme ile fade-in başlat
  }, [visible]);

  // scroll olayını izlemek için ayrı bir useEffect
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setInView(true); // Root görünür olduğunda counter başlatılır
          } else {
            setInView(false); // Root görünür olmadığında counter durdurulur
          }
        });
      },
      { threshold: 0.5 } // %50 görünürlükte tetiklenmesi için
    );

    const target = document.querySelector(".istatistik");

    if (target) observer.observe(target);

    return () => observer.disconnect();
  }, []);

  const CustomScrollList = styled(List)({
    maxHeight: "520px",
    overflowY: "auto",
    padding: "0",
    marginRight: "1rem",
    scrollbarWidth: "thin", // Firefox için ince kaydırma çubuğu
    scrollbarColor: "#e5e5e5 transparent", // Firefox için renkler
    "&::-webkit-scrollbar": {
      width: "8px", // Kaydırma çubuğu genişliği
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#e5e5e5", // Kaydırma çubuğu rengi
      borderRadius: "8px", // Köşeleri yuvarlatma
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#3a4d7e", // Hover rengini belirleyin
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent", // Kaydırma çubuğu arka planı
    },
  });

  // Önceki haber için fonksiyon
  const handlePreviousNews = () => {
    const currentIndex = newsData.indexOf(selectedNews);
    if (currentIndex > 0) {
      setSelectedNews(newsData[currentIndex - 1]); // Bir öncekine geç
    }
  };

  // Sonraki haber için fonksiyon
  const handleNextNews = () => {
    const currentIndex = newsData.indexOf(selectedNews);
    if (currentIndex < newsData.length - 1) {
      setSelectedNews(newsData[currentIndex + 1]); // Bir sonrakine geç
    }
  };

  const ortak = [
    {
      img: foto1ortak,
      name: "Kardökmak",
      link: "https://www.kardokmak.com.tr/",
    },
    { img: foto2ortak, name: "Karçel", link: "https://www.karcel.com.tr/" },
    {
      img: foto3ortak,
      name: "Kardemir Enerji",
      link: "http://kardemirenerji.com/",
    },
    { img: foto4ortak, name: "Karlımtaş", link: "https://karlimtas.com/" },
  ];

  const istirak = [
    { img: foto1s, name: "Karcimsa", link: "http://www.karcimsa.com.tr/" },
    { img: foto2s, name: "Ermaden", link: "https://www.erdemirmaden.com.tr/" },
    {
      img: foto3s,
      name: "Vademsaş",
      link: "https://www.voestalpine.com/vademsas/tr/",
    },
    { img: foto4s, name: "EPİAŞ", link: "http://www.epias.com.tr/" },
    { img: foto5s, name: "Teknopark", link: "https://www.karabuktgb.com.tr/" },
  ];

  // const departmentsl = [
  //   {
  //     title: "Endüstriyel Boyalar",
  //     items: [
  //       {
  //         label: "demiryolutekeri",
  //         link: "https://endustriyel.dyo.com.tr/sektorler/sanayi-boyalari",
  //         img: urun1,
  //       },
  //       {
  //         label: "demiryoluraylari ",
  //         link: "https://endustriyel.dyo.com.tr/sektorler/mobilya-boyalari",
  //         img: urun2,
  //       },
  //       {
  //         label: "profiller",
  //         link: "https://endustriyel.dyo.com.tr/sektorler/oto-tamir-boyalari",
  //         img: urun3,
  //       },
  //       {
  //         label: "heaprofiller",
  //         link: "https://endustriyel.dyo.com.tr/sektorler/protective-boyalar",
  //         img: urun4,
  //       },
  //       {
  //         label: "hebprofiller",
  //         link: "https://endustriyel.dyo.com.tr/sektorler/toz-boyalar",
  //         img: urun5,
  //       },
  //       {
  //         label: "ıpeprofiller",
  //         link: "https://endustriyel.dyo.com.tr/sektorler/bobin-boyalari",
  //         img: urun6,
  //       },
  //     ],
  //   },
  //   {
  //     title: "Endüstriyel Boyalar",
  //     items: [
  //       {
  //         label: "npuprofiller",
  //         link: "https://endustriyel.dyo.com.tr/sektorler/sanayi-boyalari",
  //         img: urun7,
  //       },
  //       {
  //         label: "gıthmadendirekleri",
  //         link: "https://endustriyel.dyo.com.tr/sektorler/mobilya-boyalari",
  //         img: urun8,
  //       },
  //       {
  //         label: "platinalar",
  //         link: "https://endustriyel.dyo.com.tr/sektorler/oto-tamir-boyalari",
  //         img: urun9,
  //       },
  //       {
  //         label: "kangallar",
  //         link: "https://endustriyel.dyo.com.tr/sektorler/protective-boyalar",
  //         img: urun10,
  //       },
  //       {
  //         label: "duzyuvarlaklar",
  //         link: "https://endustriyel.dyo.com.tr/sektorler/toz-boyalar",
  //         img: urun11,
  //       },
  //       {
  //         label: "nervurlubetondonati",
  //         link: "https://endustriyel.dyo.com.tr/sektorler/bobin-boyalari",
  //         img: urun12,
  //       },
  //     ],
  //   },
  //   {
  //     title: "Endüstriyel Boyalar",
  //     items: [
  //       {
  //         label: "kutukler",
  //         link: "https://endustriyel.dyo.com.tr/sektorler/sanayi-boyalari",
  //         img: urun13,
  //       },
  //       {
  //         label: "kokurunleri",
  //         link: "https://endustriyel.dyo.com.tr/sektorler/mobilya-boyalari",
  //         img: urun14,
  //       },
  //       {
  //         label: "granuleyuksekfirincurufu",
  //         link: "https://endustriyel.dyo.com.tr/sektorler/oto-tamir-boyalari",
  //         img: urun15,
  //       },
  //       {
  //         label: "merosatigi",
  //         link: "https://endustriyel.dyo.com.tr/sektorler/protective-boyalar",
  //         img: urun16,
  //       },
  //       {
  //         label: "metalurjkkirec",
  //         link: "https://endustriyel.dyo.com.tr/sektorler/toz-boyalar",
  //         img: urun17,
  //       },
  //       {
  //         label: "havaayristirmatesisiurunleri",
  //         link: "https://endustriyel.dyo.com.tr/sektorler/bobin-boyalari",
  //         img: urun18,
  //       },
  //     ],
  //   },
  // ];

  const [departments, setDepartments] = useState([
    {
      foto: demiryolutekeri,
      isim: "Demiryolu Tekerleği",
      description:
        "Boyut ve ölçü standartları tablosuna ulaşmak için tıklayın.",
      link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_0001.pdf",
    },
    {
      foto: demiryoluraylari,
      isim: "Demiryolu Raylari",
      description:
        "Boyut ve ölçü standartları tablosuna ulaşmak için tıklayın.",
      link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00002.pdf",
    },
    {
      foto: profiller,
      isim: "I Profiller",
      description:
        "Boyut ve ölçü standartları tablosuna ulaşmak için tıklayın.",
      link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00003.pdf",
    },
    {
      foto: heaprofiller,
      isim: "HEA Profiller",
      description:
        "Boyut ve ölçü standartları tablosuna ulaşmak için tıklayın.",
      link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00004.pdf",
    },
    {
      foto: hebprofiller,
      isim: "HEB Profiller",
      description:
        "Boyut ve ölçü standartları tablosuna ulaşmak için tıklayın.",
      link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00005.pdf",
    },
    {
      foto: npuprofiller,
      isim: "NPU Profiller",
      description:
        "Boyut ve ölçü standartları tablosuna ulaşmak için tıklayın.",
      link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00007.pdf",
    },
    {
      foto: ıpeprofiller,
      isim: "IPE Profiller-Orta Geniş I Profiller/Takviyeli",
      description:
        "Boyut ve ölçü standartları tablosuna ulaşmak için tıklayın.",
      link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00006.pdf",
    },

    {
      foto: gıthmadendirekleri,
      isim: "GI ve TH Maden Direkleri",
      description:
        "Boyut ve ölçü standartları tablosuna ulaşmak için tıklayın.",
      link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00008.pdf",
    },
    {
      foto: platinalar,
      isim: "Platinalar",
      description:
        "Boyut ve ölçü standartları tablosuna ulaşmak için tıklayın.",
      link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00009.pdf",
    },
    {
      foto: kangallar,
      isim: "Kangallar",
      description:
        "Boyut ve ölçü standartları tablosuna ulaşmak için tıklayın.",
      link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00010.pdf",
    },
    {
      foto: duzyuvarlaklar,
      isim: "Düz Yuvarlaklar",
      description:
        "Boyut ve ölçü standartları tablosuna ulaşmak için tıklayın.",
      link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00011.pdf",
    },
    {
      foto: nervurlubetondonati,
      isim: "Nervürlü - Beton Donatı Çelikleri",
      description:
        "Boyut ve ölçü standartları tablosuna ulaşmak için tıklayın.",
      link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00012.pdf",
    },
    {
      foto: kutukler,
      isim: "Kütükler",
      description:
        "Boyut ve ölçü standartları tablosuna ulaşmak için tıklayın.",
      link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00013.pdf",
    },
    {
      foto: kokurunleri,
      isim: "Kok Ürünleri",
      description: "Kimyasal analiz tablolarına ulaşmak için tıklayın.",
      link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00014.pdf",
    },
    {
      foto: granuleyuksekfirincurufu,
      isim: "Granüle Yüksek Fırın Cürufu",
      description: "Teknik özellikler tablosuna ulaşmak için tıklayın.",
      link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00015.pdf",
    },
    {
      foto: merosatigi,
      isim: "MEROS Atığı (Sodyum Sülfat)",
      description: "Teknik özellikler tablosuna ulaşmak için tıklayın.",
      link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00016.pdf",
    },
    {
      foto: metalurjkkirec,
      isim: "Metalürjik Kireç",
      description: "Kimyasal analiz tablosuna ulaşmak için tıklayın.",
      link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00023.pdf",
    },
    {
      foto: havaayristirmatesisiurunleri,
      isim: "Hava Ayrıştırma Tesisi Ürünleri",
      description: "Teknik özellikler tablosuna ulaşmak için tıklayın.",
      link: "https://www.kardemir.com/dosyalar/satispazarlama/urunler/urun_00021.pdf",
    },
    // Add more products here
  ]);

  const [visibleIndex, setVisibleIndex] = useState(0); // Gösterilen öğelerin başlangıç indeksi

  const rotateLeft = () => {
    setVisibleIndex((prev) => (prev === 0 ? departments.length - 3 : prev - 1)); // 3 öğe kaydır
  };

  const rotateRight = () => {
    setVisibleIndex((prev) => (prev === departments.length - 3 ? 0 : prev + 1)); // 3 öğe kaydır
  };

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
      }}
    >
      {/* video */}
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          overflow: "hidden",
          // backgroundColor: "blue",
          position: "relative",
        }}
      >
        {/* Video Alanı */}
        <Box
          sx={{
            height: "99vh",
            width: "100%",
            // backgroundColor: "red",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            loop={true}
            autoplay={{
              delay: 10000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
              el: ".custom-pagination", // Pagination'ı özel div'e yönlendir
            }}
            modules={[Autoplay, Pagination]}
            style={{ height: "100%", width: "100%" }}
          >
            {videos.map((video, index) => (
              <SwiperSlide key={index}>
                <video
                  src={video}
                  autoPlay
                  loop
                  muted
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>

          {/* <Box
            sx={{
              backgroundColor: "#11295e",
              // backgroundColor: "transparent",
              height: "80px",
              backgroundImage: `url(${videoel})`, // Arka plana resmi ekle
              backgroundSize: "contain", // Resmi kutuya sığdır
              backgroundPosition: "center", // Resmi ortala
              backgroundRepeat: "no-repeat",
              width: "100%",
              marginBottom: "-9px",
            }}
          ></Box> */}

          {/* <Box
            sx={{
              backgroundColor: "#11295e",
              // backgroundColor: "red",

              height: "10vh",
              width: "100%",
            }}
          ></Box> */}
        </Box>

        {/* Pagination Noktaları - Mavi Alanın İçinde */}
        {/* <Box
            className="custom-pagination"
            sx={{
              position: "absolute",
              zIndex: "9999999999999",
              // bottom: "5px", // Mavi alan içinde aşağıda dursun
              // transform: "translateX(-50%)", // Ortaya hizala
              display: "flex",
              textAlign: "center",
              width: "100%",
              // padding: "3rem",
              // margin: "2rem",
              backgroundImage: `url(${videoel})`, // Arka plana resmi ekle
              backgroundSize: "contain", // Resmi kutuya sığdır
              backgroundPosition: "center", // Resmi ortala
              height: "10vh",
              // paddingTop: "3rem",
              // top: "1rem",
              // backgroundColor: "blue",
              gap: 1,
              justifyContent: "center",
              backgroundColor: "blue",
            }}
          /> */}
      </Box>

      {/* <Box
        sx={{
          width: "100%",
          lineHeight: 0,
          height: "50px",
          overflow: "hidden",
          zIndex: 1,
          // top: "200px",
        }}
      >
        <img
          src={videoel}
          alt="altel"
          style={{
            // position: "absolute",
            top: "100px",
            backgroundColor: "red",
            // left: 0,
            width: "100%",
            // height: "100%",
            objectFit: "cover",
          }}
        />
      </Box> */}
      {/* <Box sx={{ height: "75vh" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            boxSizing: "border-box",
          }}
        >
          {randomVideo && (
            <div
              style={{ position: "relative", height: "100%", width: "100%" }}
            >
              <video
                src={randomVideo}
                autoPlay
                loop
                muted
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                  backgroundColor: "black",
                  opacity: 0.1,
                  pointerEvents: "none",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                  // backgroundImage: `linear-gradient(170deg, rgba(0, 0, 0, 0), #e5e5e5)`,

                  pointerEvents: "none",
                }}
              />
            </div>
          )}

          <Typography
            variant="h3"
            sx={{
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "#fff",
              fontWeight: "bold",
              padding: "1rem 2rem",
              zIndex: 1,
              borderRadius: "8rem",
            }}
          >
            {randomText}
          </Typography>
        </Box>
      </Box> */}
      {/* Ürünler */}
      {/* <Grid
        sx={{
          padding: "5rem 8% 5rem 8%",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            letterSpacing: "10px",
            color: "#1f2e5e",
            color: "#dbd5d5",
            // marginBottom: "-0.9rem",
          }}
          variant="h2"
        >
          Ürünlerimiz
        </Typography>
        <Box
          sx={{
            padding: "3rem 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            textAlign: "center",
            margin: "auto",
          }}
        >
          <Swiper
            modules={[Autoplay]}
            spaceBetween={60}
            slidesPerView="auto" // Dinamik genişlikte yuvarlakları desteklemek için
            loop={true}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
            }}
            speed={2500}
          >
            {urunler.map((urun, index) => (
              <SwiperSlide
                key={index}
                style={{
                  flexShrink: 0, // Slaytların boyutunu sabitle
                  width: "calc(20% - 70px)", // Her yuvarlak ekran genişliğine göre ayarlanır
                  maxWidth: "300px", // Çok büyük ekranlarda yuvarlakların maksimum boyutu
                  minWidth: "150px", // Küçük ekranlarda yuvarlakların minimum boyutu
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    height: "0",
                    paddingTop: "100%", // Yuvarlak oluşturmak için oran
                    borderRadius: "50%",
                    overflow: "hidden",
                    margin: "auto",
                    transition: "transform 0.3s",
                    "&:hover": {
                      transform: "scale(1.01)",
                    },
                    "&:hover .overlay": {
                      opacity: 1,
                    },
                  }}
                  onClick={() => (window.location.href = "/urunler")}
                >
                  <img
                    src={urun.foto}
                    alt={`slide-image-${index}`}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <Box
                    className="overlay"
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.3)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      opacity: 0,
                      transition: "opacity 0.3s",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        // fontWeight: "bold",
                        fontSize: "1.2rem",
                        textAlign: "center",
                      }}
                    >
                      {urun.isim}
                    </Typography>
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Grid> */}

      <Grid container spacing={6} sx={{ padding: "4rem 8%" }}>
        {/* Sol Kısım */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              letterSpacing: "5px",
              color: "#555",
              mb: 2,
            }}
          >
            Ürünler
          </Typography>
          <Typography
            variant="h3"
            sx={{
              color: "#1f2e5e",
              fontWeight: "600",
              lineHeight: "1.5",
              marginBottom: "2rem",
              fontSize: { xs: "2rem", md: "3rem" },
            }}
          >
            Yüksek kaliteli çelik üretimiyle sanayinin temelini güçlendiriyoruz.
          </Typography>
          <Typography
            variant="h5"
            sx={{
              color: "#1f2e5e",
              fontWeight: "400",
              lineHeight: "1.5",
              marginBottom: "2rem",
              fontSize: { xs: "1.3rem", md: "1.5rem" },
            }}
          >
            KARDEMİR olarak, Demir Çelik sektörüne yerli ve milli üretim
            stratejileriyle katma değer sağlayan sektörel bağımsızlığı ve
            Türkiye Yüzyılı İlkelerini benimseyen küresel güç olmak viyonuyla
            yola çıktık.
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: "#555",
              fontWeight: "400",
              lineHeight: "1.5",
            }}
          >
            Yerli ve milli üretim stratejileriyle, demir çelik sektöründe
            küresel bir güç olmayı hedefliyor; Türkiye Yüzyılı vizyonuyla
            sanayinin temel taşlarını üretiyoruz.
          </Typography>
        </Grid>

        {/* Sağ Kısım (Ürün Kartları - 2'şerli Sıralama) */}
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            {departments.slice(0, 6).map((department, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    padding: ".4rem",
                    // height: "170px",
                    bgcolor: "#f5f5f5",
                    boxShadow: 3,
                    transition: "0.3s",
                    "&:hover": { boxShadow: 6 },
                  }}
                >
                  {/* <ButtonBase sx={{ width: "100%" }}>
                    <img
                      src={department.foto}
                      alt={department.isim}
                      style={{
                        width: "140px",
                        height: "140px",
                        borderRadius: "50%",
                        marginBottom: "1rem",
                      }}
                    />
                  </ButtonBase> */}
                  <img
                    src={department.foto}
                    alt={department.isim}
                    style={{
                      width: "140px",
                      height: "140px",
                      // borderRadius: "50%",
                      marginBottom: "1rem",
                    }}
                  />
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: "#1f2e5e",
                      mb: 1,
                    }}
                  >
                    {department.isim}
                  </Typography>
                  {/* <Typography variant="body2" sx={{ color: "#555" }}>
                    <a
                      href={department.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "none",
                        color: "#1f2e5e",
                        fontWeight: "bold",
                      }}
                    >
                      {department.description}
                    </a>
                  </Typography> */}
                </Card>
              </Grid>
            ))}
            <Button
              onClick={() => navigate(`/urunler`)}
              sx={{
                textTransform: "capitalize",
                backgroundColor: "#1f2e5e",
                fontSize: "1rem",
                margin: " auto",
                marginTop: "30px",
                color: "#e5e5e5",
                borderRadius: "10px",
                paddingX: "1rem",
                "&:hover": {
                  backgroundColor: "#1f2e5e",
                  color: "#e5e5e5",
                },
              }}
            >
              Tüm Ürünler
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {/* Ürünler başlığı */}
      {/* <Grid
        item
        xs={12}
        sx={{
          padding: "2rem 0 0rem 8%",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            letterSpacing: "10px",
            color: "#1f2e5e",
            // backgroundColor: "#1f2e5e",
            // marginBottom: "-0.9rem",
          }}
          variant="h2"
        >
          Ürünler
        </Typography>
      </Grid> */}
      {/* Ürünler2 */}
      {/* <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{
          mt: 4,
          position: "relative",
          padding: "2rem 8% 4rem 8%",
        }}
      >
        {departments
          .slice(visibleIndex, visibleIndex + 3)
          .map((department, index) => {
            let transformStyle = {};
            if (index === 0)
              transformStyle = {
                transform: "rotate(-10deg) translateX(30px)",
                zIndex: 0,
                opacity: "0.7",
                backgroundColor: "#1f2e5e",
                "&:hover": {
                  transform: "rotate(-10deg) translateX(20px)",
                  opacity: "1",
                },
                "@media (max-width: 900px)": {
                  display: "none",
                },
              };
            if (index === 2)
              transformStyle = {
                transform: "rotate(10deg) translateX(-30px)",
                zIndex: 0,
                opacity: 0.7,

                backgroundColor: "#1f2e5e",
                "&:hover": {
                  transform: "rotate(10deg) translateX(-40px)",
                  opacity: "1",
                },
                "@media (max-width: 900px)": {
                  display: "none",
                },
              };

            if (index === 1)
              transformStyle = {
                zIndex: 1,
                transform: "translateY(-40px)",
                backgroundColor: "#1f2e5e",

                "&:hover": {
                  transform: " translateY(-50px)",
                },
              };

            return (
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                key={index}
                sx={{
                  display: "flex",
                  // backgroundColor: "yellow",
                  justifyContent: "center",
                  // alignItems: "center",
                  transition: "transform 0.5s ease",
                }}
              >
                <Card
                  sx={{
                    px: { xs: 0, lg: index === 1 ? 11 : 7 }, // Ortadaki kart daha büyük
                    py: index === 1 ? 5 : 1, // Ortadaki kart daha büyük
                    textAlign: "center",
                    bgcolor: "#f5f5f5",
                    transition: "0.5s ease",
                    boxShadow: index === 1 ? 6 : 2,
                    width: index === 1 ? "280px" : "320px", // Ortadaki büyük
                    height:
                      index === 1 ? "380px" : index === 2 ? "350px" : "330px", // Ortadaki büyük
                    ...transformStyle,
                    // "&:hover": {
                    //   transform: index === 1 ? "scale(1.01)" : "scale(1.003)",
                    //   boxShadow: 8,
                    // },
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="h6"
                      sx={{
                        mb: 2,
                        color: "#e5e5e5",
                      }}
                    >
                      {department.isim}
                    </Typography>
                    <ButtonBase sx={{ width: "100%" }}>
                      <img
                        src={department.foto}
                        alt={department.isim}
                        style={{
                          width: "180px",
                          height: "180px", // Kart yüksekliğine bağlı dinamik boyut
                          marginBottom: "8px",
                          borderRadius: "50%",
                        }}
                      />
                    </ButtonBase>
                    <Typography
                      variant="body2"
                      sx={{
                        mt: 2,
                        // backgroundColor: "red",
                        width: "70%",
                        margin: "auto",
                      }}
                    >
                      <a
                        href={department.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "none",
                          color: "#e5e5e5",
                          // fontWeight: "bold",
                        }}
                      >
                        {department.description}
                      </a>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}

        <IconButton
          onClick={rotateLeft}
          sx={{
            position: "absolute",
            left: "8%",
            zIndex: "999",

            top: "50%",
            transform: "translateY(-50%)",
            bgcolor: "white",
            boxShadow: 3,
            "@media (max-width: 900px)": {
              left: "25px",
            },
          }}
        >
          <ArrowBackIosIcon />
        </IconButton>

        <IconButton
          onClick={rotateRight}
          sx={{
            position: "absolute",
            zIndex: "999",
            right: "8%",
            top: "50%",
            transform: "translateY(-50%)",
            bgcolor: "white",
            boxShadow: 3,
            "@media (max-width: 900px)": {
              right: "10px",
            },
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Grid> */}
      {/* Haberler Başlığı */}
      <Grid
        item
        xs={12}
        sx={{
          padding: "0rem 0 0rem 8%",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            letterSpacing: "10px",
            color: "#1f2e5e",
            // backgroundColor: "#1f2e5e",
            // marginBottom: "-0.9rem",
          }}
          variant="h2"
        >
          Haberler
        </Typography>
      </Grid>
      {/* Haberler */}
      <Grid
        container
        sx={(theme) => ({
          margin: "0",
          padding: "0",
          background: `linear-gradient(to right, #1f2e5e 33%, rgba(255, 255, 255, 0.8) 70%)`,
          // backgroundColor: "#1f2e5e",
          clipPath: "none",
          [theme.breakpoints.up("lg")]: {
            clipPath: "polygon(0 0, 100% 0, 90% 100%, 0% 100%)",
          },
          // "polygon(0 0, 100% 0, 90% 100%, 0% 100%)", // Eğimi oluşturur
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "auto",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "center",
        })}
      >
        {/* Sol taraf - Tek haber */}
        <Grid
          item
          xs={12}
          lg={4}
          paddingLeft="8%"
          sx={(theme) => ({
            clipPath: "none ", // Kesin kaldırma
            [theme.breakpoints.up("lg")]: {
              clipPath: "polygon(0 0, 100% 0, 95% 100%, 0% 100%)",
            },
            display: "flex",
            // flexDirection: "column",
            // backgroundColor: "yellow",
            backgroundColor: "#1f2e5e",
            alignItems: "center",
            justifyContent: "center",
            // opacity: "0.5",
          })}
          // sx={(theme) => ({
          //   position: "relative",
          //   background:
          //     "linear-gradient(to left, rgba(31, 46, 94, 1) 40%, rgba(255, 255, 255, 0.8) 100%)",
          //   [theme.breakpoints.up("lg")]: {
          //     clipPath: "polygon(0 0, 80% 0, 100% 100%, 0% 100%)",
          //   },
          //   padding: { xs: "2rem 8%", lg: "2rem 8% 2rem 20%" },
          //   margin: "0",
          //   border: "3px solid lightgrey",
          //   display: "flex",
          //   alignItems: "center",
          // })}
        >
          <Box
            sx={{
              maxHeight: "520px",
              // backgroundColor: "blue",
              // position: "relative",
            }}
          >
            {newsData && newsData.length > 0 && (
              <Box
                sx={{
                  paddingY: "1.5rem",
                  color: "#fff",
                  // border: "3px solid #ccc",
                  height: "250px",
                  width: "350px",
                  // backgroundColor: "pink",
                  borderRadius: "8px",
                  position: "relative",
                  // bottom: "2px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    // marginBottom: "1rem",
                    textAlign: "center",
                  }}
                >
                  {selectedNews.title} {/* Seçili haberin başlığı */}
                </Typography>

                <Typography
                  variant="body1"
                  sx={{
                    marginBottom: "1rem",
                    color: "#e5e5e5",
                    padding: "1.8rem 0rem 1.8rem 0",
                    textAlign: "center",
                  }}
                >
                  {
                    selectedNews.content
                      .map((contentItem) => contentItem.text) // Tüm metinleri al
                      .join(" ") // Paragrafları birleştir, aralarına boşluk ekle
                      .slice(0, 120) // İlk 100 karakteri al
                      .replace(/\s+\S*$/, "") // Son kelimeyi tamamla (kesme işlemi kelimeyi bölmesin)
                      .concat("...") // 100 karakterden sonra "..." ekle
                  }
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    position: "absolute",
                    bottom: "2px",
                    margin: "auto",
                    left: "100px",
                    // backgroundColor: "red",
                  }}
                >
                  <IconButton
                    onClick={handlePreviousNews}
                    sx={{
                      backgroundColor: "#1f2e5e",
                      borderRadius: "50%",
                      padding: "1rem",
                      color: "#1f2e5e",
                      backgroundColor: "#fff",
                      "&:hover": {
                        backgroundColor: "#e5e5e5",
                      },
                    }}
                  >
                    <ArrowBackIosIcon />
                  </IconButton>

                  <IconButton
                    onClick={handleNextNews}
                    sx={{
                      backgroundColor: "#1f2e5e",
                      borderRadius: "50%",
                      padding: "1rem",
                      color: "#1f2e5e",
                      backgroundColor: "#fff",
                      "&:hover": {
                        backgroundColor: "#e5e5e5",
                      },
                    }}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>

        {/* Sağ taraf - Seçili Haber */}
        <Grid
          item
          xs={12}
          lg={8}
          sx={(theme) => ({
            clipPath: "none ", // Kesin kaldırma
            [theme.breakpoints.up("lg")]: {
              clipPath: "polygon(30% 0, 100% 0, 100% 100%, 0% 100%)",
            },
            backgroundColor: "#fff",
            // background: "linear-gradient(to right, #1f2e5e , #fff )",
            // backgroundImage: `url(${foto1ortak})`,
            backgroundSize: "cover", // Resmi kapsayacak şekilde ölçeklendir
            backgroundPosition: "center", // Resmi ortala
            // backgroundRepeat: "no-repeat", // Tekrarlanmasını engelle
            padding: { xs: "2rem 8%", lg: "2rem 8% 2rem 20%" },
            margin: "0",
            border: "3px solid white",
            display: "flex",
            alignItems: "center",
          })}
        >
          <Box
            sx={{
              height: "100%",
              maxHeight: "520px",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <Box
              sx={{
                height: "100px",
                // backgroundColor: "red",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  marginBottom: "1rem",
                  color: "#1f2e5e",
                }}
              >
                {selectedNews.title}
              </Typography>
            </Box>
            <img
              src={selectedNews.image}
              alt={selectedNews.title}
              style={{
                width: "100%",
                maxHeight: "300px",
                objectFit: "cover",
                marginBottom: "1rem",
                borderRadius: "8px",
              }}
            />
            <Box
              sx={{
                height: "400px",
              }}
            >
              {selectedNews.content && (
                <Typography
                  variant="body1"
                  sx={{
                    marginBottom: "1rem",
                    color: "#1f2e5e",
                  }}
                >
                  {
                    selectedNews.content
                      .map((contentItem) => contentItem.text) // Tüm metinleri al
                      .join(" ") // Paragrafları birleştir, aralarına boşluk ekle
                      .slice(0, 180) // İlk 100 karakteri al
                      .replace(/\s+\S*$/, "") // Son kelimeyi tamamla (kesme işlemi kelimeyi bölmesin)
                      .concat("...") // 100 karakterden sonra "..." ekle
                  }
                </Typography>
              )}
            </Box>

            <Button
              onClick={() => navigate(`/haberler/${selectedNews.id}`)}
              sx={{
                position: "absolute",
                textTransform: "capitalize",
                bottom: "0.1rem",
                left: "0",
                backgroundColor: "#1f2e5e",
                color: "#e5e5e5",
                "&:hover": {
                  backgroundColor: "#e5e5e5",
                  color: "#1f2e5e",
                },
              }}
            >
              Devamını Gör
            </Button>
          </Box>
        </Grid>
      </Grid>
      {/* Bağlı ortak  */}
      <Box
        sx={{
          padding: "5rem 8% 4rem 8%",
          // textAlign: "center",
          // backgroundColor: "red",
        }}
      >
        {/* Bağlı Ortaklıklar */}
        <Typography
          sx={{
            fontWeight: "bold",
            letterSpacing: "5px",
            color: "#1f2e5e",
            fontSize: { xs: "1.75rem", md: "2.75rem" },
            textAlign: "center",
            // color: "#dbd5d5",
            // marginBottom: "-0.9rem",
          }}
        >
          Bağlı Ortaklıklar
        </Typography>
        <Box
          sx={{
            mt: { xs: "1", md: "5" },

            width: "100%",
          }}
        >
          <Swiper
            slidesPerView={4}
            spaceBetween={30}
            freeMode={true} // FreeMode aktif
            loop={true}
            autoplay={{ delay: 3500, disableOnInteraction: false }}
            modules={[FreeMode, Autoplay]} // FreeMode burada içe aktarılmış durumda
          >
            {ortak.map((item, index) => (
              <SwiperSlide key={index}>
                <Box
                  component="a"
                  href={item.link} // Şirketin bağlantısı buraya ekleniyor
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={item.name}
                  sx={{
                    display: "block",
                    position: "relative",
                    width: 176,
                    height: 120,
                    mx: "auto",
                    opacity: 0.5,
                    transition: "transform 0.35s, opacity 0.35s",
                    "&:hover": {
                      transform: "scale(1.1)",
                      opacity: 1,
                    },
                  }}
                >
                  <Box
                    component="img"
                    src={item.img}
                    alt={item.name}
                    sx={{
                      width: { xs: "40%", md: "100%" },
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
      {/*  iştirakler */}
      <Box
        sx={{
          padding: "0 8% 2rem 8%",
          // textAlign: "center",
          // backgroundColor: "red",
        }}
      >
        {/* Bağlı Ortaklıklar */}
        <Typography
          sx={{
            fontWeight: "bold",
            letterSpacing: "5px",
            color: "#1f2e5e",
            fontSize: { xs: "1.75rem", md: "2.75rem" },
            textAlign: "center",
            // color: "#dbd5d5",
            // marginBottom: "-0.9rem",
          }}
        >
          İştiraklerimiz
        </Typography>
        <Box
          sx={{
            mt: { xs: "1", md: "5" },
            width: "100%",
          }}
        >
          <Swiper
            slidesPerView={5}
            spaceBetween={40}
            freeMode={true} // FreeMode aktif
            loop={true}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            modules={[FreeMode, Autoplay]} // FreeMode burada içe aktarılmış durumda
          >
            {istirak.map((item, index) => (
              <SwiperSlide key={index}>
                <Box
                  component="a"
                  href={item.link} // Şirketin bağlantısı buraya ekleniyor
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={item.name}
                  sx={{
                    display: "block",
                    position: "relative",
                    width: 176,
                    height: 120,
                    mx: "auto",
                    opacity: 0.5,
                    transition: "transform 0.35s, opacity 0.35s",
                    "&:hover": {
                      transform: "scale(1.1)",
                      opacity: 1,
                    },
                  }}
                >
                  <Box
                    component="img"
                    src={item.img}
                    alt={item.name}
                    sx={{
                      width: { xs: "40%", md: "100%" },

                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
      {/* Fotoğraf galerisi */}
      <Box sx={{ padding: "4rem 0" }}>
        {isMobile ? (
          <Swiper
            modules={[Pagination, Autoplay]}
            spaceBetween={100}
            slidesPerView={1}
            pagination={{ clickable: true }}
            loop={true}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
          >
            {imagesSet1.map((image, index) => (
              <SwiperSlide key={index}>
                <Box
                  component="img"
                  src={image.src}
                  alt={image.alt}
                  sx={{
                    width: "100%",
                    height: "40vh",
                    objectFit: "cover",
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <Box
            sx={{
              mx: "8%",
            }}
          >
            <Swiper
              modules={[Pagination, Autoplay, Lazy]}
              spaceBetween={100}
              slidesPerView={1}
              pagination={{ clickable: true }}
              loop={true}
              autoplay={{
                delay: 7000,
                disableOnInteraction: false,
              }}
              lazy={true}
            >
              <SwiperSlide>
                <ImageGrid images={imagesSet1} />
              </SwiperSlide>
              <SwiperSlide>
                <ImageGrid images={imagesSet2} />
              </SwiperSlide>
              <SwiperSlide>
                <ImageGrid images={imagesSet3} />
              </SwiperSlide>
            </Swiper>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default HomeNew;
