import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Paper,
} from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
  overflowX: "auto",
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

function NedenKardemirContents() {
  const maddeler = [
    {
      title: "Tarih ve Deneyim:",
      content:
        "-Kardemir, Türkiye'nin demir çelik endüstrisinde köklü bir geçmişe ve engin bir deneyime sahiptir. 80 yılı aşkın süredir sektördeki liderliğimiz, çalışanlarımız için güvenilir ve istikrarlı bir iş ortamı sunmaktadır. Kardemir'de çalışmak, bu köklü tarihin bir parçası olmak anlamına gelir.",
    },
    {
      title: "İnovasyon ve Teknoloji:",
      content:
        "-Teknolojiye ve yeniliklere olan bağlılığımız, Kardemir'i sektörde bir adım öne çıkarıyor. Modern üretim tesislerimiz ve sürekli gelişen AR-GE çalışmalarımız ile geleceğin çelik üretim tekniklerini bugünden uyguluyoruz. Kardemir'de çalışmak, en son teknolojileri kullanma ve inovasyonun bir parçası olma fırsatı sunar.",
    },
    {
      title: "Eğitim ve Gelişim:",
      content:
        "-Kardemir, çalışanlarının sürekli gelişimini destekleyen kapsamlı eğitim programları sunar. İşbaşı eğitimleri, teknik seminerler ve kişisel gelişim programları ile çalışanlarımızın yetkinliklerini artırmayı hedefliyoruz. Kardemir'de kariyer yapmak, kişisel ve profesyonel gelişiminize yatırım yapmak demektir.",
    },
    {
      title: "Güçlü Kurumsal Kültür:",
      content:
        "-Kardemir, güçlü bir kurumsal kültüre ve çalışanlarına değer veren bir yönetim anlayışına sahiptir. Çalışan memnuniyeti ve bağlılığı, başarılarımızın temelini oluşturmaktadır. Kardemir'de çalışmak, takım ruhu ve iş birliği içinde çalışmanın keyfini yaşamanızı sağlar.",
    },
    {
      title: "Sosyal Sorumluluk ve Çevre Bilinci:",
      content:
        "-Kardemir, sadece üretim süreçlerinde değil, aynı zamanda sosyal sorumluluk projelerinde de aktif rol oynar. Çevre dostu üretim teknikleri ve sürdürülebilirlik ilkeleri doğrultusunda hareket eden Kardemir, gelecek nesillere daha yaşanabilir bir dünya bırakmayı hedefler. Kardemir'de çalışmak, topluma ve çevreye duyarlı bir şirketin parçası olmak anlamına gelir.",
    },
    {
      title: "Karabük’te Yaşam:",
      content:
        "-Karabük, tarihi ve doğal güzellikleri ile ön plana çıkan bir şehir. Şehrin huzurlu yaşam tarzı ve Kardemir'in sunduğu sosyal olanaklar, çalışanlarımızın iş ve yaşam dengesini en iyi şekilde kurmalarına olanak tanır.",
    },
  ];

  return (
    <>
      <Box sx={{ flexGrow: 1, padding: 1 }}>
        <Header variant="h4" color="#1f2e5e">
          {" "}
          Yenilikçi ve Güçlü Bir Gelecek İçin KARDEMİR'de Kariyer
        </Header>

        <StyledPaper elevation={3}>
          <Typography sx={{ textAlign: "justify" }}>
            Kardemir, Türkiye'nin ilk entegre demir çelik fabrikası olarak,
            ülkemizin sanayi tarihinde önemli bir yere sahiptir. 1937 yılından
            bu yana faaliyet gösteren Kardemir, Karabük'ün kalbinde yer alan ve
            Türkiye'nin sanayileşme sürecinde lokomotif görevi üstlenmiş bir
            kuruluştur. Peki, neden Kardemir? İşte size Kardemir'de kariyer
            yapmanız için birkaç neden:
          </Typography>
          <br />
          <List>
            {maddeler.map((madde, index) => (
              <ListItem key={index} sx={{ padding: "0.5rem 0" }}>
                {/* <ListItemIcon>
                <CircleIcon fontSize="small" />
              </ListItemIcon> */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "0.5rem 0rem ",
                  }}
                >
                  <ListItemText>
                    <Typography
                      variant="h5"
                      color="#1f2e5e"
                      sx={{ fontWeight: 600 }}
                    >
                      {" "}
                      {madde.title}
                    </Typography>
                  </ListItemText>
                  <ListItemText>
                    <Typography sx={{ textAlign: "justify" }}>
                      {" "}
                      {madde.content}
                    </Typography>
                  </ListItemText>
                </Box>
              </ListItem>
            ))}
          </List>
        </StyledPaper>
      </Box>
    </>
  );
}

export default NedenKardemirContents;
