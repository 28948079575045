import React from "react";
import { Box, Typography, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import foto1 from "../../images/sustainability/4hedef.png";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
  overflowX: "auto",
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: "#1f2e5e",
  fontWeight: "bold",
  marginBottom: theme.spacing(2),
}));

function ArgeVeInovasyonContents() {
  return (
    <Box sx={{ flexGrow: 1, padding: 1 }}>
      <Header variant="h4" color="#1f2e5e">
        AR-GE ve İnovasyon
      </Header>

      <StyledPaper elevation={3}>
        <Typography
          variant="body1"
          sx={{ marginBottom: 2, textAlign: "justify" }}
        >
          Kardemir’in sürdürdüğü yeniden yapılanma ve yatırım sürecinin en
          önemli hedeflerinden biri de Ar-Ge ve inovasyon faaliyetlerini şirket
          stratejik hedeflerine hizmet edecek biçimde, sistematik ve organize
          yöntemlerle gerçekleştirilir hale getirmektir. Bu kapsamda Ar-Ge ve
          inovasyon hem şirket iş stratejisine temel unsurlardan biri olarak
          dahil edilmiş hem de diğer unsurların içinde temel bir iş yapış ilkesi
          olarak kabul edilmiştir. Dolayısıyla inovatif düşünce tüm
          faaliyetlerde gözetilmesi gereken bir ilke haline gelmiştir. <br />
          <br /> Kardemir bünyesinde Ar-Ge çalışmaları Genel Müdürlüğe doğrudan
          bağlı Ar-Ge Merkezi tarafından yürütülmektedir. Dolayısıyla yapılan
          çalışmalar üst yönetiminin ajandasında sürekli olarak yerini almış ve
          üst yönetim direktifleriyle yürütülmektedir. Bakanlığa bildirimi
          yapılan Projeler ile iyileştirme ve yeni ürün araştırmaları kapsamında
          gerçekleştirilen çalışmalar Bakanlığa ve üst yönetime aylık, 6 aylık
          ve yıllık periyotlarla raporlanmaktadır.
        </Typography>
        <img
          src={foto1}
          alt="arge"
          style={{
            width: "80%",
            height: "70%",
            display: "flex",

            margin: "0 auto",
          }}
        />
        <Typography
          variant="body1"
          sx={{ marginBottom: 2, textAlign: "justify" }}
        >
          Ar-Ge çalışmalarımızın verimliliği çeşitli yöntemlerle kontrol ve
          denetime tabi tutulmaktadır. İç kontrol ve bağımsız finansal denetim
          dışında bu alandaki en kapsamlı denetim uygulaması Sanayi ve Teknoloji
          Bakanlığı tarafından gerçekleştirilmektedir.
        </Typography>

        <SectionTitle variant="h5">Devam Eden Ar-Ge Projelerimiz</SectionTitle>

        <Typography
          variant="body1"
          sx={{ marginBottom: 2, textAlign: "justify" }}
        >
          -Metalurjik Kok’a Çeşitli Derişimlerde Bor Solüsyonlarının
          Püskürtülmesi İle Sıcak Mukavemet Değerlerinin (CSR) Geliştirilmesi
          (2019) <br />
          <br />
          -Bağlantı Elemanlarında Kullanılan Tüm MnB (Mangan ve Bor İçerikli)
          Serisi Çeliklerin Geliştirilmesi ve Deneme Üretimi (2019)
          <br />
          <br />
          -Otomotiv Sektörü İçin Topuk Teli ve Lastik Teli Üretiminde Kullanılan
          1070 ve 1080 Kalite İnce Kangalların Deneme Üretimi (2019)
          <br />
          <br />
          -Süspansiyon Üretiminde Kullanılan 54SiCr6 Kalite Çelik Üretiminin
          Geliştirilmesi ve Deneme Üretimi (2019)
          <br />
          <br />
          -Muhtelif Silah Üretimi İçin Savunma Sanayine 4140 (42CrMo4) Kalite
          Çelik Üretiminin Gerçekleştirmesi (2019)
          <br />
          <br />
          -60R1, 60R2 ve 59R1 Oluklu Rayların Geliştirilmesi ve Üretimi (2019)
          <br />
          <br />
          -Sinter Palet Yan Duvarlarında Oluşan Çatlakların Önlenmesi (2021)
          <br />
          <br />
          -Yerinde Sodyum Hipoklorit Üretim Sistemi ile Soğutma Kulelerinde
          Kimyasal Maliyetlerinin Azaltılması ve Dezenfeksiyon Performans
          Artışının Sağlanması (2021)
          <br />
          <br />
          -860 mm Metro Demiryolu Tekerinin Geliştirilmesi ve Üretimi (2021)
          <br />
          <br />
          -20 Ton Yük Vagon Tekerinin Geliştirilmesi ve Üretimi (2021)
        </Typography>

        <SectionTitle variant="h5">Tamamlanan Ar-Ge Projelerimiz</SectionTitle>
        <Typography
          variant="body1"
          sx={{ marginBottom: 2, textAlign: "justify" }}
        >
          -Çubuk Kangal Haddehanesinde Ürün İzlenebilirliği ve Raporlamaların
          Yapılabilmesini Sağlayan Takip Sisteminin Kurulması ve Geliştirilmesi
          (2019) <br />
          <br /> -BA004 Demiryolu Tekerleklerinin Geliştirilmesi ve Üretimi
          (2019)
        </Typography>

        <SectionTitle variant="h5">Tez Prosedürü</SectionTitle>
        <Typography
          variant="body1"
          sx={{ marginBottom: 2, textAlign: "justify" }}
        >
          Lisans, Yüksek Lisans, Doktora öğrencileri tezlerinin Kardemir’e
          sağlayacağı değeri, Kardemir’in bir ihtiyacını karşılayacağı veya bir
          soruna çözüm geliştireceğini düşündüğü konularda yapma talebi
          bulunulduğunda aşağıdaki adımlar izlenmektedir:
          <br />
          <br />
          -Kardemir personeli için QDMS sisteminde, Kardemir dışındaki
          öğrenciler için web sitesinde bulunan Kardemir Lisans, Yüksek Lisans,
          Doktora Tez Başvuru ve Gizlilik Beyanı Formu FRM.ARGE.0023 doldurur ve
          ilgili yöneticilere imzalatarak Ar-Ge Müdürlüğü’ne gönderilir.
          <br />
          <br />
          -Öğrencilerin çalışma yapacağı tez konuları Kardemir’e katkı
          sağlayacak nitelikte olması ve başvuru sırasında 2 adet tez önerisi
          bulunması gerekmektedir.
          <br />
          <br />
          -Kardemir dışındaki öğrencilerin başvurularının onaylanmasında not
          ortalaması dikkate alınmaktadır. Not ortalamasının 100’lük sisteme
          göre 70 puan, 4’lük sisteme göre 2 puanın altında olmaması
          gerekmektedir.
        </Typography>

        {/* <Divider sx={{ marginY: 3 }} /> ÇİZGİ ÇEKME */}
      </StyledPaper>
    </Box>
  );
}

export default ArgeVeInovasyonContents;
