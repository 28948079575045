import { Box, Typography, Paper } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
  overflowX: "auto",
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

function HakkımızdaContents() {
  return (
    <>
      <Box sx={{ flexGrow: 1, padding: 1 }}>
        <Header variant="h4" color="#1f2e5e">
          Hakkımızda
        </Header>

        <StyledPaper elevation={3}>
          {/* <Box sx={{ padding: { xs: "0", lg: "0 4rem 1rem 0" } }}> */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h4"
              sx={{ margin: "1rem 0", color: "#1f2e5e" }}
            >
              Türk Sanayisi’nin Öncüsü
            </Typography>
          </Box>
          <Typography sx={{ textAlign: "justify" }}>
            Türkiye’nin ilk entegre demir çelik fabrikası olan Kardemir’in
            temelleri Cumhuriyetimizin kurucusu Mustafa Kemal Atatürk’ün ulusal
            sanayileşme hamlelerinden birisi olarak dönemin Başbakanı İsmet
            İnönü tarafından 3 Nisan 1937 tarihinde atıldı. İlk yüksek
            fırınımızı 9 Eylül 1939’da ateşledik ve bir gün sonra 10 Eylül
            1939’da İlk Türk Demirini ürettik.
          </Typography>
          <br />
          <Typography sx={{ textAlign: "justify" }}>
            Ülkemizin endüstriyel atılımlarına öncülük ederek çok sayıdaki büyük
            endüstriyel tesisin proje, imalat ve montajını gerçekleştirdik. Bu
            nedenle Türkiye’de "fabrikalar kuran fabrika" olarak tanındık, bu
            ünvanla anıldık.
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h4"
              sx={{ margin: "1rem 0", color: "#1f2e5e" }}
            >
              Büyük Yatırım Hamlesi
            </Typography>
          </Box>
          <Typography sx={{ textAlign: "justify" }}>
            Örnek bir sahiplenmeyle 30 Mart 1995’de gerçekleşen özelleştirme
            sonrasında 2 Milyar $’ın üzerindeki yatırım harcamasıyla üretim
            teknolojilerini yenileyen şirketimiz, bugün Türkiye’nin en büyük
            sanayi kuruluşlarından biri haline geldi.
          </Typography>
          <br />
          <Typography sx={{ textAlign: "justify" }}>
            Demiryolu rayı üretiminde Türkiye’nin tek milli markasıyız. BİST 30
            endeksinde yer alan şirketimizin hisselerinin tamamı halka açıktır.
          </Typography>
          <br />
          <Typography sx={{ textAlign: "justify" }}>
            “Önceliğimiz insan” anlayışı ile faaliyetlerimizi sürdürüyoruz. Tüm
            süreçlerimizin omurgasını İş Sağlığı ve Güvenliği oluşturur.
            Sektörün sadece öncüsü değil aynı zamanda okulu da olan şirketimizde
            bilgi birikim ve yetkinliklerin artırılması ve çalışanların sürekli
            gelişimi için her türlü olanak sunulur. Bu amaç için kurulmuş modern
            bir eğitim kültür merkezine sahibiz.
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h4"
              sx={{ margin: "1rem 0", color: "#1f2e5e" }}
            >
              Üniversite Sanayi İşbirliğinde Örnek Model
            </Typography>
          </Box>
          <Typography sx={{ textAlign: "justify" }}>
            Üniversitelerde yapılacak bilimsel çalışmaların katma değere
            dönüştürülerek şirketimizde ticarileştirilmesi, öğretim üyelerinin
            her zaman eksikliğini hissettiği uygulama sahasına kavuşturulması
            amacıyla kapılarımızı bilim insanlarına sonuna kadar açtık.
            Üniversite – Sanayi İşbirliğinde örnek gösterilen bir şirket olduk.
          </Typography>
          <br />
          <Typography sx={{ textAlign: "justify" }}>
            Paydaşlarımızın ihtiyaç ve beklentileri doğrultusunda her yıl
            bütçemizden önemli oranda kaynak ayırır ve faaliyet gösterdiğimiz
            coğrafyada yaşam kalitesinin yükseltilmesine katkıda bulunuruz.
            Karabük’te eğitim, sağlık, spor, kültürel ve sosyal hayatın
            hizmetine sunulmuş onlarca tesiste bizim imzamız vardır. Karabük
            Üniversitesine kazandırdığımız Demir Çelik Enstitüsü ve AR-GE
            Merkezi, sadece şirketimize ve yükseköğretim öğrencilerine değil,
            tüm demir çelik sektörüne hizmet eden önemli bir bilim yuvası
            olmuştur.
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h4"
              sx={{ margin: "1rem 0", color: "#1f2e5e" }}
            >
              Sürdürülebilirlik Anlayışı
            </Typography>
          </Box>
          <Typography sx={{ textAlign: "justify" }}>
            Sadece ekonomik değil, çevresel ve toplumsal sorumlulukları da
            içinde barındıran, etik kurallara uygun, toplam kalite felsefesiyle
            sürdürülebilir bir büyümeyi hedefliyoruz.
          </Typography>
          <br />
          <Typography sx={{ textAlign: "justify" }}>
            Bağlı kuruluşlarımızla birlikte yaklaşık 4.500 kişilik büyük bir
            aile olarak, sahip olduğumuz köklü sanayi kültürü, yetkin insan
            kaynağı ve kurumsal yönetim tecrübesi ile sektörünün lider
            kuruluşlarından biri olarak üretmeye, ülkemize ve insanlığa hizmet
            etmeye gururla devam ediyoruz.
          </Typography>
          {/* </Box> */}

          {/* <Box
        pb={3}
        sx={{
          margin: { xs: 0.5, lg: 5 },
          display: "flex",
          flexDirection: "column",
          backgroundImage: {
            xs: "linear-gradient(180deg, #d11f25 100%, #fff 0)",
            md: "linear-gradient(180deg, #d11f25 50%, #fff 0)",
          },
        }}
      >
        <Typography
          sx={{
            padding: { xs: "2rem 1rem", lg: "3rem 10rem" },
            fontSize: { xs: "2rem", lg: "3rem" },
            color: "#fff",
          }}
        >
          #Kardemir'i takip edin
        </Typography>

        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3} mx={"1rem"} mb={2}>
            <Card
              sx={{
                width: "100%",
                height: 500,
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#1f2e5e",
                  padding: "1rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    color: "#fff",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    onClick={handleRedirectInstagram}
                  >
                    <Instagram />
                    <Typography
                      sx={{
                        marginLeft: "0.5rem",
                        color: "#fff",
                        cursor: "pointer",
                      }}
                    >
                      @sosyalkardemir
                    </Typography>
                  </Box>
                  <Typography
                    onClick={handleRedirectInstagram}
                    sx={{
                      color: "#fff",
                      cursor: "pointer",
                    }}
                  >
                    Takip Et
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  padding: "0 1rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    height: "30px",
                    padding: "1rem 0",
                  }}
                  src={logo}
                  alt="img"
                />
              </Box>

              <Box
                sx={{
                  height: "50%",
                  padding: "0.2rem 2rem 4rem 2rem",
                  marginBottom: "2rem",
                  borderBottom: "1px solid lightgrey",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ marginBottom: "1rem" }}>
                  Yeni Yüksek Katma Değerli Ürünler!
                  <br />
                  Katma değeri yüksek mamül üretimi için yeni ürünler
                  geliştirerek üretim kabiliyetimizi ve çeşitliliğimizi
                  artırıyoruz.
                </Typography>
                <img
                  src={instagonderi}
                  style={{
                    height: "60%",
                    width: "80%",
                  }}
                  alt="img"
                />
              </Box>

              <Box sx={{ padding: "0.2rem 1rem" }}>
                <FavoriteBorderIcon
                  sx={{
                    "&:hover": {
                      color: "#d11f25",
                    },
                  }}
                />
                <ChatBubbleOutlineIcon
                  sx={{
                    "&:hover": {
                      color: "#d11f25",
                    },
                  }}
                />
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={3} xl={3} mx={"1rem"} mb={2}>
            <Card
              sx={{
                width: "100%",
                height: 500,
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#1f2e5e",
                  padding: "1rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    color: "#fff",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    onClick={handleRedirectX}
                  >
                    <X />
                    <Typography
                      sx={{
                        marginLeft: "0.5rem",
                        color: "#fff",
                        cursor: "pointer",
                      }}
                    >
                      @Sosyalkardemir
                    </Typography>
                  </Box>
                  <Typography
                    onClick={handleRedirectX}
                    sx={{
                      color: "#fff",
                      cursor: "pointer",
                    }}
                  >
                    Takip Et
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  padding: "0 1rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    height: "30px",
                    padding: "1rem 0",
                  }}
                  alt="img"
                  src={logo}
                />
              </Box>

              <Box
                sx={{
                  height: "50%",
                  padding: "0.2rem 2rem 4rem 2rem",
                  marginBottom: "2rem",
                  borderBottom: "1px solid lightgrey",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ marginBottom: "1rem" }}>
                  Demiryolu rayı ve kalın kangal üretiminde Türkiye’nin milli
                  markası olarak katma değeri yüksek ürünlerimizle rekabet
                  gücümüzü her geçen gün artırıyoruz.
                  <br />
                </Typography>
                <img
                  src={Xgonderi}
                  style={{
                    height: "60%",
                    width: "80%",
                  }}
                  alt="img"
                />
              </Box>

              <Box sx={{ padding: "0.2rem 1rem" }}>
                <FavoriteBorderIcon
                  sx={{
                    "&:hover": {
                      color: "#d11f25",
                    },
                  }}
                />
                <ChatBubbleOutlineIcon
                  sx={{
                    "&:hover": {
                      color: "#d11f25",
                    },
                  }}
                />
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={3} xl={3} mx={"1rem"} mb={2}>
            <Card
              sx={{
                width: "100%",
                height: 500,
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#1f2e5e",
                  padding: "1rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    color: "#fff",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    onClick={handleRedirectYoutube}
                  >
                    <YouTube />
                    <Typography
                      sx={{
                        marginLeft: "0.5rem",
                        color: "#fff",
                        cursor: "pointer",
                      }}
                    >
                      @SosyalKardemir
                    </Typography>
                  </Box>
                  <Typography
                    onClick={handleRedirectYoutube}
                    sx={{
                      color: "#fff",
                      cursor: "pointer",
                    }}
                  >
                    Takip Et
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  padding: "0 1rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    height: "30px",
                    padding: "1rem 0",
                  }}
                  src={logo}
                  alt="img"
                />
              </Box>

              <Box
                sx={{
                  height: "50%",
                  padding: "0.2rem 2rem 4rem 2rem",
                  marginBottom: "2rem",
                  borderBottom: "1px solid lightgrey",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ marginBottom: "1rem" }}>
                  Geleneksel bayramlaşma törenimizi haddehane meydanında
                  gerçekleştirdik. Değerli çalışanlarımız ve yöneticilerimiz,
                  bayram ruhunu paylaştı.
                </Typography>
                <img
                  src={Youtubegonderi}
                  style={{
                    height: "60%",
                    width: "80%",
                  }}
                  alt="img"
                />
              </Box>

              <Box sx={{ padding: "0.2rem 1rem" }}>
                <FavoriteBorderIcon
                  sx={{
                    "&:hover": {
                      color: "#d11f25",
                    },
                  }}
                />
                <ChatBubbleOutlineIcon
                  sx={{
                    "&:hover": {
                      color: "#d11f25",
                    },
                  }}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box> */}
        </StyledPaper>
      </Box>
    </>
  );
}

export default HakkımızdaContents;
