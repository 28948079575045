import * as React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Iliskiler from "./components/Pages/Iliskiler/Iliskiler";
import Hakkımızda from "./components/Kurumsal/Hakkımızda";
import NedenKardemir from "./components/Kariyer/NedenKardemir";
import ScrollToTopButton from "./components/ScrollToTopButton";
import KilometreTaslari from "./components/Kurumsal/KilometreTaslari";
import KilometreTaslari1 from "./components/Kurumsal/KilometreTaslari1";
import Fiyatlar from "./components/Fiyatlar/Fiyatlar";

import KilometreTaslari2 from "./components/Kurumsal/KilometreTaslari2";
import KilometreTaslari3 from "./components/Kurumsal/KilometreTaslari3";
import YonetimKurulu from "./components/Kurumsal/YonetimKurulu";
import UstYonetim from "./components/Kurumsal/UstYonetim";
import VizyonMisyon from "./components/Kurumsal/VizyonMisyon";
import IsBasvuruFormu from "./components/Kariyer/IsBasvuruFormu";
import PersonelHaklari from "./components/Kariyer/PersonelHaklari";
import KurumsalKimlik from "./components/Kariyer/KurumsalKimlik";
import OrtaklikYapisi from "./components/Kurumsal/OrtaklikYapisi";
import Haberler from "./components/Medya/Haberler";
import HaberIcerik from "./components/Medya/HaberIcerik";
import Galeri from "./components/Medya/Galeri";
import Medya from "./components/Medya/Medya";
import { useEffect } from "react";
import SurdurulebilirlikYonetimi from "./components/Sürdürülebilirlik/SurdurulebilirlikYonetimi";
import SikcaSorulanSorular from "./components/Kariyer/SikcaSorulanSorular";
import Urunler from "./components/Urunler/Urunler";
import Sertifikalar from "./components/Urunler/Sertifikalar";
import SatisIslemleri from "./components/Urunler/SatisIslemleri";
import UrunKatalogumuz from "./components/Urunler/UrunKatalogumuz";
import ArgeVeInovasyon from "./components/Sürdürülebilirlik/ArgeVeInovasyon";
import EnerjiYonetimi from "./components/Sürdürülebilirlik/EnerjiYonetimi";
import CevreVeIklim from "./components/Sürdürülebilirlik/CevreVeIklim";
import IsDevamliligiVeYatirimlar from "./components/Sürdürülebilirlik/IsDevamliligiVeYatirimlar";
import RaporveSertifika from "./components/Sürdürülebilirlik/RaporveSertifika";
import BagliOrtakliklarVeIstirakler from "./components/AltSatir/BagliOrtakliklarVeIstirakler";
import YasalUyarilar from "./components/AltSatir/YasalUyarilar";
import Kvkk from "./components/AltSatir/Kvkk";
import Iletisim from "./components/AltSatir/Iletisim";
import CerezPolitikasi from "./components/AltSatir/CerezPolitikasi";
import Komiteler from "./components/Pages/Iliskiler/yatirimciPages/Komiteler";
import OrtaklikYapisiYatirim from "./components/Pages/Iliskiler/yatirimciPages/OrtaklikYapisiYatirim";
import OzetMali from "./components/Pages/Iliskiler/yatirimciPages/OzetMali";
import KarsilastirmaliGrafik from "./components/Pages/Iliskiler/yatirimciPages/KarsilastirmaliGrafik";
import Analist from "./components/Pages/Iliskiler/yatirimciPages/Analist";
import Sunumlar from "./components/Pages/Iliskiler/yatirimciPages/Sunumlar";
import Kredi from "./components/Pages/Iliskiler/yatirimciPages/Kredi";
import TedarikciIslemleri from "./components/Pages/Iliskiler/tedarikciPages/TedarikciIslemleri";
import HurdaAlim from "./components/Pages/Iliskiler/tedarikciPages/HurdaAlim";
import BulutBox from "./components/BulutBox";
import Info from "./components/Info";
import HurdaAlimFiyat from "./components/Pages/Iliskiler/tedarikciPages/HurdaAlimFiyat";
import Politikalar from "./components/Pages/Iliskiler/yatirimciPages/Politikalar";
import GenelKurul from "./components/Pages/Iliskiler/yatirimciPages/GenelKurul";
import AnaSozlesme from "./components/Pages/Iliskiler/yatirimciPages/AnaSozlesme";
import HisseDetay from "./components/Pages/Iliskiler/yatirimciPages/HisseDetay";
import AltSatir from "./components/AltSatir/AltSatir";
import SiteHaritasi from "./components/AltSatir/SiteHaritasi";
import HomeNew from "./components/Pages/HomeNew";
import TcddHome from "./components/Pages/TcddHome";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Cookies from "./components/Pages/Cookies";
import HeaderTcdd from "./components/Header/HeaderTcdd";
import HeaderTcdd2 from "./components/Header/HeaderTcdd2";
import Header2 from "./components/Header/Header2";

const theme = createTheme({
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});

function App() {
  const [infoVisible, setInfoVisible] = React.useState(true);

  const handleInfoVisibility = (visible) => {
    setInfoVisible(visible);
  };

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Info onShow={handleInfoVisibility} />
        {/* <Header /> */}
        {/* <HeaderTcdd infoVisible={infoVisible} /> */}
        <HeaderTcdd2 infoVisible={infoVisible} />
        {/* <Header2 infoVisible={infoVisible} /> */}

        <Routes>
          {/* <Route index element={<Home />} /> */}
          {/* <Route index element={<HomeNew />} /> */}
          {/* <Route index element={<TcddHome />} /> */}
          <Route index element={<TcddHome />} />

          {/* KURUMSAL */}
          <Route path="hakkımızda" element={<Hakkımızda />} />
          <Route path="kilometre-taslari" element={<KilometreTaslari />} />
          <Route path="kilometre-taslari-1" element={<KilometreTaslari1 />} />
          <Route path="kilometre-taslari-2" element={<KilometreTaslari2 />} />
          <Route path="kilometre-taslari-3" element={<KilometreTaslari3 />} />
          <Route path="yonetim-kurulu" element={<YonetimKurulu />} />
          <Route path="ust-yonetim" element={<UstYonetim />} />
          <Route path="vizyon-misyon" element={<VizyonMisyon />} />
          <Route path="ortaklik-yapisi" element={<OrtaklikYapisi />} />

          {/* FİYATLAR */}
          <Route path="fiyatlar" element={<Fiyatlar />} />

          {/* İLİŞKİLER */}
          <Route path="iliskiler" element={<Iliskiler />} />
          <Route
            path="ortaklik-yapisi-yatirim"
            element={<OrtaklikYapisiYatirim />}
          />
          <Route path="komiteler" element={<Komiteler />} />
          <Route path="politikalar-tahvil-ihrac" element={<Politikalar />} />
          <Route path="genel-kurul" element={<GenelKurul />} />
          <Route path="ana-sozlesme" element={<AnaSozlesme />} />
          <Route path="ozet-mali-tablolar" element={<OzetMali />} />
          <Route
            path="karsilastirmali-grafik"
            element={<KarsilastirmaliGrafik />}
          />
          <Route path="analist-bilgileri" element={<Analist />} />
          <Route path="sunumlar-raporlar-tablolar" element={<Sunumlar />} />
          <Route path="kredi-derecelendirme" element={<Kredi />} />
          <Route path="tedarikci-islemleri" element={<TedarikciIslemleri />} />
          <Route path="hurda-alim-sartnamesi" element={<HurdaAlim />} />
          <Route path="hurda-alim-fiyatlari" element={<HurdaAlimFiyat />} />
          <Route path="hisse-detay-grafik" element={<HisseDetay />} />

          {/* ÜRÜNLER VE HİZMETLER */}
          <Route path="urunler" element={<Urunler />} />
          <Route path="urun-katalogumuz" element={<UrunKatalogumuz />} />
          <Route path="sertifikalarimiz" element={<Sertifikalar />} />
          <Route path="satis-islemleri" element={<SatisIslemleri />} />

          {/* SÜRDÜRÜLEBİLİRLİK */}
          <Route
            path="surdurulebilirlik-yonetimi"
            element={<SurdurulebilirlikYonetimi />}
          />
          <Route
            path="rapor-ve-sertifikalarimiz"
            element={<RaporveSertifika />}
          />
          <Route path="arge-ve-inovasyon" element={<ArgeVeInovasyon />} />
          <Route path="enerji-yonetimi" element={<EnerjiYonetimi />} />
          <Route path="cevre-ve-iklim" element={<CevreVeIklim />} />
          <Route
            path="is-devamliliği-ve-yatirimlar"
            element={<IsDevamliligiVeYatirimlar />}
          />

          {/* KARİYER */}
          <Route path="kariyer" element={<NedenKardemir />} />
          <Route path="is-basvuru-formu" element={<IsBasvuruFormu />} />
          <Route path="personel-haklari" element={<PersonelHaklari />} />
          <Route path="kurumsal-kimlik" element={<KurumsalKimlik />} />
          <Route
            path="sikca-sorulan-sorular"
            element={<SikcaSorulanSorular />}
          />

          {/* MEDYA */}
          <Route path="/medya" element={<Medya />} />
          <Route path="/haberler" element={<Haberler />} />
          <Route path="/haberler/:id" element={<HaberIcerik />} />
          <Route path="/galeri" element={<Galeri />} />

          {/* ALT SATIR */}
          <Route
            path="/bagli-ortakliklar-ve-istirakler"
            element={<BagliOrtakliklarVeIstirakler />}
          />
          <Route path="/yasal-uyarilar" element={<YasalUyarilar />} />
          <Route path="/kvkk" element={<Kvkk />} />

          <Route path="/iletisim" element={<Iletisim />} />
          <Route path="/cerez-politikasi" element={<CerezPolitikasi />} />
          <Route path="/site-haritasi" element={<SiteHaritasi />} />
        </Routes>
        {/* <Dark /> */}
        {/* <BulutBox /> */}
        {/* <ScrollToTopButton /> */}
        {/* <AltSatir /> */}
        <Cookies />
        {/* <Footer /> */}
        <AltSatir />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
