import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
  overflowX: "auto",
  maxWidth: 800, // Genişliği sınırlamak için maxWidth ayarlandı
  margin: "0 auto", // Merkezde hizalama sağlamak için margin kullanıldı
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));
function HurdaAlimContents() {
  const sections = [
    {
      title: "SINIFLANDIRMA",
      details: (
        <>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            <Box sx={{ padding: "1rem" }}>
              <Typography>
                KARDEMİR 'in istediği hurda cinsleri aşağıdaki şekilde
                sınıflandırılmıştır:
              </Typography>
              <ListItem disableGutters>
                <ListItemText primary={"* Özel Ekstra Hurda"} />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  sx={{ ml: "2rem" }}
                  primary={
                    "Et kalınlıklarına bakılmaksızın ; Bütün hadde bozuğu ,kütük başı, uç - baş; temiz hadde hurdası Bu malzemelerin tamamında pas, oksit istenmemektedir. Aksi halde et kalınlığına göre sınıflandırılır."
                  }
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText primary={"* DKP Hurda"} />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  sx={{ ml: "2rem" }}
                  primary={
                    "Et kalınlığı 6 mm ve yukarısı olan oksitsiz, boyasız CNC ve Plazma kesikleri sonucu ortaya çıkan hurdalar ve et kalınlığına bakılmaksızın dövme çelik olarak adlandırılan halka şeklindeki malzemeleri içerir. Bu malzemelerde atmosferik pas da dahil olmak üzere herhangi bir pasa veya boyaya rastlanır ise bir alt sınıf olan EKSTRA olarak değerlendirilir."
                  }
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText primary={"* Ekstra Hurda"} />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  sx={{ ml: "2rem" }}
                  primary={"Vagon tekerleği ve mili"}
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  sx={{ ml: "2rem" }}
                  primary={"Ray, Travers, Demiryolu bağlantı elemanları"}
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  sx={{ ml: "2rem" }}
                  primary={
                    "Et kalınlığı 6 mm ve yukarısı olan profil, borular ve tüm hurdalar"
                  }
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  sx={{ ml: "2rem" }}
                  primary={
                    "Et kalınlığı 20 mm dahil yukarısı ve boyu 1 metre altına kesilmiş inşaat çubuğu"
                  }
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  sx={{ ml: "2rem" }}
                  primary={
                    "Söküm veya demontaj işlemleri sonucu ortaya çıkan et kalınlığı en az 6 mm olan bütün hurdalar"
                  }
                />
              </ListItem>
              <Typography>
                Yukarıda bahsedilen hurdalar aşırı yağlı, paslı çürümüş olmadığı
                takdirde değerlendirmeye tabi olurlar. Aksi takdirde aşırı yağın
                ve pasın temas ettiği malzeme miktarı kadar istenmeyen
                malzeme/şartname dışı olarak değerlendirilir.
              </Typography>
              <ListItem disableGutters>
                <ListItemText primary={"* 1. Sınıf Hurda"} />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  sx={{ ml: "2rem" }}
                  primary={"2 - 6 mm arası DKP Hurdaları"}
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  sx={{ ml: "2rem" }}
                  primary={
                    "Et kalınlığı 3-6 mm olan hurdalar (profiller, köşebentler, borular ,ve tüm hurdalar)"
                  }
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  sx={{ ml: "2rem" }}
                  primary={"Açık ve temizlenmiş motor gövdeleri"}
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  sx={{ ml: "2rem" }}
                  primary={
                    "Et kalınlığı 10-20 mm ve boyu 1 metre altına kesilmiş inşaat çubuğu"
                  }
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText primary={"* 2. Sınıf Hurda"} />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  sx={{ ml: "2rem" }}
                  primary={"1 - 2 mm arası DKP Hurdaları"}
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  sx={{ ml: "2rem" }}
                  primary={"Et kalınlıkları 1-3 mm arası olan hurdalar"}
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  sx={{ ml: "2rem" }}
                  primary={
                    "En az iki parçaya bölünmüş kalorifer petekleri ve otomobil LPG tüpleri"
                  }
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  sx={{ ml: "2rem" }}
                  primary={
                    "Et kalınlığı 10 mm altında ve boyu 1 metre altına kesilmiş inşaat çubuğu"
                  }
                />
              </ListItem>
            </Box>
          </List>
        </>
      ),
    },
    {
      title: "DİĞER ÖZELLİKLER",
      details: (
        <>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            <Box sx={{ padding: "1rem" }}>
              <ListItem disableGutters>
                <ListItemText
                  primary={
                    "* Boşaltma KARDEMİR tarafından yapılacaktır. Boşaltmayla ilgili satıcıdan herhangi bir ücret alınmayacaktır. Boşaltma; Hafta içi; Cumartesi, Resmi tatiller, Bayram günleri dahil her gün 08.00-17.00 saatleri arasında olup, KARDEMİR A.Ş. ana kapıdan son giriş saati 17.00 olacaktır. Pazar günleri hurda kabulü yapılmayacaktır. Hurda kabul gün ve saatlerinde herhangi bir değişiklik yapıldığı takdirde firmalara bildirilecektir."
                  }
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  primary={
                    "* Her vasıtanın dolu ve boş tartıları yapılacaktır. Tartısı yapılmadan boşaltılan hurdanın bedeli ödenmeyecektir. Tartımda KARDEMİR kantarı geçerlidir."
                  }
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  primary={
                    "* Hurdaların içerisinde aşağıda belirtilen şartname dışı malzemeler bulunduğu takdirde, yüzde miktarı net tonajdan düşülecektir. Örneğin;10 ton hurda içerisinde % 5 skal ,% 5 pres bulunduğu takdirde net tonaj 10 - 1 = 9 ton olarak kabul edilecek ve yapılan eksper değerlendirmesi 9 ton için geçerli olacaktır."
                  }
                />
              </ListItem>
            </Box>
          </List>
        </>
      ),
    },
    {
      title: "EBAT DIŞI",
      details: (
        <>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            <Box sx={{ padding: "1rem" }}>
              <ListItem disableGutters>
                <ListItemText
                  primary={
                    "* Bütün gruplar için boyutlar 50X150 cm sınırı içerisindedir. Eni veya boyu bunların dışına çıkan malzemeler kaliteleri değiştirilmeksizin tespit edilen miktar kadar kesilecek malzeme olarak değerlendirilir. Ayrıca çember ve boru şeklindeki malzemeler kesilerek açılmış şekilleri düşünülerek değerlendirmeye alınır."
                  }
                />
              </ListItem>
            </Box>
          </List>
        </>
      ),
    },
    {
      title: "ŞARTNAME DIŞI",
      details: (
        <>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            <Box sx={{ padding: "1rem" }}>
              <ListItem disableGutters>
                <ListItemText
                  primary={"* Teneke, varil ve /veya bunların preslenmiş hali"}
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText primary={"* Preslenmiş bütün hurdalar"} />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  primary={
                    "* Tufal, skal, talaş ,lastik ,bez ,tahta, demir dışı metaller ve bunların alaşımı"
                  }
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  primary={
                    "* İçerisinde toz, toprak, kum, su, mıcır, beton, refrakter v.b maddeler bulunan hurdalar"
                  }
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  primary={
                    "* Patlayıcı, parlayıcı maddeler, araç veya iş makinesi piston, amartisör ve benzeri basınçlı ve yağlı malzemeler ( Bu malzemelerden doğabilecek her türlü zarar ve ziyandan satıcı sorumludur.)"
                  }
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText primary={"* Aşırı paslı hurdalar"} />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  primary={
                    "* Kapalı tüpler, banyo kazanları, kalorifer petekleri v.b kapalı hacimler"
                  }
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText primary={"* Motorlu araç kaportası"} />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  primary={
                    "* Değerlendirme sadece kamyon/tır başına olmak üzere hurdalar içerisinde kesilmemiş sağlam, dolu veya boş mutfak tüpü, piknik tüpü, LPG tüpü, yangın tüpü ve basınçlı kaplar tespit edilen adete göre en az malzeme miktarının %1 şartname dışı olarak değerlendirme yapılır."
                  }
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  primary={
                    "* Kangal şeklinde, yumak şeklinde ve boyları 100 cm’den uzun olan tüm inşaat çubukları"
                  }
                />
              </ListItem>
              <Typography>
                <b>Not:</b> Hurda gözetim ve değerlendirmesi müteahhit firma
                eksperi tarafından yapılacaktır.
              </Typography>
            </Box>
          </List>
        </>
      ),
    },
    {
      title: "MALZEMENİN FONKSİYONEL VE KULLANIMI İLE İLGİLİ BİLGİLER",
      details: (
        <>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            <Box sx={{ padding: "1rem" }}>
              <ListItem disableGutters>
                <ListItemText
                  primary={
                    "* Hurdalar, konverterde çelik üretiminde kullanılacaktır."
                  }
                />
              </ListItem>
            </Box>
          </List>
        </>
      ),
    },
    {
      title: "TESLİM YERİ",
      details: (
        <>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            <Box sx={{ padding: "1rem" }}>
              <ListItem disableGutters>
                <ListItemText
                  primary={
                    "* KARDEMİR A.Ş. Çelik Üretim Müdürlüğü hurda stok sahaları"
                  }
                />
              </ListItem>
            </Box>
          </List>
        </>
      ),
    },
    {
      title: "TESLİM ŞEKLİ",
      details: (
        <>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            <Box sx={{ padding: "1rem" }}>
              <ListItem disableGutters>
                <ListItemText
                  primary={
                    "* Malzemeler damperli / dampersiz kamyonlarla veya vagonlarla teslim edilebilir."
                  }
                />
              </ListItem>
            </Box>
          </List>
        </>
      ),
    },
    {
      title: "MUAYENE VE KABULDE İSTENİLENLER",
      details: (
        <>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            <Box sx={{ padding: "1rem" }}>
              <ListItem disableGutters>
                <ListItemText
                  primary={
                    "* Muayene ; kamyon boşaldıktan sonra ,KARDEMİR eksperi ve/veya müteahhit eksperi tarafından yapılacaktır. KARDEMİR eksperinin ve/veya müteahhit eksperinin vereceği rapora itirazda bulunulmayacaktır."
                  }
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  primary={
                    "* Kardemir A.Ş.'ye hurda getiren kamyonlar bu teknik şartnamedeki hususları kabul etmiş sayılacaklardır."
                  }
                />
              </ListItem>
            </Box>
          </List>
        </>
      ),
    },
    {
      title: "ÖDEME",
      details: (
        <>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            <Box sx={{ padding: "1rem" }}>
              <Typography>
                <b>07.03.2023</b> tarihinden itibaren;
              </Typography>
              <ListItem disableGutters>
                <ListItemText
                  primary={
                    "* 8.000.000 TL' ye kadar olması durumunda ödeme 2 gün içerisinde,"
                  }
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  primary={
                    "* 8.000.000 TL' nin üzerinde olması durumunda ödeme 7 gün sonra "
                  }
                />
              </ListItem>
              <Typography>
                <b>havale masrafı satıcıya ait olmak üzere</b> istenilen banka
                adresine havale edilerek ödenecektir.
              </Typography>
            </Box>
          </List>
        </>
      ),
    },
  ];

  return (
    <Box sx={{ flexGrow: 1, padding: 1 }}>
      <Header variant="h4" color="#1f2e5e">
        Hurda Alım Şartnamesi
      </Header>

      <StyledPaper elevation={3}>
        <Box sx={{ padding: "3rem" }}>
          <Typography
            sx={{
              borderBottom: "1px solid #19467d",

              color: "#1f2e5e",
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            ALAŞIMSIZ ÇELİK HURDA (YURT İÇİ) TEKNİK ŞARTNAMESİ
          </Typography>

          <Box sx={{ margin: { xs: "0", md: "2rem " } }}>
            {sections.map((section, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index + 1}-content`}
                  id={`panel${index + 1}-header`}
                >
                  <Paper
                    elevation={2}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      p: 2,
                      width: "100%",
                      mb: 1,
                      backgroundColor: "#1f2e5e",
                    }}
                  >
                    <Typography sx={{ fontWeight: 600, color: "#fff" }}>
                      {section.title}
                    </Typography>
                  </Paper>
                </AccordionSummary>
                <AccordionDetails>
                  <Paper
                    elevation={2}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      p: 2,
                      mb: 1,
                      backgroundColor: "#fff",
                    }}
                  >
                    <Typography>{section.details}</Typography>
                  </Paper>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Box>
      </StyledPaper>
    </Box>
  );
}

export default HurdaAlimContents;
