import React from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import { Typography, Paper, Box } from "@mui/material";
import bg from "../../images/timeline.jpg";
import foto1 from "../../images/kmtasi/kurulus.jpg";
import foto2 from "../../images/kmtasi/makine.jpeg";
import foto3 from "../../images/kmtasi/yfirin.jpg";
import foto4 from "../../images/kmtasi/duoh.jpg";
import foto5 from "../../images/kmtasi/kontinuh.jpg";
import foto6 from "../../images/kmtasi/grev.jpg";
import foto7 from "../../images/kmtasi/hisse.webp";
import foto8 from "../../images/kmtasi/blum.jpg";
import foto9 from "../../images/kmtasi/rekor.jfif";
import foto10 from "../../images/kmtasi/ayristirma.jfif";
import foto11 from "../../images/kmtasi/teker.jpg";
import foto12 from "../../images/kmtasi/svc.jpg";
import { styled } from "@mui/material/styles";

const timelineData = [
  {
    year: "1937",
    events: ["Kuruluş", "Temellerin Atılması"],
    image: foto1,
  },
  { year: "1938", events: ["Makine Montajları"], image: foto2 },
  {
    year: "1939",
    events: [
      "Kuvvet Santrali",
      "Kok Fabrikası",
      "1 No.lu Yüksek Fırın",
      "İlk Türk Demiri",
    ],
  },
  { year: "1940", events: ["Çelikhane", "Trio Haddehane"] },
  { year: "1950", events: ["2 No.lu Yüksek Fırın"], image: foto3 },
  { year: "1953", events: ["Sinter"] },
  { year: "1955", events: ["Sümerbank’tan Ayrılması"] },
  { year: "1960", events: ["Düo Haddehane"], image: foto4 },
  { year: "1962", events: ["3 No.lu Yüksek Fırın"] },
  { year: "1964", events: ["Düo Profil Haddehanesi"] },
  { year: "1965", events: ["Kontinü Haddehane"], image: foto5 },
  { year: "1966", events: ["Çelik Konstrüksiyon"] },
  { year: "1967", events: ["Dökümhaneler"] },
  { year: "1976", events: ["Statü Değişikliği"] },
  { year: "1989", events: ["Grev"], image: foto6 },
  {
    year: "1994",
    events: [
      "Kapatılma Kararının Alınması",
      "Özelleştirilme Kararının Alınması",
    ],
  },
  {
    year: "1995",
    events: ["Özelleştirilmesi", "Hisselerinin Satışa Çıkarılması"],
    image: foto7,
  },
  {
    year: "1998",
    events: ["Pota Fırını", "Kütük Makinesi", "Borsaya Açılması"],
  },
  {
    year: "1999",
    events: [
      "Kireç Fabrikası ve Hava Ayrıştırma Tesisleri",
      "Çelikhane Konverter",
    ],
  },
  { year: "2000", events: ["Blum Makinesi"], image: foto8 },
  {
    year: "2007",
    events: ["Ray ve Profil Haddehanesi", "72 metre Boyunda İlk Ray Sevkiyatı"],
  },
  {
    year: "2008",
    events: ["Yeni Tesislerinin İşletmeye Alınması", "4 No.lu Yüksek Fırın"],
  },
  {
    year: "2011",
    events: [
      "Yeni Sinter Tesisi",
      "Kapasite Arttırımları",
      "3. Hava Ayrıştırma Tesisi",
    ],
  },
  {
    year: "2012",
    events: ["Hava Kalitesi İzleme İstasyonu", "Yeni Kireç Fabrikası"],
  },
  {
    year: "2013",
    events: [
      "3. Sürekli Döküm Makinesi",
      "Yeni Enerji Santrali",
      "Demiryolu Tekeri Üretim Tesisi",
      "Yeni Kok Fırınları",
    ],
  },
  {
    year: "2014",
    events: [
      "Eğitim ve Kültür Merkezi",
      "Merkezi Arıtma Tesisi",
      "3. Konverter",
      "Hidroelektrik Santrali",
      "Sinter Makinesi",
    ],
  },
  { year: "2015", events: ["5. Yüksek Fırın", "Üretim Rekoru"], image: foto9 },
  {
    year: "2016",
    events: ["İlk Kangal Üretimi", "Torpidoda Kükürt Giderme Tesisi"],
  },
  { year: "2017", events: ["TSI Sertifikası", "Kalite Ödülü"] },
  { year: "2018", events: ["IV. Hava Ayrıştırma Tesisi"], image: foto10 },
  {
    year: "2019",
    events: [
      "Kireç Fabrikası",
      "Yeni Sürekli Döküm Makinesi(SDM4)",
      "Sinter Tesisleri Meros Sistemi",
    ],
  },
  { year: "2020", events: ["Dijital Dönüşüm"] },
  { year: "2021", events: ["Demiryolu Tekeri Üretim Tesisi"], image: foto11 },
  { year: "2022", events: ["Konverter-3"] },
  {
    year: "2023",
    events: ["4 Nolu Pota Fırını ve SVC Sistemi"],
    image: foto12,
  },
];
const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

const KilometreTaslari3Context = () => {
  return (
    <Box>
      <Header variant="h4" color="#1f2e5e">
        Kilometre Taşlarımız
      </Header>

      <Box
        sx={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "20px",
        }}
      >
        <Timeline position="alternate">
          {timelineData.map((data, index) => (
            <TimelineItem key={index}>
              <TimelineSeparator>
                <TimelineDot />
                {index < timelineData.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <Paper
                  elevation={3}
                  style={{ padding: "10px", marginBottom: "20px" }}
                >
                  <Typography variant="h6" fontWeight="bold" color="#1f2e5e">
                    {data.year}
                  </Typography>
                  {data.events.map((event, i) => (
                    <Typography variant="body2" key={i}>
                      {event}
                    </Typography>
                  ))}
                  {/* Fotoğraf ekleme */}
                  {data.image && (
                    <img
                      src={data.image}
                      alt={data.year}
                      style={{ width: "100%", marginTop: "10px" }}
                    />
                  )}
                </Paper>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Box>
    </Box>
  );
};

export default KilometreTaslari3Context;
