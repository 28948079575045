import React, { useState } from "react";
import SolYapiKurumsal from "./SolYapiKurumsal";

function VizyonMisyon() {
  const [page, setPage] = useState("Vizyon ve Misyon");

  return (
    <>
      <SolYapiKurumsal setPage={setPage} page={page} />
    </>
  );
}

export default VizyonMisyon;
