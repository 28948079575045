import React from "react";
import {
  Box,
  Typography,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  tableCellClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import foto1 from "../../images/sustainability/yatirim.png";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
  overflowX: "auto",
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

const Table = styled("table")(({ theme }) => ({
  width: "100%",
  borderCollapse: "collapse",
  "& th, & td": {
    padding: theme.spacing(2),
    textAlign: "left",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  "& th": {
    // backgroundColor: "#d11f25",
    color: "#ffffff",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1f2e5e",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // backgroundColor: "#d11f25",
  color: "#1f2e5e",
  "& td, & th": {
    color: "#1f2e5e",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function IsDevamliligiVeYatirimlarContents() {
  function createData(name, paragraph) {
    return { name, paragraph };
  }

  const rows = [
    createData("Vakum Tesisi"),
    createData("Yeni SDM 2 Makinesi"),
    createData(
      "Çubuk Kangal Haddehanesi Sizing Block, Waterbox, Çap Ölçüm Ekipmanı"
    ),
    createData("Ar-Ge Projeleri"),
    createData(
      "Mevcut Sahanın Rehabilitasyonu ve Düzenli Depolama Alanı Yapılması"
    ),
    createData("Kok Bataryaları Emisyon Düşürülmesi"),
    createData("Yeni Biyolojik Arıtma Tesisi"),
    createData("Kok Gazı Yeni Gaz Holder Yapımı"),
    createData("Yüksek Fırınlar Toz Toplama Sistemleri İyileştirmesi"),
    createData("Hammadde Maniplasyon Tozsuzlaştırma Sistemleri"),
  ];

  return (
    <Box sx={{ flexGrow: 1, padding: 1 }}>
      <Header variant="h4" color="#1f2e5e">
        İş Devamlılığı ve Yatırımlar
      </Header>

      <StyledPaper elevation={3}>
        <Typography
          variant="body1"
          sx={{ marginBottom: 2, textAlign: "justify" }}
        >
          Sürdürülebilirliğin genel tanımı olan bugünün ihtiyaçları eksiksiz,
          zamanında ve beklenen nitelikte ürünlerle karşılanırken gelecekte de
          bu ihtiyaçların karşılanabilmesini güvence altına almak Kardemir’in iş
          devamlılığı ve yatırım politikasının da temelini oluşturur. <br />
          <br />
          Türk sanayinin ilk kurulduğu yıllardan bugüne, tedarikten başlayarak
          müşterilerimize ihtiyaç duydukları ürünlerin sunulmasına kadar süren
          değer zincirimizin kesintisiz biçimde devamı en temel
          önceliklerimizden biridir. Bazı ürün gruplarının Türkiye’deki tek
          üreticisi olmamız bu önemi bir kat daha artırmaktadır.
          <br />
          <br />
          Tüm bu hassasiyetten ötürü iş stratejimizin ana unsurlarından biri
          olarak kabul ettiğimiz iş devamlılığını şirket genelinde üst
          yönetimden iş birimlerine uzanan bir sorumluluk dağılımıyla yönetiriz.
          Risk yönetimi süreçlerimizde de önemli bir yeri bulunan iş
          devamlılığının üst düzey yönetimi Riskin Erken Saptanması Komitesi ve
          Yönetim Sistemleri ve Stratejik Planlama Başmühendisliği tarafından
          gerçekleştirilmektedir. Tedarik devamlılığının sağlanması açısından
          satın alma süreçleri, üretim devamlılığı bakımından üretim planlama ve
          ünite yönetimleri, tüm otomasyon ve yazılım sistem altyapısının
          güvenilirliği bakımından bilişim yönetimi süreçleri ön plana
          çıkmaktadır. Tüm bu süreçlerden sorumlu iş birimleri oluşturdukları
          şirket politikalarında iş devamlılığı tedbir ve uygulamalarını
          tanımlayarak faaliyetlerinde hayata geçirmektedir. Bu uygulamaları
          gerçekleştirmek için oluşturduğumuz standartların yanında hizmet
          verdiğimiz otomotiv endüstrisinin uluslararası ölçekte kabul görmüş
          standardı olan IATF16949 ve ISO 27001 Bilgi Güvenliği Yönetim
          Standardını da takip ederiz. Tüm sorumlu iş birimleri
          gerçekleştirdikleri süreçleri periyodik olarak iş devamlılığı
          risklerine karşı test ederler. Elde edilen test sonuçlarını da
          periyodik olarak şirket üst yönetimine raporlarız.
          <br />
          <br />
          İleri planlama çalışmaları iş devamlılığımızın sağlanmasında hayati
          bir rol oynar. Bu çalışmaların temelinde de üretim planlama
          uygulamaları yer alır. Bu kapsamda Kardemir'in önümüzdeki 5 yıl içinde
          gerçekleştireceği üretim çalışmalarına yönelik projeksiyon çalışmaları
          dikkatle yapılır. Bu projeksiyon neticesinde elde edilen tablolar tüm
          iş birimleri için kısa ve uzun vadeli ölçülebilir, kontrol edilebilir
          gelişim ve yatırım hedeflerine dönüşür.
          <br />
          <br />
          Kurumsal sürdürülebilirliğin bir ayağı bugünün ihtiyaçlarını
          karşılamaksa diğer ve en önemli ayağı gelecek ihtiyaçlarının
          karşılanmasını güvence altına almaktır. Fabrikalar kuran fabrika
          unvanı bize bu sorumluluğu ciddiyetle ele almamız gerektiğini
          hatırlatır.
        </Typography>
        <img
          src={foto1}
          alt="isdevamlilgi"
          style={{
            width: "50%",
            height: "70%",
            display: "flex",

            margin: "0 auto",
          }}
        />

        <Typography
          variant="body1"
          sx={{ marginBottom: 2, textAlign: "justify" }}
        >
          Tedarik sürecinden nihai üretime kadar yapılan tüm planlamalar yatırım
          ihtiyaçlarını da içerisinde barındırır. Ürettikçe büyüyen, büyüdükçe
          katma değerli ürünler üretmeye ağırlık veren bir stratejiyle; yeni
          ürün üretimi, çevre, bilgi güvenliği, dijital dönüşüm projeleri,
          enerji ve modernizasyon yatırımlarıyla iş devamlılığına yüksek katkı
          sağlanır.
        </Typography>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Üyeler</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell component="th" scope="row">
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.paragraph}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* <Divider sx={{ marginY: 3 }} /> ÇİZGİ ÇEKME */}
      </StyledPaper>
    </Box>
  );
}

export default IsDevamliligiVeYatirimlarContents;
