import React from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: "#ffffff",
  boxShadow: theme.shadows[3],
  overflowX: "auto",
  maxWidth: 800, // Genişliği sınırlamak için maxWidth ayarlandı
  margin: "0 auto", // Merkezde hizalama sağlamak için margin kullanıldı
}));

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  textAlign: "center",
  marginBottom: theme.spacing(3),
}));

const ContentLink = styled("a")(({ theme }) => ({
  display: "block",
  marginBottom: theme.spacing(2),
  textDecoration: "none",
}));

const BoldText = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  marginBottom: theme.spacing(1),
}));

function AnaSozlesmeContents() {
  return (
    <Box sx={{ flexGrow: 1, padding: 1 }}>
      <Header variant="h4" color="#1f2e5e">
        Ana Sözleşme, Ticaret Sicil Belgesi ve Bağımsız Denetçi Bilgileri
      </Header>

      <StyledPaper elevation={3} sx={{ marginBottom: "2rem" }}>
        <Typography
          sx={{
            borderBottom: "1px solid #19467d",

            color: "#1f2e5e",
            fontSize: "2rem",
            fontWeight: "bold",
            marginBottom: "2rem",
          }}
        >
          Ana Sözleşme ve Ticaret Sicil Belgesi
        </Typography>
        <ContentLink
          href="https://www.kardemir.com/dosyalar/yatirimci/46/21042021/2021042108563523_yatirimci_46_21042021.pdf?v=31a3b273_b9bb_e1aa_856d_eccb73cae078"
          target="_blank"
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#1f2e5e",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#1f2e5e",
              },
            }}
            fullWidth
          >
            Kardemir Ana Sözleşmesi'ne buradan ulaşabilirsiniz.
          </Button>
        </ContentLink>

        <ContentLink
          href="https://www.kardemir.com/dosyalar/sayfalar/47/05102018/2018100509205379_sayfalar_47_05102018.pdf?v=ae3351cd_47b8_0910_c03b_df89e91e4798"
          target="_blank"
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#1f2e5e",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#1f2e5e",
              },
            }}
            fullWidth
          >
            Ticaret Sicil Belgesi için tıklayın.
          </Button>
        </ContentLink>
      </StyledPaper>

      <StyledPaper elevation={3}>
        <Typography
          sx={{
            borderBottom: "1px solid #19467d",

            color: "#1f2e5e",
            fontSize: "2rem",
            fontWeight: "bold",
            marginBottom: "2rem",
          }}
        >
          Bağımsız Denetçi Bilgileri
        </Typography>

        <BoldText variant="h6">Ünvan:</BoldText>
        <Typography variant="body1" gutterBottom>
          DRT Bağımsız Denetim ve SMMM A.Ş.
        </Typography>
        <BoldText variant="h6">Adres:</BoldText>

        <Typography variant="body1" gutterBottom>
          Armada İş Merkezi A Blok Kat: 7 No: 8 06510 Söğütözü /Ankara
        </Typography>
        <BoldText variant="h6">Telefon:</BoldText>

        <Typography variant="body1" gutterBottom>
          00 90 312 295 47 00
        </Typography>
      </StyledPaper>
    </Box>
  );
}

export default AnaSozlesmeContents;
