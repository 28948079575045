import { useState } from "react";
import SolYapiMedya from "./SolYapiMedya";

const Galeri = () => {
  const [page, setPage] = useState("Galeri");

  return (
    <>
      <SolYapiMedya setPage={setPage} page={page} />{" "}
    </>
  );
};

export default Galeri;
