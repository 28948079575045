import React, { useEffect, useState } from "react";
import image from "../../images/kariyerBanner.jpg";
import {
  Box,
  Breadcrumbs,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import NedenKardemirContents from "./NedenKardemirContents";
import IsBasvuruFormuContents from "./IsBasvuruFormuContents";
import PersonelHaklariContents from "./PersonelHaklariContents";
import KurumsalKimlikContents from "./KurumsalKimlikContents";
import SikcaSorulanSorularContents from "./SikcaSorulanSorularContents";

function SolYapiKariyer({ page, setPage }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [currentContent, setCurrentContent] = useState();
  const navigate = useNavigate();

  const handleItemClick = (pageName, content) => {
    setPage(pageName);
    setCurrentContent(content);

    if (pageName === "Neden Kardemir?") {
      navigate("/kariyer");
    } else if (pageName === "İş Başvuru Formu") {
      navigate("/is-basvuru-formu");
    } else if (pageName === "Personel Hakları") {
      navigate("/personel-haklari");
    } else if (pageName === "Kurumsal Kimlik") {
      navigate("/kurumsal-kimlik");
    } else if (pageName === "Sıkça Sorulan Sorular") {
      navigate("/sikca-sorulan-sorular");
    }
  };

  return (
    <>
      <Box
        component="img"
        alt=""
        src={image}
        style={{
          marginTop: "130px",
          width: "100%",
          height: "30rem",
          objectFit: "cover",
          objectPosition: "bottom",
          // cursor: "pointer",
        }}
      />

      <Box
        sx={{
          borderBottom: "1px solid lightgray",
          height: "5rem",
          display: "flex",
          alignItems: "center",
          paddingLeft: "1rem",
          justifyContent: "center",
        }}
      >
        {" "}
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link
            href="/"
            style={{
              color: "gray",
              padding: "8px",
              textDecoration: "none",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            Ana Sayfa
          </Link>
          <Link
            href=""
            style={{
              color: "gray",
              padding: "8px",
              textDecoration: "none",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            Kariyer
          </Link>
          <Link
            href=""
            style={{
              color: "black",
              padding: "8px",
              textDecoration: "none",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            {page}
          </Link>
        </Breadcrumbs>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: "space-between",
          padding: { xs: "1rem", lg: "1rem 0 1rem 1rem" },
        }}
      >
        <Box
          sx={{
            borderRight: { xs: "none", lg: "1px solid gray" },

            marginBottom: "2rem",
            paddingRight: { xs: "0rem", lg: "2rem" },
          }}
        >
          <Paper
            sx={{
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              padding: "2rem",
              width: { xs: "auto", lg: "80%" },
            }}
          >
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "1.5rem",
                textDecoration: "underline",
              }}
            >
              Kariyer
            </Typography>

            <List>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleItemClick("Neden Kardemir?")}
                >
                  <ListItemText primary="Neden Kardemir?" />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleItemClick("İş Başvuru Formu")}
                >
                  <ListItemText primary="İş Başvuru Formu" />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleItemClick("Personel Hakları")}
                >
                  <ListItemText primary="Personel Hakları" />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleItemClick("Kurumsal Kimlik")}
                >
                  <ListItemText primary="Kurumsal Kimlik" />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleItemClick("Sıkça Sorulan Sorular")}
                >
                  <ListItemText primary="Sıkça Sorulan Sorular" />
                </ListItemButton>
              </ListItem>
            </List>
          </Paper>
        </Box>

        <Box
          sx={{
            marginLeft: { xs: "0", lg: "2rem" },
            flex: 1,
          }}
        >
          {page === "Neden Kardemir?" && (
            <>
              {/* <Typography variant="h4">Neden Kardemir?</Typography> */}
              <NedenKardemirContents content={currentContent} />
            </>
          )}

          {page === "İş Başvuru Formu" && (
            <>
              {/* <Typography variant="h4">İş Başvuru Formu</Typography> */}
              <IsBasvuruFormuContents content={currentContent} />
            </>
          )}

          {page === "Personel Hakları" && (
            <PersonelHaklariContents content={currentContent} />
          )}

          {page === "Kurumsal Kimlik" && (
            <KurumsalKimlikContents content={currentContent} />
          )}

          {page === "Sıkça Sorulan Sorular" && (
            <SikcaSorulanSorularContents content={currentContent} />
          )}
        </Box>
      </Box>
    </>
  );
}

export default SolYapiKariyer;
