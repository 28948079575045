import { Box } from "@mui/material";
import React from "react";
import TedarikciIslemleri from "./tedarikciPages/TedarikciIslemleri";
import HurdaAlim from "./tedarikciPages/HurdaAlim";
import HurdaAlimFiyat from "./tedarikciPages/HurdaAlimFiyat";

function TedarikciIliskileri({ collapse }) {
  return (
    <Box>
      {collapse === "Tedarikçi İşlemleri" ? (
        <TedarikciIslemleri />
      ) : collapse === "Hurda Alım Şartnamesi" ? (
        <HurdaAlim />
      ) : collapse === "Hurda Alım Fiyatları" ? (
        <HurdaAlimFiyat />
      ) : null}
    </Box>
  );
}

export default TedarikciIliskileri;
