import React, { useEffect, useState } from "react";
import image from "../../images/kurumsalBanner.jpg";
import {
  Box,
  Breadcrumbs,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import KilometreTaslariContext from "./KilometreTaslariContext";
import HakkımızdaContents from "./HakkımızdaContents";
import UstYonetimContents from "./UstYonetimContents";
import YonetimKuruluContents from "./YonetimKuruluContents";
import VizyonMisyonContents from "./VizyonMisyonContents";
// import OrtaklikYapisiContents from "./OrtaklikYapisiContents";
import KilometreTaslari1Context from "./KilometreTaslari1Context";
import KilometreTaslari2Context from "./KilometreTaslari2Context";
import KilometreTaslari3Context from "./KilometreTaslari3Context";

function SolYapiKurumsal({ page, setPage }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [currentContent, setCurrentContent] = useState();
  const navigate = useNavigate();

  const handleItemClick = (pageName, content) => {
    setPage(pageName);
    setCurrentContent(content);

    if (pageName === "Hakkımızda") {
      navigate("/hakkımızda");
    } else if (pageName === "Kilometre Taşları") {
      navigate("/kilometre-taslari");
    } else if (pageName === "Kilometre Taşları-1") {
      navigate("/kilometre-taslari-1");
    } else if (pageName === "Kilometre Taşları-2") {
      navigate("/kilometre-taslari-2");
    } else if (pageName === "Kilometre Taşları-3") {
      navigate("/kilometre-taslari-3");
    } else if (pageName === "Üst Yönetim") {
      navigate("/ust-yonetim");
    } else if (pageName === "Yönetim Kurulu") {
      navigate("/yonetim-kurulu");
    } else if (pageName === "Vizyon ve Misyon") {
      navigate("/vizyon-misyon");
    }
    // else if (pageName === "Ortaklık Yapısı") {
    //   navigate("/ortaklik-yapisi");
    // }
  };
  return (
    <>
      <Box
        component="img"
        alt=""
        src={image}
        style={{
          marginTop: "130px",
          width: "100%",
          height: "30rem",
          objectFit: "cover",
          // objectPosition: "bottom",
        }}
      />

      <Box
        sx={{
          borderBottom: "1px solid lightgray",
          height: "5rem",
          display: "flex",
          alignItems: "center",
          paddingLeft: "1rem",
          justifyContent: "center",
        }}
      >
        {" "}
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link
            href="/"
            style={{
              color: "gray",
              padding: "8px",
              textDecoration: "none",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            Ana Sayfa
          </Link>
          <Link
            href=""
            style={{
              color: "gray",
              padding: "8px",
              textDecoration: "none",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            Kurumsal
          </Link>
          <Link
            href=""
            style={{
              color: "black",
              padding: "8px",
              textDecoration: "none",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            {page}
          </Link>
        </Breadcrumbs>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: "space-between",
          padding: { xs: "1rem", lg: "1rem 0 1rem 1rem" },
        }}
      >
        <Box
          sx={{
            borderRight: { xs: "none", lg: "1px solid gray" },
            marginBottom: "2rem",
            paddingRight: { xs: "0rem", lg: "2rem" },
          }}
        >
          <Paper
            sx={{
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              padding: "2rem",
              width: { xs: "auto", lg: "80%" },
            }}
          >
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "1.5rem",
                textDecoration: "underline",
              }}
            >
              Kurumsal
            </Typography>

            <List>
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleItemClick("Hakkımızda")}>
                  <ListItemText primary="Hakkımızda" />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleItemClick("Kilometre Taşları")}
                >
                  <ListItemText primary="Kilometre Taşları" />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleItemClick("Kilometre Taşları 1")}
                >
                  <ListItemText primary="Kilometre Taşları 1" />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleItemClick("Kilometre Taşları 2")}
                >
                  <ListItemText primary="Kilometre Taşları 2" />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleItemClick("Kilometre Taşları 3")}
                >
                  <ListItemText primary="Kilometre Taşları 3" />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleItemClick("Yönetim Kurulu")}
                >
                  <ListItemText primary="Yönetim Kurulu" />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton onClick={() => handleItemClick("Üst Yönetim")}>
                  <ListItemText primary="Üst Yönetim" />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleItemClick("Vizyon ve Misyon")}
                >
                  <ListItemText primary="Vizyon ve Misyon" />
                </ListItemButton>
              </ListItem>

              {/* <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleItemClick("Ortaklık Yapısı")}
                >
                  <ListItemText primary="Ortaklık Yapısı" />
                </ListItemButton>
              </ListItem> */}
            </List>
          </Paper>
        </Box>

        <Box
          sx={{
            marginLeft: { xs: "0", lg: "2rem" },
            flex: 1,
          }}
        >
          {page === "Hakkımızda" && (
            <>
              <Typography variant="h4"></Typography>
              <HakkımızdaContents content={currentContent} />
            </>
          )}

          {page === "Kilometre Taşları" && (
            <>
              {/* <Typography variant="h4">Kilometre Taşlarımız</Typography> */}
              <KilometreTaslariContext content={currentContent} />
            </>
          )}

          {page === "Kilometre Taşları 1" && (
            <>
              {/* <Typography variant="h4">Kilometre Taşlarımız</Typography> */}
              <KilometreTaslari1Context content={currentContent} />
            </>
          )}

          {page === "Kilometre Taşları 2" && (
            <>
              {/* <Typography variant="h4">Kilometre Taşlarımız</Typography> */}
              <KilometreTaslari2Context content={currentContent} />
            </>
          )}

          {page === "Kilometre Taşları 3" && (
            <>
              {/* <Typography variant="h4">Kilometre Taşlarımız</Typography> */}
              <KilometreTaslari3Context content={currentContent} />
            </>
          )}

          {page === "Üst Yönetim" && (
            <>
              <UstYonetimContents content={currentContent} />
            </>
          )}

          {page === "Yönetim Kurulu" && (
            <>
              <YonetimKuruluContents content={currentContent} />
            </>
          )}

          {page === "Vizyon ve Misyon" && (
            <>
              <VizyonMisyonContents content={currentContent} />
            </>
          )}

          {/* {page === "Ortaklık Yapısı" && (
            <>
              <OrtaklikYapisiContents content={currentContent} />
            </>
          )} */}
        </Box>
      </Box>
    </>
  );
}

export default SolYapiKurumsal;
